import { Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useImperativeHandle, useState } from 'react';

import { ComboOption } from '../../types/share/ComboOption';

interface Props
{
    options: ComboOption<string>[],
    onSelect: (value: any) => void;
    valueDefault: any;
}


export interface CustomSelectRef
{
    setData: (options: ComboOption<string>[]) => void;
    setNewValue: (value: any) => void;
}
export const CustomSelect = (props: Props, ref: React.ForwardedRef<CustomSelectRef>) =>
{
    const { valueDefault } = props;
    const [options, setOptions] = useState<ComboOption<string>[]>(props.options);
    const [value, setValue] = useState<any>(valueDefault);
    const onSelect = (value: any) =>
    {
        props.onSelect(value);
        setValue(value);
    };

    useImperativeHandle(ref, () => ({
        setData: (options: ComboOption<string>[]) => setOptions(options),
        setNewValue: (value: any) => setValue(value)
    }), []);
    return <Select onSelect={onSelect}
        className={'w-full'}
        value={value}
        options={options}>
    </Select>;
};

export const NewSelect = React.forwardRef(CustomSelect);