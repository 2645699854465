import '../style/index.scss';

import { HubConnection } from '@microsoft/signalr';
import { debounce } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { Loading } from '../../../components/common/Loading';
import { avartaUser } from '../../../constants/SvgIcon';
import useMergeState from '../../../hooks/useMergeState';
import { Results } from '../../../types/api.type';
import { Conversation, ConversationRead } from '../../../types/Message/Conversation';
import { Message, MessageRead } from '../../../types/Message/MessageChat';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { GET_LIST_MESSAGE, READ_MESSAGE } from '../api/constants.api';
import ChatInput from './ChatInput';

interface IProps
{
    connection: HubConnection | null,
    onReloadConversationList: () => void
}

export type MessageRef = {
    loadData: (conversation: Conversation) => void,
    reciveMessage: (data: Message) => void,
    readMessage: (data: MessageRead[]) => void
    onScrollBottom: () => void
};

interface IState
{
    data: Message[],
    isLoading: boolean,
    fullName: string,
    conversation?: Conversation,
    isLoadingScroll: boolean
}
const NUMBER_GET_MESSAGE = 20;
const MessageConversation = React.forwardRef<MessageRef, IProps>((props, ref) =>
{
    const offset = useRef<number>(0);
    const limit = useRef<number>(NUMBER_GET_MESSAGE);


    const refDiv = useRef<HTMLDivElement>(null);
    const refLoadDiv = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        loadData: loadData,
        reciveMessage: reciveMessage,
        readMessage: readMessage,
        onScrollBottom: scrollToBottom
    }));
    const [state, setState] = useMergeState<IState>({
        data: [],
        isLoading: true,
        fullName: '',
        isLoadingScroll: false
    });

    const callLoad = useRef<boolean>(false);
    useEffect(() =>
    {
        offset.current = 0;
    }, [state.conversation?.id]);


    const readMessage = (data: MessageRead[]) =>
    {
        const dataMessageNew = [...state.data];
        data.forEach(ele =>
        {
            const index = dataMessageNew.findIndex(x => x.id === ele.id);
            if (index > -1)
            {
                dataMessageNew[index].type = ele.type;
            }
        });
        setState({
            data: dataMessageNew
        });
    };

    const reciveMessage = (data: Message) =>
    {
        const dataMessage = [...state.data];

        dataMessage.unshift(data);
        onReloadConversationList && onReloadConversationList();
        if (!data.isDoctor)
        {
            return callReadMessage(dataMessage);

        }
        setState({
            data: dataMessage
        });

    };




    const callReadMessage = debounce((dataMessage: Message[]) =>
    {
        if (state.conversation)
        {
            const conversationRead: ConversationRead = {
                conversationId: state.conversation.conversationId
            };
            return ApiUtil.Axios.post(BASE_API_PATH + READ_MESSAGE, conversationRead, { withCredentials: true }).then(result =>
            {
                if (result.data.success)
                {
                    setState({
                        data: dataMessage
                    });
                }
            });
        }
    }, 500);
    const loadMoreMessage = () =>
    {
        callLoad.current = true;
        offset.current += NUMBER_GET_MESSAGE;
        const { conversation, data } = state;
        try
        {
            ApiUtil.Axios.get<Results<Message>>(BASE_API_PATH + GET_LIST_MESSAGE, { withCredentials: true, params: { limit: limit.current, offset: offset.current, conversationId: conversation?.conversationId } }).then(result =>
            {
                if (result.data.success)
                {
                    callLoad.current = false;
                    const dataClone = [...data];
                    const dataAppend = result.data.results.items.filter(y =>
                    {
                        return dataClone.map(x => x.id).indexOf(y.id) < 0;
                    });
                    const dataSort = [...dataAppend].sort(function (a, b) { return new Date(a.sendDateTime).getTime() - new Date(b.sendDateTime).getTime(); });
                    dataSort.forEach(item =>
                    {
                        dataClone.unshift(item);
                    });
                    setState({
                        data: [...data, ...dataSort],
                        isLoadingScroll: false
                    });
                }

            });
        } catch (error)
        {
            console.log('err');
        }
    };

    const handleScroll = () =>
    {
        const NUMBER_SCROLL = 6;
        if ((refLoadDiv.current?.scrollHeight || 0) / (refLoadDiv.current?.scrollTop || 1) >= NUMBER_SCROLL)
        //(refLoadDiv.current?.scrollTop || 0) <= HEIGHT_MAX_SCROLL && (refLoadDiv.current?.scrollTop || 0) >= 1)
        {
            setState({ isLoadingScroll: true });
            setTimeout(() =>
            {
                if (callLoad.current) return;
                loadMoreMessage();
            });
        }
    };

    const scrollToBottom = () =>
    {
        refDiv.current?.scrollIntoView({ behavior: 'auto' });
    };

    useEffect(() =>
    {
        scrollToBottom();
    }, []);


    useEffect(() =>
    {

        refLoadDiv.current?.addEventListener('scroll', handleScroll);
        return () => refLoadDiv.current?.removeEventListener('scroll', handleScroll);

    }, [JSON.stringify(state.conversation), JSON.stringify(state.data)]);



    const loadData = (conversation: Conversation) =>
    {
        offset.current = 0;
        ApiUtil.Axios.get<Results<Message>>(BASE_API_PATH + GET_LIST_MESSAGE, { withCredentials: true, params: { limit: limit.current, offset: offset.current, conversationId: conversation.conversationId } }).then(result =>
        {
            if (result.data.success)
                setState({
                    data: result.data.results.items,
                    isLoading: false,
                    fullName: conversation.userChatPatient.fullName,
                    conversation: conversation
                });
            setTimeout(() =>
            {
                scrollToBottom();
            });
        });
    };
    const renderUserLeft = (message: Message) =>
    {
        return <li className="flex justify-start" key={message.id}>
            <div className="relative max-w-xl px-4 py-2 text-gray-700 rounded shadow" style={{ backgroundColor: '#E8EAEE', borderRadius: '7px' }}>
                <span className="block">{message.textMessage}</span>
            </div>
        </li>;
    };

    const renderUserRight = (data: Message) =>
    {
        return <li className="flex justify-end" key={data.id}>
            <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow" style={{ backgroundColor: '#2C999C', borderRadius: '7px' }}>
                <span className="block" style={{ color: 'white' }}>{data.textMessage}</span>
            </div>
        </li>;
    };
    // const renderUserLeft = (message: Message) =>
    // {
    //     return <div className="flex justify-start">

    //         <div className="relative max-w-xl px-4 py-2 text-gray-700 rounded shadow" style={{ backgroundColor: '#E8EAEE', borderRadius: '7px' }}>
    //             <span className="block">{message.textMessage}</span>
    //         </div>
    //     </div>;
    // };

    // const renderUserRight = (data: Message) =>
    // {
    //     return <div className="flex justify-end">

    //         <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow" style={{ backgroundColor: '#2C999C', borderRadius: '7px' }}>
    //             <span className="block" style={{ color: 'white' }}>{data.textMessage}</span>
    //         </div>
    //     </div>;
    // };




    const renderDetailMessage = (data: Message[]) =>
    {
        const dataSort = [...data].sort(function (a, b) { return new Date(a.sendDateTime).getTime() - new Date(b.sendDateTime).getTime(); });
        return <ul className="space-y-2 h-full w-full" style={{ padding: '12px 24px' }}>
            {dataSort?.map(item =>
            {
                if (item.isDoctor)
                {
                    return renderUserRight(item);
                }
                return renderUserLeft(item);
            })}
            <div style={{ float: 'left', clear: 'both', opacity: 0 }}
                ref={refDiv}> {''}
            </div>
        </ul>;
    };
    // const renderDetailMessage = (data: Message[]) =>
    // {
    //     const dataSort = [...data].sort(function (a, b) { return new Date(a.sendDateTime).getTime() - new Date(b.sendDateTime).getTime(); });
    //     return (
    //         <div className="detail-msg space-y-2" style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '12px 24px' }}>
    //             {dataSort?.map(item =>
    //             {
    //                 if (item.isDoctor)
    //                 {
    //                     return renderUserRight(item);
    //                 }
    //                 return renderUserLeft(item);
    //             })}
    //             <div style={{ float: 'left', clear: 'both' }}
    //                 ref={refDiv}>
    //             </div>
    //         </div >
    //     );
    // };


    const renderHeaderChat = () =>
    {
        return (
            <div className="chat-header border-b border-gray-300 ml-4" style={{ height: '60px', flexDirection: 'row', display: 'flex' }}>
                <img className="object-cover w-10 h-10"
                    style={{ borderRadius: '7px' }}
                    src={avartaUser}
                    alt="username" />
                <div className="flex flex-col">
                    <span className="block ml-2 font-bold text-gray-600">{state.fullName}</span>
                    {/* <span className="block ml-2 text-gray-600">Online</span> */}
                </div>
                {/* <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3">
            </span> */}
            </div>
        );
    };

    // const renderHeaderChat = () =>
    // {
    //     return <div className="relative flex items-center p-3 border-b border-gray-300" style={{ height: '50px' }}>
    //         <img className="object-cover w-10 h-10"
    //             style={{ borderRadius: '7px' }}
    //             src={avartaUser}
    //             alt="username" />
    //         <div className="flex flex-col">
    //             <span className="block ml-2 font-bold text-gray-600">{state.fullName}</span>
    //             {/* <span className="block ml-2 text-gray-600">Online</span> */}
    //         </div>
    //         {/* <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3">
    //         </span> */}
    //     </div>;
    // };

    const onReloadConversationList = () =>
    {
        props.onReloadConversationList && props.onReloadConversationList();
    };


    if (state.isLoading) return <div></div>;
    return (
        <div className="conversation-detail-block" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {renderHeaderChat()}
            <div
                id='message-list'
                className='flex flex-column flex-1'
                style={{ overflow: 'auto' }}
                ref={refLoadDiv} >
                {state.isLoadingScroll && <div className='loading-scroll'
                >
                    <Loading showLabel={false} />
                </div>}
                {renderDetailMessage(state.data)}

            </div>

            <ChatInput dataConversation={state.conversation && state.conversation}
                connection={props.connection}
                onReloadConversationList={onReloadConversationList}
            />
        </div>
    );
    // return (
    //     <div className="h-full hidden lg:col-span-2 lg:block ml-4 message-conversation">
    //         <div className="flex flex-col h-full w-full"
    //         >
    //             {renderHeaderChat()}
    //             <div className="w-full p-4 flex flex-col flex-1 h-full detail-message">
    //                 {state.isLoadingScroll && <div className='loading-scroll'
    //                 >
    //                     <Loading showLabel={false} />
    //                 </div>}
    //                 <div className="w-full p-4 flex flex-col flex-1 h-full overflow-y-auto"
    //                     ref={refLoadDiv}
    //                 >
    //                     {renderDetailMessage(state.data)}
    //                     <div style={{ float: 'left', clear: 'both' }}
    //                         ref={refDiv}>
    //                     </div>
    //                 </div>
    //             </div>
    //             <ChatInput dataConversation={state.conversation && state.conversation}
    //                 connection={props.connection}
    //                 onReloadConversationList={onReloadConversationList}
    //             />

    //         </div>
    //     </div>
    // );

});
MessageConversation.displayName = 'MessageConversation';
export default MessageConversation;