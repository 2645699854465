import './style/style.scss';

import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { ApiResponse } from '../../types/api.type';
import { BeingVital } from '../../types/VitalSign/BeingVital';
import { EndVitalSignBody } from '../../types/VitalSign/EndVitalSignBody';
import { VitalMeasure } from '../../types/VitalSign/VitalMeasure';
import { ApiUtil, BASE_API_PATH } from '../../utils/ApiUtil';
import { cancelVital, endVital, getBeingVitalByUser } from './api/constants';

interface IState
{
    vitalSignMeasure?: VitalMeasure[];
    state: object;
    setState: any;
    reloadVitalRecord: any;
}

function MeasuringVitalSign(props: IState)
{
    const { vitalSignMeasure = [], setState, state, reloadVitalRecord } = props;
    const initialVital: VitalMeasure[] = [
        {
            'vitalName': 'Height',
            'vitalValue': 0,
            'vitalText': 'Chiều cao',
            'vitalUnit': 'cm'
        },
        {
            'vitalName': 'Weight',
            'vitalValue': 0,
            'vitalText': 'Cân nặng',
            'vitalUnit': 'kg'
        },
        {
            'vitalName': 'Temp',
            'vitalValue': 0,
            'vitalText': 'Nhiệt độ',
            'vitalUnit': '°C'
        },
        {
            'vitalName': 'PressureHigh',
            'vitalValue': 0,
            'vitalText': 'Huyết áp cao',
            'vitalUnit': 'Hg'
        },
        {
            'vitalName': 'PressureLow',
            'vitalValue': 0,
            'vitalText': 'Huyết áp thấp',
            'vitalUnit': 'Hg'
        },
        {
            'vitalName': 'Beat',
            'vitalValue': 0,
            'vitalText': 'Nhịp tim',
            'vitalUnit': 'lần/phút'
        }
    ];
    const [patient, setPatient] = useState<BeingVital | null>();
    const [finalVitals, setFinalVitals] = useState<VitalMeasure[]>(initialVital);
    const [vitalSignMeasureId, setVitalSignMeasureId] = useState<number>(0);

    useEffect(() =>
    {
        setFinalVitals(prevVitals =>
        {
            const updatedVitals = [...prevVitals];
            vitalSignMeasure.forEach(newVital =>
            {
                const index = updatedVitals.findIndex(
                    oldVital => oldVital.vitalName === newVital.vitalName
                );
                if (index !== -1)
                {
                    updatedVitals[index] = { ...updatedVitals[index], vitalValue: newVital.vitalValue / 100 };
                } else
                {
                    updatedVitals.push({ ...newVital, vitalValue: newVital.vitalValue / 100 });
                }
            });
            return updatedVitals;
        });
    }, [vitalSignMeasure]);

    const handleInputChange = (index: number, newValue: number) =>
    {
        const updatedVitals = [...finalVitals];
        updatedVitals[index] = {
            ...updatedVitals[index],
            vitalValue: newValue
        };
        setFinalVitals(updatedVitals);
    };

    const loadInfoBeingVital = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + getBeingVitalByUser, { withCredentials: true });
        if (response.data.results)
        {
            setPatient(response.data.results);
            setVitalSignMeasureId(response.data.results.vitalSignMeasureId);
        } else
        {
            setState({ ...state, isMeasuring: false });
        }
    };

    const endVitalSign = async (body: EndVitalSignBody) =>
    {
        const response = await AxiosClient.post<ApiResponse<any>>(BASE_API_PATH + endVital, body, { withCredentials: true });
        if (response.data.success)
        {
            setState({ ...state, isMeasuring: false });
            setFinalVitals(initialVital);
            reloadVitalRecord();
        }
    };

    const cancelVitalSign = async (id: number) =>
    {
        const response = await AxiosClient.post<ApiResponse<any>>(BASE_API_PATH + `${ cancelVital }/${ id }`, { withCredentials: true });
        if (response.data.success)
        {
            setState({ ...state, isMeasuring: false });
            setFinalVitals(initialVital);
        }
    };

    const reMeasure = () =>
    {
        setFinalVitals(initialVital);
    };

    useEffect(() =>
    {
        loadInfoBeingVital();
    }, []);
    const renderVitalSign = ({ vitalName, vitalText, vitalUnit, vitalValue }: VitalMeasure, index: number) => <div key={vitalName} className='flex items-center justify-between'>
        <div className='text-2xl'>{vitalText} {vitalUnit ? `(${ vitalUnit })` : ''} </div>
        <div className='w-32 flex'>
            <Input
                type='number'
                step='any'
                className='text-center w-12 h-8 big-input'
                value={vitalValue}
                onChange={(event) =>
                    handleInputChange(index, Number(event.target.value))
                } />
        </div>
    </div>;

    return (
        <div className='w-full p-4'>
            <div className='w-full grid grid-cols-4 gap-2' style={{ backgroundColor: '#F3F5F9', borderRadius: '8px', padding: '12px', height: '140px' }}>
                <div>
                    <span className='font-bold'>1. Họ và tên: </span> <span> {patient?.fullName} </span>
                </div>
                <div>
                    <span className='font-bold'>2. Năm sinh: </span> <span> {patient?.yearOfBirth} </span>
                </div>
                <div>
                    <span className='font-bold'>3. Giới tính: </span> <span> {patient?.gender} </span>
                </div>
                <div>
                    <span className='font-bold'>4. Số điện thoại: </span> <span> {patient?.phone} </span>
                </div>
                <div>
                    <span className='font-bold'>5. Địa chỉ: </span> <span> {patient?.location} </span>
                </div>
            </div>
            <div className='mx-auto max-w-4xl p-4'>
                <div className='text-xl font-bold text-center mb-4'>ĐO CHỈ SỐ NGƯỜI BỆNH</div>
                <div className='grid grid-cols-2 gap-4'>
                    {finalVitals?.map((item: VitalMeasure, index: number) => renderVitalSign(item, index))}
                </div>
            </div>
            <div className='flex items-center justify-center gap-x-8 mt-4'>
                <ButtonBase buttonName={'HOÀN TẤT ĐO'}
                    buttonType="save"
                    onClick={async () =>
                    {
                        if (finalVitals.length === 0)
                        {
                            ApiUtil.ToastError('Vui lòng đo chỉ số');
                        }
                        else
                        {
                            const results = finalVitals.map((item: VitalMeasure) => ({
                                ...item, vitalValue: Math.round(item.vitalValue * 100)
                            }));
                            await endVitalSign({
                                vitalSignMeasureId, results
                            });
                        }
                    }}
                />
                <ButtonBase buttonName={'HUỶ ĐO'}
                    backgroudColorCustom='#E78429'
                    buttonType="close"
                    onClick={async () =>
                    {
                        await cancelVitalSign(vitalSignMeasureId);
                    }}
                />
                <ButtonBase buttonName={'ĐO LẠI'}
                    backgroudColorCustom='#E78429'
                    buttonType="close"
                    onClick={() => reMeasure()}
                />
            </div>
        </div>
    );
}

export default MeasuringVitalSign;