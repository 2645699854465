import React, { useEffect, useState } from 'react';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { ApiResponse } from '../../types/api.type';
import { BeingVital } from '../../types/VitalSign/BeingVital';
import { VitalMeasure } from '../../types/VitalSign/VitalMeasure';
import { BASE_API_PATH } from '../../utils/ApiUtil';
import { detailVital } from './api/constants';

interface IState
{
    id?: number;
    onCloseModal?: any;
}
function ModalDetailVital(props: IState)
{
    const { id, onCloseModal } = props;
    const [vitalData, setVitalData] = useState<BeingVital | null>();

    const loadDetailData = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + detailVital + '/' + id, { withCredentials: true });
        if (response.data.results)
        {
            setVitalData(response.data.results);
        }
    };

    useEffect(() =>
    {
        loadDetailData();
    }, [id]);

    const renderResult = (item: VitalMeasure) => <div className='flex gap-x-2'>
        <div className='font-bold'>{item.vitalText}: </div>
        <div>{item.vitalValue / 100}</div>
        <div>{item.vitalUnit ? `(${ item.vitalUnit })` : ''}</div>
    </div>;

    return (
        <div className='p-4'>
            <div className='mb-4'>
                <div>
                    <span className='font-bold'>Họ và tên: </span> <span> {vitalData?.fullName} </span>
                </div>
                <div>
                    <span className='font-bold'>Năm sinh: </span> <span> {vitalData?.yearOfBirth} </span>
                </div>
                <div>
                    <span className='font-bold'>Giới tính: </span> <span> {vitalData?.genderLabel} </span>
                </div>
                <div>
                    <span className='font-bold'>Số điện thoại: </span> <span> {vitalData?.phone} </span>
                </div>
                <div>
                    <span className='font-bold'>Địa chỉ: </span> <span> {vitalData?.location} </span>
                </div>
            </div>
            <hr />
            <div className='my-4'>
                <div>
                    <span className='font-bold'>Thời gian đo: </span> <span> {vitalData?.dateMeasure} </span>
                </div>
                <div className='grid grid-cols-2 gap-2'>
                    {
                        vitalData?.results?.map((item: VitalMeasure) => renderResult(item))
                    }
                </div>
            </div>
            <div className='flex items-center justify-center'>
                <ButtonBase buttonName='ĐÓNG'
                    buttonType='close'
                    onClick={() => onCloseModal()} />
            </div>
        </div>
    );
}

export default ModalDetailVital;