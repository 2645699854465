/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, ReactElement, useImperativeHandle } from 'react';
import { FieldErrorsImpl, FormProvider, useForm } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { Resolver } from 'react-hook-form/dist/types/resolvers';

interface IFormProps
{
    onSubmit: (formValues: any) => void;
    defaultValues?: DefaultValues<any>;
    onKey?: React.KeyboardEventHandler<HTMLFormElement>;
    className?: string;
    resolver?: Resolver<any>;
    style?: CSSProperties
}
export interface BaseFormRef
{
    getValues: () => Record<string, any>;
    setValue: (key: string, value: any) => void;
    setValues: (records: Record<string, any>) => void;
    errors: FieldErrorsImpl<any>;
}

type IProps<T> = IFormProps & { children: ReactElement | ReactElement[] };
type IForwardRef<T> = React.ForwardedRef<BaseFormRef>;

function BaseForm<T>(props: IProps<T>, ref: IForwardRef<T>)
{
    const { defaultValues, children, onSubmit, className, resolver, style, onKey } = props;
    const methods = useForm({ defaultValues, resolver });
    const { handleSubmit, getValues, setValue, reset, formState: { errors } } = methods;

    useImperativeHandle(
        ref,
        () => ({
            getValues,
            setValue,
            setValues: reset,
            errors: errors
        }),
        [getValues, reset, setValue, errors]
    );

    return (
        <FormProvider {...methods}>
            <form className={className}
                onKeyDown={onKey}
                style={style}
                onSubmit={handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
}

export default React.forwardRef(BaseForm);
