import {Form} from 'antd';
import React, {useEffect, useRef, useState} from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, {BaseFormRef} from '../../../components/FormFields/BaseForm';
import ComboBox, {IComboBox} from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import {ApiResponse} from '../../../types/api.type';
import {PartnerCreateDto} from '../../../types/PartnerManagement/PartnerGridDto';
import {BASE_API_PATH} from '../../../utils/ApiUtil';
import {comboProvince} from '../../Infomation/api/constants';

interface IState {
    handleSubmit: (value: PartnerCreateDto) => Promise<void>,
    defaultValue?: PartnerCreateDto,
    onClose: () => void
    isEdit?: boolean
}

function PartnerCreateForm(props: IState) {
    const {handleSubmit, isEdit, defaultValue = {}, onClose} = props;

    const [provinceList, setProvinceList] = useState<IComboBox[]>([]);

    const formRefFilter = useRef<BaseFormRef>(null);

    const onSubmit = (value: PartnerCreateDto) => {
        const body = {
            ...value,
            provinceId: Number(value.provinceId),
            userName: value.phone,
            email: '',
        };
        if (!isEdit) {
            body.status = true;
        }
        handleSubmit(body);
    };

    const loadProvince = async ( ) => {
        const response = await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince);
        if (response.data.success)
        {
            setProvinceList(response.data.results as unknown as IComboBox[]);
        }
    };


    useEffect(() => {
        if (isEdit && formRefFilter) {
            formRefFilter?.current?.setValues({...defaultValue});
        }
        loadProvince();
    }, []);

    return (
        <div className='p-4'>
            <BaseForm ref={formRefFilter}
                onSubmit={onSubmit}
                style={{ width: '100%' }}
                defaultValues={{}} >

                <Form.Item>
                    <TextField
                        name={'fullName'}
                        label={'Họ và tên'} />
                </Form.Item>
                <Form.Item>
                    <TextField
                        onKeyPress={(event) => {
                            const allowedKeys = /[0-9]/;
                            if (!allowedKeys.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        disabled={isEdit}
                        name={'phone'}
                        label={'Số điện thoại'} />
                </Form.Item>
                <Form.Item>
                    <ComboBox
                        noUpperCaseLabel={true}
                        isClear={true}
                        comboValue={provinceList}
                        name={'provinceId'}
                        label={'Tỉnh thành'} />
                </Form.Item>
                {/*<Form.Item>*/}
                {/*    <TextAreaField label={'Ghi chú'} name={'note'}/>*/}
                {/*</Form.Item>*/}
                <hr className={'mt-4'}/>
                <div className={'flex justify-end items-center mt-4 pr-4'}>
                    <ButtonBase buttonName={'Hoàn tất'}
                        buttonType={'create'}
                        htmlType={'submit'} />
                </div>
            </BaseForm>
        </div>
    );
}

export default PartnerCreateForm;