import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { UserAccountDoctor } from '../../types/UserAccountDoctor';

//resolver={yupResolver(schema)}
const schema = yup.object().shape({
    password: yup.string()
        .required('Vui lòng nhập mật khẩu!')
        .min(8, 'Mật khẩu tối thiểu 8 kí tự.')
        .matches(/[a-zA-Z0-9]/, 'Nhập mật khẩu đúng định dạng. Mật khẩu bao gồm chữ cái hoặc số')
});

interface IFormAccountSettingProps
{
    handleSubmit: (value: UserAccountDoctor) => Promise<void>;
    onClose: () => void
    defaultvalue?: UserAccountDoctor

}

const UserAccountSettingEditPassword: FunctionComponent<IFormAccountSettingProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, defaultvalue } = props;
    const onSubmit = async (value: UserAccountDoctor) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    return ( //defaultValues={{check:'a'}}
        <BaseForm ref={formRef}
            className="p-3"
            defaultValues={defaultvalue}
            resolver={yupResolver(schema)}
            onSubmit={(value) => { onSubmit(value); }} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField disabled={true}
                            name={'fullName'}
                            label={'Họ và tên'} />
                    </Form.Item>
                </Col>

            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField disabled={true}
                            name={'userName'}
                            label={'Tên đăng nhập *'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField type='password'
                            name={'password'}
                            label={'Mật khẩu mới *'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />
        </BaseForm>
    );
};

export default UserAccountSettingEditPassword; 
