import { HubConnection } from '@microsoft/signalr';
import { Form } from 'antd';
import { get, isEmpty, isNull, isUndefined } from 'lodash';
import { useRef } from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import TextField from '../../../components/FormFields/TextField';
import { pathMessageChat } from '../../../constants/SvgIcon';
import { Conversation } from '../../../types/Message/Conversation';
import { MessageNew } from '../../../types/Message/MessageChat';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { CREATE_NEW_MESSAGE } from '../api/constants.api';

interface IProps
{
    dataConversation?: Conversation,
    connection: HubConnection | null,
    onReloadConversationList: () => void
}

const ChatInput = (props: IProps) =>
{
    const formRef = useRef<BaseFormRef>(null);

    const { connection } = props;
    const { dataConversation, onReloadConversationList } = props;

    const onSubmit = (value: any) =>
    {
        const message = get(value, 'message');
        if (!isNull(message) && !isEmpty(message) && !isUndefined(message))
        {
            formRef.current?.setValue('message', '');
            const newMessage: MessageNew = {
                conversationId: props.dataConversation?.conversationId,
                textMessage: message
            };
            ApiUtil.Axios.post(BASE_API_PATH + CREATE_NEW_MESSAGE, newMessage, { withCredentials: true }).then(result =>
            {
                if (result.data.success)
                {
                    connection?.invoke('SendNewMessage', dataConversation?.roomName);
                }

            });
        }
    };

    return (<div className="mt-2 flex items-center justify-between w-full border-t border-gray-300" style={{ height: '83px' }}>

        <BaseForm
            onSubmit={(value) => { onSubmit(value); }}
            defaultValues={{}}
            ref={formRef}
            className="w-full"
        >
            <div className='flex items-center justify-between w-full w-full'>
                <div style={{ width: '100%' }}>
                    <TextField
                        style={{ width: '100%', borderRadius: '7px', height: '50px', backgroundColor: '#E9F1F1' }}
                        label="tin nhắn"
                        noShowLabel={true}
                        labelClass={'py-2 pl-4 mx-3 bg-gray-100 rounded-full focus:text-gray-700'}
                        name={'message'} />
                </div>
                <button >
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width="44"
                        height="44"
                        fill="none"><rect width="44"
                            height="44"
                            fill="#2C999C"
                            rx="4" /><path fill="#fff" d={pathMessageChat} /></svg>
                </button>

            </div>

            {/* <button>
                <svg xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </button>
            <button type="submit">
                <svg className="w-5 h-5 text-gray-500 origin-center transform rotate-90"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                        d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                </svg>
            </button> */}
        </BaseForm>
    </div>);

};

export default ChatInput;