export const updateMedicalExamination = '/MedicalExamination/update-custom';
export const deleteMedicalExamination = '/MedicalExamination/delete-custom/';
export const getInfomationWaiting = '/MedicalExamination/get-info-patient-waiting/';

export const indexMedicalExaminationPatientRecord = '/MedicalExamination/get-index-patient-record';
export const startExam = '/MedicalExamination/start-exam';
export const startSession = '/MedicalSession/start-session';
export const endExam = '/MedicalExamination/end-exam';
export const updateFlow = '/MedicalSession/update-flow';

export const loadInfoExam = '/MedicalExamination/get-info-being-exam';
export const getInfoBeingSession = '/MedicalSession/get-info-being-session';
export const loadInfoPatientFileExam = '/MedicalExamination/get-info-patient-file-exam';
export const GET_MEDICINE_GROUP = '/MedicineGroup/medicine-group';
export const GET_MEDICINE_NOTE_USE = '/MedicineGroup/medicine-note-use';

export const loadImageFileBeingExam = '/ImageFileBeingExam/load-image-being-process';
export const loadImageFileBeingExamV2 = '/ImageFileBeingExam/load-image-being-process-v2';

export const loadDiseaseAnalysisList = '/DiseaseAnalysis/load-disease-process';

//
export const loadExamResult = '/MedicalExamination/get-exam-result/';
export const loadMedicineResult = '/MedicalExamination/get-medical-result/';
//QR view file
export const loadExamResultByToken = '/MedicalExamination/get-result-by-token';
export const loadMedicineResultByToken = '/MedicalExamination/get-medical-result-by-token';
//
export const cancleExam = '/ExamBeingCancleSetting/cancle-exam/';

export const queueItemList = '/DesignationQueueItem/get-index/';
export const queueItemDelete = '/DesignationQueueItem/delete-custom/';
export const queueItemAdd = '/DesignationQueueItem/create-custom';
export const sessionCancel = '/MedicalSession/cancel-session/';
export const getListSession = '/MedicalSession/get-list';