import { yupResolver } from '@hookform/resolvers/yup';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Button, Col, DatePicker, Divider, Form, Input, Popconfirm, Row, Select, Space } from 'antd';
import moment from 'moment';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import GridButtonBase from '../../components/ButtonBase/GridButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import NumberField from '../../components/FormFields/NumberField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { CANCEL, CONFIRM_TO_DELETE, SUCCESS } from '../../constants/MessageConstants';
import { ApiResponse } from '../../types/api.type';
import { MedicineDetail } from '../../types/DiseaseAnalysisDetail/DiseaseAnalysisDetail';
import { MedicineFormCreate } from '../../types/Medicine/Medicine';
import { MedicineDetailSetting } from '../../types/Medicine/MedicineSetting';
import { ApiUtil, BASE_API_PATH } from '../../utils/ApiUtil';
import { getListUnit } from './api/constants';
import MedicineCreateHeader from './MedicineCreateHeader';


const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
    medicineCode: yup.string().required('Vui lòng nhập thông tin!')
});


interface IFormMedicineFormProps
{
    handleSubmit: (value: MedicineFormCreate) => Promise<void>,
    onClose: () => void,
    defaultvalue?: MedicineFormCreate,
    isCreate?: boolean,
}

interface Unit
{
    id: number;
    text: string;
}

interface SelectedUnit 
{
    unitId: number | null;
    unit: {
        text: string;
    },
}

const MedicineCreateForm: FunctionComponent<IFormMedicineFormProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, defaultvalue } = props;
    const gridOptions: GridOptions<MedicineDetail> = {};


    const onSubmit = async (value: MedicineFormCreate) =>
    {
        gridOptions.api?.stopEditing();
        const medicineDetail = gridOptions.rowData as MedicineDetail[];
        if (!medicineDetail || medicineDetail.length === 0) return ApiUtil.ToastError('Vui lòng có ít nhất một cách dùng!');
        const isCheckEmpty = onCheckValueGrid(medicineDetail);
        if (isCheckEmpty) return ApiUtil.ToastError('Vui lòng nhập đầy đủ dữ liệu trên bảng cách dùng!');
        const params: MedicineFormCreate = {
            ...value,
            medicineNoteUseList: medicineDetail,
            importPrice: Number(value.importPrice),
            price: Number(value.price),
        };
        overlayRef.current?.open();
        await handleSubmit(params);
        overlayRef.current?.close();
    };


    const onCheckValueGrid = (value: MedicineDetail[]): boolean =>
    {
        console.log('value: ', value);
        let dataEmpty = false;
        const dataEmptyList = value.filter(x => !x.note === true) as MedicineDetail[];
        if (dataEmptyList.length > 0)
            dataEmpty = true;
        return dataEmpty;
    };
    const getColumnConfig = (): ColDef[] =>
    {

        return [
            ...MedicineDetailSetting,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<MedicineDetail>) =>
                {
                    const rowIndex = params.rowIndex;
                    // const data =params.data as DiseaseAnalysisDetail;
                    return (
                        <div className="flex items-center justify-center">
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                        onRemove(rowIndex);
                                    }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };

    const onRemove = (rowIndex: number) =>
    {
        gridOptions.api?.stopEditing();
        gridOptions.rowData?.splice(rowIndex, 1);
        gridOptions.api?.setRowData(gridOptions.rowData as MedicineDetail[]);
    };

    const onCreate = () =>
    {
        gridOptions.api?.stopEditing();
        gridOptions.rowData?.unshift({});
        const rowData = gridOptions.rowData as MedicineDetail[];
        gridOptions.api?.setRowData(rowData);
        setTimeout(() =>
        {
            gridOptions.api?.startEditingCell({ rowIndex: 0, colKey: 'note' });
        }, 300);
    };

    const onGridReady = () =>
    {
        if (props.isCreate ?? false)
        {
            onCreate();
        } else
        {
            gridOptions.api?.startEditingCell({ rowIndex: 0, colKey: 'note' });
        }
    };

    const RenderForm = () =>
    {
        const [optionsUnit, setOptionUnit] = useState<any>([]);
        const [newUnitName, setNewUnitName] = useState<string>('');
        const [selectedUnit, setSelectedUnit] = useState<SelectedUnit>();

        const updateListUnit = async () =>
        {
            const response = await AxiosClient.get<ApiResponse<Unit[]>>(BASE_API_PATH + getListUnit, { withCredentials: true });
            if (response.data.success)
            {
                const listUnit = response.data.results?.map((item: Unit) => ({
                    label: item.text, value: item.id
                }));
                setOptionUnit(listUnit);
            }
        };

        useEffect(() =>
        {
            updateListUnit();
        }, []);

        const onChangeSelect = (value: number, option: any) =>
        {
            const dataForm = formRef?.current?.getValues();
            formRef?.current?.setValues({
                ...dataForm,
                unitId: value,
                unit: {
                    text: option.label
                }
            });
            setSelectedUnit({
                unitId: value,
                unit: {
                    text: option.label
                }
            });
        };

        const addItem = (e: any) =>
        {
            e.preventDefault();
            setOptionUnit([...optionsUnit, { label: newUnitName, value: null }]);
            const dataForm = formRef?.current?.getValues();
            formRef?.current?.setValues({
                ...dataForm,
                unitId: null,
                unit: {
                    text: newUnitName
                }
            });
            setSelectedUnit({
                unitId: null,
                unit: {
                    text: newUnitName
                }
            });
        }; return <div>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'name'}
                            label={'Tên thuốc'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'medicineCode'}
                            label={'Mã thuốc'} />
                    </Form.Item>
                </Col>
                <Col span={16} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'activeIngredients'}
                            label={'Hoạt chất'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={6} className="flex-1">
                    <Form.Item>
                        <div className='px-3'>
                            <label
                                className={'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'}
                            >
                                Đơn vị tính
                            </label>
                            <Select
                                defaultValue={defaultvalue?.unitId ? defaultvalue?.unitId : undefined}
                                value={selectedUnit?.unitId}
                                onChange={onChangeSelect}
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '7px',
                                    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'
                                }}
                                bordered={false}
                                options={optionsUnit}
                                notFoundContent="Không có dữ liệu!"
                                placeholder={'Nhập đơn vị tính'}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0' }} />
                                        <Space style={{ padding: '0 8px 4px' }}>

                                            <Input
                                                placeholder="Nhập đơn vị tính"
                                                onChange={(e) => setNewUnitName(e.target.value)}
                                            />
                                            <Button type="text" onClick={addItem}>
                                                Thêm đơn vị
                                            </Button>
                                        </Space>
                                    </>

                                )} />
                        </div>
                    </Form.Item>
                </Col>
                <Col span={6} className="flex-1">
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            requiredError={true}
                            name={'importPrice'}
                            label={'Giá nhập'} />
                    </Form.Item>
                </Col>
                <Col span={6} className="flex-1">
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            requiredError={true}
                            name={'price'}
                            label={'Giá bán'} />
                    </Form.Item>
                </Col>
                <Col span={6} className="flex-1">
                    <Form.Item>
                        <div className='px-3'>
                            <label
                                className={'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'}
                            >
                                Hạn dùng
                            </label>
                            <DatePicker
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '7px',
                                    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'
                                }}
                                placeholder={'Chọn hạn dùng'}
                                format={'DD/MM/YYYY'}
                                defaultValue={defaultvalue?.expiredTime ? moment(defaultvalue?.expiredTime) : undefined}
                                onChange={(value) =>
                                {
                                    const dataForm = formRef?.current?.getValues();
                                    formRef?.current?.setValues({ ...dataForm, expiredTime: value?.format('YYYY-MM-DD') });

                                }}
                            />
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        </div>;
    };


    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
            resolver={yupResolver(schema)} >
            <RenderForm />
            <div style={{ height: '400px' }}>
                <MedicineCreateHeader onOpenCreate={onCreate} />
                <div className='mt-2' style={{ height: '90%' }}>
                    <BaseGrid
                        gridOptions={gridOptions}
                        className="ag-theme-alpine"
                        rowSelection={'multiple'}
                        rowGroupPanelShow={'always'}
                        pivotPanelShow={'always'}
                        columnDefs={getColumnConfig()}
                        pagination={true}
                        rowData={defaultvalue?.medicineNoteUseList ? defaultvalue?.medicineNoteUseList : []}
                        onGridReady={onGridReady}
                    />
                </div>
            </div>

            {/* <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField name={'noteUse'} label={'Cách dùng'} />
                    </Form.Item>
                </Col>
            </Row> */}

            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />
        </BaseForm >
    );
};
export default MedicineCreateForm;



