import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox from '../../components/FormFields/ComboBox';
import NumberField from '../../components/FormFields/NumberField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { optionGender } from '../../constants/OptionsCombo';
import { pathDateTime } from '../../constants/SvgIcon';
import { phoneRegExp } from '../../constants/YupContants';
import { PatientRecord } from '../../types/PatientRecord/PatientRecord';

const schema = yup.object().shape({
    fullName: yup.string().required('Vui lòng nhập thông tin!'),
    phone: yup.string().required('Vui lòng nhập thông tin!').min(8, 'Số điện thoại lớn hơn 8 số.').max(12, 'Số điện thoại ít hơn 12 số').matches(phoneRegExp, 'Định dạng không hợp lệ'),
    gender: yup.string().max(255),
    location: yup.string().required('Vui lòng nhập thông tin!'),
    reason: yup.string().required('Vui lòng nhập thông tin!').nullable(),
});

interface IPatienCareProps
{
    handleSubmit: (value: PatientRecord) => Promise<void>;
    onClose: () => void,
    defaultvalue: PatientRecord
}


const PatientCareUpdate: FunctionComponent<IPatienCareProps> = (props) =>
{
    const { defaultvalue, handleSubmit } = props;
    console.log('🚀 ~ file: PatientCareUpdate.tsx:38 ~ defaultvalue:', defaultvalue);
    const overlayRef = useRef<OverlayRef>(null);
    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(!defaultvalue.reExam);

    const formRef = useRef<BaseFormRef>(null);
    const onSubmit = async (value: PatientRecord) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    const suffixDateTimeFilter = () =>
    {
        // return <SearchOutlined />;
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
            resolver={yupResolver(schema)} >
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'fullName'}
                            label={'Họ và tên :'} />
                    </Form.Item>
                </Col>

                <Col style={{ width: '50%' }} className="">
                    <Form.Item style={{ marginLeft: '10px', marginRight: '10px' }}
                    >
                        <label className='text-xs mr-2 mb-2 block tracking-wide text-gray-700 font-bold'>Năm sinh</label>
                        <Controller
                            name={'yearOfBirth'}
                            render={({ field }) =>
                            {
                                return <DatePicker
                                    {...field}
                                    placeholder='Chọn năm sinh'
                                    style={{ width: '100%', borderRadius: '7px' }}
                                    allowClear={false}
                                    disabledDate={(current) =>
                                    {
                                        return current && current > moment().endOf('day');
                                    }}
                                    suffixIcon={suffixDateTimeFilter()}
                                    picker="year"
                                />;
                            }
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <NumberField requiredError={true}
                            name={'phone'}
                            label={'Số điện thoại :'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <ComboBox requiredError={true}
                            onChange={() => { }}
                            comboValue={optionGender}
                            name={'gender'}
                            label={'Giới tính :'} />
                    </Form.Item>
                </Col>

            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'location'}
                            label={'Địa chỉ bệnh nhân :'} />
                    </Form.Item>

                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'reason'}
                            label={'Lý do khám :'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex" gutter={24}>
                <Col style={{ width: '50%', marginTop: '-8px' }} className="">
                    <Form.Item
                    >
                        <label style={{ marginLeft: '14px' }} className='text-xs mb-2 block uppercase tracking-wide text-gray-700 font-bold'>Tái khám</label>
                        <Controller
                            name={'reExam'}
                            render={({ field }) =>
                            {
                                return <Checkbox
                                    {...field}
                                    name={'reExam'}
                                    defaultChecked={defaultvalue?.reExam}
                                    className="checkbox-red"
                                    style={{ marginLeft: '14px' }}
                                    onChange={(value) =>
                                    {
                                        const dataForm = formRef.current?.getValues();
                                        formRef.current?.setValues({ ...dataForm, reExam: value.target.checked });
                                        setIsActiveTimeRange(!value.target.checked);
                                    }}
                                >
                                </Checkbox>;
                            }
                            }
                        />
                    </Form.Item>
                </Col>
                <Col style={{ marginTop: '-12px', width: '50%' }} className="">
                    <Form.Item style={{ marginLeft: '10px', marginRight: '10px' }}
                    >
                        <label style={{}} className='text-xs mb-2 block uppercase tracking-wide text-gray-700 font-bold'>Tái khám</label>
                        <Controller
                            name={'reExamDateTime'}
                            render={({ field }) =>
                            {
                                return <DatePicker
                                    {...field}
                                    // value={field.value ?field.value :undefined}
                                    placeholder='Chọn ngày tái khám'
                                    style={{ width: '100%', borderRadius: '7px' }}
                                    disabledDate={(current) =>
                                    {
                                        const datePatient = moment(defaultvalue.datePatient, 'DD/MM/YYYY HH:mm').add(1, 'years');
                                        return current && (current > datePatient || current <= moment().endOf('day'));
                                    }}
                                    format='DD/MM/YYYY'
                                    disabled={isActiveTimeRange}
                                />;
                            }
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>
    );
};

export default PatientCareUpdate; 
