import { Input, InputNumber } from 'antd';
import React, { useImperativeHandle, useState } from 'react';


interface Props
{
    disabled: boolean;
    onChange: (event: any) => void;
    defaultValue: any,
    type?: string
}

export interface CustomInputRef
{
    setDisable: (disabled: boolean) => void;
    setDefaultValue: (value: string) => void
}


export const CustomInput = (props: Props, ref: React.ForwardedRef<CustomInputRef>) =>
{
    const { defaultValue, type } = props;
    const [disabled, setDisabled] = useState<boolean>(props.disabled);
    const [value, setValue] = useState<any>(defaultValue);
    const onSelect = (valueOnchange: any) =>
    {
        props.onChange(valueOnchange);
        setValue(valueOnchange?.target?.value);
    };
    useImperativeHandle(ref, () => ({
        setDisable: (disabled: boolean) => setDisabled(disabled),
        setDefaultValue: (value: string) => setValue(value)
    }), []);
    return <Input style={{ textAlign: 'center' }}
        value={value}
        onChange={onSelect}
        type={type ? type : 'string'}
        defaultValue={1}
        className={'w-full'}
        disabled={disabled}
        onKeyPress={(event) =>
        {
            if (type === 'number' && !/[0-9]/.test(event.key))
            {
                event.preventDefault();
            }
        }}
        min={1} />;
};

export const NewInput = React.forwardRef(CustomInput);