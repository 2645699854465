import { ColDef } from 'ag-grid-community';
import { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { BaseFormRef } from '../../components/FormFields/BaseForm';
import { HistoryDeductColumns, HistoryDeductDto } from '../../types/PaymentClinic/HistoryDeductPayment';


const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
    medicineCode: yup.string().required('Vui lòng nhập thông tin!')

});


interface IFormMedicineFormProps
{
    onClose: () => void,
    defaultvalue?: HistoryDeductDto[]
}



const HistoryDeductPayment: FunctionComponent<IFormMedicineFormProps> = (props) =>
{
    const { onClose, defaultvalue } = props;

    const getColumnConfig = (): ColDef[] =>
    {

        return [
            ...HistoryDeductColumns,
        ];
    };


    return (
        <div>
            <div style={{ height: '500px' }}>
                <div className='mt-2' style={{ height: '100%' }}>
                    <BaseGrid
                        className="ag-theme-alpine"
                        rowSelection={'multiple'}
                        rowGroupPanelShow={'always'}
                        pivotPanelShow={'always'}
                        columnDefs={getColumnConfig()}
                        pagination={true}
                        rowData={defaultvalue ? defaultvalue : []}
                    />
                </div>
            </div>
            <div className="footer flex justify-center items-center mb-2">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'ĐÓNG'}
                    onClick={() => { onClose(); }}
                    buttonType="close"
                />
            </div>
        </div>
    );
};
export default HistoryDeductPayment;



