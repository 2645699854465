import axios from 'axios';
import Cookies from 'js-cookie';

export const Axios = axios;

type NodeEnv = 'development' | 'staging' | 'production' ;
const nodeEnv = process.env.NODE_ENV as NodeEnv;

const baseURL = nodeEnv === 'development' || nodeEnv === undefined
    ? ''
    : process.env.REACT_APP_PUBLIC_API_URL;


const AxiosClient = axios.create({
    baseURL: baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': true
    }
});


AxiosClient.interceptors.request.use(async request =>
{
    return request;
}, async error =>
{

    return Promise.reject(error);
}
);
AxiosClient.interceptors.response.use(async response =>
{
    return response;
}, async error =>
{
    // if (error.response.status === 401)
    // {
    //     const response = await AxiosClient.post('refresh', {}, { withCredentials: true });
    //     if (response.status === 200)
    //     {
    //         AxiosClient.defaults.headers.common['Authorization'] = `Bearer ${ response.data['token'] }`;
    //         return AxiosClient(error.config);
    //     }

    // }
    if (error.response.status === 500)
    {
        Cookies.remove('dev2earn-clinic');
        // ApiUtil.ToastWarning('Phiên đăng nhập của bạn đã hết hạn, vui lòng đăng nhập lại!');
        window.location.reload();
    }
    return Promise.reject(error);
}
);

export default AxiosClient;