import { Spin } from 'antd';
import React from 'react';

interface Iprops
{
    showLabel?: boolean
}

export function Loading(props: Iprops)
{
    const { showLabel = true } = props;
    return (
        <div className="loading" >
            <Spin style={{ color: 'var(--main-color-green-light)' }} tip={showLabel ? 'Đang tải dữ liệu...' : ''}></Spin>
        </div>);
}