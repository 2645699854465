import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, Row } from 'antd';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import { pathDateTime } from '../../../constants/SvgIcon';
import { MedicalExaminationFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';

interface IHeaderProps
{
    onFilter?: () => void;
    onOpenCreate: () => void;
    loadOptionsDistrict: (value: string) => Promise<void>;
    optionsProvince: IComboBox[];
    optionsDistrict: IComboBox[];
    options?: IComboBox[];
    onLoadDataGrid?: () => Promise<void>;
    formRefFilter?: React.RefObject<BaseFormRef>
}



const HeaderFilterClinic = (props: IHeaderProps) =>
{
    const { onLoadDataGrid, formRefFilter, onOpenCreate, optionsProvince, optionsDistrict, loadOptionsDistrict } = props;
    // const formRef = useRef<BaseFormRef>(null);

    const suffixFilter = () =>
    {
        return <SearchOutlined />;
    };




    return (
        <div className="mt-3 justify-between" style={{ height: '90px' }}>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginBottom: 16, marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>QUẢN LÝ PHÒNG KHÁM</p>
                <ButtonBase
                    style={{ width: 180 }}
                    onClick={() => onOpenCreate()}
                    buttonName={'THÊM PHÒNG KHÁM'}
                    buttonType={'create'}
                />
            </div>


            <div>
                <BaseForm ref={formRefFilter}
                    onSubmit={(value) => { onLoadDataGrid?.(); }}
                    style={{ width: '100%' }}
                    defaultValues={{}} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px', marginBottom: 16 }}>
                        <Col style={{ width: '30%' }} className="">
                            <Form.Item>
                                <TextField noShowLabel={true}
                                    suffix={suffixFilter()}
                                    name={'fullNameOrPhone'}
                                    label={'Tên phòng khám,SĐT đăng ký'} />
                            </Form.Item>
                        </Col>
                        <div
                            style={{
                                width: '55%', display: 'flex'
                            }}
                            className="">
                            <Col
                                style={{
                                    width: '40%', paddingRight: 0
                                }} >
                                <Form.Item>
                                    <TextField
                                        noUpperCaseLabel={true}
                                        noShowLabel={true}
                                        indexLabel='5'
                                        name={'location'}
                                        label={'Địa chỉ bệnh nhân'} />
                                </Form.Item>
                            </Col>
                            <Col
                                style={{
                                    width: '35%', padding: 0
                                }} >
                                <Form.Item >
                                    <ComboBox
                                        noUpperCaseLabel={true}
                                        noShowLabel={true}
                                        isClear={true}
                                        comboValue={optionsDistrict}
                                        name={'districtId'}
                                        label={'Quận/Huyện'} />
                                </Form.Item>
                            </Col>
                            <Col
                                style={{
                                    width: '25%', padding: 0
                                }}>
                                <Form.Item>
                                    <ComboBox
                                        noUpperCaseLabel={true}
                                        noShowLabel={true}
                                        isClear={true}
                                        onChange={(value) => { loadOptionsDistrict(value); }}//}
                                        comboValue={optionsProvince}
                                        name={'provinceId'}
                                        label={'Tỉnh/TP'} />
                                </Form.Item>
                            </Col>
                        </div>
                        <Col className='' style={{ width: '10%', display: 'flex', justifyContent: 'end', justifyItems: 'end', flex: 1, paddingRight: 0 }}>
                            <div className="footer ">
                                <ButtonBase style={{ width: 180, display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                                    buttonName={'TÌM KIẾM'}
                                    buttonType="filter"
                                    htmlType="submit" />
                            </div>
                        </Col>

                    </Row>

                </BaseForm>
            </div>

        </div>
    );
};

export default HeaderFilterClinic; 