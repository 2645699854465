import { ColDef, ICellRendererParams, NewValueParams } from 'ag-grid-community';
import { Input, Select } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

import { CustomInputRef, NewInput } from '../../components/FormFields/CustomInput';
import { CustomSelect, CustomSelectRef, NewSelect } from '../../components/FormFields/CustomSelect';
import TextField from '../../components/FormFields/TextField';
import { ComboOption, MedicineCombo, MedicineGroupCombo, MedicineNoteCombo } from '../share/ComboOption';
export interface OptionCellRenderer
{
    groupMedicine: MedicineGroupCombo[];
    medicineCode: MedicineCombo[];
    medicineNoteUse: MedicineNoteCombo[];
    medicineName: '';
    noteUse: '';
    order: number;
}

export interface PrescriptionRows
{
    medicineGroup: any;
    medicineCode: any;
    medicineName: any;
    quantity: any;
    noteUse: any;
    noteUseMedicinePlus: any;
    medicineCodeRef: React.RefObject<CustomSelectRef>
    medicineNoteUseRef: React.RefObject<CustomSelectRef>
    quantityRef: React.RefObject<CustomInputRef>
    medicineNameRef: React.RefObject<CustomInputRef>
    noteUseMedicinePlusRef: React.RefObject<CustomInputRef>
    noteUseRef: React.RefObject<HTMLDivElement>
}

export const PrescriptionGridColumns = (options: OptionCellRenderer, callback: (type: string, value: any, rowData: any) => void) =>
{
    return [
        {
            headerName: 'STT',
            valueGetter: 'node.rowIndex + 1',
            width: 50,
            field: 'stt',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'center' },
        },
        {
            headerName: 'Nhóm thuốc',
            field: 'medicineGroup',
            tooltipField: 'medicineGroup',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            minWidth: 150,
            editable: false,
            cellRenderer: (params: ICellRendererParams) =>
            {
                const data = params?.data?.['medicineGroup'];
                return <Select className='w-full'
                    options={options.groupMedicine}
                    value={data ? data : ''}
                    onSelect={(value: any) => callback('medicineGroup', value, params.data)}
                />;
            }

        },
        {
            headerName: 'Mã thuốc',
            field: 'medicineCode',
            tooltipField: 'medicineCode',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            minWidth: 150,
            cellRenderer: (params: ICellRendererParams) =>
            {
                const medicineGroupId = params?.data?.['medicineGroup'];
                const data = params?.data?.['medicineCode'];
                let medicineComboOptions: MedicineCombo[] = [];
                if (medicineGroupId)
                {
                    const { medicineCombo } = options.groupMedicine.find(item => item.value === medicineGroupId) as MedicineGroupCombo;
                    medicineComboOptions = medicineCombo;
                }
                return <NewSelect ref={params.data.medicineCodeRef}
                    options={medicineComboOptions}
                    valueDefault={data}
                    onSelect={(value: any) => callback('medicineCode', value, params.data)} />;
            },
        },
        {
            headerName: 'Tên thuốc/Hàm lượng/Quy cách',
            field: 'medicineName',
            tooltipField: 'medicineName',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            minWidth: 150,
            cellRenderer: (params: ICellRendererParams) =>
            {
                let medicineName = '';
                const medicineGroupId = params?.data?.['medicineGroup'];
                const medicineCode = params?.data?.['medicineCode'];
                if (medicineGroupId)
                {
                    const { medicineCombo } = options.groupMedicine.find(item => item.value === medicineGroupId) as MedicineGroupCombo;
                    medicineName = (medicineCombo.find(x => x.value === medicineCode) as MedicineCombo | null)?.label as string;

                }
                // return <div className='w-full' ref={params.data.medicineNameRef}>
                //     {medicineName}
                // </div>;
                return <NewInput
                    disabled={medicineCode ? false : true}
                    ref={params.data.medicineNameRef}
                    defaultValue={medicineName}
                    onChange={debounce((e) =>
                    {
                        callback('medicineName', e.target.value, params.data);
                    }, 300)
                    } ></NewInput>;

            }
        },
        {
            headerName: 'Số lượng',
            field: 'quantity',
            tooltipField: 'quantity',
            headerClass: 'text-center',
            minWidth: 100,
            cellStyle: { 'text-align': 'center' },
            editable: true,
            cellRenderer: (params: ICellRendererParams) =>
            {
                const data = params?.data?.['quantity'];
                const medicineCode = params?.data?.['medicineCode'];
                return <NewInput
                    disabled={medicineCode ? false : true}
                    ref={params.data.quantityRef}
                    defaultValue={data}
                    type="number"
                    onChange={debounce((e) =>
                    {
                        callback('quantity', e.target.value, params.data);
                    }, 300)
                    } ></NewInput>;
            }
        },
        {
            headerName: 'Cách dùng',
            field: 'noteUse',
            tooltipField: 'noteUse',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            maxWidth: 200,
            editable: false,
            cellRenderer: (params: ICellRendererParams) =>
            {
                const medicineComboOptions: MedicineNoteCombo[] = [];
                return <NewSelect ref={params.data.medicineNoteUseRef}
                    options={medicineComboOptions}
                    valueDefault={''}
                    onSelect={(value: any) => callback('noteUse', value, params.data)} />;
            }
        }
        ,
        {
            headerName: 'Chỉnh sửa cách dùng',
            field: 'noteUse',
            tooltipField: 'noteUseMedicinePlus',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            maxWidth: 300,
            editable: false,
            cellEditorParams: {
                onKeyDown: (event: any) => console.log('does not output')
            },
            cellRenderer: (params: ICellRendererParams) =>
            {
                let noteUse = '';
                const medicineGroupId = params?.data?.['medicineGroup'];
                const medicineCode = params?.data?.['medicineCode'];
                if (medicineGroupId)
                {
                    const { medicineCombo } = options.groupMedicine.find(item => item.value === medicineGroupId) as MedicineGroupCombo;
                    noteUse = (medicineCombo.find(x => x.value === medicineCode) as MedicineCombo | null)?.noteUse as string;

                }
                return <NewInput
                    disabled={medicineCode ? false : true}
                    ref={params.data.noteUseMedicinePlusRef}
                    defaultValue={''}
                    onChange={debounce((e) =>
                    {
                        callback('noteUseMedicinePlus', e.target.value, params.data);
                    }, 300)
                    } ></NewInput>;
            }
        },

    ] as ColDef[];
};


