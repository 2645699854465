import '../style/styles.scss';

import { useEffect, useRef } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import appleStoreImg from '../../../assets/images/apple-store.png';
import googlePlayImg from '../../../assets/images/google-play.png';
import { loadExamResult, loadExamResultByToken } from '../../../features/PatientRecord/api/constants';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { ExamResultModal } from '../../../types/ExamResult/ExamResult';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import ViewMedicalExamResult from '../components/ViewMedicalExamResult';

interface IState
{
    loading: boolean,
    infoExamResult?: ExamResultModal
}

function ViewMedicalFile()
{
    const componentRef = useRef<HTMLDivElement>(null);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const medicalExaminationId = 33;
    const datePatient = '';
    const [state, setState] = useMergeState<IState>({
        loading: true
    });
    console.log('token', token);
    useEffect(() =>
    {
        console.log('loadApi');
        loadApi();
    }, []);

    const loadApi = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<ExamResultModal>>(BASE_API_PATH + loadExamResultByToken + '?token=' + token);
        if (response.data.success)
        {
            const data = response.data.results;
            setState({
                loading: false,
                infoExamResult: data as ExamResultModal
            });
        } else
        {
            setState({
                loading: false,
                infoExamResult: {} as ExamResultModal
            });
        }
        console.log('ExamResultModal', state);
    };

    return (
        <div className='fixed top-0 left-0 h-screen w-screen z-50 bg-white' style={{ overflowY: 'scroll', overflowX: 'hidden', paddingBottom: 80 }}>
            <div className='mx-auto max-w-lg flex flex-col justify-center gap-y-4'>
                <ViewMedicalExamResult
                    data={state.infoExamResult as ExamResultModal} />
            </div>
            <div style={{
                display: 'block',
                flexDirection: 'column',
                width: '100%',
                backgroundColor: 'white',
                position: 'fixed',
                bottom: 0,
                paddingBottom: 5
            }}>
                <span style={{ display: 'block', textAlign: 'center', fontStyle: 'italic' }}>Tải ứng dụng mobile VNMEDIC để xem nhiều hơn!</span>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto' }}>
                    <div style={{ width: '50%', margin: 'auto' }}>
                        <img src={appleStoreImg}
                            style={{ width: 90, marginLeft: 10, marginRight: 10, float: 'right' }}
                            onClick={() =>
                            {
                                window.location.href = 'https://apps.apple.com/app/vnmedic/id1663700343';
                            }}></img>
                    </div>
                    <div style={{ width: '50%', margin: 'auto', float: 'left' }}>
                        <img src={googlePlayImg}
                            style={{ width: 90, marginLeft: 10, marginRight: 10 }}
                            onClick={() =>
                            {
                                window.location.href = 'https://play.google.com/store/apps/details?id=vn.vnmedic.app';
                            }}></img>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ViewMedicalFile;