import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Empty } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { Loading } from '../../../components/common/Loading';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import useMergeState from '../../../hooks/useMergeState';
import { Results } from '../../../types/api.type';
import { Conversation, ConversationRead } from '../../../types/Message/Conversation';
import { Message } from '../../../types/Message/MessageChat';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { UrlDev } from '../../../utils/AppUtil';
import { GET_LIST_CONVERSATIONS, JOIN_USER_DOCTOR_ROM_CLINIC, READ_MESSAGE } from '../api/constants.api';
import ListConversationView, { ListConversationRef } from './ListConversationView';
import MessageConversation, { MessageRef } from './MessageConversation';

interface IState
{
    isLoading: boolean,
    connectionChat: null | HubConnection,
    isEmpty?: boolean
}


const MessageView = () =>
{
    const modalRef = useRef<ModalRef>(null);
    const overlayRef = useRef<OverlayRef>(null);
    const messageConversationRef = useRef<MessageRef>(null);
    const listConversationRef = useRef<ListConversationRef>(null);
    const messageCurrentId = useRef<number | null>(null);
    const offset = useRef<number>(0);
    const limit = useRef<number>(40);
    const [state, setState] = useMergeState<IState>({
        isLoading: true,
        connectionChat: null
    });

    useEffect(() =>
    {
        const connectionChat = setupSignalRConnection();

        const joinUserToAllRomClinic = async () =>
        {
            const result = await ApiUtil.Axios.get(BASE_API_PATH + JOIN_USER_DOCTOR_ROM_CLINIC, { withCredentials: true });
            if (result.data.success) return true;
            return false;
        };
        joinUserToAllRomClinic().then(result =>
        {
            if (result)
            {
                getListConversationAll(connectionChat);
                // setState({ isLoading: false, connectionChat });
            }
        });

    }, []);


    useEffect(() =>
    {
        state.connectionChat?.on('reciveMessage', (data: Message) =>
        {
            messageConversationRef.current?.reciveMessage(data);
            messageConversationRef.current?.onScrollBottom();
        });

        state.connectionChat?.on('ReloadListConversation', () =>
        {
            listConversationRef.current?.reloadData();
        });
        state.connectionChat?.on('readMessage', () =>
        {
            listConversationRef.current?.reloadData();
        });
        return () =>
        {
            state.connectionChat?.stop();
        };
    }, [state.connectionChat]);


    const getListConversationAll = (connectionChat: HubConnection) =>
    {
        const currentOffset = offset.current;
        const DEFAULT_OFFSET = 0;
        ApiUtil.Axios.get<Results<Conversation>>(BASE_API_PATH + GET_LIST_CONVERSATIONS, { withCredentials: true, params: { limit: currentOffset, offset: DEFAULT_OFFSET } }).then(result =>
        {
            const data = result.data.results.items;
            const isEmpty = data.length > 0 ? false : true;
            setState({
                isLoading: false,
                connectionChat: connectionChat,
                isEmpty: isEmpty
            });
        });
    };

    const setupSignalRConnection = () =>
    {
        const connection = new HubConnectionBuilder()
            .withUrl(UrlDev + '/hub/chat', {
                skipNegotiation: true,
                withCredentials: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        if (connection)
        {
            try
            {
                connection.start().then(value =>
                {
                });
            } catch (err)
            {
                console.log(err);
            }

            return connection;
        }
        return connection;
    };


    const onHandleOpenMessge = (conversation: Conversation, isCallRead: boolean) =>
    {
        state.connectionChat?.invoke('JoinRoom', conversation.roomName);
        const newActive = document.getElementById(`${ conversation.id }`);
        newActive?.classList.add('unread');
        if (messageCurrentId.current != null)
        {
            const oldActive = document.getElementById(`${ messageCurrentId.current }`);
            oldActive?.classList.remove('unread');
        }

        messageCurrentId.current = conversation.id as number;
        if (isCallRead)
        {
            const conversationRead: ConversationRead = {
                conversationId: conversation.conversationId
            };

            // newActive?.classList.replace('hidden', 'inline-block');
            return ApiUtil.Axios.post(BASE_API_PATH + READ_MESSAGE, conversationRead, { withCredentials: true }).then(result =>
            {
                if (result)
                {
                    messageConversationRef.current?.loadData(conversation);
                }
            });
        }
        messageConversationRef.current?.loadData(conversation);
    };

    const onReloadConversationList = () =>
    {
        listConversationRef.current?.reloadData();
    };

    const renderBody = () =>
    {
        return !state.isLoading && (
            <div className="msg-body" style={{ flex: 1, flexDirection: 'row', height: 500, display: 'flex' }}>
                {
                    state.isEmpty ?
                        (
                            renderLayoutEmpty()
                        )
                        :
                        (<>
                            <ListConversationView
                                onHandleOpenMessge={(conversation, isCallRead) => { onHandleOpenMessge(conversation, isCallRead); }}
                                ref={listConversationRef}
                            />
                            <MessageConversation connection={state.connectionChat}
                                ref={messageConversationRef}
                                onReloadConversationList={onReloadConversationList} />
                        </>
                        )
                }
            </div>
        );
    };

    const renderLayoutEmpty = () =>
    {
        return (
            <div className='flex w-full h-full justify-center items-center'>

                <Empty description={'Không có tin nhắn'} />
            </div>
        );
    };
    // const headerBody = () =>
    // {
    //     return <div className='flex text-xl font-bold ml-6 mt-2' style={{ height: '30px' }}>
    //         <p className='mr-2 text-[#2c999c]' style={{ font: 'Roboto', fontSize: 16 }}>TIN NHẮN BỆNH NHÂN</p>
    //     </div>;
    // };
    const headerBody = () =>
    {
        return <div className='msg-header  ml-4 mt-4' >
            <p className='mr-2 text-[#2c999c] font-bold' style={{ font: 'Roboto', fontSize: 16 }}>TIN NHẮN BỆNH NHÂN</p>
        </div>;
    };
    // const headerBody = () =>
    // {
    //     return <div className='flex text-xl font-bold ml-6 mt-2' style={{ height: '30px' }}>
    //         <p className='mr-2 text-[#2c999c]' style={{ font: 'Roboto', fontSize: 16 }}>TIN NHẮN BỆNH NHÂN</p>
    //     </div>;
    // };
    if (state.isLoading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;
    return (
        // <div className='flex flex-col w-full h-full' style={{ overflowY: 'hidden' }}>
        <div className='msg-view-content' style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
            {headerBody()}
            {renderBody()}
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default MessageView; 
