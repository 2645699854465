import PropTypes from 'prop-types';
import React from 'react';

import { pathDateTime } from '../constants/SvgIcon';

interface DateTimeIconData
{
    size?: string
    color?: string

}

export const DateTimeIcon: React.FC<DateTimeIconData> = (props) =>
{
    const { size = '12', color = '#65676B' } = props;
    return (
        <svg fill="none"
            width={size}
            height={size}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill={color} />
        </svg>
    );
};

DateTimeIcon.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};
