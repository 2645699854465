import './App.css';
import './style/globals.scss';
import './messaging_init_in_sw';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from './components/common';
import LayoutApp from './components/LayoutApp';
import LoginPage from './features/auth/pages/LoginPage';
import ViewMedicalFile from './features/MedicalExamQR/pages/ViewMedicalFile';
import ViewMedicineFile from './features/MedicineQR/pages/ViewMedicineFile';
import RemoveUser from './features/RemoveUser/pages/RemoveUser';
import { onMessageListener } from './firebase';


const queryClient = new QueryClient();

function App()
{

    return (
        // Provide the client to your App
        <QueryClientProvider client={queryClient}>
            <Switch>
                <Route exact
                    path="/"
                    component={LoginPage} />
                <Route exact
                    path="/medicine"
                    component={ViewMedicineFile} />
                <Route exact
                    path="/medical"
                    component={ViewMedicalFile} />
                <Route exact
                    path="/user/remove"
                    component={RemoveUser} />
                <PrivateRoute exact
                    path="/Home"
                    component={LayoutApp} />
                <PrivateRoute path="*" component={LayoutApp} />
            </Switch>
        </QueryClientProvider>
    );
}

export default App;
