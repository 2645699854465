import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import NumberField from '../../components/FormFields/NumberField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { phoneRegExp } from '../../constants/YupContants';
import { UserAccountDoctor } from '../../types/UserAccountDoctor';

//resolver={yupResolver(schema)}
const schema = yup.object().shape({
    fullName: yup.string()
        .min(12, 'Họ và tên phải lớn hơn 12 ký tự')
        .max(80, 'Họ và tên phải nhỏ hơn 80 ký tự')
        .required('Vui lòng nhập thông tin!'),
    phone: yup.string().required('Vui lòng nhập thông tin!').min(8, 'Số điện thoại lớn hơn 8 số.').max(12, 'Số điện thoại ít hơn 12 số').matches(phoneRegExp, 'Định dạng không hợp lệ'),
    email: yup.string().email('Vui lòng nhập đúng định dạng').max(255).required('Vui lòng nhập thông tin!'),
    userName: yup.string()
        .min(8, 'Tên đăng nhập phải dài hơn 8 ký tự')
        .required('Vui lòng nhập thông tin!'),
    password: yup.string()
        .required('Vui lòng nhập mật khẩu!')
        .min(8, 'Mật khẩu tối thiểu 8 kí tự.')
        .matches(/[a-zA-Z0-9]/, 'Nhập mật khẩu đúng định dạng. Mật khẩu bao gồm chữ cái hoặc số')
});

interface IFormAccountSettingProps
{
    handleSubmit: (value: UserAccountDoctor) => Promise<void>;
    onClose: () => void
}


const UserAccountSettingCreate: FunctionComponent<IFormAccountSettingProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit } = props;

    const onSubmit = async (value: UserAccountDoctor) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    return ( //defaultValues={{check:'a'}}
        <BaseForm ref={formRef}
            className="p-3"
            resolver={yupResolver(schema)}
            onSubmit={(value) => { onSubmit(value); }} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'fullName'}
                            label={'Họ và tên'} />
                    </Form.Item>
                </Col>

            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'email'}
                            label={'Email'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <NumberField requiredError={true}
                            name={'phone'}
                            label={'Số điện thoại'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'userName'}
                            label={'Tên đăng nhập'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            type='password'
                            name={'password'}
                            label={'Mật khẩu'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>
    );
};

export default UserAccountSettingCreate; 
