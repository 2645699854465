/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage } from '@hookform/error-message';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import React, { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps extends InputProps
{
    label?: string;
    indexLabel?: string;
    labelClass?: string;
    labelStyle?: CSSProperties;
    errors?: any;
    name: string;
    type?: string;
    requiredError?: boolean | null,
    labelPositison?: 'left' | 'top';
    noShowLabel?: true | false;
    noUpperCaseLabel?: boolean;
    noShowPlaceholder?: boolean
}

const TextField: React.FC<IProps> = (props) =>
{
    const { label, labelStyle = {}, name, type, requiredError, labelPositison, noShowLabel, indexLabel, noUpperCaseLabel = false, noShowPlaceholder } = props;
    const methods = useFormContext();


    const { control, formState: { errors } } = methods;
    return (
        <div className="flex flex-col justify-start">
            <div className="flex items-center">
                <div className={labelPositison === 'left' ? 'w-full px-3 md:mb-0 flex' : 'w-full px-3 md:mb-0'}>
                    <div className='flex'>
                        {(label && !noShowLabel) && (
                            <label
                                className={noUpperCaseLabel ?
                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                    : 'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                                }
                                style={{
                                    ...labelStyle,
                                }}
                            >
                                {indexLabel ? (indexLabel + '. ') : null}{label}
                            </label>

                        )}
                        {requiredError && (<div className='text-red-600 ml-1 -left-4 -top-4'> *</div>)}
                    </div>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) =>
                        {
                            return type === 'password' ?
                                <Input.Password status={errors[name] ? 'error' : ''}
                                    style={{ borderRadius: '7px' }}
                                    className='border-red-800 rounded-lg'
                                    {...field}
                                    {...props}
                                    placeholder={'Nhập ' + label + '...' || ''} />
                                : <Input status={errors[name] ? 'error' : ''}
                                    style={{ borderRadius: '7px' }}
                                    className='border-red-800'
                                    {...field}
                                    {...props}
                                    placeholder={noShowPlaceholder ? '' : 'Nhập ' + label?.toLocaleLowerCase() + '...' || ''} />;
                        }

                        }
                    />
                </div>
            </div>
            {errors && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (message ? <p className=" ml-6 mt-1 m-0 text-red-400">{message}</p> : null)}

                />
            )}
        </div>
    );
};

export default TextField;
