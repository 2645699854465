import React, { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { DELETE_TO_SUCCESS, UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import MedicalExamPicturesForm from '../../../module/MedicalExamPictures/MedicalExamPicturesForm';
import { ApiResponse } from '../../../types/api.type';
import { MedicalExamPictures } from '../../../types/MedicalExamPictures/MedicalExamPictures';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createMedicalExamPictures, deleteMedicalExamPictures, indexMedicalExamPictures, loadComboDesignationMedicalExam, updateMedicalExamPictures } from '../api/constants';
import GridMedicalExamPictures from './GridMedicalExamPictures';
import HeaderMedicalExamPictures from './HeaderMedicalExamPictures';

interface IState
{
    valueOption: string;
    designationMedicalExam: MedicalExamPictures[];
    loading: boolean
}
const MedicalExamPicturesView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const [optionsMedicalMedicalExam, setOptionsMedicalMedicalExam] = useState<IComboBox[]>([]);
    const [state, setState] = useMergeState<IState>({
        valueOption: '',
        designationMedicalExam: [],
        loading: true
    });
    useEffect(() =>
    {
        loadOptionsMedicalExamTemplate();
        setState({
            ...state,
            loading: false
        });
    }, []);
    useEffect(() =>
    {
        if (optionsMedicalMedicalExam.length > 0)
        {
            onChangeComboMedicalExamPictures(optionsMedicalMedicalExam[0].value);
        }
    }, [optionsMedicalMedicalExam]);

    const loadOptionsMedicalExamTemplate = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadComboDesignationMedicalExam, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as IComboBox[];
            setOptionsMedicalMedicalExam(data);
        }
    };
    const onChangeComboMedicalExamPictures = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<MedicalExamPictures[]>>(BASE_API_PATH + indexMedicalExamPictures + value);
        let designationMedicalExamOption: MedicalExamPictures[] = [];
        if (response.data.success)
        {
            const data = response.data.results as MedicalExamPictures[];
            designationMedicalExamOption = data;
        }
        setState({
            valueOption: value,
            designationMedicalExam: designationMedicalExamOption
        });
    };
    const onOpenCreate = () =>
    {
        modalRef.current?.onOpen(<MedicalExamPicturesForm handleSubmit={onCreateDesignationMedicalExam} onClose={onCloseModal} />, 'Thêm hình ảnh', 30);
    };

    const onCreateDesignationMedicalExam = async (value: MedicalExamPictures) =>
    {
        const params: MedicalExamPictures = {
            ...value,
            designationMedicalExamId: parseInt(state.valueOption)
        };
        const response = await AxiosClient.post<ApiResponse<MedicalExamPictures>>(BASE_API_PATH + createMedicalExamPictures, params);
        if (response.data.success)
        {
            await onChangeComboMedicalExamPictures(state.valueOption);
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onOpenUpdate = (value: MedicalExamPictures) =>
    {
        modalRef.current?.onOpen(<MedicalExamPicturesForm defaultvalue={value}
            handleSubmit={onUpdateDesignationMedicalExam}
            onClose={onCloseModal} />, 'Sửa hình ảnh', 30);
    };

    const onUpdateDesignationMedicalExam = async (value: MedicalExamPictures) =>
    {
        const designationMedicalExamId = value.id;
        const response = await AxiosClient.put<ApiResponse<MedicalExamPictures>>(BASE_API_PATH + updateMedicalExamPictures + designationMedicalExamId, value);
        if (response.data.success)
        {
            await onChangeComboMedicalExamPictures(state.valueOption);
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onDeleteDesignationMedicalExam = async (value: MedicalExamPictures) =>
    {
        overlayRef.current?.open();
        const designationMedicalExamId = value.id;
        const response = await AxiosClient.delete<ApiResponse<MedicalExamPictures>>(BASE_API_PATH + deleteMedicalExamPictures + designationMedicalExamId);
        if (response.data.success)
        {
            await onChangeComboMedicalExamPictures(state.valueOption);
            ApiUtil.ToastSuccess(DELETE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError('Thiết lập đang được sử dụng tại vị trí khác!');
        }
        onCloseModal();
        overlayRef.current?.close();

    };


    return (
        <div style={{ display: state.loading ? 'none' : 'inline' }}>
            <HeaderMedicalExamPictures valueOptions={state.valueOption}
                onChange={onChangeComboMedicalExamPictures}
                options={optionsMedicalMedicalExam}
                onOpenCreate={onOpenCreate} />
            <GridMedicalExamPictures record={state.designationMedicalExam}
                onEdit={onOpenUpdate}
                onDelete={onDeleteDesignationMedicalExam} />
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>
    );
};

export default MedicalExamPicturesView;