import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import React from 'react';

import AppleStore from '../../assets/images/apple-store.png';
import GooglePlay from '../../assets/images/google-play.png';
import QRAndroid from '../../assets/images/qr-android.png';
import QRIphone from '../../assets/images/qr-iphone.png';
import { Clinic } from '../../types/Clinic';

interface Props
{
    clinicInfo: Clinic | undefined
    componentRef: React.RefObject<HTMLDivElement>

}

const PrintQR: React.FC<Props> = (props) =>
{
    const { clinicInfo, componentRef } = props;

    useEffect(() =>
    {
    }, []);

    return (
        <div
            className='overflow-y-hidden'
            ref={componentRef}
            style={{
                backgroundColor: '#fff',
                margin: 0, marginTop: 15,
                marginBottom: -15,
                padding: 10,
                border: 'solid',
                borderWidth: 3,
                height: '98%'
            }}
        >
            <div
                style={{}}
                className='flex flex-col'>
                <div className="row m-0">
                    <div className='text-center ' style={{ fontSize: '28pt', color: '#106948', fontFamily: 'sans-serif' }}>VNMEDIC</div>
                </div>
                <div className="row m-0">
                    <div className="col-12 text-start">
                        <div className="row">
                            <QRCode
                                id='qrcode'
                                value={clinicInfo?.clinicCode ?? 'VNM-00000'}
                                size={300}
                                level={'H'}
                                includeMargin={true}
                                style={{
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    margin: 'auto',
                                }}
                            />
                        </div>
                        <div className="row">
                            <span className="text-center" style={{ fontWeight: 'bold', fontSize: '20pt', display: 'block', color: '#106948', fontFamily: 'sans-serif' }}>{clinicInfo?.name?.toLocaleUpperCase()}</span>
                        </div>
                        <div className="row">
                            <span className="text-center" style={{ fontSize: '20pt', display: 'block', fontFamily: 'sans-serif' }}>{clinicInfo?.clinicCode}</span>
                        </div>
                        <div className="row">
                            <br />
                        </div>
                        <div className="row">
                            <span className="text-center" style={{ fontWeight: 'bold', fontSize: '16pt', display: 'block', color: '#8f1b13' }}>Tải ứng dụng VNMEDIC trên điện thoại để đặt khám và xem kết quả khám, chữa bệnh.</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '50%', margin: 0 }}>
                                <img src={QRAndroid} style={{ width: 120, height: 'auto', margin: 'auto', border: 'solid', borderWidth: 2, borderColor: '#106948' }} />
                                <img src={GooglePlay} style={{ width: 100, height: 'auto', margin: 'auto', marginTop: 5 }} />
                            </div>
                            <div style={{ width: '50%', margin: 0 }}>
                                <img src={QRIphone} style={{ width: 120, height: 'auto', margin: 'auto', border: 'solid', borderWidth: 2, borderColor: '#106948' }} />
                                <img src={AppleStore} style={{ width: 100, height: 'auto', margin: 'auto', marginTop: 5 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PrintQR;