import '../style/index.scss';

import React, { useEffect } from 'react';

import { avartaUser } from '../../../constants/SvgIcon';
import { Conversation } from '../../../types/Message/Conversation';


interface IProps
{
    data: Conversation
    onHandleOpenMessge: (conversation: Conversation) => void
    textAbstract: (text: string, length: number) => string
}
const LENG_TEXT = 25 as number;

const ConversationItem = (props: IProps) =>
{
    const { data, textAbstract } = props;
    const renderTimeLastMessage = (date: Date) =>
    {
        const dateTimeNow = new Date();
        const dateMessage = new Date(date);

        if (dateTimeNow.getFullYear() === dateMessage.getFullYear() && dateTimeNow.getMonth() === dateMessage.getMonth() && dateTimeNow.getDate() === dateMessage.getDate())
        {

            return dateMessage.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            });
        }
        return dateMessage.toLocaleDateString();
    };


    const onHandleOpenMessage = (conversation: Conversation) =>
    {
        props.onHandleOpenMessge && props.onHandleOpenMessge(conversation);
    };
    return <li id={`${ data.id }`}
        className={`ml-2 item-conversation ${ !data.isClinic && data.countUnRead > 0 ? 'unread' : '' }`}
        style={{ height: '75px' }}
        onClick={(e) => onHandleOpenMessage(data)}>
        <a
            className={`${ !data.isClinic && data.countUnRead > 0 ? '' : 'border-b border-gray-300' } flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out cursor-pointer hover:bg-gray-100 focus:outline-none`}>
            <img className="object-cover w-10 h-10"
                src={avartaUser}
                style={{ borderRadius: '7px' }}
                alt="username" />
            <div className="w-full pb-2">
                <div className="flex justify-between">
                    <span className="block ml-2 font-semibold text-gray-600">{data.userChatPatient.fullName}</span>
                    <span className="block ml-2 text-sm text-gray-600">{renderTimeLastMessage(data.lastTimeSendMessage)}</span>
                </div>
                <div className="flex justify-between">
                    <span className={'block ml-2 text-sm text-gray-600'} >{textAbstract(data.lastMessage, LENG_TEXT)}</span>
                    {!data.isClinic && data.countUnRead > 0 &&
                        <span className={'block ml-2 text-sm text-gray-600'}
                            style={{
                                backgroundColor: '#2C999C',
                                width: '24px',
                                height: '24px',
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'white'
                            }}
                        >{data.countUnRead}</span>}
                </div>
            </div>
        </a>
    </li>;
};

export default ConversationItem;