import 'antd/dist/antd.min.css';

import { notification } from 'antd';

import AxiosClient from '../apis/api/axiosClient';


// export const AxiosClient = axios.create({
//     baseURL: process.env.REACT_APP_PUBLIC_API_URL,
//     headers: {
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         'Access-Control-Allow-Credentials': true}});

export const BASE_API_PATH = '/api';

// AxiosClient.interceptors.request.use(async request =>{
//     return request;
// }, async error => {

//     return Promise.reject(error);
// }
// );
// AxiosClient.interceptors.response.use(async response =>{
//     return response;
// }, async error => {
//     if(error.response.status === 401)
//     {
//         const response = await AxiosClient.post('refresh', {}, {withCredentials:true});
//         if(response.status === 200)
//         {
//             AxiosClient.defaults.headers.common['Authorization'] = `Bearer ${response.data['token']}`;
//             return AxiosClient(error.config);
//         }
//     }
//     return Promise.reject(error);
// }
// );


export class ApiUtil
{
    static Axios = AxiosClient;
    static serialize = (obj: { [key: string]: string | number | boolean } = {}) =>
        Object.entries(obj)
            .map(([key]) => `${ key }=${ encodeURIComponent(obj[key]) }`)
            .join('&');

    static openNotification = (
        message: string,
        title?: string): void =>
    {
        notification.destroy();
        notification.info({
            message: title || 'Thông báo',
            style: getNotificationStyle('success'),
            description: message || 'Có thông báo!',
        });

        // setTimeout(() =>
        // {
        //     api.open({
        //         key,
        //         message: 'New Title',
        //         description: 'New description.',
        //     });
        // }, 1000);
    };

    static ToastSuccess = (
        message: string,
        title?: string): void =>
    {

        notification.success({
            message: title || 'Thông báo',
            description: message || 'Thao tác thành công!',
            // className: "notification-success",
            style: getNotificationStyle('success'),
        });
    };
    static ToastError = (
        message: string,
        title?: string): void =>
    {
        notification.error({
            message: title || 'Thông báo',
            description: message || 'Đã có lỗi khi thực hiện thao tác này, vui lòng kiểm tra lại hoặc liên hệ nhân viên VNMEDIC để được hỗ trợ!',
            // className: "notification-error",
            style: getNotificationStyle('error'),
        });
    };
    static ToastWarning = (
        message: string,
        title?: string): void =>
    {
        notification.warning({
            message: title || 'Thông báo',
            description: message || 'Có thông báo!',
            // className: "notification-error",
            style: getNotificationStyle('warning'),
        });
    };
}

const getNotificationStyle = (
    type: 'success' | 'warning' | 'error' | 'info'
) =>
{
    return {
        success: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #b7eb8f',
            backgroundColor: '#f6ffed',
        },
        warning: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #ffe58f',
            backgroundColor: '#fffbe6',
        },
        error: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #ffa39e',
            backgroundColor: '#fff1f0',
        },
        info: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #91d5ff',
            backgroundColor: '#e6f7ff',
        },
    }[type];
};
