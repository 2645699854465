import { Col, DatePicker, Form, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactToPrint from 'react-to-print';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import TextAreaField from '../../../components/FormFields/TextAreaField';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import { pathDateTime, pathMedicine, pathPrint } from '../../../constants/SvgIcon';
import {useAppDispatch} from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { DiseaseAnalysisProcess, DiseaseAnalysisProcessCreate, DiseaseAnalysisProcessDetailViewDto } from '../../../types/DiseaseAnalysisProcess/DiseaseAnalysisProcess';
import { InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';
import { InfoFileUpload } from '../../../types/UploadFile/InfoFileUpload';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { IStateExamResultScan } from '../../ExaminationProcess/page/ExamProcessLayout';
import { loadInfomationClinic } from '../../Infomation/api/constants';
import {clinicAction} from '../../Infomation/ClinicSlice';
import HeaderExamResultProcessScanView from './HeaderExamResultProcessScanView';
// import { loadCombo, loadDiseaseAnalysisById, updateDiseaseProcess } from '../api/constants';

interface IProps
{
    imageFileBeingExam?: { [medicalPicturesId: number]: InfoFileUpload[] } | null;
    optionsMedicalMedicalPictureLayout: IComboBox[];
    diseaseAnalysisProcess?: DiseaseAnalysisProcessDetailViewDto;
    formRef?: React.RefObject<BaseFormRef>;
    infoPatientExam: InformationPatientExam;
    stateScan: IStateExamResultScan;
    componentRef: React.RefObject<HTMLDivElement>;
    tokenUrl: string | null | undefined
}


interface IState
{
    diseaseCombo: IComboBox[];
    diseaseList: { [diseaseAnalysisId: number]: IComboBox[] } | null;
    loading: boolean
}
const ExamResultProcessScanView = (props: IProps) =>
{
    const { componentRef, imageFileBeingExam, infoPatientExam, optionsMedicalMedicalPictureLayout, diseaseAnalysisProcess, formRef, stateScan } = props;
    const [clinicInfo, SetClicnicInfo] = useState<Clinic>();
    const overlayRef = useRef<OverlayRef>(null);
    const dispatch = useAppDispatch();

    useEffect(() =>
    {
        loadInfoClinic();
    }, []);
    useEffect(() =>
    {
    }, [diseaseAnalysisProcess, imageFileBeingExam]);

    const loadInfoClinic = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<Clinic>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results;
            if (data) {
                dispatch(clinicAction.setShowStorageWarning(data.isSizeWarning || false));
            }
            SetClicnicInfo(data as Clinic);
        }
    };


    const renderDiseaseList = () =>
    {
        return <div>
            {diseaseAnalysisProcess?.diseaseAnalysisProcessList ?
                Object.entries(_.cloneDeep(diseaseAnalysisProcess?.diseaseAnalysisProcessList) as { [diseaseAnalysisId: number]: DiseaseAnalysisProcess })?.map(
                    ([key, value]) => 
                    {
                        return (<Col
                            key={key}
                            className="mt-1 mb-2">
                            <div className="flex items-center">
                                <div className="font-bold col-3" style={{ width: 150 }}>
                                    {value['diseaseAnalysisContent']}:&nbsp;
                                </div>
                                <div className="col-9">
                                    <div>
                                        {value['content']}
                                    </div>
                                </div>
                            </div>
                        </Col>);
                    }
                )
                :
                <div></div>
            }
        </div>;
    };

    const onRenderImage = () =>
    {
        return Object.entries(_.cloneDeep(imageFileBeingExam as { [diseaseAnalysisId: number]: InfoFileUpload[] })).map(
            ([key, value]) => 
            {
                let nameImage = '';
                const thumbUrl = value?.[0]?.['thumbUrlCloud'];
                const fileType = value?.[0]?.['fileType'];
                optionsMedicalMedicalPictureLayout.map(item =>
                {
                    if (item.value === key)
                    {
                        nameImage = item.label;
                    }
                });
                return FileType.Image.includes(fileType ?? '') ? <div key="1" style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: '95%', width: '95%' }} >
                        <div className={'rounded-lg'}
                            style={{ width: '100%', aspectRatio: '1/1' }}
                        >
                            <div className="flex h-full w-full" >
                                <div className="flex flex-col h-full w-full" style={{ borderRadius: '8px', justifyContent: 'center' }}>
                                    <img
                                        src={thumbUrl}
                                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                        className="bg-white border rounded"
                                        alt="..."
                                    />
                                </div>
                            </div>
                            <div className='ml-1 font-bold'>
                                {nameImage}
                            </div>
                        </div>
                    </div>
                </div> : <div className='display-none'></div>;
            }
        );
    };

    const renderBaseForm = () =>
    {
        return <BaseForm ref={formRef}
            onSubmit={() => { }}
            style={{}}
            defaultValues={{}} >
            <div className='w-full ' style={{ height: '100%' }}>
                <div className="divide-y-2" style={{ width: '100%' }}>
                    <div style={{}} className="ml-3">
                        {renderDiseaseList()}
                    </div>
                    <div style={{}} className="ml-3">
                        <Col className=" mb-2">
                            <div className="flex items-center row">
                                <div className="font-bold col-3" style={{ width: 150 }}>
                                    Lịch tái khám:
                                </div>
                                <div className="col-9">
                                    <div>
                                        {stateScan.isChecked ? stateScan.reExamDate : 'Không'}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>

                </div>

            </div>
        </BaseForm>;
    };

    const renderInfoClinic = () =>
    {
        return <div className='w-full'>
            <div style={{}}>
                <p className="text-xl font-bold" style={{ font: 'Roboto', fontSize: 16, height: 14, flex: 1 }}>{clinicInfo?.name}</p>
                <p className='text-xs ml-3' style={{ font: 'Roboto', fontSize: 16, height: 14, flex: 1 }}>{clinicInfo?.address}</p>
            </div>
            <div className='ml-3' style={{ width: '100%', marginTop: '-5px' }}>
                <p className='flex text-xl font-bold text-[#2c999c]' style={{ justifyContent: 'center', font: 'Roboto', fontSize: 24, height: 17, flex: 1 }}>THÔNG TIN KHÁM BỆNH</p>
            </div>
            <div></div>
        </div>;
    };

    console.log('props.tokenUrl ', props.tokenUrl);
    return (
        <div ref={componentRef}
            style={{ width: '100%', height: '100%', paddingBottom: 120 }}
            className='flex flex-col ml-3 mr-3'>
            <div style={{ position: 'absolute', left: 0, bottom: 0, height: '40px' }}>
                <div className='w-full'>
                    <div style={{ width: '40%' }}>
                        <div className=' mt-10'>
                            <p className="text-xl font-bold" style={{ font: 'Roboto', fontSize: 16, height: 14, flex: 1 }}>{clinicInfo?.name}</p>
                            <p className='text-xs ml-3' style={{ font: 'Roboto', fontSize: 16, height: 14, flex: 1 }}>{clinicInfo?.address}</p>
                        </div>
                    </div>
                    <div className='ml-3' style={{ width: '100%', marginTop: '-5px' }}>
                        <p className='flex text-xl font-bold text-[#2c999c]' style={{ justifyContent: 'center', font: 'Roboto', fontSize: 24, height: 17, flex: 1 }}>THÔNG TIN KHÁM BỆNH</p>
                    </div>
                    <div></div>
                </div>
            </div>
            <div >
                {renderInfoClinic()}
            </div>
            <div className='mb-2' style={{}}>
                {<HeaderExamResultProcessScanView infoPatientExam={infoPatientExam} />}
            </div>
            <div className='flex flex-col flex-1  ml-3 mr-3'>
                <div className=' font-bold text-[#2C999C]' style={{ fontSize: '16px' }}>
                    MÔ TẢ KẾT QUẢ KHÁM BỆNH
                </div>
                {renderBaseForm()}
                <div className='' style={{ width: '100%' }}>
                    <div className=' font-bold text-[#2C999C] mt-2 mb-2' style={{ fontSize: '16px' }}>
                        HÌNH ẢNH KHÁM BỆNH
                    </div>
                    <div style={{ height: '95%', width: '100%' }}>
                        <div className="flex" style={{ height: '100%', width: '100%', flexFlow: 'wrap' }}>
                            {onRenderImage()}
                        </div>
                    </div>
                </div>
            </div>
            {props.tokenUrl && props.tokenUrl != '' &&
                (<div style={{
                    position: 'absolute',
                    bottom: 0,
                }}>
                    <QRCode
                        id='qrcode'
                        value={props.tokenUrl ?? ''}
                        style={{ height: '120px', width: '120px' }}
                        level={'H'}
                        includeMargin={true}
                    />
                    <span style={{ fontStyle: 'italic' }}>Quét mã QR hoặc tải ứng dụng VNMedic để xem chi tiết</span>
                </div>)}
            <Overlay ref={overlayRef} />

        </div>
    );
};

export default ExamResultProcessScanView;