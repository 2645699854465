import { useRef } from 'react';
import Iframe from 'react-iframe';

import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';

// interface IState
// {

// }
const ClinicServiceView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);


    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <Iframe
                url="https://www.vnmedic.vn/dichvuphongkham"
                // position="absolute"
                width="100%"
                id="myId"
                className="myClassname"
                height="100%"
            // styles={{ height: '100%' }}
            />
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default ClinicServiceView;