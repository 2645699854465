import { Select, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import React from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { IComboBox } from '../../../components/FormFields/ComboBox';

interface IHeaderProps
{
    onOpenCreate: () => void;
    // onChange:(value:string)=>Promise<void>;
    valueOptions: string
    options?: IComboBox[];
    defaultValue?: string
}

const HeaderDesignationMedicalExam = (props: IHeaderProps) =>
{
    // const {options, onChange, valueOptions} = props;

    // const tooltipValue = options?.join(', ');

    // const renderToolTip = () => {
    //     return <Tooltip placement="topLeft" title={tooltipValue} />;
    // };
    return (
        <div className="flex mt-6 justify-between">
            <div className=' text-xl font-bold text-[#2c999c]  ml-3'>
                CẤU HÌNH DỊCH VỤ
            </div>
            <div className='order-last flex mr-3'>
                <div className='mr-3 flex'>
                    {/* <div className='mr-2 mt-2'>
                        <p className='font-bold'>Chọn loại mẫu kết quả</p>
                    </div>
                    <div>
                        <Select 
                            style={{ width: 120 }} 
                            onChange={(value)=>{onChange(value);}}
                            showSearch = {true}
                            value={valueOptions}
                            onMouseEnter={renderToolTip}
                            optionFilterProp="children"
                            filterOption={(input, option) =>  
                                option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
								|| option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } >
                            {options?.map(item => {
                                return <>
                                    <Option value={item.value}>{item.label}</Option>;
                                </>;
                            })}
							
                        </Select>

                    </div> */}


                </div>
                <div className='order-last mr-2'>
                    <ButtonBase style={{ width: 165 }}
                        onClick={props.onOpenCreate}
                        buttonName={'THÊM DỊCH VỤ'}
                        buttonType={'create'} />
                </div>
            </div>

        </div>
    );
};

export default HeaderDesignationMedicalExam; 