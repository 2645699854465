import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';

import BaseForm from '../../../components/FormFields/BaseForm';
import TextField from '../../../components/FormFields/TextField';


interface IState
{
    onLoadDataGrid: () => void
    formRefFilter: any
}


function HeaderFilterPatient(props: IState)
{
    const { onLoadDataGrid, formRefFilter } = props;

    const suffixFilter = () =>
    {
        return <SearchOutlined />;
    };

    return (
        <div className="mt-3 justify-between" style={{ height: '90px' }}>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginBottom: 16, marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>QUẢN LÝ BỆNH NHÂN</p>
            </div>

            <div>
                <BaseForm ref={formRefFilter}
                    onSubmit={onLoadDataGrid}
                    style={{ width: '100%' }}
                    defaultValues={{}} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px', marginBottom: 16 }}>
                        <Col style={{ width: '30%' }} className="">
                            <Form.Item>
                                <TextField noShowLabel={true}
                                    suffix={suffixFilter()}
                                    name={'search'}
                                    label={'Tìm kiếm'} />
                            </Form.Item>
                        </Col>

                    </Row>
                </BaseForm>
            </div>
        </div>
    );
}

export default HeaderFilterPatient;