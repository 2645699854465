import { DatePicker, Form, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AxiosClient from '../../../apis/api/axiosClient';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import RadioField from '../../../components/FormFields/RadioField';
import TextField from '../../../components/FormFields/TextField';
import { optionGender } from '../../../constants/OptionsCombo';
import { pathDateTime } from '../../../constants/SvgIcon';
import { DateTimeIcon } from '../../../icons/DateTimeIcon';
import { ApiResponse } from '../../../types/api.type';
import { PatientDataDto } from '../../../types/UserManagement/PatientGridDto';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import { comboDistrict, comboProvince } from '../../Infomation/api/constants';
import { patientDetails } from '../api/constants';


interface IState
{
    handleSubmit: (value: PatientDataDto) => Promise<void>,
    defaultValue?: PatientDataDto,
    onClose: () => void
    isEdit?: boolean
}

function UserCreateOrEditForm(props: IState)
{
    const { handleSubmit, isEdit, defaultValue, onClose } = props;

    const [provinceList, setProvinceList] = useState<IComboBox[]>([]);
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>([]);
    const [dataDetail, setDataDetail] = useState<PatientDataDto[]>();
    const [yearOfBirth, setYearOfBirth] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const formRefFilter: any = useRef<BaseFormRef>(null);
    const idProvince = formRefFilter?.current?.getValues('provinceId');

    const loadDataDetail = async () =>
    {
        setIsLoading(true);
        if (defaultValue?.id)
        {
            const response = await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + patientDetails + defaultValue?.id);
            if (response?.data?.success)
            {
                const data = response?.data?.results as PatientDataDto[];
                setDataDetail(data);
                setIsLoading(false);
            } else
            {
                setIsLoading(false);
            }
        }

    };

    const onSubmit = (value: PatientDataDto) =>
    {
        const body = {
            ...value,
            provinceId: Number(value.provinceId),
            userName: value.phone,
            email: '',
            yearOfBirth: yearOfBirth || defaultValue?.yearOfBirth
        };

        handleSubmit(body);
    };




    const loadProvince = async () =>
    {
        const response = await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince);
        if (response.data.success)
        {
            setProvinceList(response.data.results as unknown as IComboBox[]);
        }
    };

    const loadOptionsDistrict = async (value: string) =>
    {
        if (value)
        {
            const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
            if (response?.data?.success)
            {
                const data = response?.data?.results as unknown as IComboBox[];
                setOptionsDistrict(data);
            }
        }
        else 
        {
            setOptionsDistrict([]);
        }

    };


    useEffect(() =>
    {
        loadProvince();
    }, []);

    useEffect(() =>
    {
        idProvince && loadOptionsDistrict(idProvince || defaultValue?.provinceId);
    }, [idProvince]);


    useEffect(() =>
    {
        if (isEdit && formRefFilter)
        {
            formRefFilter?.current?.setValues({ ...dataDetail, gender: defaultValue?.gender === 'Nữ' ? 'FEMALE' : 'MALE' });
        }
    }, [dataDetail]);

    useEffect(() =>
    {
        defaultValue?.id && loadDataDetail();
    }, [defaultValue?.id]);

    return (
        <div className='p-4'>
            <Spin spinning={isLoading}>
                <BaseForm ref={formRefFilter}
                    onSubmit={onSubmit}
                    style={{ width: '100%' }}
                    defaultValues={{}}
                >
                    <div className='flex justify-between'>
                        <div className='w-1/2'>
                            <Form.Item>
                                <InputCustom
                                    name={'fullName'}
                                    label={'Họ và tên'}
                                />

                            </Form.Item>

                            <Form.Item>
                                <RadioField noUpperCaseLabel={true}
                                    comboValue={optionGender}
                                    name={'gender'}
                                    label={'Giới tính'} />
                            </Form.Item>



                            {/* <Form.Item className='!mt-9'>
                                <InputCustom
                                    name={'clinicName'}
                                    label={'Phòng khám'}
                                    disabled
                                />
                            </Form.Item> */}
                            <Form.Item className='!mt-[33px]'>
                                <ComboBox
                                    noUpperCaseLabel={true}
                                    isClear={true}
                                    comboValue={optionsDistrict}
                                    name={'districtId'}
                                    label={'Quận/Huyện'}
                                />
                            </Form.Item>


                        </div>
                        <div className='w-1/2'>
                            <FormItemCustom label='Năm sinh'
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                                name={'yearOfBirth'}
                            >
                                <DatePicker
                                    defaultValue={defaultValue?.yearOfBirth ? moment(defaultValue?.yearOfBirth, 'YYYY') : undefined}
                                    placeholder='Chọn năm sinh'
                                    style={{ width: '100%', borderRadius: '7px' }}
                                    allowClear={false}
                                    suffixIcon={<DateTimeIcon />}
                                    onChange={(value: any) =>
                                    {
                                        setYearOfBirth(moment(value).year());


                                    }}
                                    picker="year"
                                />


                            </FormItemCustom>

                            <Form.Item>
                                <InputCustom
                                    onKeyPress={(event: any) =>
                                    {
                                        const allowedKeys = /[0-9]/;
                                        if (!allowedKeys.test(event.key))
                                        {
                                            event.preventDefault();
                                        }
                                    }}
                                    disabled
                                    name={'phone'}
                                    label={'Số điện thoại'} />
                            </Form.Item>
                            <Form.Item>
                                <ComboBox
                                    noUpperCaseLabel={true}
                                    isClear={true}
                                    comboValue={provinceList}
                                    onChange={(value) => { loadOptionsDistrict(value); }}//}
                                    name={'provinceId'}
                                    label={'Tỉnh thành'}
                                />

                            </Form.Item>
                        </div>

                    </div>
                    <Form.Item>
                        <InputCustom
                            name={'location'}
                            label={'Địa chỉ'}
                        />
                    </Form.Item>
                    <div className={'flex justify-end items-center mt-4 pr-4'}>
                        <ButtonBase buttonName={'Hoàn tất'}
                            buttonType={'create'}
                            htmlType={'submit'} />
                    </div>
                </BaseForm>
            </Spin>

        </div>
    );
}

export default UserCreateOrEditForm;

const InputCustom = styled(TextField)`
  & .border-red-800{
    border-color: none;
   }
`;

const FormItemCustom = styled(Form.Item)`
  & .ant-form-item-label>label{
    height: 24px !important;
   }
`;