

import 'mapbox-gl/dist/mapbox-gl.css';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'vietmaps-gl';

import { LocationIcon } from '../../icons/LocationIcon';
import { firstLocation } from './InfomationUpdate';


mapboxgl.accessToken = 'ba6f250a9bf1df85dc1cae8ca3f605ad5e9be5c871a83240';


interface ModalSelectLocationData
{
    onChangeValue?: (value: number[]) => void // hàm để hứng giá trị select khi đã xác nhận giá trị đã chọn
    defaultLocation?: number[] // biến chứa giá trị vị trí đã lưu dưới DB
}

export const ModalSelectLocation: React.FC<ModalSelectLocationData> = ({
    onChangeValue,
    defaultLocation = firstLocation
}) =>
{
    const [openModal, setOpenModal] = useState(false);

    // biến chứa giá trị tung độ, vĩ độ khi click vào 1 điểm trên bản đồ
    const [locationSelected, setLocationSelected] = useState<number[]>(defaultLocation);

    const [locationConfirm, setLocationConfirm] = useState<number[]>([]);


    const handleConfirmModal = () =>
    {
        onChangeValue && onChangeValue(locationSelected);
        setLocationConfirm(locationSelected);
        setOpenModal(false);
    };




    const handleCancelModal = () =>
    {
        if (locationConfirm.length === 0)
        {
            // th1: chọn lần đầu -> hủy -> set về defaultLocation
            setLocationSelected(defaultLocation);

        } else
        {
            // th2: chọn, xác nhận, => chọn vị trí -> hủy -> set về locationSelected
            setLocationSelected(locationConfirm);
        }

        setOpenModal(false);
    };

    const geojson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    message: 'Foo',
                    iconSize: [60, 60],
                },
                geometry: {
                    type: 'Point',
                    coordinates: [105.85473, 21.0334],
                },
            },
            {
                type: 'Feature',
                properties: {
                    message: 'Bar',
                    iconSize: [50, 50],
                },
                geometry: {
                    type: 'Point',
                    coordinates: [105.85473, 21.0334],
                },
            },
            {
                type: 'Feature',
                properties: {
                    message: 'Baz',
                    iconSize: [40, 40],
                },
                geometry: {
                    type: 'Point',
                    coordinates: [105.85473, 21.0334],
                },
            },
        ],
    };



    useEffect(() =>
    {
        if (openModal)
        {
            setTimeout(() =>
            {

                const map = new mapboxgl.Map({
                    container: 'vietmap1',
                    style: 'vietmap://styles/default/style.json',
                    hash: true,
                    center: [defaultLocation[0], defaultLocation[1]],
                    zoom: 15,
                    maxBounds: [
                        [102.0409, 7.730748],
                        [111.6685, 23.47731],
                    ],

                });

                // Đặt mức zoom mặc định ban đầu
                // map.setZoom(15);
                // Thêm sự kiện click cho đối tượng bản đồ
                map.on('click', (e: any) =>
                {
                    setLocationSelected([e?.lngLat?.lng, e?.lngLat?.lat]);
                });



                // Thêm các marker vào bản đồ
                for (const marker of geojson.features)
                {
                    const popupContent = marker.properties.message;

                    // Tạo một popup cho marker (tùy chọn)
                    const popup = new mapboxgl.Popup().setHTML(popupContent);

                    // Tạo một marker với icon SendOutlined
                    const el = document.createElement('div');
                    el.className = 'marker';

                    // Tạo một React element từ icon SendOutlined
                    const iconElement = React.createElement(LocationIcon);

                    // Gắn React element vào marker
                    ReactDOM.render(iconElement, el);

                    // Thêm marker vào bản đồ
                    new mapboxgl.Marker(el)
                        .setLngLat(locationSelected)
                        .setPopup(popup) // Thêm popup cho marker (tùy chọn)
                        .addTo(map);
                }

            }, 100);

        }

    }, [openModal, locationSelected]);



    const footerModal = (
        <div className="gap-3 flex justify-end">
            <Button
                className='!bg-[#faad14] !text-white'
                onClick={() =>
                {
                    handleCancelModal();
                }}
            >
                Hủy
            </Button>
            <Button
                className='!bg-[#24afc0] !text-white'
                onClick={() =>
                {
                    handleConfirmModal();
                }}
            >
                Xác nhận
            </Button>
        </div>
    );


    return (
        <>
            <Button
                className='!h-8 !rounded-md'
                onClick={() =>
                {
                    setOpenModal(true);
                }}
            >Chọn vị trí</Button>


            <Modal
                title='Chọn vị trí'
                open={openModal}
                onCancel={handleCancelModal}
                footer={footerModal}
                width={1000}
            >
                <div id="vietmap1"
                    style={{ width: '100%', height: '100vh' }}
                ></div>;

            </Modal>
        </>
    );
};

ModalSelectLocation.propTypes = {
    onChangeValue: PropTypes.func,
};