import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { KeyOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm, Tooltip } from 'antd';
import React, { useState } from 'react';

import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import { CANCEL, CONFIRM_TO_DISABLE_STATUS, CONFIRM_TO_ENABLE_STATUS, SUCCESS } from '../../../constants/MessageConstants';
import { ChangeIcon } from '../../../icons/ChangeIcon';
import { UserAccountSettingGridColumns } from '../../../types/config/userAccountSetting';
import { UserAccountDoctor } from '../../../types/UserAccountDoctor';

interface IGridUserAccount
{
    record: UserAccountDoctor[],
    onEdit: (value: UserAccountDoctor) => void;
    onChangeStatus: (value: UserAccountDoctor) => void;
    onOpenChangePassword: (value: UserAccountDoctor) => void;
}

const GridUserAccount = (props: IGridUserAccount) =>
{
    const { onEdit, onChangeStatus, onOpenChangePassword } = props;
    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...UserAccountSettingGridColumns,
            {
                headerName: 'Trạng thái',
                headerClass: 'text-center',
                field: 'status',
                cellRenderer: (params: ICellRendererParams) =>
                {
                    return params.value ? 'Đã xác thực' : 'Chưa xác thực';
                }
            }
            ,
            {
                headerName: 'Hoạt động',
                headerClass: 'text-center',
                field: 'isDisable',
                cellRenderer: (params: ICellRendererParams) =>
                {
                    return <div className='text-center'>{params.value ? 'Không' : 'Có'}</div>;
                }
            }
            ,
            {
                headerName: 'Hành động',
                field: 'action',
                width: 250,
                maxWidth: 250,
                pinned: 'right',
                headerClass: 'text-center',
                cellRenderer: (params: ICellRendererParams<UserAccountDoctor>) =>
                {
                    const data = params.data as UserAccountDoctor;
                    const [openConfirm, setOpenConfirm] = useState(false);
                    const handleConfirm = () =>
                    {
                        onChangeStatus(data);
                        setOpenConfirm(false);
                    };

                    return (
                        <div className="flex items-center justify-center">
                            <GridButtonBase type={'edit'} onClick={() => { onEdit(data); }} />
                            <Tooltip title={'Đổi mật khẩu'}>
                                <button
                                    type="button"
                                    className="text-white bg-gradient-to-r bg-purple-600  hover:bg-gradient-to-br  focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-3 py-2 text-center mr-2 mb-2"
                                    onClick={() =>
                                    {
                                        onOpenChangePassword(data);
                                    }}
                                >
                                    <KeyOutlined style={{ fontSize: '130%' }} />
                                </button>
                            </Tooltip>

                            {/* isDisable = true (đang tắt hoạt động) */}
                            <Popconfirm
                                placement="topRight"
                                title={data?.isDisable ? CONFIRM_TO_ENABLE_STATUS : CONFIRM_TO_DISABLE_STATUS}
                                onConfirm={() => handleConfirm()}
                                onCancel={() =>
                                {
                                    setOpenConfirm(false);
                                }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                                visible={openConfirm}
                            >
                                <Tooltip title={data?.isDisable ? 'Bật hoạt động' : 'Tắt hoạt động'}>
                                    <div
                                        onClick={() =>
                                        {
                                            // nếu là tài khoản đang đăng nhập thì không có action nào
                                            if (!data.isCurrentUser)
                                            {
                                                setOpenConfirm(true);
                                            }

                                        }}
                                        className={`${ data.isCurrentUser && 'cursor-not-allowed' } flex w-11 h-[38px] text-white ${ data?.isDisable ? ' bg-[#2C999C] hover:!bg-[#2C999C] focus:!bg-[#2C999C]' : 'bg-gray-600 hover:!bg-gray-600 focus:!bg-gray-600' }  font-medium rounded-lg mr-2 mb-2 px-3 justify-center items-center`}
                                    >
                                        <ChangeIcon />
                                    </div></Tooltip>
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };
    return (
        <div className='mt-2 ag-theme-alpine'
            style={{ height: 'calc(70vh)', display: 'flex', flexDirection: 'column' }}
        >
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                rowGroupPanelShow={'always'}
                pivotPanelShow={'always'}
                columnDefs={getColumnConfig()}
                pagination={true}
                suppressClickEdit={true}
                rowData={props.record}
            />
        </div>
    );
};

export default GridUserAccount; 