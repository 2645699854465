/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../saga/store';
import { Clinic } from '../../types/Clinic';
import { User } from '../../types/User';

export interface ClinicPayload{ notification:string}

export interface ClinicState{notification:string, showStorageWarning: boolean}

const initialState:ClinicState= {
    notification:'',
    showStorageWarning: false
};
const clinicSlice = createSlice({
    name:'clinic',
    initialState:initialState,
    reducers:{
        loadNotification(state, action:PayloadAction<ClinicPayload>){  
            state.notification= action.payload.notification || '';
        },
        setShowStorageWarning(state, {payload}:{payload: boolean}) {
            state.showStorageWarning = payload;
        }
    }
});

//action
export const clinicAction =clinicSlice.actions;
//selector
export const selectNotification = (state:RootState) => state.clinic.notification;
export const selectShowStorageWarning = (state:RootState) => state.clinic.showStorageWarning;

//reducers
const clinicReducer = clinicSlice.reducer;
export default clinicReducer;