import 'react-image-crop/dist/ReactCrop.css';

import React, { useCallback, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
// const ReactCrop = require('react-image-crop');
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import useMergeState from '../../hooks/useMergeState';
import { useDebounceEffect } from './useDebounceEffect';
interface IHeaderProps
{
    imageThumb: string,
    onCropImage: (blob: Blob, src: string) => void
}


interface IState
{
    imgSrc?: string | null,
    blob?: Blob | null,
}
const CropImageForm = (props: IHeaderProps) =>
{
    const { imageThumb, onCropImage } = props;
    // const [imgSrc, setImgSrc] = useState('');
    // const [blob, setBlob] = useState('');
    const [state, setState] = useMergeState<IState>({
        // imgSrc:null
    });
    let imageRef = useRef<HTMLImageElement>(null);
    const fileUrl = useRef<string>('');
    const [crop, setCrop] = useState<Crop>(
        {
            unit: 'px', // Can be 'px' or '%'
            x: 50,
            y: 50,
            width: 50,
            height: 50
        }
    );
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number>(16 / 9);

    const onCropChange = (crop: Crop) =>
    {
        setCrop(crop);
    };
    const onImageLoaded = (image: any) =>
    {
        imageRef = image;
    };

    const onCropComplete = (crop: Crop) =>
    {
        makeClientCrop(crop);
    };

    const makeClientCrop = async (crop: Crop) =>
    {
        if (imageRef && crop.width && crop.height)
        {
            const croppedImageUrl = await getCroppedImg(
                imageRef?.current,
                crop,
                'newFile.jpeg'
            );
            // setImgSrc(croppedImageUrl as string);
        }
    };

    const getCroppedImg = (image: any, crop: Crop, fileName: string) =>
    {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx!.imageSmoothingQuality = 'high';

        ctx!.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) =>
        {
            canvas.toBlob(
                (blob: any) =>
                {
                    if (!blob)
                    {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(fileUrl.current);
                    fileUrl.current = window.URL.createObjectURL(blob);
                    setState({
                        blob: blob,
                        imgSrc: fileUrl.current
                    });
                    resolve(fileUrl.current);
                },
                'image/jpeg',
                1
            );
        });
    };
    return <div className='flex flex-col w-full h-full'>
        <div className="flex flex-1" style={{ width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <div className='mt-4' style={{ width: '50%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '90%', height: '90%' }}>
                    <ReactCrop
                        crop={crop}
                        onChange={onCropChange}
                        onComplete={(c) => onCropComplete(c)}
                    // aspect={aspect}
                    >
                        <img
                            ref={imageRef}
                            alt="Crop me"
                            src={imageThumb}
                            style={{ transform: `scale(${ scale }) rotate(${ rotate }deg)` }}
                            onLoad={onImageLoaded}
                        />
                    </ReactCrop>
                </div>
            </div>
            <div className='mt-4' style={{ width: '50%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                {
                    state.imgSrc ?
                        <img
                            src={state.imgSrc}
                            style={{ maxWidth: '90%', maxHeight: '90%' }} />
                        : <div></div>
                }
            </div>
        </div>
        <div className="w-full flex justify-center items-center" style={{ height: '50px' }}>
            <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                buttonName={'Hoàn tất'}
                buttonType="save"
                disabled={state.imgSrc ? false : true}
                onClick={() => { onCropImage(state.blob as Blob, state.imgSrc as string); }}
            />
        </div>
    </div>;
};
export default CropImageForm; 
