import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Tabs } from 'antd';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

import { Loading } from '../../components/common/Loading';
import { IComboBox } from '../../components/FormFields/ComboBox';
import Overlay from '../../components/Overlay/Overlay';
import useMergeState from '../../hooks/useMergeState';
import { Clinic } from '../../types/Clinic';
import ClinicInfomationForm from './ClinicInfomationForm';
import ClinicStatisticForm from './ClinicStatisticForm';



const { TabPane } = Tabs;
interface IState
{
    loading: boolean
}
interface IClinicProps
{
    onLoadAllClinic: () => Promise<void>
    onClose: () => void,
    defaultValue?: Clinic,
    optionsProvince?: IComboBox[]
    role?: 'PARTNER' | 'SUPER_ADMIN'
}

const ClinicDetailForm = (props: IClinicProps) =>
{
    const { onClose, onLoadAllClinic, role = 'SUPER_ADMIN', defaultValue, optionsProvince } = props;
    const [state, setState] = useMergeState<IState>({
        loading: true
    });
    useEffect(() =>
    {
        // setState({
        //     ...state,
        //     loading: false
        // });
    }, []);


    // if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //     <Loading />
    // </div>;

    return (
        <div
            className='overflow-y-hidden overflow-x-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%', borderRadius: '8px' }}
        >
            <div className='flex-1 ml-2 w-full h-full'>
                <Tabs className='w-full h-full'>
                    <TabPane
                        className='w-full h-full'
                        tab="Thông tin chi tiết"
                        key="1">
                        <ClinicInfomationForm role={role}
                            onClose={onClose}
                            onLoadAllClinic={onLoadAllClinic}
                            defaultvalue={defaultValue}
                            optionsProvince={optionsProvince as IComboBox[]} />
                    </TabPane>
                    <TabPane tab="Thống kê" key="2">
                        <ClinicStatisticForm defaultvalue={defaultValue} optionsProvince={optionsProvince as IComboBox[]} />
                    </TabPane>

                </Tabs>
            </div>
            {/* <Overlay ref={overlayRef} /> */}
        </div>

    );
};

export default ClinicDetailForm;