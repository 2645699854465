// import '../style/auto-send.scss';

import { Tabs } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import { Loading } from '../../../components/common/Loading';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { AutoCancle, AutoSend, AutoSendUpdate } from '../../../types/AutoSend/AutoSend';
import { Clinic } from '../../../types/Clinic';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import InfomationView from '../../Infomation/pages/InfomationView';
import WorkingTimeClinicView from '../../Infomation/pages/WorkingTimeClinicView';

interface IState
{
    infoAutoSendNoti: AutoSend;
    infoAutoSendSms: AutoSend;
    infoAutoCancle: AutoCancle;

    loading: boolean
}
const { TabPane } = Tabs;

const ClinicSettingView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const [state, setState] = useMergeState<IState>({
        infoAutoSendNoti: {},
        infoAutoSendSms: {},
        infoAutoCancle: {},
        loading: true
    });
    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
    };

    const loadInfoAutoSend = async () =>
    {
        // Axios.all([
        //     await AxiosClient.get<ApiResponse<AutoSend>>(BASE_API_PATH + indexAutoSendNotification, { withCredentials: true }),
        //     await AxiosClient.get<ApiResponse<AutoSend>>(BASE_API_PATH + indexAutoSendSms, { withCredentials: true }),
        //     await AxiosClient.get<ApiResponse<AutoCancle>>(BASE_API_PATH + indexCancleExamBeing, { withCredentials: true })
        // ]).then(axios.spread((resNoti, resSms, resSmsCancle) =>
        // {
        //     let infomationNoti: AutoSend = {};
        //     let infomationSms: AutoSend = {};
        //     let infomationAutoCancle: AutoCancle = {};

        //     if (resNoti.data.success)
        //     {
        //         infomationNoti = resNoti.data.results as unknown as AutoSend;
        //     }
        //     if (resSms.data.success)
        //     {
        //         infomationSms = resSms.data.results as unknown as AutoSend;
        //     }
        //     if (resSmsCancle.data.success)
        //     {
        //         infomationAutoCancle = resSmsCancle.data.results as unknown as AutoCancle;
        //     }

        //     setState({
        //         ...state,
        //         infoAutoSendNoti: infomationNoti,
        //         infoAutoSendSms: infomationSms,
        //         infoAutoCancle: infomationAutoCancle,
        //         loading: false
        //     });


        // }));
    };







    return (
        <div className='overflow-hidden flex flex-col' style={{ width: '100%', height: '100%' }}>
            {/* <div className="flex mt-6 justify-between" style={{ height: '50px' }}>
                <div className='text-xl font-bold text-[#2c999c]  ml-3'>
                    CẤU HÌNH TỰ ĐỘNG
                </div>
            </div> */}
            <div className='flex-1 ml-2' style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                <Tabs className='w-full h-full'>
                    <TabPane
                        className='w-full h-full'
                        tab="Thông tin phòng khám"
                        key="1">
                        <InfomationView />
                    </TabPane>

                    <TabPane tab="Thông tin giờ hoạt động" key="2">
                        <WorkingTimeClinicView />

                    </TabPane>
                </Tabs>

            </div>
            <Overlay ref={overlayRef} />

        </div>

    );
};

export default ClinicSettingView;