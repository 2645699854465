import { yupResolver } from '@hookform/resolvers/yup';
import {useQuery} from '@tanstack/react-query';
import { Col, DatePicker, Form, Row, Space, TimePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import NumberField from '../../components/FormFields/NumberField';
import RadioField from '../../components/FormFields/RadioField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { optionGender } from '../../constants/OptionsCombo';
import { pathClock, pathDateTime } from '../../constants/SvgIcon';
import { phoneRegExp } from '../../constants/YupContants';
import { comboDistrict } from '../../features/Infomation/api/constants';
import { loadComboPatient, loadInfoPatient } from '../../features/PatientWaiting/api/constants';
import {useDebounce} from '../../hooks/useDebounce';
import { ApiResponse } from '../../types/api.type';
import { ClinicCreateDto } from '../../types/Clinic/ClinicGridDto';
import { PatientFileCreateRecord } from '../../types/PatientRecord/PatientFileRecord';
import { BASE_API_PATH } from '../../utils/ApiUtil';

const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
    userName: yup.string().required('Vui lòng nhập thông tin!').min(8, 'Số điện thoại lớn hơn 8 số.').max(12, 'Số điện thoại ít hơn 12 số').matches(phoneRegExp, 'Định dạng không hợp lệ'),
    address: yup.string().required('Vui lòng nhập thông tin!').max(255),
    // introduce: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IClinicProps
{
    handleSubmit: (value: ClinicCreateDto) => Promise<void>;
    onClose: () => void,
    defaultValue?: ClinicCreateDto,
    optionsProvince: IComboBox[]
}

const ClinicCreateForm = (props: IClinicProps) =>
{
    const { handleSubmit, optionsProvince, defaultValue } = props;
    const disabled = useRef<boolean>(defaultValue ? true : false);
    // const [disabled, setDisabled] = useState<boolean>();
    const overlayRef = useRef<OverlayRef>(null);
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>();
    const [searchAddress, setSearchAddress] = useState(defaultValue?.fullAddress ?? 'Phường');
    const searchAddressText = useDebounce(searchAddress, 400);

    useEffect(() =>
    {
        if (defaultValue?.provinceId)
        {
            loadOptionsDistrict(defaultValue?.provinceId);
        }
    }, []);

    useEffect(() => {
        setSearchAddress(defaultValue?.fullAddress ?? 'Phường');
    }, [defaultValue?.fullAddress]);

    const {data: addressOptions} = useQuery(['getAddressOptions', searchAddressText], async () => {
        const res = await fetch(`https://maps.vietmap.vn/api/search/v3?apikey=ba6f250a9bf1df85dc1cae8ca3f605ad5e9be5c871a83240&text=${searchAddressText}`).then(res => res.json());
        return res.map((item: {ref_id: string; address: string}) => ({
            value: item.ref_id,
            label: item.address
        }));
    }, {
        initialData: []
    });

    const getLocationByRefId = async (refId: string) => {
        return await fetch(`https://maps.vietmap.vn/api/place/v3?apikey=ba6f250a9bf1df85dc1cae8ca3f605ad5e9be5c871a83240&refid=${refId}`).then(res => res.json());
    };

    const loadOptionsDistrict = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
        if (response.data.success)
        {
            const data = response.data.results as unknown as IComboBox[];
            setOptionsDistrict(data);
        }
    };


    // const loadComboInfo = async () =>
    // {
    //     overlayRef.current?.open();
    //     let comboBoxPatientFile: IComboBox[] = [];

    //     const resCombo = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadComboPatient, { withCredentials: true });
    //     if (resCombo.data.success)
    //     {
    //         comboBoxPatientFile = resCombo.data.results as IComboBox[];
    //         setComboBoxPatientFile(comboBoxPatientFile);
    //     }
    //     overlayRef.current?.close();


    // };
    const formRef = useRef<BaseFormRef>(null);
    const onSubmit = async (value: ClinicCreateDto) =>
    {
        overlayRef.current?.open();
        const location = await getLocationByRefId(value.fullAddress as string);

        await handleSubmit({...value, fullAddress: location.display,
            lat: location.lat,
            lon: location.lng});
        overlayRef.current?.close();
    };



    const onLoadInfoPatient = async (value: number) =>
    {
        overlayRef.current?.open();
        const res = await AxiosClient.get<ApiResponse<PatientFileCreateRecord>>(BASE_API_PATH + loadInfoPatient + value);
        if (res.data.success)
        {
            const data = res.data.results as PatientFileCreateRecord;
            data.yearOfBirth = moment(data.yearOfBirth, 'yyyy');
            formRef.current?.setValues(
                {
                    ...data,
                    patientFileId: value.toString()
                }
            );
        }
        overlayRef.current?.close();
    };

    const onChangeProvince = (value: string) =>
    {
        const dataForm = formRef.current?.getValues();
        loadOptionsDistrict(value);
        formRef.current?.setValues({ ...dataForm, districtId: '' });
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            defaultValues={defaultValue}
            className="p-3"
            resolver={yupResolver(schema)} >

            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            requiredError={true}
                            disabled={disabled.current}
                            name={'userName'}
                            label={'SĐT đăng ký'} />
                    </Form.Item>
                </Col>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            requiredError={true}
                            name={'name'}
                            label={'Tên phòng khám'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            onChange={onChangeProvince}
                            comboValue={optionsProvince}
                            name={'provinceId'}
                            label={'Thành phố/Tỉnh'} />

                    </Form.Item>
                </Col>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            comboValue={optionsDistrict || []}
                            name={'districtId'}
                            label={'Quận/Huyện'} />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '100%' }} className="flex-1">
                    <Form.Item>
                        {!defaultValue?.id ? <TextField noUpperCaseLabel={true}
                            name={'address'}
                            requiredError={true}
                            label={'Địa chỉ'} />
                            :
                            <ComboBox
                                onSearch={(e: string) => setSearchAddress(e)}
                                name="fullAddress"
                                label="Địa chỉ phòng khám"
                                comboValue={addressOptions}/>}
                    </Form.Item>

                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '100%' }} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            name={'introduce'}
                            label={'Giới thiệu phòng khám'} />
                    </Form.Item>

                </Col>
            </Row>

            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 110 }}
                    className="mr-2"
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>
    );
};

export default ClinicCreateForm; 
