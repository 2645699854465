

export const indexPatientWaiting = '/PatientFile/get-index';

export const deletePatientWaiting = '/PatientFile/delete-custom/';

export const updatePatientDay = ( patientWaitId: number ) => `/PatientFile/update-doctor/${patientWaitId}`;

export const successPatientDay = (patientWaitId: number) => `/PatientFile/disable/${patientWaitId}`;

export const removePatientDay = ( patientWaitId: number ) => `/PatientFile/remove-doctor/${patientWaitId}`;

export const updateNumberOrderPatientWaiting = '/PatientFile/update-number-order/';


export const updatePatientWaiting = '/PatientFile/update-custom';
export const createPatientWaiting = '/PatientFile/create-custom';

export const loadComboPatient = '/PatientFile/load-combo-patient';

export const loadInfoPatient = '/PatientFile/load-info-patient/';



