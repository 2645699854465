import { ColDef } from 'ag-grid-community';
import QRCode from 'qrcode.react';
import { useEffect, useRef } from 'react';
import React from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import { Loading } from '../../../components/common/Loading';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { loadMedicineResult } from '../../../features/PatientRecord/api/constants';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { InfoPatientFileResulExamDto } from '../../../types/ExamResult/ExamResult';
import { MedicineResultColumns, MedicneResultDto } from '../../../types/ExamResult/MedicineResult';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import HeaderMedicineResult from './HeaderMedicineResult';
import MedicineResultFormScan from './MedicineResultFormScan';

interface IState
{
    medicineResult: MedicneResultDto;
    loading: boolean;
}
interface Props
{
    medicalExaminationId: number
    datePatient: string

}

const MedicineResultForm: React.FC<Props> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const componentRef = useRef<HTMLDivElement>(null);

    const { medicalExaminationId } = props;

    const [state, setState] = useMergeState<IState>({
        medicineResult: {},
        loading: true,
    });
    useEffect(() =>
    {
        loadApi();
    }, []);
    const loadApi = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<MedicneResultDto>>(BASE_API_PATH + loadMedicineResult + medicalExaminationId);
        if (response.data.success)
        {
            const data = response.data.results;
            setState({
                loading: false,
                medicineResult: data as MedicneResultDto
            });
        }
    };


    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...MedicineResultColumns,
        ];
    };


    const renderGrid = () =>
    {

        return <div className='flex flex-col flex-1'>
            {state.medicineResult.noteMedicine ? <div className='flex justify-between' style={{ height: 50 }}>
                <div className="ml-6 flex aligns-center items-center" style={{ width: '500px' }}>
                    <p className='font-bold'>Ghi chú đơn thuốc:  </p>
                    <p className='ml-1'> {state.medicineResult.noteMedicine}</p>
                </div>
            </div> : <div style={{ height: 10 }}></div>}

            <div className="w-full" style={{ height: '400px' }}>
                <BaseGrid
                    className="ag-theme-alpine base-grid"
                    // className="ag-theme-alpine"

                    rowSelection={'multiple'}
                    columnDefs={getColumnConfig()}
                    pagination={true}
                    // pagination={false}

                    paginationPageSize={10}
                    suppressRowTransform={true}
                    suppressClickEdit={true}
                    rowData={state.medicineResult.medicineResultGrid}
                    enableBrowserTooltips={true}
                    overlayNoRowsTemplate={'Không có dữ liệu!'}
                />
            </div>
        </div>;
    };

    if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;


    return (
        <div
            className='overflow-y-hidden'
            // ref={componentRef}

            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div style={{ height: '100%' }} className='flex flex-col'>
                <HeaderMedicineResult
                    isScan={true}
                    componentRef={componentRef}
                    infoPatientExam={state.medicineResult?.infoPatient as InfoPatientFileResulExamDto}
                    prescriptionAmount={state.medicineResult?.prescriptionAmount}
                    datePatient={props.datePatient}
                    note={state.medicineResult?.note || ''}
                    symptom={state.medicineResult?.symptom || ''}
                />
                {renderGrid()}
                <div style={{ height: 0, display: 'none' }} className='overflow-y-hidden'>
                    <MedicineResultFormScan
                        medicineResult={state.medicineResult}
                        componentRef={componentRef}
                    />
                </div>
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />

        </div>

    );
};

export default MedicineResultForm;