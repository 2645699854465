/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaretDownOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { Select, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import { isFunction } from 'lodash';
import React, { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps
{
    label?: string;
    labelClass?: string;
    labelStyle?: CSSProperties;
    errors?: any;
    name: string;
    type?: string;
    value?: string;
    noFieldForm?: boolean;
    comboValue: IComboBox[] | null;
    onChange?: ((value: any, option: any) => void) | undefined
    requiredError?: boolean | null;
    noShowLabel?: true | false;
    isClear?: true | false;
    indexLabel?: string;
    noUpperCaseLabel?: boolean;
    labelPositison?: 'left' | 'top';
    disabled?: boolean
    onSearch?: any
}

export type IComboBox = {
    label: string;
    value: string;
    disable?: boolean;
}

export type NumberComboBox = {
    label: string;
    value: number;
}

export type IComboBoxAntd = {
    label: string;
    value: string;
    disabled?: boolean;
}

const ComboBox: React.FC<IProps> = (props) =>
{
    const {onSearch, label, labelStyle = {}, name, isClear, comboValue, onChange, noFieldForm, value, requiredError, noShowLabel, indexLabel, noUpperCaseLabel = false, disabled = false, labelPositison } = props;
    const methods = useFormContext();
    const tooltipValue = comboValue?.join(', ');

    const renderToolTip = () =>
    {
        return <Tooltip placement="topLeft" title={tooltipValue} />;
    };

    const Remove_Dau = (str: string) =>
    {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        return str;
    };

    const { control, formState: { errors }, setValue } = methods;

    return (
        <div className="flex flex-col justify-start w-full">
            <div className="flex items-center w-full">
                <div className={labelPositison === 'left' ? 'w-full px-3 md:mb-0 flex' : 'w-full px-3 md:mb-0'}>
                    {/* <div className="w-full px-3 md:mb-0"> */}
                    <div className='flex' style={{ width: 250 }}>
                        {(label && !noShowLabel) && (
                            <label
                                className={noUpperCaseLabel ?
                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                    : 'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                                }
                                style={{
                                    ...labelStyle,
                                }}
                            >
                                {indexLabel ? (indexLabel + '. ') : null}{label}
                            </label>

                        )}
                        {requiredError && (<div className='text-red-600 ml-1 -left-4 -top-4'> *</div>)}
                    </div>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) =>
                        {
                            return <Select {...field}
                                onSearch={onSearch}
                                showSearch={true}
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '7px',
                                    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'
                                }}
                                bordered={false}
                                value={value ? value : (!noFieldForm ? (field.value ? field.value.toString() : null) : null)}
                                onChange={isFunction(onChange) ? (value, option) => { onChange(value, option); setValue(name, value); } : (value) => { setValue(name, value); }}
                                disabled={disabled}
                                onMouseEnter={renderToolTip}
                                notFoundContent="Không có dữ liệu!"
                                placeholder={label ? ('Nhập ' + label?.toLocaleLowerCase() + '...') : ''}
                                allowClear={!isClear ? false : true}
                                status={errors[name] ? 'error' : ''}
                                className='border-red-800'
                                filterOption={(input, option) =>
                                    Remove_Dau(option?.props.children.toLowerCase()).indexOf(Remove_Dau(input.toLowerCase())) >= 0
                                    || Remove_Dau(option?.props.value.toLowerCase()).indexOf(Remove_Dau(input.toLowerCase())) >= 0
                                }
                                suffixIcon={disabled ? null : <CaretDownOutlined />}
                            >
                                {comboValue?.map((item: IComboBox) =>
                                {
                                    return <Option key={'1'}
                                        value={item.value}
                                        disabled={!!item.disable}>{item.label}</Option>;
                                })}
                            </Select>;
                        }
                        }
                    />
                </div>
            </div>
            {errors && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (message ? <p className=" ml-4 m-0 text-red-400">{message}</p> : null)}
                />
            )}
        </div>
    );
};

export default ComboBox;
