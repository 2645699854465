import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import NumberField from '../../components/FormFields/NumberField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { ClinicVisitsUpdateDto } from '../../types/ClinicVisits/ClinicVisitsDto';
import { DesignationMedicalExam } from '../../types/DesignationMedicalExam/DesignationMedicalExam';

const schema = yup.object().shape({
    numberAdd: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IProps
{
    handleSubmit: (value: ClinicVisitsUpdateDto) => Promise<void>;
    onClose: () => void,
    defaultValue?: ClinicVisitsUpdateDto,
}

const ClinicVisitsForm = (props: IProps) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, defaultValue } = props;
    const onSubmit = async (value: ClinicVisitsUpdateDto) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultValue ? defaultValue : {}}
            resolver={yupResolver(schema)} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField name={'name'}
                            disabled={true}
                            label={'Tên phòng khám'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <NumberField
                            name={'numberAdd'}
                            requiredError={true}
                            label={'Số lượng'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />
        </BaseForm>
    );
};
export default ClinicVisitsForm; 
