import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {Tabs} from 'antd';
import {useEffect, useRef} from 'react';

import CustomModal, {ModalRef} from '../../../components/CustomModal/CustomModal';
import Overlay, {OverlayRef} from '../../../components/Overlay/Overlay';
import useMergeState from '../../../hooks/useMergeState';
import NotificationDashboardView from './NotificationDashboardView';
import PatientFileDashboardView from './PatientFileDashboardView';
import RevenueStatisticView from './RevenueStatisticView';
import StatisticTodayView from './StatisticTodayView';
import StorageDashboardView from './StorageDashboardView';


const { TabPane } = Tabs;
interface IState
{
    loading: boolean
}
const StatisticsDashboardView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const [state, setState] = useMergeState<IState>({
        loading: true
    });
    useEffect(() =>
    {
        setState({
            ...state,
            loading: false
        });
    }, []);


    // if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //     <Loading />
    // </div>;

    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='flex-1 ml-2 w-full h-full'>
                <Tabs className='w-full h-full'>
                    <TabPane
                        className='w-full h-full'
                        tab="Hôm nay"
                        key="1">
                        <StatisticTodayView />
                    </TabPane>
                    <TabPane
                        className='w-full h-full'
                        tab="Doanh thu"
                        key="2">
                        <RevenueStatisticView />
                    </TabPane>
                    <TabPane tab="Thông báo" key="3">
                        <NotificationDashboardView />

                    </TabPane>
                    <TabPane tab="Hồ sơ khám bệnh" key="4">
                        <PatientFileDashboardView />
                    </TabPane>
                    <TabPane tab="Lưu trữ" key="5">
                        <StorageDashboardView />
                    </TabPane>
                </Tabs>
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default StatisticsDashboardView;