import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import React, { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import { Loading } from '../../../components/common/Loading';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { DELETE_TO_SUCCESS, UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import DesignationMedicalExamForm from '../../../module/DesignationMedicalExam/DesignationMedicalExamForm';
import { ApiResponse, PaginatedList } from '../../../types/api.type';
import { DesignationMedicalExam } from '../../../types/DesignationMedicalExam/DesignationMedicalExam';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createDesignationMedicalExam, deleteDesignationMedicalExam, indexDesignationMedicalExam, loadComboMedicalExamTemplate, updateDesignationMedicalExam } from '../api/constants';
import GridDesignationMedicalExam from './GridDesignationMedicalExam';
import HeaderDesignationMedicalExam from './HeaderDesignationMedicalExam';

interface IState
{
    valueOption: string;
    designationMedicalExam: DesignationMedicalExam[];
    loading?: boolean;
}
const DesignationMedicalExamView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    // const [optionsMedicalExamTemplate, setOptionsMedicalExamTemplate] = useState<IComboBox[]>([]);
    const [state, setState] = useMergeState<IState>({
        valueOption: '',
        designationMedicalExam: [],
        loading: true
    });
    useEffect(() =>
    {
        // loadOptionsMedicalExamTemplate();
        onLoadDataGrid();
        if (state.loading)
        {
            setState({
                ...state,
                loading: false
            });
        }
    }, []);

    const onLoadDataGrid = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<DesignationMedicalExam[]>>(BASE_API_PATH + indexDesignationMedicalExam, { withCredentials: true });
        let designationMedicalExamOption: DesignationMedicalExam[] = [];
        if (response.data.success)
        {
            const data = response.data.results as DesignationMedicalExam[];
            designationMedicalExamOption = data;
        }
        setState({
            designationMedicalExam: designationMedicalExamOption
        });
    };
    const onOpenCreate = () =>
    {
        modalRef.current?.onOpen(<DesignationMedicalExamForm handleSubmit={onCreateDesignationMedicalExam}
            onClose={onCloseModal}
            defaultvalue={{groupDesignation: 0, name: ''}} />, 'Thêm dịch vụ', 30);
    };

    const onCreateDesignationMedicalExam = async (value: DesignationMedicalExam) =>
    {
        const params: DesignationMedicalExam = {
            ...value,
            designationTypeId: Number(value.designationTypeId) ?? 1
            // medicalExamTemplateId: parseInt(state.valueOption)
        };
        const response = await AxiosClient.post<ApiResponse<DesignationMedicalExam>>(BASE_API_PATH + createDesignationMedicalExam, params, { withCredentials: true });
        if (response.data.success)
        {
            // await onChangeComboMedicalExam(state.valueOption);
            await onLoadDataGrid();
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onOpenUpdate = (value: DesignationMedicalExam) =>
    {
        modalRef.current?.onOpen(<DesignationMedicalExamForm isUpdate={true}
            defaultvalue={{...value, designationTypeId: value?.designationTypeId?.toString() ?? '1'}}
            handleSubmit={onUpdateDesignationMedicalExam}
            onClose={onCloseModal} />, 'Sửa chỉ định khám', 30);
    };

    const onUpdateDesignationMedicalExam = async (value: DesignationMedicalExam) =>
    {
        const designationMedicalExamId = value.id;
        const response = await AxiosClient.put<ApiResponse<DesignationMedicalExam>>(BASE_API_PATH + updateDesignationMedicalExam + designationMedicalExamId,
            {...value,
                designationTypeId: Number(value.designationTypeId) ?? 1
            });
        if (response.data.success)
        {
            // await onChangeComboMedicalExam(state.valueOption);
            await onLoadDataGrid();

            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onDeleteDesignationMedicalExam = async (value: DesignationMedicalExam) =>
    {
        overlayRef.current?.open();
        const designationMedicalExamId = value.id;
        const response = await AxiosClient.delete<ApiResponse<DesignationMedicalExam>>(BASE_API_PATH + deleteDesignationMedicalExam + designationMedicalExamId);
        if (response.data.success)
        {
            // await onChangeComboMedicalExam(state.valueOption);
            await onLoadDataGrid();

            ApiUtil.ToastSuccess(DELETE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError('Thiết lập đang được sử dụng tại vị trí khác!');
        }
        onCloseModal();
        overlayRef.current?.close();

    };



    if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;
    return (
        <div
            className="ag-theme-alpine"
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <HeaderDesignationMedicalExam valueOptions={state.valueOption} onOpenCreate={onOpenCreate} />
            <GridDesignationMedicalExam record={state.designationMedicalExam.filter((item: DesignationMedicalExam) => item.groupDesignation === 0)}
                onEdit={onOpenUpdate}
                onDelete={onDeleteDesignationMedicalExam}
                tableName={'Dịch vụ chính'}/>
            <GridDesignationMedicalExam record={state.designationMedicalExam.filter((item: DesignationMedicalExam) => item.groupDesignation === 1)}
                onEdit={onOpenUpdate}
                onDelete={onDeleteDesignationMedicalExam}
                tableName={'Dịch vụ bổ sung'}/>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />

        </div>

    );
};

export default DesignationMedicalExamView;