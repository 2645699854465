import PropTypes from 'prop-types';
import React from 'react';

interface LocationIconData
{
    size?: string
    color?: string

}

export const LocationIcon: React.FC<LocationIconData> = (props) =>
{
    const { size = '3rem', color = 'red' } = props;
    return (
        <svg width={size}
            height={size}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 19.1583L15.5375 14.6208C16.4348 13.7234 17.0459 12.5801 17.2934 11.3354C17.541 10.0907 17.4138 8.80055 16.9282 7.62809C16.4425 6.45564 15.62 5.45353 14.5648 4.74849C13.5096 4.04344 12.2691 3.66713 11 3.66713C9.73093 3.66713 8.49036 4.04344 7.43516 4.74849C6.37996 5.45353 5.55752 6.45564 5.07184 7.62809C4.58616 8.80055 4.45905 10.0907 4.70658 11.3354C4.95412 12.5801 5.56518 13.7234 6.4625 14.6208L11 19.1583ZM11 21.7507L5.16634 15.917C4.01256 14.7632 3.22684 13.2932 2.90852 11.6929C2.5902 10.0925 2.75358 8.43373 3.37801 6.92624C4.00243 5.41876 5.05986 4.13029 6.41656 3.22377C7.77326 2.31726 9.36831 1.8334 11 1.8334C12.6317 1.8334 14.2267 2.31726 15.5834 3.22377C16.9401 4.13029 17.9976 5.41876 18.622 6.92624C19.2464 8.43373 19.4098 10.0925 19.0915 11.6929C18.7732 13.2932 17.9874 14.7632 16.8337 15.917L11 21.7507ZM11 11.9167C11.4862 11.9167 11.9525 11.7235 12.2964 11.3797C12.6402 11.0359 12.8333 10.5696 12.8333 10.0833C12.8333 9.5971 12.6402 9.13079 12.2964 8.78697C11.9525 8.44315 11.4862 8.25 11 8.25C10.5138 8.25 10.0475 8.44315 9.70364 8.78697C9.35982 9.13079 9.16667 9.5971 9.16667 10.0833C9.16667 10.5696 9.35982 11.0359 9.70364 11.3797C10.0475 11.7235 10.5138 11.9167 11 11.9167ZM11 13.75C10.0275 13.75 9.09491 13.3637 8.40728 12.6761C7.71964 11.9884 7.33334 11.0558 7.33334 10.0833C7.33334 9.11087 7.71964 8.17824 8.40728 7.49061C9.09491 6.80297 10.0275 6.41667 11 6.41667C11.9725 6.41667 12.9051 6.80297 13.5927 7.49061C14.2804 8.17824 14.6667 9.11087 14.6667 10.0833C14.6667 11.0558 14.2804 11.9884 13.5927 12.6761C12.9051 13.3637 11.9725 13.75 11 13.75Z"
                fill={color}
            />
        </svg>
    );
};

LocationIcon.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};
