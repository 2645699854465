import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Col, Form, Row } from 'antd';
import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { AutoCancle } from '../../types/AutoSend/AutoSend';

const schema = yup.object().shape({
    // numberTimeCancle: yup.string().required('Vui lòng chọn giờ!'),
});

interface IInfomationProps
{
    formRef: React.RefObject<BaseFormRef>
    handleSubmit: (value: AutoCancle) => Promise<void>;
    defaultvalue?: AutoCancle

}

const AutoCancleExamForm = (props: IInfomationProps) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const { handleSubmit, defaultvalue, formRef } = props;
    const [isActive, setIsActive] = useState<boolean>(defaultvalue?.isCancle ? true : false);
    const optionHour24h: IComboBox[] = [];
    for (let i = 0; i < 24; i++)
    {
        optionHour24h.push({
            label: i.toString() + ':00',
            value: i.toString()
        });
    }
    const onSubmit = async (value: AutoCancle) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };

    return (
        <BaseForm
            ref={formRef}
            className=" flex flex-col h-full w-full"
            defaultValues={defaultvalue}
            onSubmit={(value) => { onSubmit(value); }} >
            <div className='flex flex-col flex-1'>
                <Row className="flex flex-col mb-2 ml-2" gutter={24}>
                    <Col className="ml-2" style={{ width: '190px' }}>
                        <Controller
                            name={'isCancle'}
                            defaultValue={false}
                            render={({ field }) =>
                            {
                                return <Checkbox
                                    {...field}
                                    className="checkbox-red mt-4"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onChange={(value) =>
                                    {
                                        const dataForm = formRef?.current?.getValues();
                                        formRef?.current?.setValues({ ...dataForm, isCancle: value.target.checked });
                                        setIsActive(value.target.checked);
                                    }}
                                    defaultChecked={defaultvalue?.isCancle}


                                >
                                    <div className='mt-3'>
                                        Tự động hủy
                                    </div>

                                </Checkbox>;
                            }
                            }
                        />
                    </Col>

                </Row>
                <Row>
                    <Col style={{ width: '400px' }} className="mt-2">
                        <Form.Item>
                            <ComboBox
                                noUpperCaseLabel={true}
                                requiredError={true}
                                disabled={!isActive}
                                comboValue={optionHour24h}
                                name={'numberTimeCancle'}
                                label={'Thời gian hủy luồng khám mỗi ngày'} />

                        </Form.Item>
                    </Col>
                </Row>

            </div>
            <div className="footer flex justify-center items-center mt-4" style={{ height: 50 }}>
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'HOÀN TẤT'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>

    );
};

export default AutoCancleExamForm;