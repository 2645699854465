import { Col, Form, Row } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import TextAreaField from '../../../components/FormFields/TextAreaField';
import TextField from '../../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { DiseaseAnalysisProcess, DiseaseAnalysisProcessCreate, DiseaseAnalysisProcessDetailViewDto } from '../../../types/DiseaseAnalysisProcess/DiseaseAnalysisProcess';
import { InfoFileUpload } from '../../../types/UploadFile/InfoFileUpload';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { loadCombo, loadDiseaseAnalysisById, updateDiseaseProcess } from '../api/constants';

interface IProps
{
    imageFileBeingExam?: { [medicalPicturesId: number]: InfoFileUpload[] } | null;
    optionsMedicalMedicalPictureLayout: IComboBox[];
    loadDiseaseAnalysis: () => Promise<void>;
    diseaseAnalysisProcess?: DiseaseAnalysisProcessDetailViewDto;
    formRef?: React.RefObject<BaseFormRef>;
    medicalExaminationId?: number;
    setSelectFlow: React.Dispatch<React.SetStateAction<number>>
    onChangeProcessBar: (value: string, oldValue: string) => void;
    onloadInfoBeingExam: () => Promise<void>;
    diseaseAnalysisProcessCurrent: React.MutableRefObject<any>;
    showConfirm: () => void;
    steps?: string[]
    updateNextFlow: any
}

interface IState
{
    diseaseCombo: IComboBox[];
    diseaseList: { [diseaseAnalysisId: number]: IComboBox[] } | null;
    loading: boolean
}
const DiseaseAnalysisProcessView = (props: IProps) =>
{
    const { steps = [], updateNextFlow, imageFileBeingExam, diseaseAnalysisProcessCurrent, showConfirm, optionsMedicalMedicalPictureLayout, loadDiseaseAnalysis, diseaseAnalysisProcess, formRef, medicalExaminationId, onChangeProcessBar, setSelectFlow, onloadInfoBeingExam } = props;
    const overlayRef = useRef<OverlayRef>(null);

    const [state, setState] = useMergeState<IState>({
        diseaseCombo: [],
        diseaseList: {},
        loading: true
    });
    useEffect(() =>
    {
        onLoadDiseaseAnalysis();
    }, []);
    useEffect(() =>
    {
        onFormatData();
    }, [diseaseAnalysisProcess]);

    useEffect(() =>
    {
    }, [imageFileBeingExam]);

    const onFormatData = async () =>
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const valueForm: any = {
            advice: diseaseAnalysisProcess?.advice,
            diagnotic: diseaseAnalysisProcess?.diagnotic,
            treating: diseaseAnalysisProcess?.treating
        };
        await Object.entries(_.cloneDeep(diseaseAnalysisProcess?.diseaseAnalysisProcessList ? diseaseAnalysisProcess?.diseaseAnalysisProcessList : {}) as { [medicalPicturesId: number]: DiseaseAnalysisProcess }).map(
            ([key, value]) => 
            {
                valueForm[key] = value.diseaseAnalysisDetailId ? value.diseaseAnalysisDetailId.toString() : null;
                valueForm['text' + key] = value.content;
            }
        );
        diseaseAnalysisProcessCurrent.current = valueForm;
        formRef?.current?.setValues(valueForm);
    };

    const onLoadDiseaseAnalysis = async () =>
    {
        Axios.all([
            await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadCombo, { withCredentials: true }),
            await AxiosClient.get<ApiResponse<{ [diseaseAnalysisId: number]: IComboBox[] }>>(BASE_API_PATH + loadDiseaseAnalysisById, { withCredentials: true })
        ]).then(axios.spread((resDiseaseCombo, resDiseaseList) =>
        {
            let diseaseCombo: IComboBox[] = [];
            let diseaseList: { [diseaseAnalysisId: number]: IComboBox[] } | null = null;
            if (resDiseaseCombo.data.success)
            {
                diseaseCombo = resDiseaseCombo.data.results as unknown as IComboBox[];
            }
            if (resDiseaseList.data.success)
            {
                diseaseList = resDiseaseList.data.results as { [diseaseAnalysisId: number]: IComboBox[] };
            }
            setState({
                diseaseCombo: diseaseCombo,
                diseaseList: diseaseList,
                loading: false
            });
        }));
    };

    const onSaveDiseaseProcess = async () =>
    {
        overlayRef.current?.open();
        const paramsDiseaseProcess: DiseaseAnalysisProcess[] = [];
        const dataForm = formRef?.current?.getValues();
        Object.entries(_.cloneDeep(state.diseaseList) as { [diseaseAnalysisId: number]: IComboBox[] }).map(
            ([key]) => 
            {
                const itemDiseaseProcess: DiseaseAnalysisProcess = {
                    content: dataForm?.[`text${ key }`],
                    diseaseAnalysisDetailId: dataForm?.[`${ key }`] ? parseInt(dataForm?.[`${ key }`]) : null,
                    diseaseAnalysisId: parseInt(key),
                    medicalExaminationId: medicalExaminationId
                };
                paramsDiseaseProcess.push(itemDiseaseProcess);
            }
        );
        // const params: DiseaseAnalysisProcessCreate = {
        //     advice:  dataForm?.['advice'],
        //     diagnotic:dataForm?.['diagnotic'],
        //     treating: dataForm?.['treating'],
        //     medicalExaminationId: medicalExaminationId,
        //     diseaseAnalysisProcessList: paramsDiseaseProcess
        // };
        const params: DiseaseAnalysisProcessCreate = {
            advice: '',
            diagnotic: '',
            treating: '',
            medicalExaminationId: medicalExaminationId,
            diseaseAnalysisProcessList: paramsDiseaseProcess
        };

        // Lấy ra bước hiện tại
        const currentIndex = steps?.indexOf('2');
        const nextStep = steps[currentIndex + 1];

        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + updateDiseaseProcess, params);
        if (response.data.success)
        {
            await updateNextFlow(nextStep);
            ApiUtil.ToastSuccess(response.data.message);
            await loadDiseaseAnalysis();
            await onloadInfoBeingExam();

            setSelectFlow(Number(nextStep));
            onChangeProcessBar(nextStep, '2');
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }

        overlayRef.current?.close();

    };

    const onChangeCombo = (value: string, key: string, options: IComboBox[]) =>
    {
        let dataForm = formRef?.current?.getValues();
        const valueText = options.filter(x => x.value == value);
        dataForm = {
            ...dataForm,
            ['text' + key]: valueText[0].label
        };
        formRef?.current?.setValues({ ...dataForm });
    };

    const renderDiseaseList = () =>
    {


        return <>
            {
                Object.entries(_.cloneDeep(state.diseaseList) as { [diseaseAnalysisId: number]: IComboBox[] }).map(
                    ([key, value], index) => 
                    {
                        let nameFile = '';
                        _.cloneDeep(state.diseaseCombo).map(x =>
                        {
                            if (x.value === key)
                            {
                                nameFile = x.label;
                            }
                        });
                        return (
                            <Row key={index}
                                className={'mb-6' + (index === 0 ? ' mt-2' : '')}
                                // style={{ width: '100%', height: '85px' }}>
                                style={{ width: '100%' }}>
                                <Col key="1"
                                    className="flex"
                                    // style={{ width: '100%', height: '40px', padding: 0 }}>
                                    style={{ width: '100%', padding: 0 }}>
                                    <div className='flex font-bold h-full ml-4' style={{ width: '15%' }}>
                                        {nameFile}
                                    </div>
                                    <div className='mb-2' style={{ width: '85%', height: '100%' }}>
                                        <ComboBox noUpperCaseLabel={true}
                                            noShowLabel={true}
                                            comboValue={value}
                                            onChange={(valueOption) =>
                                            {
                                                onChangeCombo(valueOption, key, value);
                                            }}
                                            name={key} />
                                    </div>
                                </Col>
                                {/* <div className="flex justify-between" style={{ width: '100%', height: '35px', padding: 0 }}> */}
                                <div className="flex justify-between" style={{ width: '100%', padding: 0 }}>
                                    <div className='ml-4' style={{ width: '15%' }}> </div>
                                    <div className='order-last' style={{ width: '85%' }}>
                                        <TextField
                                            style={{ borderRadius: '7px' }}
                                            noUpperCaseLabel={true}
                                            label=''
                                            name={'text' + key} />
                                    </div>
                                </div>
                            </Row>);
                    }
                )
            }
        </>;
    };
    const onRenderImage = () =>
    {

        return Object.entries(_.cloneDeep(imageFileBeingExam as { [diseaseAnalysisId: number]: InfoFileUpload[] })).map(
            ([key, value]) => 
            {
                let nameImage = '';
                optionsMedicalMedicalPictureLayout.map(item =>
                {
                    if (item.value === key)
                    {
                        nameImage = item.label;
                    }
                });
                let keyValue = 0;
                return value.map(itemValue =>
                {
                    keyValue++;
                    const thumbUrl = itemValue?.['thumbUrlCloud'];
                    const fileType = itemValue?.['fileType'];
                    return <div key={keyValue.toString()} style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ height: '95%', width: '95%' }} >
                            <div className={'rounded-lg'}
                                style={{ width: '100%', aspectRatio: 'auto' }}
                            >
                                <div className="flex h-full w-full" >
                                    <div className="flex flex-col h-full w-full" style={{ borderRadius: '8px', justifyContent: 'center' }}>
                                        {FileType.Video.includes(fileType ?? '') ?
                                            <video
                                                style={{ height: '100%', width: '100%', zIndex: 1, borderRadius: '8px' }}
                                                controls>
                                                <source
                                                    src={thumbUrl}
                                                />
                                            </video>
                                            : (FileType.Pdf.includes(fileType ?? '')) ?
                                                <a href={thumbUrl}
                                                    target="_blank"
                                                    rel="noreferrer"><img
                                                        src={pdfIcon}
                                                        style={{ height: '50%', width: '50%', zIndex: 1, borderRadius: '8px' }}
                                                        className="bg-white border rounded"
                                                        alt="..."
                                                    /></a> :
                                                <img
                                                    src={thumbUrl}
                                                    style={{ height: '100%', width: '100%', zIndex: 1, borderRadius: '8px' }}
                                                    className="bg-white border rounded"
                                                    alt="..."
                                                />
                                        }
                                    </div>
                                </div>
                                <div className='ml-1 font-bold'>
                                    {nameImage}
                                </div>
                            </div>
                        </div>

                    </div>;
                });


            }
        );
    };


    return (
        <div className='flex flex-col' style={{ width: '100%', height: '100%' }}>
            <div className=' font-bold text-[#2C999C] ml-3 mt-2' style={{ height: '10%', fontSize: '16px' }}>
                MÔ TẢ KẾT QUẢ KHÁM BỆNH
            </div>
            <div className='flex' style={{ width: '100%', height: '90%' }}>
                <div className='flex ml-3 ' style={{ height: '100%', width: '70%' }}>
                    <BaseForm ref={formRef}
                        className="flex flex-col "
                        onSubmit={() => { onSaveDiseaseProcess(); }}
                        style={{ width: '100%', height: '100%' }}
                        defaultValues={{}} >
                        <div className='w-full overflow-y-auto flex-1' style={{ backgroundColor: '#F3F5F9' }}>
                            <div className="" style={{ width: '100%', height: '100%', borderRadius: '8px' }}>
                                {renderDiseaseList()}
                            </div>
                        </div>
                        <div className='flex justify-between w-full items-center' style={{ height: 60, width: '83%' }}>
                            <div></div>
                            <div className='flex order-last'>
                                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                                    buttonName={'Hoàn tất'}
                                    className='mr-2'
                                    buttonType="save"
                                    htmlType="submit" />

                                <ButtonBase className=''
                                    onClick={() =>
                                    {
                                        showConfirm();
                                    }}
                                    style={{ width: 180, height: 40, justifyContent: 'center' }}
                                    buttonName={'Hủy luồng khám'}
                                    backgroudColorCustom='#E78429'
                                    buttonType="close"
                                />
                            </div>

                        </div>
                    </BaseForm>

                </div>
                <div className='ml-2 mr-2' style={{ height: '100%', width: '30%' }}>
                    <div style={{ height: '97%', width: '100%' }}>
                        <div className="flex overflow-y-auto" style={{ height: '85%', width: '100%', flexFlow: 'wrap' }}>
                            {onRenderImage()}

                        </div>
                    </div>
                </div>
            </div>
            <Overlay ref={overlayRef} />


        </div>
    );
};

export default DiseaseAnalysisProcessView;