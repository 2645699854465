import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../style/layout-header.scss';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Popconfirm } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { CANCEL, CONFIRM_TO_DELETE, CONFIRM_TO_TOP, SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import PatientFileCreate from '../../../module/PatientFile/PatientFileCreate';
import PatientFileUpdate from '../../../module/PatientFile/PatientFileUpdate';
import { ApiResponse } from '../../../types/api.type';
import { PatientFileCreateRecord, PatientFileRecord, PatientFileUpdateRecord } from '../../../types/PatientRecord/PatientFileRecord';
import { PatientWaitingGridColumns } from '../../../types/PatientRecord/PatientWaitingSetting';
import { PatientWaitingFilterDto } from '../../../types/PatientWaiting/PatientWaitingFilterDto';
import { PatientWaitingGridDto } from '../../../types/PatientWaiting/PatientWaitingGridDto';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { comboDistrict, comboProvince } from '../../Infomation/api/constants';
import { createPatientWaiting, deletePatientWaiting, indexPatientWaiting, updateNumberOrderPatientWaiting, updatePatientWaiting } from '../api/constants';
import HeaderFilterPatientWaiting from './HeaderFilterPatientWaiting';
interface IState
{
    patientWaiting: PatientWaitingGridDto[];
    optionsProvince: IComboBox[];
    loading?: boolean;
}
const PatientWaitingView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const formRefFilter = useRef<BaseFormRef>(null);

    const [state, setState] = useMergeState<IState>({
        patientWaiting: [],
        optionsProvince: [],
        loading: true,
    });

    useEffect(() =>
    {

        loadApi();
    }, []);

    const loadApi = async () =>
    {
        const params: PatientWaitingFilterDto = {};
        overlayRef.current?.open();
        Axios.all([
            await AxiosClient.post<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + indexPatientWaiting, params, { withCredentials: true }),
            await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince),
        ]).then(axios.spread((resMedicalExaminationGrid, resComboProvince) =>
        {
            let optionsProvince: IComboBox[] = [];
            let patientWaitingGridDto: PatientWaitingGridDto[] = [];

            if (resMedicalExaminationGrid.data.success)
            {
                patientWaitingGridDto = resMedicalExaminationGrid.data.results as unknown as PatientWaitingGridDto[];
            }
            if (resComboProvince.data.success)
            {
                optionsProvince = resComboProvince.data.results as unknown as IComboBox[];
            }
            setState({
                patientWaiting: patientWaitingGridDto,
                optionsProvince: optionsProvince
            });
            overlayRef.current?.close();

        }));
    };



    const onLoadDataGrid = async (value: PatientWaitingFilterDto) =>
    {
        overlayRef.current?.open();
        const params: PatientWaitingFilterDto = {
            ...value,
            filterByDateFrom: value?.timeRange ? value?.timeRange[0].format('DD/MM/YYYY') : null,
            filterByDateTo: value?.timeRange ? value?.timeRange[1].format('DD/MM/YYYY') : null
        };
        const response = await AxiosClient.post<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + indexPatientWaiting, params, { withCredentials: true });
        if (response.data.success)
        {
            setState({
                loading: false,
                patientWaiting: response.data.results as PatientWaitingGridDto[]
            });
        }
        overlayRef.current?.close();

    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };
    const onOpenUpdate = async (value: PatientFileUpdateRecord) =>
    {
        const timeBookArray = (value.timeBook as string).split('-');
        const defaultValue: PatientFileUpdateRecord = {
            ...value,
            dateBook: value.dateBook ? moment(value.dateBook, 'DD/MM/YYYY') : null,
            startTimeBook: timeBookArray[0] ? moment(timeBookArray[0], 'HH:mm') : null,
            endTimeBook: timeBookArray[0] ? moment(timeBookArray[0], 'HH:mm') : null,
            yearOfBirth: moment(value.yearOfBirth, 'YYYY')
        };

        modalRef.current?.onOpen(<PatientFileUpdate
            defaultvalue={defaultValue}
            handleSubmit={onUpdatePatientFile}
            optionsProvince={state.optionsProvince}
            onClose={onCloseModal} />, 'CHỈNH SỬA THÔNG TIN BỆNH NHÂN', 40);
    };

    const onOpenCreate = async () =>
    {
        modalRef.current?.onOpen(<PatientFileCreate
            handleSubmit={onCreatePatientFile}
            optionsProvince={state.optionsProvince}
            onClose={onCloseModal} />, 'THÊM THÔNG TIN BỆNH NHÂN', 40);
    };
    const onCreatePatientFile = async (value: PatientFileCreateRecord) =>
    {
        const params: PatientFileCreateRecord = {
            ...value,
            districtId: value.districtId ? parseInt(value.districtId as string) : value.districtId,
            provinceId: value.provinceId ? parseInt(value.provinceId as string) : value.provinceId,
            dateBook: value.dateBook ? (value.dateBook as moment.Moment).format('DD/MM/YYYY').toString() : null,
            startTimeBook: value.startTimeBook ? (value.startTimeBook as moment.Moment).format('HH:mm').toString() : null,
            endTimeBook: value.startTimeBook ? (_.cloneDeep(value.startTimeBook) as moment.Moment).add(2, 'hours').format('HH:mm').toString() : null,
            yearOfBirth: value.yearOfBirth ? (value.yearOfBirth as moment.Moment).format('YYYY').toString() : null
        };

        const response = await AxiosClient.post<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + createPatientWaiting, params, { withCredentials: true });
        if (response.data.success)
        {
            onLoadDataGrid(formRefFilter.current?.getValues() as PatientWaitingFilterDto);
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onUpdatePatientFile = async (value: PatientFileUpdateRecord) =>
    {
        const params: PatientFileUpdateRecord = {
            ...value,
            dateBook: value.dateBook ? (value.dateBook as moment.Moment).format('DD/MM/YYYY').toString() : null,
            startTimeBook: value.startTimeBook ? (value.startTimeBook as moment.Moment).format('HH:mm').toString() : null,
            endTimeBook: value.startTimeBook ? (value.startTimeBook as moment.Moment).add(2, 'hours').format('HH:mm').toString() : null,
            yearOfBirth: value.yearOfBirth ? (value.yearOfBirth as moment.Moment).format('YYYY').toString() : null
        };
        const response = await AxiosClient.post<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + updatePatientWaiting, params);
        if (response.data.success)
        {
            onLoadDataGrid(formRefFilter.current?.getValues() as PatientWaitingFilterDto);
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        onCloseModal();
    };

    const onHandleDelete = async (value: number) =>
    {
        const response = await AxiosClient.post<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + deletePatientWaiting + value);
        if (response.data.success)
        {
            onLoadDataGrid(formRefFilter.current?.getValues() as PatientWaitingFilterDto);
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        onCloseModal();
    };

    const onHandleUpdateNumberOrder = async (value: number) =>
    {
        const response = await AxiosClient.post<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + updateNumberOrderPatientWaiting + value);
        if (response.data.success)
        {
            onLoadDataGrid(formRefFilter.current?.getValues() as PatientWaitingFilterDto);
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        onCloseModal();
    };



    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...PatientWaitingGridColumns,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<PatientFileRecord>) =>
                {
                    const data = params.data as PatientFileRecord;
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <GridButtonBase type={'edit'}
                                onClick={() => { onOpenUpdate(data); }} />
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { onHandleDelete(data.id as number); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm>
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_TOP}
                                onConfirm={() => { onHandleUpdateNumberOrder(data.id as number); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'top'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };
    const renderGrid = () =>
    {
        return <div className='' style={{ marginTop: -14, height: '80%' }}>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                columnDefs={getColumnConfig()}
                suppressRowTransform={true}
                pagination={true}
                suppressClickEdit={true}
                rowData={state.patientWaiting}
            />
        </div>;
    };


    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='h-full'>

                <HeaderFilterPatientWaiting formRefFilter={formRefFilter}
                    onOpenCreate={onOpenCreate}
                    onLoadDataGrid={onLoadDataGrid}
                    onFilter={() => { }} />
                {renderGrid()}
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default PatientWaitingView;