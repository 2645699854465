import { Layout, Modal } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useRef, useState } from 'react';

import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { InformationBeingExam } from '../../../types/PatientRecord/PatientFileRecord';
import { ApiUtil } from '../../../utils/ApiUtil';

interface Props
{
    flowValue: number;
    informationBeingExam: InformationBeingExam | undefined;
    onChangeProcessBar: (value: string, oldValue: string) => void;
    selectFlow: number;
    setSelectFlow: React.Dispatch<React.SetStateAction<number>>
    oldNumber: React.MutableRefObject<number>
    onCheckChangeImageProcess: () => boolean
    onCheckChangeDiseaseAnalysisProcess: () => boolean
    steps?: string[]
}

export const ProcessBar: React.FC<Props> = (props): JSX.Element =>
{
    const {steps, onChangeProcessBar, setSelectFlow, selectFlow, oldNumber, informationBeingExam, onCheckChangeImageProcess, onCheckChangeDiseaseAnalysisProcess } = props;
    const overlayRef = useRef<OverlayRef>(null);
    // const oldNumber = useRef<number>(flowValue);

    const onChangeFlowSelect = (value: number) =>
    {
        setSelectFlow(value);
        onChangeProcessBar(value.toString(), oldNumber.current.toString());
        // oldNumber.current = value;
    };
    const { confirm } = Modal;

    const renderProcess = () =>
    {
        const arrNumberFlow = [1, 2, 3, 4];
        return (
            <>
                {
                    arrNumberFlow.map(item =>
                    {
                        if (!steps?.includes(item.toString())) return; 
                        return (
                            <div key={item}
                                onClick={() => onCheckChangeData(item)}
                                className={item == 1 ? 'menu-item-start' : 'menu-item ' + (selectFlow == item ? 'rounded-r-full' : '')}
                                style={{ backgroundColor: item == 1 ? (selectFlow === item ? '#fff' : '#A3DBDC') : (selectFlow >= item ? '#A3DBDC' : '#fff') }}>
                                <div className='item-border' style={{ backgroundColor: item == 1 ? '' : selectFlow >= item ? '#A3DBDC' : '#fff', cursor: (informationBeingExam?.flowCurrent as number) === 4 ? 'default' : 'pointer' }}>
                                    <div
                                        className={item == 1 ? 'rounded-full item-start' : 'rounded-full item'}
                                        style={{ backgroundColor: item == 1 ? '#fff' : (selectFlow >= item ? '#fff' : '#56BABD') }}>
                                        {item}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </>
        );
    };


    const onCheckChangeData = (value: number) =>
    {
        if ((informationBeingExam?.flowCurrent as number) === 4) return;
        const valueOldNumber = parseInt(oldNumber.current.toString());
        // Check có thay đổi dữ liệu cũ hay không
        if (valueOldNumber === 1 && (informationBeingExam?.flowCurrent as number) > 1 && value !== 1)
        {
            const status = onCheckChangeImageProcess();
            if (status)
            {
                showConfirm(value);
                return;
            }
        }
        else if (valueOldNumber === 2 && (informationBeingExam?.flowCurrent as number) > 2 && value !== 2)
        {
            const status = onCheckChangeDiseaseAnalysisProcess();
            if (status)
            {
                showConfirm(value);
                return;
            }
        }
        onCheckFlowClick(value);

    };


    const showConfirm = (value: number) =>
    {
        return confirm({
            title: 'Dữ liệu mới thay đổi chưa được lưu trữ. Bạn có muốn tiếp tục thao tác?',
            okText: 'TIẾP TỤC',
            className: 'newStyleConfirm',
            style: { marginTop: '10%', borderRadius: '8px' },
            cancelText: 'HỦY',
            async onOk()
            {
                onCheckFlowClick(value);
            },
            onCancel()
            {
            },
        });
    };

    const onCheckFlowClick = (value: number) =>
    {


        // Check theo luồng từ flow 1 -> 3
        if ((informationBeingExam?.flowCurrent as number) === 3 || (value <= (informationBeingExam?.flowCurrent ? informationBeingExam?.flowCurrent as number : 1)))
        {
            onChangeFlowSelect(value);
        }
        else
        {
            switch (informationBeingExam?.flowCurrent)
            {
                case 1: {
                    ApiUtil.ToastWarning('Vui lòng hoàn tất khám bệnh!');
                    break;
                }
                case 2: {
                    ApiUtil.ToastWarning('Vui lòng hoàn tất phân tích bệnh!');
                    break;
                }
                default: {
                    //statements; 
                    break;
                }
            }
        }
    };
    const renderHeaderProcess = () =>
    {
        return (
            <>
                <Layout>
                    <Header style={{ zIndex: 1, padding: '0px' }} className='content-body-header '>
                        <div
                            className='menu-header-custom-process overflow-y-hidden'
                            style={{ padding: 0 }}>
                            {renderProcess()}
                        </div>
                    </Header>
                </Layout>
                <div style={{ zIndex: 1, padding: '0px' }} className='content-body-header-decription'>
                    {steps?.includes('1') && <div className="item-content-start">
                        <div className={selectFlow == 1 ? 'item-text-selected' : 'item-text'}>Khám bệnh</div>
                    </div>}
                    {steps?.includes('2') && <div className="item-content">
                        <div
                            className={selectFlow == 2 ? 'item-text-selected item-text-center' : 'item-text item-text-center'}>Phân
                            tích bệnh
                        </div>
                    </div>}
                    {steps?.includes('3') && <div className="item-content">
                        <div
                            className={selectFlow == 3 ? 'item-text-selected item-text-center' : 'item-text item-text-center'}>Kết
                            quả khám bệnh
                        </div>
                    </div>}
                    {steps?.includes('4') && <div className="item-content-end">
                        <div className={selectFlow == 4 ? 'item-text-selected' : 'item-text'}>Đơn thuốc</div>
                    </div>}
                </div>
            </>);
    };
    return (
        <div style={{ height: '60px' }}>
            {renderHeaderProcess()}
            <Overlay ref={overlayRef} />

        </div>);

};