import { CaretDownOutlined } from '@ant-design/icons';
import { Col, DatePicker, Radio, Row, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Option } from 'antd/lib/mentions';
import axios from 'axios';
import _, { isFunction } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactToPrint from 'react-to-print';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import { MESSAGE_EXPIRED } from '../../../constants/MessageConstants';
import { optionServiceType } from '../../../constants/OptionsCombo';
import { pathDateTime, pathMedicine, pathPrint } from '../../../constants/SvgIcon';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import
{
    DiseaseAnalysisProcess,
    DiseaseAnalysisProcessDetailViewDto
} from '../../../types/DiseaseAnalysisProcess/DiseaseAnalysisProcess';
import { InformationBeingExam, InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';
import { ComboOption } from '../../../types/share/ComboOption';
import { InfoFileUpload } from '../../../types/UploadFile/InfoFileUpload';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { loadComboDesignationMedicalExamByServiceType } from '../../DiseaseAnalysis/api/constants';
import { IStateExamResultScan } from '../../ExaminationProcess/page/ExamProcessLayout';
import ExtraServiceUsage from './ExtraServiceUsage';


// import { loadCombo, loadDiseaseAnalysisById, updateDiseaseProcess } from '../api/constants';

interface IProps
{
    imageFileBeingExam?: { [medicalPicturesId: number]: InfoFileUpload[] } | null;
    optionsMedicalMedicalPictureLayout: IComboBox[];
    loadDiseaseAnalysis: () => Promise<void>;
    onSumbitFlow: ({ useExtraService, designationMedicalExamId }: { useExtraService: boolean, designationMedicalExamId?: string }) => Promise<void>;
    diseaseAnalysisProcess?: DiseaseAnalysisProcessDetailViewDto;
    formRef?: React.RefObject<BaseFormRef>;
    medicalExaminationId?: number;
    setSelectFlow: React.Dispatch<React.SetStateAction<number>>
    onChangeProcessBar: (value: string, oldValue: string) => void;
    onloadInfoBeingExam: () => Promise<void>;
    handleAddonService: (designationMedicalExamId?: string) => Promise<void>;
    setStateScan: (newState: Partial<IStateExamResultScan>) => void;
    componentRef: React.RefObject<HTMLDivElement>;
    showConfirm: () => void;
    informationBeingExam: InformationBeingExam | undefined
    infoPatientExam: InformationPatientExam | undefined
}

interface IState
{
    diseaseCombo: IComboBox[];
    diseaseList: { [diseaseAnalysisId: number]: IComboBox[] } | null;
    loading: boolean
}
const ExamResultProcessView = (props: IProps) =>
{
    const { handleAddonService, componentRef, imageFileBeingExam, optionsMedicalMedicalPictureLayout, informationBeingExam, showConfirm, onSumbitFlow, diseaseAnalysisProcess, formRef, onChangeProcessBar, setSelectFlow, setStateScan } = props;
    // const componentRef = useRef<HTMLDivElement>(null);

    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);

    const dateFormatList = 'DD/MM/YYYY';

    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(true);
    const [extraService, setExtraService] = useState<number>(0);
    const [optionsDesignMedical, setOptionsDesignMedical] = useState<IComboBox[]>([]);
    const [selectedExtraService, setSelectedExtraService] = useState<string>('');
    const [selectedExtraServiceName, setSelectedExtraServiceName] = useState<string>('');

    useEffect(() =>
    {
        getListServiceComboBoxByType();
    }, [extraService]);
    const getListServiceComboBoxByType = async () =>
    {
        try
        {
            Axios.all([
                await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadComboDesignationMedicalExamByServiceType + `?group=${ extraService }`, { withCredentials: true }),
            ]).then(axios.spread((resComboDesign) =>
            {
                let optionsDesign: ComboOption<string>[] = [];
                if (resComboDesign.data.success)
                {
                    optionsDesign = resComboDesign.data.results as unknown as IComboBox[];
                }
                setOptionsDesignMedical(optionsDesign);
            }));
        } catch (error: any)
        {
            ApiUtil.ToastWarning(MESSAGE_EXPIRED);
        }
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const handleSubmitExtraService = () =>
    {
        if (extraService === 0)
        {
            onSumbitFlow({ useExtraService: true, designationMedicalExamId: selectedExtraService });
        } else
        {
            handleAddonService(selectedExtraService);
        }
        onCloseModal();
    };
    const onOpenModal = () =>
    {
        modalRef.current?.onOpen(<ExtraServiceUsage
            handleSubmit={handleSubmitExtraService}
            onClose={onCloseModal}
            serviceName={selectedExtraServiceName}
        />, 'Thêm dịch vụ', 30);
    };

    const iconMedicine: React.ReactNode = <svg fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 14">
        <path fillRule="evenodd"
            clipRule="evenodd"
            d={pathMedicine}
            fill="#fff" />
    </svg>;
    const suffixDateTimeFilter = () =>
    {
        // return <SearchOutlined />;
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };

    const iconPrint: React.ReactNode = <svg fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d={pathPrint} fill="#fff" />
    </svg>;
    const [state, setState] = useMergeState<IState>({
        diseaseCombo: [],
        diseaseList: {},
        loading: true
    });
    useEffect(() =>
    {
        // onLoadDiseaseAnalysis();
    }, []);

    useEffect(() =>
    {
    }, [diseaseAnalysisProcess, imageFileBeingExam]);



    const renderDiseaseList = () =>
    {
        return <div>
            {diseaseAnalysisProcess?.diseaseAnalysisProcessList ?
                Object.entries(_.cloneDeep(diseaseAnalysisProcess?.diseaseAnalysisProcessList) as { [diseaseAnalysisId: number]: DiseaseAnalysisProcess })?.map(
                    ([key, value]) => 
                    {
                        return (<Row key="1"
                            className='mb-4'
                            style={{ width: '100%', marginTop: '-6px' }}>
                            <Col className="flex" style={{ width: '100%', marginTop: 15, padding: 0 }}>
                                <div className='flex font-bold ml-4' style={{ width: '20%' }}>
                                    {value['diseaseAnalysisContent']} :
                                </div>
                                <div className='flex ml-2' style={{ width: '80%' }}>
                                    {value['content']}
                                </div>
                            </Col>
                        </Row>);
                    }
                )
                :
                <div></div>
            }
        </div>;
    };

    const renderDefault = () =>
    {
        return <>
            <Row className='mb-4' style={{ width: '100%', marginTop: '-6px' }}>
                <Col className="flex" style={{ width: '100%', marginTop: 15, padding: 0 }}>
                    <div className='flex font-bold h-full items-center ml-4' style={{ width: '20%' }}>
                        Chuẩn đoán :
                    </div>
                    <div className='flex  ml-2' style={{ width: '80%' }}>
                        {diseaseAnalysisProcess?.['diagnotic']}
                    </div>
                </Col>
            </Row>
            <Row className='mb-4' style={{ width: '100%', marginTop: '-6px' }}>
                <Col className="flex" style={{ width: '100%', marginTop: 15, padding: 0 }}>
                    <div className='flex font-bold h-full items-center ml-4' style={{ width: '20%' }}>
                        Điều trị :
                    </div>
                    <div className='flex ml-2' style={{ width: '80%' }}>
                        {diseaseAnalysisProcess?.['treating']}
                    </div>
                </Col>
            </Row>
            <Row className='mb-4' style={{ width: '100%', marginTop: '-6px' }}>
                <Col className="flex" style={{ width: '100%', marginTop: 15, padding: 0 }}>
                    <div className='flex font-bold  ml-4' style={{ width: '20%' }}>
                        Lời dặn bác sĩ :

                    </div>
                    <div className='flex  ml-2' style={{ width: '80%' }}>
                        {diseaseAnalysisProcess?.['advice']}
                    </div>
                </Col>
            </Row>
        </>;
    };
    const onRenderImage = () =>
    {
        return Object.entries(_.cloneDeep(imageFileBeingExam as { [diseaseAnalysisId: number]: InfoFileUpload[] })).map(
            ([key, value]) => 
            {
                let nameImage = '';
                optionsMedicalMedicalPictureLayout.map(item =>
                {
                    if (item.value === key)
                    {
                        nameImage = item.label;
                    }
                });
                return value.map(itemValue =>
                {
                    const thumbUrl = itemValue?.['thumbUrlCloud'];
                    const fileType = itemValue?.['fileType'];
                    return <div key="1" style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ height: '95%', width: '95%' }} >
                            <div className={'rounded-lg'}
                                style={{ width: '100%', aspectRatio: 'auto' }}
                            >
                                <div className="flex h-full w-full" >
                                    <div className="flex flex-col h-full w-full" style={{ borderRadius: '8px', justifyContent: 'center' }}>
                                        {FileType.Video.includes(fileType ?? '') ?
                                            <video
                                                style={{ height: '100%', width: '100%', zIndex: 1, borderRadius: '8px' }}
                                                controls>
                                                <source
                                                    src={thumbUrl}
                                                />
                                            </video>
                                            : (FileType.Pdf.includes(fileType ?? '')) ?
                                                <a href={thumbUrl}
                                                    target="_blank"
                                                    rel="noreferrer"><img
                                                        src={pdfIcon}
                                                        style={{ height: '50%', width: '50%', zIndex: 1, borderRadius: '8px' }}
                                                        className="bg-white border rounded"
                                                        alt="..."
                                                    /></a> :
                                                <img
                                                    src={thumbUrl}
                                                    style={{ height: '100%', width: '100%', zIndex: 1, borderRadius: '8px' }}
                                                    className="bg-white border rounded"
                                                    alt="..."
                                                />
                                        }
                                    </div>
                                </div>
                                <div className='ml-1 font-bold'>
                                    {nameImage}
                                </div>
                            </div>
                        </div>

                    </div>;
                });
            }
        );
    };

    const Remove_Dau = (str: string) =>
    {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        return str;
    };

    const renderBaseForm = () =>
    {
        return <BaseForm ref={formRef}
            onSubmit={() => { }}
            style={{ width: '100%', height: '97%' }}
            defaultValues={{}} >
            <div className='w-full ' style={{ height: '100%' }}>
                <div className="overflow-y-auto divide-y-2" style={{ width: '100%', height: '100%', backgroundColor: '#F3F5F9', borderRadius: '8px' }}>
                    <div>
                        {renderDiseaseList()}
                        {/* {renderDefault()} */}
                    </div>
                    <div style={{ width: '90%' }} className="ml-4">
                        <Col className="ml-4 mt-4">
                            {/* <div style={{width:'18%'}} className="ml-1"> */}
                            <Controller
                                name={'reExam'}
                                defaultValue={false}
                                render={({ field }) =>
                                {
                                    return <Checkbox
                                        {...field}
                                        className="checkbox-red mt-4"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        onChange={(value) =>
                                        {
                                            const dataForm = formRef?.current?.getValues();
                                            formRef?.current?.setValues({ ...dataForm, reExam: value.target.checked });
                                            setStateScan({ isChecked: value.target.checked, reExamDate: dataForm?.['reExamDate'] ? dataForm?.['reExamDate'].format('dd/MM/yyyy') : '' });
                                            setIsActiveTimeRange(!isActiveTimeRange);
                                        }}
                                        defaultChecked={false}

                                    >
                                        <div style={{ marginTop: '2px' }}>
                                            Hẹn tái khám
                                        </div>

                                    </Checkbox>;
                                }
                                }
                            />
                            {/* </div> */}
                        </Col>
                        <Col className="ml-4 mt-4 mb-2">
                            <Controller
                                name={'reExamDate'}
                                render={({ field }) =>
                                {
                                    return <div className="flex items-center">
                                        <div className="h-full font-bold mr-4" style={{ width: '20%' }}>
                                            Lịch tái khám:
                                        </div>
                                        <div className="h-full" style={{ width: '80%' }}>
                                            <DatePicker
                                                {...field}
                                                className='w-full'
                                                placeholder={'Chọn ngày tái khám'}
                                                format={dateFormatList}
                                                suffixIcon={suffixDateTimeFilter()}
                                                onChange={(value) =>
                                                {
                                                    const dataForm = formRef?.current?.getValues();
                                                    formRef?.current?.setValues({ ...dataForm, reExamDate: value });
                                                    setStateScan({ isChecked: true, reExamDate: value?.format('DD/MM/YYYY') });

                                                }}
                                                disabledDate={(current) =>
                                                {
                                                    const datePatient = moment(informationBeingExam?.startExam).add(1, 'years');
                                                    return current && (current > datePatient || current <= moment().endOf('day'));
                                                }}
                                                disabled={isActiveTimeRange}
                                            />
                                        </div>

                                    </div>;
                                }
                                }
                            />
                        </Col>
                    </div>
                    {/* <div style={{width: '90%'}} className="ml-4 pt-4">
                        <div className="font-bold mb-4">
                            Thêm dịch vụ:
                        </div>
                        <Radio.Group
                            onChange={(e) => setExtraService(e.target.value)}
                            value={extraService}
                            options={optionServiceType}/>
                        <div className={'flex items-center gap-x-4 mt-4'}>
                            <Select
                                showSearch={true}
                                style={{
                                    position: 'relative',
                                    width: '50%',
                                    backgroundColor: '#fff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '7px',
                                    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'
                                }}
                                bordered={false}
                                onChange={(e, option: any) => {
                                    setSelectedExtraService(e);
                                    setSelectedExtraServiceName(option.children);
                                }}
                                notFoundContent="Không có dữ liệu!"
                                placeholder={'Dịch vụ'}
                                allowClear={false}
                                className="border-red-800"
                                filterOption={(input, option) =>
                                    Remove_Dau(option?.props.children.toLowerCase()).indexOf(Remove_Dau(input.toLowerCase())) >= 0
                                    || Remove_Dau(option?.props.value.toLowerCase()).indexOf(Remove_Dau(input.toLowerCase())) >= 0
                                }
                                suffixIcon={<CaretDownOutlined/>}
                            >
                                {optionsDesignMedical?.map((item: IComboBox) => {
                                    return <Option
                                        key={item.value}
                                        value={item.value}
                                        disabled={!!item.disable}>{item.label}</Option>;
                                })}
                            </Select>
                            <ButtonBase
                                style={{width: 154, height: 40, justifyContent: 'center'}}
                                buttonName={'Thêm dịch vụ'}
                                buttonType="create"
                                onClick={() => {
                                    if (selectedExtraService) {
                                        onOpenModal();
                                    } else {
                                        ApiUtil.ToastError('Vui lòng chọn dịch vụ!');
                                    }
                                }}
                            />
                        </div>
                    </div> */}
                </div>

            </div>


        </BaseForm>;

    };

    const documentTitle = `${ props?.infoPatientExam?.fullName ?? 'Vnmedic-' } - ${ moment(informationBeingExam?.startExam).format('DD_MM_YYYY HH_mm') } - Kết quả khám`;
    return (
        <div
            style={{ width: '100%', height: '100%' }}
            className='flex flex-col'>
            <div className=' font-bold text-[#2C999C] ml-3 mt-2' style={{ height: '25px', fontSize: '16px' }}>
                MÔ TẢ KẾT QUẢ KHÁM BỆNH
            </div>
            <div className='flex' style={{ width: '100%', height: 'calc(100% - 80px)' }}>

                <div className='flex ml-3' style={{ height: '100%', width: '50%' }}>
                    {renderBaseForm()}
                </div>
                <div className='ml-2 mr-2' style={{ height: '100%', width: '50%' }}>
                    <div style={{ height: '95%', width: '100%' }}>
                        <div className="flex overflow-y-auto" style={{ height: '100%', width: '100%', flexFlow: 'wrap' }}>
                            {onRenderImage()}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between w-full items-center' style={{ height: '50px', justifyContent: 'center' }}>
                <div className='mr-2'>
                    <ReactToPrint
                        trigger={() => (
                            <ButtonBase className=''
                                onClick={() => { }}
                                style={{ width: 180, height: 40, justifyContent: 'center' }}
                                buttonName={'IN KẾT QUẢ'}
                                iconCustom={iconPrint}
                                backgroudColorCustom='#4D9FDA'
                                buttonType="button_custom"
                                htmlType="submit" />
                        )}
                        content={() => componentRef.current}
                        documentTitle={documentTitle}
                        pageStyle={'@page { size: A4; margin: 10mm 5mm; } @top-center {content: "Vnmedic";}'}
                    />

                </div>
                <div className='flex'>
                    <ButtonBase style={{ width: 240, height: 40, justifyContent: 'center' }}
                        buttonName={'HOÀN TẤT KHÁM BỆNH'}
                        buttonType="save"
                        className='mr-2'
                        onClick={() =>
                        {
                            onSumbitFlow({ useExtraService: false });
                            localStorage.removeItem('patientInfoValue');
                        }} />
                    <ButtonBase className=''
                        onClick={() =>
                        {
                            showConfirm();
                        }}
                        style={{ width: 180, height: 40, justifyContent: 'center' }}
                        buttonName={'Hủy luồng khám'}
                        backgroudColorCustom='#E78429'
                        buttonType="close"
                    />
                </div>
                <div className='ml-2'>
                    <ButtonBase className=''
                        onClick={() =>
                        {
                            setSelectFlow(4);
                            onChangeProcessBar('4', '3');
                        }}
                        style={{ width: 180, height: 40, justifyContent: 'center' }}
                        buttonName={'TẠO ĐƠN THUỐC'}
                        iconCustom={iconMedicine}
                        backgroudColorCustom='#E78429'
                        buttonType="button_custom"
                        htmlType="submit" />
                </div>

            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />

        </div>
    );
};

export default ExamResultProcessView;