import { Modal } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { pathCloseModal } from '../../constants/SvgIcon';
import useMergeState from '../../hooks/useMergeState';


export type ModalRef = {
    onClose: () => void;
    onOpen: (Component: JSX.Element, title: string | React.ElementType, size?: number) => void;
};
interface IState
{
    visible: boolean;
    title: string | React.ElementType;
    children: JSX.Element | null;
    size: number
}
const CustomModal = forwardRef((props, ref) =>
{
    const [state, setState] = useMergeState<IState>({
        visible: false,
        title: '',
        children: null,
        size: 50
    });

    const getWindowDimensions = () =>
    {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };
    const useWindowDimensions = () =>
    {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
        useEffect(() =>
        {
            const handleResize = () => setWindowDimensions(getWindowDimensions());
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);

        }, []);

        return windowDimensions;
    };
    const { width } = useWindowDimensions();



    const onOpen = (Component: JSX.Element, title: string | React.ElementType, size?: number) =>
    {
        setState({
            title,
            visible: true,
            children: Component,
            size: size ? size : 50
        });
    };

    const onClose = () =>
    {
        setState({ visible: false });
    };

    useImperativeHandle(
        ref,
        () => ({
            onClose,
            onOpen,
        }),
        [onClose, onOpen]
    );

    // const handleOk = () => setState({ visible: true }); //#d2e7e4
    const handleCancel = () => setState({ visible: false });

    if (!state.visible) return null;
    return (
        <Modal
            // className='modal-custom' 
            visible={state.visible}
            title={<div style={{ fontWeight: 700, color: '#2C999C' }}>{state.title as string}</div>}
            bodyStyle={{ padding: 0 }}
            maskClosable={false}
            closeIcon={<div className='flex items-center h-full' style={{ justifyContent: 'center' }}>
                <svg width="30"
                    height="30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"><path d={pathCloseModal} fill="#2C999C" /></svg>
            </div>}
            className="newStyle"
            style={{ width: (state.size * width / 100), minWidth: (state.size * width / 100), borderRadius: '10px', marginTop: '10px' }}
            onCancel={handleCancel}
            destroyOnClose
            footer={null}
        >
            {state.children}
        </Modal>
    );
});

CustomModal.displayName = 'CustomModal';
export default CustomModal;
