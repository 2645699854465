// import { createStore, combineReducers, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { userLoginReducer } from './reducers/UserRecuder';

// const reducers = combineReducers({
//     userLogin: userLoginReducer,
// });
// const middleware = [thunk];

// const userInfoFromStorage = localStorage.getItem('token')
//     ? localStorage.getItem('token')
//     : undefined;

// const initialState = {
//     userLogin: { token: userInfoFromStorage },
// } as Record<string, unknown>;

// const store = createStore(
//     reducers,
//     initialState,
//     composeWithDevTools(applyMiddleware(...middleware))
// );
// export type RootState = ReturnType<typeof store.getState>
// export default store;
import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import authReducer from '../features/auth/AuthSlice';
import clinicReducer from '../features/Infomation/ClinicSlice';
import { history } from '../utils/History';
import rootSaga from './rootSaga';

const rootReducer = combineReducers({
    router: connectRouter(history),
    auth:authReducer,
    clinic:clinicReducer
});

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
    reducer:rootReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware() // Sử dụng các middleware có sẵn trong redux-toolkit
        .concat(sagaMiddleware, routerMiddleware(history)) // Thêm middleware saga vào
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type Appthunk<ReturnType=void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;