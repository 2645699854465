export const indexAllClinic = '/Clinic/index-custom-all';
export const indexAllClinicFilter = '/Clinic/index-custom-filter';
export const createClinic = '/Clinic/create-custom';
export const createClinicPartner = '/Clinic/create-clinic-partner';
export const updateClinic = '/Clinic/update-custom-clinic';


export const detailStatictisClinic = '/Clinic/statictis-clinic';

export const requestApproveClinic = '/Clinic/request-approve';
export const adminApproveRequest = '/Clinic/update-status';