import { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { CHANGE_STATUS_SUCCESS, DELETE_TO_SUCCESS } from '../../../constants/MessageConstants';
import UserAccountSettingCreate from '../../../module/UserAccountSettingForm/UserAccountSettingCreate';
import UserAccountSettingEdit from '../../../module/UserAccountSettingForm/UserAccountSettingEdit';
import UserAccountSettingEditPassword from '../../../module/UserAccountSettingForm/UserAccountSettingEditPassword';
import { ApiResponse, PaginatedList } from '../../../types/api.type';
import { UserAccountDoctor } from '../../../types/UserAccountDoctor';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createUserAccount, disableStatusUser, editPasswordUserAccount, editUserAccount, enableStatusUser, indexUserAccount } from '../api/urlUserAccountConstant';
import GridUserAccount from './GridUserAccount';
import HeaderUserAccount from './HeaderUserAccount';

const UserAccountSettingView = () =>
{
    const [userAccountSetting, setUserAccountSetting] = useState<UserAccountDoctor[]>([]);
    const modalRef = useRef<ModalRef>(null);
    const overlayRef = useRef<OverlayRef>(null);

    useEffect(() =>
    {
        loadUserAccountSetting();
    }, []);


    const loadUserAccountSetting = async () =>
    {
        // overlayRef.current?.open();
        const response = await AxiosClient.get<ApiResponse<UserAccountDoctor>>(BASE_API_PATH + indexUserAccount, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as UserAccountDoctor[];
            setUserAccountSetting(data);
        }
        // overlayRef.current?.close();

    };

    const onOpenEdit = (value: UserAccountDoctor) =>
    {
        modalRef.current?.onOpen(<UserAccountSettingEdit defaultvalue={value}
            handleSubmit={onEditAccount}
            onClose={onCloseModal} />, 'Sửa thông tin bác sĩ', 30);
    };

    const onChangeStatusAccount = async (value: UserAccountDoctor) =>
    {
        overlayRef.current?.open();

        const idUserAccount = value.id;

        // biến kiểm tra tài khoản đang tắt hay bật hoạt động / isDisable = true (đang tắt hoạt động)
        const isDisableStatus = value?.isDisable;
        const apiUrl = isDisableStatus ? enableStatusUser : disableStatusUser;


        const response = await AxiosClient.put<ApiResponse<UserAccountDoctor>>(BASE_API_PATH + apiUrl + idUserAccount);
        if (response.data.success)
        {
            await loadUserAccountSetting();
            ApiUtil.ToastSuccess(CHANGE_STATUS_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        overlayRef.current?.close();

    };

    const onOpenCreate = () =>
    {
        modalRef.current?.onOpen(<UserAccountSettingCreate handleSubmit={onCreateAccount} onClose={onCloseModal} />, 'Thêm thông tin bác sĩ', 30);
    };

    const onOpenChangePassword = (value: UserAccountDoctor) =>
    {
        modalRef.current?.onOpen(<UserAccountSettingEditPassword defaultvalue={value}
            handleSubmit={onEditPassword}
            onClose={onCloseModal} />, 'Đổi mật khẩu', 30);
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onEditPassword = async (value: UserAccountDoctor) =>
    {

        const params = {
            ...value,
            clinicId: 1,
            roleUserId: 2,
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<UserAccountDoctor>>>(BASE_API_PATH + editPasswordUserAccount, params);
        if (response.data.success)
        {
            await loadUserAccountSetting();
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }

    };

    const onCreateAccount = async (value: UserAccountDoctor) =>
    {
        const params = {
            ...value,
            roleUserId: 2,
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<UserAccountDoctor>>>(BASE_API_PATH + createUserAccount, params, { withCredentials: true });
        if (response.data.success)
        {
            await loadUserAccountSetting();
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }

    };

    const onEditAccount = async (value: UserAccountDoctor) =>
    {

        const params = {
            ...value,
            roleUserId: 2,
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<UserAccountDoctor>>>(BASE_API_PATH + editUserAccount, params);
        if (response.data.success)
        {
            await loadUserAccountSetting();
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }

    };

    return (
        <>
            <HeaderUserAccount onOpenCreate={onOpenCreate} />
            <GridUserAccount onOpenChangePassword={onOpenChangePassword}
                onEdit={onOpenEdit}
                onChangeStatus={onChangeStatusAccount}
                record={userAccountSetting} />
            <CustomModal ref={modalRef} />
            <Overlay ref={overlayRef} />

        </>

    );
};

export default UserAccountSettingView; 
