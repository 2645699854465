import {IComboBox, NumberComboBox} from '../components/FormFields/ComboBox';

export const optionGender: IComboBox[] = [
    { value: 'MALE', label: 'Nam' },
    { value: 'FEMALE', label: 'Nữ' }
];

export const optionGenderPatientCare: IComboBox[] = [
    { value: 'MALE', label: 'Nam' },
    { value: 'FEMALE', label: 'Nữ' },
    { value: 'ALL', label: 'Tất cả' },
];



export const optionFilterTime: IComboBox[] = [
    { value: 'week', label: '1 Tuần' },
    { value: 'month', label: '1 Tháng' },
    { value: 'year', label: '1 Năm' },
    { value: 'day', label: 'Ngày' }

];

export const optionFilterTimeClinic: IComboBox[] = [
    { value: 'week', label: '1 Tuần' },
    { value: 'month', label: '1 Tháng' },
    { value: 'year', label: '1 Năm' },

];

export const optionServiceType: NumberComboBox[] = [
    {value: 0, label: 'Dịch vụ chính'},
    {value: 1, label: 'Dịch vụ bổ sung'},
];