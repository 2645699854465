/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage } from '@hookform/error-message';
import { Radio } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import React, { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps extends InputProps
{
    label?: string;
    indexLabel?: string;
    labelClass?: string;
    labelStyle?: CSSProperties;
    errors?: any;
    name: string;
    type?: string;
    comboValue: ICombo[] | null;
    requiredError?: boolean | null
    noUpperCaseLabel?: boolean;
}

export interface ICombo
{
    label: string;
    value: string | number | boolean | null;
}

const RadioField: React.FC<IProps> = (props) =>
{
    const { label, labelStyle = {}, name, comboValue, indexLabel, requiredError, disabled, noUpperCaseLabel } = props;
    const methods = useFormContext();
    const { control, formState: { errors } } = methods;
    return (
        <div className="flex flex-col justify-start">
            <div className="flex items-center">
                <div className="w-full px-3 md:mb-0">
                    <div className='flex' style={{ width: 200 }}>

                        {label && (
                            <label
                                className={noUpperCaseLabel ?
                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                    : 'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                                }
                                style={{
                                    ...labelStyle,
                                }}
                            >
                                {indexLabel ? (indexLabel + '. ') : null}{label}
                            </label>
                        )}
                        {requiredError && (<div className='text-red-600 ml-1 -left-4 -top-4'> *</div>)}
                    </div>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) =>
                        {
                            return <Radio.Group {...field} disabled={disabled}>
                                {comboValue?.map((item: ICombo) =>
                                {
                                    return <Radio key={'1'} value={item.value}>{item.label}</Radio>;
                                })}
                            </Radio.Group>;
                        }
                        }
                    />
                </div>
            </div>
            {errors && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (message ? <p className="ml-6 mt-1 m-0 text-red-400">{message}</p> : null)}

                />
            )}
        </div>
    );
};

export default RadioField;
