import { LoginPayload, LogoutPayload } from '../../features/auth/AuthSlice';
import { ApiResponse } from '../../types/api.type';
import { BASE_API_PATH } from '../../utils/ApiUtil';
import AxiosClient from './axiosClient';

const authApi = {
    loginToken(params: LoginPayload)
    {
        const url = '/auth/login-token';
        return AxiosClient.post<ApiResponse>(BASE_API_PATH + url, params, { withCredentials: true });
    },
    logout(deviceToken: string)
    {
        const params = {
            deviceToken: deviceToken
        };
        const url = '/auth/logout';
        return AxiosClient.post<ApiResponse>(BASE_API_PATH + url, params, { withCredentials: true });
    }
};

export default authApi;