import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { authAction } from './features/auth/AuthSlice';
import { useAppDispatch } from './hooks/hook';
import { VapidKey } from './types/NotificationWeb/NotificationConfig';
import { ApiUtil } from './utils/ApiUtil';

const firebaseConfig = {
    apiKey: 'AIzaSyBIFVqqat3yUX0E_E6tANZBekSrHPZPwTU',
    authDomain: 'vnmedic-greenlight.firebaseapp.com',
    projectId: 'vnmedic-greenlight',
    storageBucket: 'vnmedic-greenlight.appspot.com',
    messagingSenderId: '1025523451784',
    appId: '1:1025523451784:web:5f3a912086cabf6cdfdc3d',
    measurementId: 'G-4V8MFTMZBV'
};

initializeApp(firebaseConfig);

export const messaging = getMessaging();

export const requestForToken = (setDeviceToken: React.Dispatch<React.SetStateAction<string | undefined>>) =>
{

    return getToken(messaging, { vapidKey: VapidKey })
        .then((currentToken) =>
        {
            if (currentToken)
            {
                setDeviceToken(currentToken);
                // Perform any other neccessary action with the token
            } else
            {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) =>
        {
            console.log('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) =>
    {
        onMessage(messaging, (payload: any) =>
        {
            ApiUtil.openNotification(payload?.notification.body as string, payload?.notification.title as string);

            resolve(payload);
        });
    });

