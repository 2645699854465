/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../saga/store';
import { userAccountLogin } from '../../types/config/userAccountLogin';
import { User } from '../../types/User';

export interface LoginPayload { userName: string; password: string; }

export interface LogoutPayload { deviceToken: string; }

export interface AuthState { isLoggedIn: boolean; logging?: boolean; user?: User, fullName?: string, roles?: string[], deviceToken?: string }

const initialState: AuthState = {
    isLoggedIn: false,
    logging: false,
    user: undefined,
    fullName: '',
    roles: [],
    deviceToken: ''
};
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login(state, action: PayloadAction<LoginPayload>)
        {
            state.logging = true;
        },
        loadDeviceToken(state, action: PayloadAction<string>)
        {
            state.deviceToken = action.payload;
        },
        loginSuccess(state, action: PayloadAction<userAccountLogin>)
        {
            state.isLoggedIn = true;
            state.logging = false;
            // state.user = action.payload;
        },
        loginFail(state, action: PayloadAction<string>)
        {
            state.logging = false;

        },
        logout(state)
        {
            state.logging = false;
            state.isLoggedIn = false;
            state.user = undefined;
        }
    }
});

//action
export const authAction = authSlice.actions;
//selector
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsLogging = (state: RootState) => state.auth.logging;

export const selectRoles = (state: RootState) => state.auth.roles;
export const selectDeviceToken = (state: RootState) => state.auth.deviceToken;

export const selectfullName = (state: RootState) => state.auth.fullName;

//reducers
const authReducer = authSlice.reducer;
export default authReducer;