import React, { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { DELETE_TO_SUCCESS, UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import DesignationMedicalForm from '../../../module/MedicalExamTemplate/MedicalExamTemplateForm';
import { ApiResponse, PaginatedList } from '../../../types/api.type';
import { MedicalExamTemplate } from '../../../types/MedicalExamTemplate/MedicalExamTemplate';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createMedicalExamTemplate, deleteMedicalExamTemplate, indexMedicalExamTemplate, updateMedicalExamTemplate } from '../api/constants';
import GridMedicalExamTemplate from './GridMedicalExamTemplate';
import HeaderMedicalExamTemplate from './HeaderMedicalExamTemplate';


const MedicalExamTemplateView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);

    const [designationMedicalExam, setMedicalExamTemplate] = useState<MedicalExamTemplate[]>([]);
    useEffect(() =>
    {
        loadMedicalExamTemplate();
    }, []);

    const loadMedicalExamTemplate = async () =>
    {
        // overlayRef.current?.open();
        const response = await AxiosClient.get<ApiResponse<PaginatedList<MedicalExamTemplate>>>(BASE_API_PATH + indexMedicalExamTemplate);
        if (response.data.success)
        {
            const data = response.data.results?.items as MedicalExamTemplate[];
            setMedicalExamTemplate(data);
        }
        // overlayRef.current?.close();

    };

    const onOpenCreate = () =>
    {
        modalRef.current?.onOpen(<DesignationMedicalForm handleSubmit={onCreateMedicalExamTemplate} onClose={onCloseModal} />, 'Thêm chỉ định khám', 30);
    };

    const onCreateMedicalExamTemplate = async (value: MedicalExamTemplate) =>
    {
        const params = {
            ...value
        };
        const response = await AxiosClient.post<ApiResponse<MedicalExamTemplate>>(BASE_API_PATH + createMedicalExamTemplate, params);
        if (response.data.success)
        {
            await loadMedicalExamTemplate();
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onOpenUpdate = (value: MedicalExamTemplate) =>
    {
        modalRef.current?.onOpen(<DesignationMedicalForm defaultvalue={value}
            handleSubmit={onUpdateMedicalExamTemplate}
            onClose={onCloseModal} />, 'Sửa chỉ định khám', 30);

    };

    const onUpdateMedicalExamTemplate = async (value: MedicalExamTemplate) =>
    {
        const designationMedicalExamId = value.id;
        const response = await AxiosClient.put<ApiResponse<MedicalExamTemplate>>(BASE_API_PATH + updateMedicalExamTemplate + designationMedicalExamId, value);
        if (response.data.success)
        {
            await loadMedicalExamTemplate();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onDeleteMedicalExamTemplate = async (value: MedicalExamTemplate) =>
    {
        overlayRef.current?.open();
        const designationMedicalExamId = value.id;
        const response = await AxiosClient.delete<ApiResponse<MedicalExamTemplate>>(BASE_API_PATH + deleteMedicalExamTemplate + designationMedicalExamId);
        if (response.data.success)
        {
            await loadMedicalExamTemplate();
            ApiUtil.ToastSuccess(DELETE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError('Thiết lập đang được sử dụng tại vị trí khác!');
        }
        onCloseModal();
        overlayRef.current?.close();

    };


    return (
        <>
            <HeaderMedicalExamTemplate onOpenCreate={onOpenCreate} />
            <GridMedicalExamTemplate record={designationMedicalExam}
                onEdit={onOpenUpdate}
                onDelete={onDeleteMedicalExamTemplate} />
            {/* <div>
                {state.infomationClinic.id ?<InfomationUpdate defaultvalue = {state.infomationClinic} handleSubmit={onSaveInfomationClinic} loadOptionsDistrict={loadOptionsDistrict} optionsDistinct={optionsDistrict} optionsProvince={state.optionsProvince}/>:<></> }
            </div> */}
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />

        </>

    );
};

export default MedicalExamTemplateView;