import ButtonBase from '../../../components/ButtonBase/ButtonBase';


interface IHeaderProps
{
    onOpenCreate: () => void;
}

const HeaderMedicineGroup = (props: IHeaderProps) => (
    <div className="flex mt-6 justify-between">
        <div className='text-xl font-bold text-[#2c999c]  ml-3'>
            DANH MỤC NHÓM THUỐC
        </div>
        <div className='order-last mr-3'>
            <ButtonBase onClick={props.onOpenCreate}
                buttonName={'THÊM NHÓM THUỐC'}
                buttonType={'create'} />
        </div>
    </div>
);

export default HeaderMedicineGroup;