import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './style/base-grid.scss';

import { AgGridReact, AgGridReactProps, AgReactUiProps } from 'ag-grid-react'; // the AG Grid React Component



interface IProps extends AgGridReactProps, AgReactUiProps { }

const BaseGrid = (props: IProps) =>
{
    const defaultColDef = {
        editable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    };

    return (
        <div className="h-full w-full">
            <AgGridReact {...props}
                pagination={!props.pagination ? props.pagination : true}
                enableBrowserTooltips={true}
                overlayNoRowsTemplate={'Không có dữ liệu!'}
                className="ag-theme-alpine base-grid"
                defaultColDef={defaultColDef}
            />
        </div>
    );
};

export default BaseGrid;