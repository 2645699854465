import {ColDef} from 'ag-grid-community';




export const renderPatientsGridColumns = (pageIndex: number, pageSize: number) => [
    {
        headerName: 'STT',
        // valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 80,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        cellRenderer: (value: any,) => {
            return pageIndex * pageSize + value.rowIndex + 1 - pageSize;
        }
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        width: 150,
        tooltipField: 'fullName',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Năm sinh',
        field: 'yearOfBirth',
        width: 150,
        tooltipField: 'yearOfBirth',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Giới tính',
        field: 'gender',
        tooltipField: 'gender',
        headerClass: 'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    // {
    //     headerName: 'Phòng khám',
    //     field: 'clinicName',
    //     tooltipField: 'clinicName',
    //     headerClass: 'text-center',
    //     width: 100,
    //     cellStyle: { 'text-align': 'start' }
    // },
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        tooltipField: 'phone',
        headerClass: 'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Địa chỉ',
        field: 'location',
        tooltipField: 'location',
        headerClass: 'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    
 
];
