import {SearchOutlined} from '@ant-design/icons';
import {Col, Form, Row} from 'antd';
import React, {useEffect, useState} from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm from '../../../components/FormFields/BaseForm';
import ComboBox, {IComboBox} from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import {ApiResponse} from '../../../types/api.type';
import {BASE_API_PATH} from '../../../utils/ApiUtil';
import {comboProvince} from '../../Infomation/api/constants';

interface IState {
    onLoadDataGrid: any
    onOpenCreate: any
    formRefFilter: any
}
const statusList: IComboBox[] = [
    {value: '-1', label: 'Tất cả' },
    {value: '0', label: 'Tạm dừng' },
    {value: '1', label: 'Đang hoạt động' },
];

function HeaderFilterPartner(props: IState) {
    const { onLoadDataGrid, formRefFilter, onOpenCreate} = props;

    const [provinceList, setProvinceList] = useState<IComboBox[]>([]);
    const loadProvince = async ( ) => {
        const response = await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince);
        if (response.data.success)
        {
            setProvinceList(response.data.results as unknown as IComboBox[]);
        }
    };

    useEffect(() => {
        loadProvince();
    }, []);

    const suffixFilter = () =>
    {
        return <SearchOutlined />;
    };

    return (
        <div className="mt-3 justify-between" style={{ height: '90px' }}>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginBottom: 16, marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>QUẢN LÝ ĐỐI TÁC</p>
                <ButtonBase
                    style={{ width: 180 }}
                    onClick={() => onOpenCreate()}
                    buttonName={'THÊM ĐỐI TÁC'}
                    buttonType={'create'}
                />
            </div>

            <div>
                <BaseForm ref={formRefFilter}
                    onSubmit={onLoadDataGrid}
                    style={{ width: '100%' }}
                    defaultValues={{}} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px', marginBottom: 16 }}>
                        <Col style={{ width: '30%' }} className="">
                            <Form.Item>
                                <TextField noShowLabel={true}
                                    suffix={suffixFilter()}
                                    name={'text'}
                                    label={'Tên, số điện thoại'} />
                            </Form.Item>
                        </Col>
                        <Col
                            style={{
                                width: '30%', padding: 0
                            }} >
                            <Form.Item >
                                <ComboBox
                                    noUpperCaseLabel={true}
                                    noShowLabel={true}
                                    isClear={true}
                                    comboValue={provinceList}
                                    name={'provinceId'}
                                    label={'Tỉnh thành'} />
                            </Form.Item>
                        </Col>
                        <Col
                            style={{
                                width: '30%', padding: 0
                            }} >
                            <Form.Item >
                                <ComboBox
                                    noUpperCaseLabel={true}
                                    noShowLabel={true}
                                    isClear={true}
                                    comboValue={statusList}
                                    name={'status'}
                                    label={'Trạng thái'} />
                            </Form.Item>
                        </Col>
                        <Col className='' style={{ width: '10%', display: 'flex', justifyContent: 'end', justifyItems: 'end', flex: 1, paddingRight: 0 }}>
                            <div className="footer ">
                                <ButtonBase style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                                    buttonName={'TÌM KIẾM'}
                                    buttonType="filter"
                                    htmlType="submit" />
                            </div>
                        </Col>

                    </Row>
                </BaseForm>
            </div>
        </div>
    );
}

export default HeaderFilterPartner;