import {yupResolver} from '@hookform/resolvers/yup';
import {Col, Form, Row} from 'antd';
import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, {BaseFormRef} from '../../components/FormFields/BaseForm';
import ComboBox from '../../components/FormFields/ComboBox';
import NumberField from '../../components/FormFields/NumberField';
import RadioField from '../../components/FormFields/RadioField';
import TextField from '../../components/FormFields/TextField';
import Overlay, {OverlayRef} from '../../components/Overlay/Overlay';
import {optionServiceType} from '../../constants/OptionsCombo';
import {getAllDesignationType} from '../../features/DesignMedicalExam/api/constants';
import {ApiResponse} from '../../types/api.type';
import {DesignationMedicalExam} from '../../types/DesignationMedicalExam/DesignationMedicalExam';
import {BASE_API_PATH} from '../../utils/ApiUtil';

const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IFormDesignationMedicalExamFormProps
{
    handleSubmit: (value: DesignationMedicalExam) => Promise<void>;
    onClose: () => void,
    defaultvalue?: DesignationMedicalExam;
    isUpdate?: boolean;
}

const DesignationMedicalExamForm: FunctionComponent<IFormDesignationMedicalExamFormProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const [optionDesignationType, setOptionDesignationType ] = useState([]);

    const getDesignationType = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + getAllDesignationType, { withCredentials: true });
        if (response.data.success)
        {
            setOptionDesignationType(response.data.results.map((item:any) => ({
                label: item.name, value: item.id.toString()
            })));
        }

    };

    useEffect(() => {
        getDesignationType();
    }, []);

    const { handleSubmit, defaultvalue, isUpdate = false } = props;
    const onSubmit = async (value: DesignationMedicalExam) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
            resolver={yupResolver(schema)} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField name={'name'}
                            requiredError={true}
                            label={'Tên dịch vụ'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item >
                        <RadioField
                            disabled={isUpdate}
                            requiredError={true}
                            name={'groupDesignation'}
                            comboValue={optionServiceType}
                            label={'Loại dịch vụ'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item >
                        <ComboBox
                            requiredError={true}
                            comboValue={optionDesignationType}
                            name='designationTypeId'
                            label="Loại chỉ định"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <NumberField requiredError={true} 
                            name={'designationAmount'}
                            label={'Giá tiền'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />
        </BaseForm>
    );
};
export default DesignationMedicalExamForm; 
