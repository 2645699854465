import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React, { CSSProperties } from 'react';

import { pathClose, pathCloseButton, pathFilterButton, pathMessage, pathMessageChat, pathNoti, pathPlay, pathPlus } from '../../constants/SvgIcon';
import styles from './ButtonBase.module.scss';

export interface ButtonBaseProps extends ButtonProps
{
    buttonName: string | JSX.Element;
    buttonType?: 'create' | 'edit' | 'filter' | 'detail' | 'delete' | 'save' | 'close' | '' | 'sms' | 'notification' | 'play_exam' | 'button_custom' | 'message_chat';
    className?: string;
    backgroudColorCustom?: string;
    iconCustom?: React.ReactNode;
    // errors?: <Record
}

const ButtonBase: React.FC<ButtonBaseProps> = (props) =>
{
    const { style, buttonType, className, backgroudColorCustom, iconCustom } = props;
    const styleDefault: CSSProperties = { height: '36px' };
    const getButtonTypeStyle = (): [ButtonProps, CSSProperties, string, React.ReactNode] =>
    {
        let buttonTypeStyle: ButtonProps = {};
        let overrideStyle: CSSProperties = {};
        let icon: React.ReactNode = null;
        let className = '';
        switch (buttonType)
        {
            case 'create':
                buttonTypeStyle = { type: 'primary' };
                overrideStyle = { backgroundColor: '#4D9FDA', borderRadius: '5px' };
                icon = <svg fill="none"
                    className="w-3 h-3 sm:w-4 sm:h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 14">
                    <path d={pathPlus} fill="#fff" />
                </svg>;
                break;
            case 'filter':
                overrideStyle = { backgroundColor: '#2C999C', color: 'white', borderRadius: '5px' };
                icon = <>
                    <svg fill="none"
                        className="w-5 h-7 sm:w-5 sm:h-7 mr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 15 14">
                        <path fillRule="evenodd"
                            clipRule="evenodd"
                            d={pathFilterButton}
                            fill="#fff" />
                    </svg>                </>;
                break;
            case 'detail':
                overrideStyle = { backgroundColor: '#7518c4', color: 'white' };
                break;
            case 'edit':
                overrideStyle = { backgroundColor: '#46ad50', color: 'white' };
                break;
            case 'delete':
                buttonTypeStyle = { danger: true };
                break;
            case 'save':
                className = 'btn-save';
                overrideStyle = { backgroundColor: '#2C999C', color: 'white', borderRadius: '4px' };
                icon = <svg fill="none"
                    className="w-6 h-6 sm:w-6 sm:h-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path fillRule="evenodd"
                        clipRule="evenodd"
                        d={pathPlay}
                        fill="#fff" />
                </svg>;
                break;
            case 'play_exam':
                className = 'btn-save';
                overrideStyle = { backgroundColor: '#4D9FDA', color: 'white', borderRadius: '4px' };
                icon = <svg fill="none"
                    className="w-6 h-6 sm:w-6 sm:h-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path fillRule="evenodd"
                        clipRule="evenodd"
                        d={pathPlay}
                        fill="#fff" />
                </svg>;
                break;
            case 'button_custom':
                className = 'btn-save';
                overrideStyle = { backgroundColor: backgroudColorCustom, color: props.disabled ? '#00000040' : 'white', borderRadius: '4px' };
                icon = iconCustom;
                break;
            case 'close':
                className = 'btn-close';
                overrideStyle = { backgroundColor: '#C2C5CB', color: 'white' };
                icon = <svg width="21"
                    className="w-6 h-6 sm:w-6 sm:h-6 mr-1 mt-1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"><path d={pathCloseButton} fill="#fff" /></svg>;
                break;
            case 'sms':
                overrideStyle = { backgroundColor: '#4D9FDA', color: 'white', borderRadius: '4px' };
                icon = <svg fill="none"
                    className="w-6 h-6 sm:w-6 sm:h-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 14">
                    <path d={pathMessage} fill="#fff" />
                </svg>;
                break;
            case 'message_chat':
                overrideStyle = { backgroundColor: '#4D9FDA', color: 'white', borderRadius: '4px' };
                icon = <svg xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    fill="none"><rect width="44"
                        height="44"
                        fill="#2C999C"
                        rx="4" /><path fill="#fff" d={pathMessageChat} /></svg>;
                break;
            case 'notification':
                overrideStyle = { backgroundColor: '#E78429', color: 'white', borderRadius: '4px' };
                icon = <svg fill="none"
                    className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 14">
                    <path d={pathNoti} fill="#fff" />
                </svg>;
                break;
            default:
                break;
        }
        return [buttonTypeStyle, overrideStyle, className, icon];
    };

    const [buttonTypeStyle, overrideStyle, btnClass, icon] = getButtonTypeStyle();
    const buttonBaseStyle: CSSProperties = {
        borderRadius: 3,
        boxShadow: 'box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        // fontWeight: 'bold',
        alignItems: 'center',
        display: 'flex'
    };
    const buttonStyle = Object.assign(buttonBaseStyle, style || {});
    return (
        <div className={`${ styles['button-base'] } ${ className }`}>
            <Button className={btnClass}
                {...props}
                {...buttonTypeStyle}
                icon={icon ? icon : null}
                style={Object.assign(styleDefault, Object.assign(buttonStyle, overrideStyle || {}))}>
                <div style={{ height: '20px' }}>
                    <p style={{ fontSize: 14, font: 'Roboto', fontWeight: 600 }}>{props.buttonName}</p>
                </div>
            </Button>
        </div>
    );
};

export default ButtonBase;
