import { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { ADD_TO_SUCCESS, DELETE_TO_SUCCESS, UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import MedicineCreateForm from '../../../module/Medicine/MedicineCreateForm';
import MedicineForm from '../../../module/Medicine/MedicineForm';
import { ApiResponse, PaginatedList } from '../../../types/api.type';
import { Medicine, MedicineFormCreate, MedicineNoteUse } from '../../../types/Medicine/Medicine';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createMedicine, deleteMedicine, editMedicine, indexMedicineById, indexMedicineGroupCombo } from '../api/contants';
import HeaderMedicine from './HeaderMedicine';
import MedicineGrid from './MedicineGrid';

interface IState
{
    valueOption: string;
    medicine: MedicineFormCreate[];
    loading: boolean;
}

const MedicineView = () =>
{
    const modalRef = useRef<ModalRef>(null);
    const overlayRef = useRef<OverlayRef>(null);
    const [optionsMedicine, setOptionsMedicine] = useState<IComboBox[]>([]);
    const [state, setState] = useMergeState<IState>({
        valueOption: '',
        medicine: [],
        loading: true
    });


    useEffect(() =>
    {
        loadOptionsMedicine();
    }, []);

    useEffect(() =>
    {
        if (optionsMedicine.length > 0)
        {
            onChangeComboMedicine(optionsMedicine.find(x => x)?.value || '');
        }
    }, [optionsMedicine]);

    const loadOptionsMedicine = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + indexMedicineGroupCombo, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as IComboBox[];
            setOptionsMedicine(data);
            setState({ loading: false });
        }
    };
    const onChangeComboMedicine = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<MedicineFormCreate[]>>(BASE_API_PATH + indexMedicineById + value);
        let medicineOption: Medicine[] = [];
        if (response.data.success)
        {
            const data = response.data.results as MedicineFormCreate[];
            medicineOption = data;
        }
        setState({
            valueOption: value,
            medicine: medicineOption
        });
    };

    const onOpenUpdate = (value: MedicineFormCreate) =>
    {
        modalRef.current?.onOpen(<MedicineCreateForm defaultvalue={value}
            handleSubmit={onUpdateMedicine}
            onClose={onCloseModal} />, 'Sửa thuốc', 80);

    };

    const onOpenCreate = () =>
    {

        modalRef.current?.onOpen(<MedicineCreateForm isCreate={true}
            handleSubmit={onCreateMedicine}
            onClose={onCloseModal} />, 'Thêm thuốc', 80);

    };



    const onCreateMedicine = async (value: MedicineFormCreate) =>
    {

        const params: MedicineFormCreate = {
            ...value,
            medicineGroupId: parseInt(state.valueOption),
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<Medicine>>>(BASE_API_PATH + createMedicine, params);
        if (response.data.success)
        {
            await onChangeComboMedicine(state.valueOption);
            ApiUtil.ToastSuccess(ADD_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };

    const onUpdateMedicine = async (value: MedicineFormCreate) =>
    {
        const medicineId = value.id;
        const params: MedicineFormCreate = {
            ...value,
            id: medicineId,
            medicineGroupId: parseInt(state.valueOption),
        };
        const response = await AxiosClient.post<ApiResponse<Medicine>>(BASE_API_PATH + editMedicine, params);
        if (response.data.success)
        {
            await onChangeComboMedicine(state.valueOption);
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onDeleteMedicine = async (value: Medicine) =>
    {
        overlayRef.current?.open();
        const medicineId = value.id;
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + deleteMedicine + medicineId);
        if (response.data.success)
        {
            await onChangeComboMedicine(state.valueOption);
            ApiUtil.ToastSuccess(DELETE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError('Thuốc đang được sử dụng tại vị trí khác!');
        }
        onCloseModal();
        overlayRef.current?.close();

    };
    if (state.loading)
        return null;
    return (
        <>
            <HeaderMedicine valueOptions={state.valueOption}
                onChange={onChangeComboMedicine}
                options={optionsMedicine}
                onOpenCreate={onOpenCreate} />
            <MedicineGrid onEdit={onOpenUpdate}
                onDelete={onDeleteMedicine}
                record={state.medicine} />
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </>

    );
};

export default MedicineView; 
