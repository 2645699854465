import { Checkbox, Col, DatePicker, Form, Row } from 'antd';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import { pathDateTime, pathFilterTextbox } from '../../../constants/SvgIcon';
import { MedicalExaminationPatientRecordFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';

interface IHeaderProps
{
    options?: IComboBox[];
    onLoadDataGrid: (value: MedicalExaminationPatientRecordFilterDto) => Promise<void>;
    formRefFilter: React.RefObject<BaseFormRef>
    isFilterByDate?: boolean // biến kiểm tra hiển thị bộ lọc theo ngày
}


const HeaderFilterPatientRecord = (props: IHeaderProps) =>
{
    const { onLoadDataGrid, formRefFilter, isFilterByDate = false } = props;
    // const formRef = useRef<BaseFormRef>(null);

    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(true);
    const suffixFilter = () =>
    {
        // return <SearchOutlined />;
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16">
            <path fillRule="evenodd"
                clipRule="evenodd"
                d={pathFilterTextbox}
                fill="#65676B" />
        </svg>;
    };
    const suffixDateTimeFilter = () =>
    {
        // return <SearchOutlined />;
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };
    const { RangePicker } = DatePicker;


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    return (
        <div className="mt-3 justify-between">
            <div className=' '>
                <BaseForm ref={formRefFilter}
                    onSubmit={(value) => { onLoadDataGrid(value); }}
                    style={{ width: '100%' }}
                    defaultValues={{}} >
                    <Row className="flex justify-between"
                        gutter={30}
                        style={{ width: '100%' }}>
                        <Col style={{ width: '50%', paddingRight: 0 }} className="">
                            <Form.Item>
                                <TextField noShowLabel={true}
                                    suffix={suffixFilter()}
                                    name={'fullNameOrPhoneorLocation'}
                                    label={'SĐT, họ tên, địa chỉ	'} />
                            </Form.Item>
                        </Col>

                        {isFilterByDate &&
                            <>
                                <Col style={{ width: '12%', padding: 0 }} >
                                    <Controller
                                        name={'isFilterByDate'}

                                        render={({ field }) =>
                                        {
                                            return <div className='flex items-center gap-1'>
                                                <Checkbox

                                                    {...field}
                                                    onChange={(value) =>
                                                    {
                                                        const dataForm = formRefFilter.current?.getValues();
                                                        formRefFilter.current?.setValues({ ...dataForm, isFilterByDate: value.target.checked });
                                                        setIsActiveTimeRange(!value.target.checked);
                                                    }}
                                                />
                                                <div >
                                                    Theo ngày
                                                </div>
                                            </div>;
                                        }
                                        }
                                    />
                                </Col>
                                <Col style={{ width: '20%', padding: 0 }}>
                                    <Controller
                                        name={'timeRange'}
                                        render={({ field }) =>
                                        {
                                            return <RangePicker
                                                {...field}
                                                placeholder={['Từ ngày', 'Đến ngày']}
                                                format={dateFormatList}
                                                suffixIcon={suffixDateTimeFilter()}
                                                disabled={isActiveTimeRange}
                                            />;
                                        }
                                        }
                                    />
                                </Col>
                            </>
                        }

                        <Col style={{ width: '18%', display: 'flex', justifyContent: 'end', justifyItems: 'end' }}>
                            <div className="footer ">
                                <ButtonBase style={{ width: 130, display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                                    buttonName={'TÌM KIẾM'}
                                    buttonType="filter"
                                    htmlType="submit" />
                            </div>
                        </Col>
                    </Row>
                </BaseForm>
            </div>

        </div>
    );
};

export default HeaderFilterPatientRecord; 