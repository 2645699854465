import React from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import {PartnerGridDto} from '../../../types/PartnerManagement/PartnerGridDto';

interface IState {
    handleSubmit: (id: number, isActivated: boolean) => Promise<void>
    onClose: () => void
    partner: PartnerGridDto
}
function StopPartnerModal(props: IState) {
    const {handleSubmit, partner, onClose} = props;
    return (
        <div className={'p-4'}>
            <h1>Bạn có chắc chắn muốn tạm dừng hoạt động của đối tác dưới đây?</h1>
            <div>
                <strong>Họ và tên: {partner.fullName}</strong>
            </div>
            <div>
                <strong>Số điện thoại: {partner.phone}</strong>
            </div>
            <div>
                <strong>Địa chỉ: {partner.provinceId}</strong>
            </div>
            <hr className={'mt-4'}/>
            <div className={'flex justify-end items-center mt-4'}>
                <ButtonBase buttonName={'Tạm dừng'}
                    buttonType={'delete'}
                    onClick={() => {
                        handleSubmit(partner.id, false);
                        onClose();
                    }} />
            </div>
        </div>
    );
}

export default StopPartnerModal;