import { DownloadOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { Loading } from '../../components/common/Loading';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import NumberField from '../../components/FormFields/NumberField';
import TextAreaField from '../../components/FormFields/TextAreaField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import {UPDATE_TO_SUCCESS} from '../../constants/MessageConstants';
import {adminApproveRequest} from '../../features/Clinic/api/constants';
import { comboDistrict } from '../../features/Infomation/api/constants';
import useMergeState from '../../hooks/useMergeState';
import { ApiResponse } from '../../types/api.type';
import { Clinic } from '../../types/Clinic';
import {ApiUtil, BASE_API_PATH} from '../../utils/ApiUtil';


interface IInfomationProps
{
    optionsProvince: IComboBox[];
    defaultvalue?: Clinic
    role?: 'PARTNER' | 'SUPER_ADMIN'
    onLoadAllClinic: () => Promise<void>
    onClose: () => void,
}

interface IState
{
    isLoading: boolean,
    optionsDistrict?: IComboBox[]
}
const ClinicInfomationForm = (props: IInfomationProps) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const disabled = useRef<boolean>(true);
    const { onLoadAllClinic, onClose, role = 'SUPER_ADMIN', optionsProvince, defaultvalue } = props;
    const formRef = useRef<BaseFormRef>(null);
    const [state, setState] = useMergeState<IState>({
        isLoading: true,
        optionsDistrict: []
    });
    useEffect(() =>
    {
        // loadApi();
        if (defaultvalue?.provinceId)
        {
            onChangeProvince(defaultvalue?.provinceId as string);
        }
        else
        {
            setState({
                ...state,
                isLoading: false
            });
        }
    }, []);

    const onChangeProvince = (value: string) =>
    {
        const dataForm = formRef.current?.getValues();
        loadOptionsDistrict(value);
        formRef.current?.setValues({ ...dataForm, districtId: '' });
    };

    const loadOptionsDistrict = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
        if (response.data.success)
        {
            const data = response.data.results as unknown as IComboBox[];
            setState({
                ...state,
                optionsDistrict: data,
                isLoading: false
            });
        }

    };

    // admin phe duyet phong kham partner
    const handleRequest = async (status: 'APPROVED' | 'REJECT')=> {
        const response = await AxiosClient.put<ApiResponse<IComboBox>>(BASE_API_PATH + adminApproveRequest + `/${defaultvalue?.id}/${status}`);
        if (response.data.success)
        {
            await onLoadAllClinic();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            onClose();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);

        }
    };

    const renderQrCode = () =>
    {
        return <div className='flex'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', fontSize: '14pt' }}>{defaultvalue?.clinicCode ?? 'VNM-00000'}</span>
                <QRCode
                    id='qrcode'
                    value={defaultvalue?.clinicCode ?? 'VNM-00000'}
                    style={{ height: '150px', width: '150px' }}
                    level={'H'}
                    includeMargin={true}
                />
            </div>
        </div>;
    };
    if (state.isLoading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;

    return ( //onSubmit(value);
        <BaseForm ref={formRef}
            className="p-3"
            style={{ overflowX: 'hidden' }}
            defaultValues={defaultvalue}
            onSubmit={(value) => { }}
        >
            <Row className="flex m-1" gutter={24}>
                <Col span={16} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            name={'name'}
                            disabled={disabled.current}
                            requiredError={true}
                            label={'Tên phòng khám'} />
                    </Form.Item>

                </Col>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            name={'phone'}
                            requiredError={true}
                            disabled={disabled.current}

                            label={'Số điện thoại'} />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="flex m-1" gutter={24}>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            disabled={disabled.current}
                            name={'address'}
                            requiredError={true}
                            label={'Địa chỉ phòng khám'} />
                    </Form.Item>

                </Col>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            // requiredError={true}
                            disabled={disabled.current}
                            comboValue={optionsProvince}
                            name={'provinceId'}
                            label={'Thành phố/Tỉnh'} />

                    </Form.Item>
                </Col>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            disabled={disabled.current}
                            // requiredError={true}
                            comboValue={state.optionsDistrict || []}
                            name={'districtId'}
                            label={'Quận/Huyện'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={16} className="flex-1">
                    <Form.Item>
                        <TextAreaField noUpperCaseLabel={true}
                            style={{ height: 100, borderRadius: '7px' }}
                            disabled={disabled.current}
                            name={'introduce'}
                            label={'Giới thiệu về phòng khám'} />
                    </Form.Item>
                </Col>
                <Col span={8} className="flex-1">
                    <Form.Item>
                        <label
                            className={
                                'ml-3 block tracking-wide text-gray-700 text-xs font-bold mb-2'
                            }

                        >
                            Mã phòng khám
                        </label>
                        <div className={'ml-3 block'}>{renderQrCode()}</div>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextAreaField noUpperCaseLabel={true}
                            style={{ height: 150, borderRadius: '7px' }}
                            disabled={disabled.current}
                            name={'notification'}
                            label={'Thông báo của phòng khám'} />
                    </Form.Item>
                </Col>
            </Row>
            {
                role === 'SUPER_ADMIN' && defaultvalue?.status === 'PENDING' ?
                    <Row className="flex m-1 w-full" gutter={24}>
                        <div className="flex w-full items-center justify-center gap-x-4">
                            <ButtonBase buttonName={'Phê duyệt'}
                                type={'primary'}
                                onClick={() => handleRequest('APPROVED')}/>
                            <ButtonBase buttonName={'Không phê duyệt'}
                                type={'default'}
                                onClick={() => handleRequest('REJECT')}/>
                        </div>
                    </Row> : <div></div>}
            <Overlay ref={overlayRef} />

        </BaseForm>

    ); //onClose
};

export default ClinicInfomationForm;