import { ColDef } from 'ag-grid-community';



export const PatientWaitingGridColumns : ColDef[] = [
    {
        headerName: 'STT', 
        valueGetter: 'node.rowIndex + 1',
        field:'stt',
        maxWidth: 80,
        headerClass:'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        width: 150,
        tooltipField:'fullName',
        headerClass:'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Năm sinh',
        field: 'yearOfBirth',
        tooltipField:'yearOfBirth',
        headerClass:'text-center',
        width: 50,
        cellStyle: { 'text-align': 'center' }
    }
    ,
    {
        headerName: 'Điện thoại',
        field: 'phone',
        tooltipField:'phone',
        headerClass:'text-center',
        width: 100,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Giới tính',
        field: 'gender',
        tooltipField:'gender',
        headerClass:'text-center',
        width: 60,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Địa chỉ',
        field: 'location',
        tooltipField:'location',
        headerClass:'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Lý do khám',
        field: 'reason',
        tooltipField:'reason',
        headerClass:'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    },
        
    {
        headerName: 'TG đặt khám',
        field: 'startBook',
        tooltipField: 'startBook',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'center' },
        width: 100


    },
		
    {
        headerName: 'Ngày',
        field: 'dateBook',
        tooltipField:'dateBook',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'center' },
    },
    
    {
        headerName: 'STT',
        field: 'numberOrder',
        tooltipField:'numberOrder',
        headerClass:'text-center',
        maxWidth: 100,
        cellStyle: { 'text-align': 'center' }
    }

];


// export const PatientWaitingGridColumns : (ColDef | ColGroupDef)[] = [
//     {
      
//         children: [
//             {
//                 headerName: 'STT', 
//                 valueGetter: 'node.rowIndex + 1',
//                 field:'stt',
//                 maxWidth: 80,
//                 headerClass:'text-center',
//                 cellStyle: { 'text-align': 'start' }
//             },
//             {
//                 headerName: 'Họ và tên',
//                 field: 'fullName',
//                 width: 150,
//                 tooltipField:'fullName',
//                 headerClass:'text-center',
//                 cellStyle: { 'text-align': 'start' }
//             },
//             {
//                 headerName: 'Năm sinh',
//                 field: 'yearOfBirth',
//                 tooltipField:'yearOfBirth',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'center' }
//             }
//             ,
//             {
//                 headerName: 'Số điện thoại',
//                 field: 'phone',
//                 tooltipField:'phone',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'start' }
//             }
//             ,
//             {
//                 headerName: 'Giới tính',
//                 field: 'gender',
//                 tooltipField:'gender',
//                 headerClass:'text-center',
//                 width: 60,
//                 cellStyle: { 'text-align': 'start' }
//             }
//             ,
//             {
//                 headerName: 'Địa chỉ',
//                 field: 'location',
//                 tooltipField:'location',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'start' }
//             },
//             {
//                 headerName: 'Lý do khám',
//                 field: 'reason',
//                 tooltipField:'reason',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'start' }
//             },
//         ]
//     },
//     {
//         headerName: 'Athlete Details',
//         children: [
//             {
//                 headerName: 'Khung giờ',
//                 field: 'timeBook',
//                 suppressSizeToFit: true,
//                 enableRowGroup: true,
//             },
		
//             {
//                 headerName: 'Ngày',
//                 field: 'dateBook',
//                 suppressSizeToFit: true,
//                 enableRowGroup: true,
//             },
//         ]
//     },
//     {
//         children: [
//             {
//                 headerName: 'Khung giờ',
//                 field: 'timeBook',
//                 tooltipField:'timeBook',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'center' }
//             },
		
//             {
//                 headerName: 'Ngày',
//                 field: 'dateBook',
//                 tooltipField:'dateBook',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'center' }
//             },
//             {
//                 headerName: 'Số thứ tự khám',
//                 field: 'order',
//                 tooltipField:'order',
//                 headerClass:'text-center',
//                 width: 100,
//                 cellStyle: { 'text-align': 'center' }
//             }
//         ]
//     }
    
// ];



