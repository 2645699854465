import '../style/index.scss';

import { Empty, Form } from 'antd';
import { debounce, DebouncedFunc } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { Loading } from '../../../components/common/Loading';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import TextField from '../../../components/FormFields/TextField';
import useMergeState from '../../../hooks/useMergeState';
import { Results } from '../../../types/api.type';
import { Conversation } from '../../../types/Message/Conversation';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { GET_LIST_CONVERSATIONS } from '../api/constants.api';
import ConversationItem from './ConversationItem';

interface IProps
{
    onHandleOpenMessge: (conversation: Conversation, isCallRead: boolean) => void,
}
interface IState
{
    conversations: Conversation[],
    isLoadingScroll: boolean,
}

export type ListConversationRef = {
    reloadData: DebouncedFunc<() => void>
};
const NUMBER_GET_MESSAGE = 40;

const ListConversationView = forwardRef<ListConversationRef, IProps>((props, ref) =>
{
    const offset = useRef<number>(0);
    const limit = useRef<number>(40);
    const [state, setState] = useMergeState<IState>({
        conversations: [],
        isLoadingScroll: false,
    });

    const refLoadUl = useRef<HTMLUListElement>(null);
    const formRef = useRef<BaseFormRef>(null);
    const suffixFilter = () =>
    {
        return <svg xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"><path fill="#65676B"
                fillRule="evenodd"
                d="M5.042.99C6.124.75 7.437.667 9 .667c1.563 0 2.876.082 3.958.323 1.092.242 2 .657 2.697 1.355.698.698 1.112 1.605 1.355 2.697.24 1.082.323 2.395.323 3.958 0 1.563-.082 2.876-.323 3.958-.243 1.092-.657 2-1.355 2.697-.698.698-1.605 1.112-2.697 1.355-1.082.24-2.395.323-3.958.323-1.563 0-2.876-.082-3.958-.323-1.092-.243-2-.657-2.698-1.355-.697-.698-1.112-1.605-1.354-2.697C.75 11.876.667 10.563.667 9c0-1.563.082-2.876.323-3.958.242-1.092.657-2 1.354-2.697C3.042 1.647 3.95 1.232 5.042.99ZM6.5 8.583a2.083 2.083 0 1 1 4.167 0 2.083 2.083 0 0 1-4.167 0Zm2.083-3.75a3.75 3.75 0 1 0 1.997 6.925l1.164 1.165a.833.833 0 0 0 1.178-1.179l-1.164-1.164a3.75 3.75 0 0 0-3.175-5.746Z"
                clipRule="evenodd" /></svg>;
    };



    const reloadData = useRef(
        debounce(() =>
        {
            getListConversationAll();
        }, 500)
    ).current;

    useImperativeHandle(ref, () => ({
        reloadData: reloadData
    }));

    const getListConversationAll = () =>
    {
        const currentOffset = offset.current;
        const DEFAULT_OFFSET = 0;
        ApiUtil.Axios.get<Results<Conversation>>(BASE_API_PATH + GET_LIST_CONVERSATIONS, { withCredentials: true, params: { limit: currentOffset, offset: DEFAULT_OFFSET } }).then(result =>
        {
            setState({
                conversations: result.data.results.items
            });
        });
    };


    useEffect(() =>
    {
        return () =>
        {
            reloadData.cancel();
        };
    }, [reloadData]);

    useEffect(() =>
    {
        getListConversation();
    }, []);


    const textAbstract = (text: string, length: number) =>
    {
        if (text == null)
        {
            return '';
        }
        if (text.length <= length)
        {
            return text;
        }
        text = text.substring(0, length);
        const last = text.lastIndexOf(' ');
        text = text.substring(0, last);
        return text + '...';
    };
    const getListConversation = () =>
    {
        ApiUtil.Axios.get<Results<Conversation>>(BASE_API_PATH + GET_LIST_CONVERSATIONS, { withCredentials: true, params: { limit: limit.current, offset: offset.current } }).then(result =>
        {
            const data = result.data.results.items;
            setState({
                conversations: [...data]
            });
        });
    };

    const onHandleOpenMessge = (conversation: Conversation) =>
    {
        const callReadMessage = !conversation.isClinic && conversation.countUnRead > 0;
        props.onHandleOpenMessge && props.onHandleOpenMessge(conversation, callReadMessage);
    };

    const renderListMessage = () =>
    {
        return <ul className="flex flex-col flex-1 overflow-auto mr-6" ref={refLoadUl}>

            {state.conversations?.map(item =>
            {
                return <ConversationItem data={item}
                    key={item.roomName}
                    textAbstract={textAbstract}
                    onHandleOpenMessge={(conversation) => onHandleOpenMessge(conversation)} />;
            })}
        </ul>;
    };

    const onSubmit = (value: any) =>
    {
        ApiUtil.Axios.get<Results<Conversation>>(BASE_API_PATH + GET_LIST_CONVERSATIONS, { withCredentials: true, params: { limit: limit.current, offset: offset.current, key: value.search } }).then(result =>
        {
            setState({
                conversations: result.data.results.items
            });
        });
    };

    const onSearchMessage = debounce((e: any) =>
    {
        ApiUtil.Axios.get<Results<Conversation>>(BASE_API_PATH + GET_LIST_CONVERSATIONS, { withCredentials: true, params: { limit: limit.current, offset: offset.current, key: e.target.value } }).then(result =>
        {
            setState({
                conversations: result.data.results.items
            });
        });
    }, 500);

    const loadMoreConversation = debounce(() =>
    {
        offset.current += NUMBER_GET_MESSAGE;
        const { conversations } = state;

        try
        {
            ApiUtil.Axios.get<Results<Conversation>>(BASE_API_PATH + GET_LIST_CONVERSATIONS, { withCredentials: true, params: { limit: limit.current, offset: offset.current } }).then(result =>
            {
                if (result.data.success)
                {
                    const clone = [...conversations, ...result.data.results.items];
                    setState({
                        conversations: clone,
                        isLoadingScroll: false
                    });
                }

            });
        } catch (error)
        {
            console.log('err');
        }

    }, 500);
    const handleScroll = () =>
    {

        const diff = (refLoadUl.current?.scrollHeight || 0) - (refLoadUl.current?.scrollTop || 0);
        const height = refLoadUl.current?.clientHeight || 0;
        const offPageHeight = 1;
        if (diff < (height + offPageHeight))
        {
            setState({ isLoadingScroll: true });
            setTimeout(() =>
            {
                loadMoreConversation();
            });
        }

    };
    useEffect(() =>
    {
        refLoadUl.current?.addEventListener('scroll', handleScroll);
        return () => refLoadUl.current?.removeEventListener('scroll', handleScroll);
    }, [JSON.stringify(state.conversations)]);

    const renderInputSearch = () =>
    {
        return <div className="w-full mt-2 mb-2" style={{ height: '30px' }}>
            <div className="relative text-gray-600">
                <BaseForm
                    onSubmit={(value) => { onSubmit(value); }}
                    defaultValues={{}}
                    ref={formRef}

                >
                    <Form.Item>
                        <TextField
                            labelClass={'lock w-full py-2 pl-10 bg-gray-100 rounded outline-none'}
                            name={'search'}
                            style={{ borderRadius: '7px', height: '30px' }}
                            noShowLabel
                            suffix={suffixFilter()}
                            label={'tin nhắn'}
                            onChange={(e) =>
                            {
                                onSearchMessage(e);
                            }}
                        />
                    </Form.Item>

                </BaseForm>
            </div>

        </div>;
    };


    return (
        <div className="h-full flex flex-col border-r border-gray-300 lg:col-span-1 conversations" style={{ width: '25%' }}>
            {renderInputSearch()}
            {renderListMessage()}
            {state.isLoadingScroll && <div className="loading-conversation-scroll"
            >
                <Loading showLabel={false} />
            </div>}
        </div >
    );
});
ListConversationView.displayName = 'ListConversationView';
export default ListConversationView;