import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import NumberField from '../../components/FormFields/NumberField';
import RadioField, { ICombo } from '../../components/FormFields/RadioField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { phoneRegExp } from '../../constants/YupContants';
import { UserAccountDoctor } from '../../types/UserAccountDoctor';

//resolver={yupResolver(schema)}
const schema = yup.object().shape({
    fullName: yup.string().required('Vui lòng nhập thông tin!'),
    phone: yup.string().required('Vui lòng nhập thông tin!').min(8, 'Số điện thoại lớn hơn 8 số.').max(12, 'Số điện thoại ít hơn 12 số').matches(phoneRegExp, 'Định dạng không hợp lệ'),
    email: yup.string().email('Vui lòng nhập đúng định dạng').max(255),
    userName: yup.string().required('Vui lòng nhập thông tin!')
});

interface IFormAccountSettingProps
{
    handleSubmit: (value: UserAccountDoctor) => Promise<void>;
    onClose: () => void,
    defaultvalue?: UserAccountDoctor
}

const comboActive: ICombo[] = [
    { label: 'Đã xác thực', value: true },
    { label: 'Chưa xác thực', value: false }
];

const UserAccountSettingEdit: FunctionComponent<IFormAccountSettingProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, defaultvalue } = props;
    const onSubmit = async (value: UserAccountDoctor) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
            resolver={yupResolver(schema)} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'fullName'}
                            label={'Họ và tên'} />
                    </Form.Item>
                </Col>

            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'email'}
                            label={'Email'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <NumberField requiredError={true}
                            name={'phone'}
                            label={'Số điện thoại'}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'userName'}
                            label={'Tên đăng nhập'} />
                    </Form.Item>

                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <RadioField name={'status'}
                            comboValue={comboActive}
                            label={'Trạng thái'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>
    );
};

export default UserAccountSettingEdit; 
