import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, Row } from 'antd';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import { pathDateTime } from '../../../constants/SvgIcon';
import { MedicalExaminationFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';

interface IHeaderProps
{
    onFilter: () => void;
    onOpenCreate: () => void;

    // loadOptionsDistrict:(value:string)=>Promise<void>;
    // optionsProvince : IComboBox[];
    // optionsDistrict : IComboBox[];
    options?: IComboBox[];
    onLoadDataGrid: (value: MedicalExaminationFilterDto) => Promise<void>;
    formRefFilter: React.RefObject<BaseFormRef>
}



const HeaderFilterPatientWaiting = (props: IHeaderProps) =>
{
    const { onLoadDataGrid, formRefFilter, onOpenCreate } = props;
    // const formRef = useRef<BaseFormRef>(null);

    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(true);
    const suffixFilter = () =>
    {
        return <SearchOutlined />;
    };
    const suffixDateTimeFilter = () =>
    {
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };
    const { RangePicker } = DatePicker;


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];


    return (
        <div className="mt-3 justify-between">
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginBottom: 16, marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>DANH SÁCH BỆNH NHÂN ĐẶT KHÁM</p>
                <ButtonBase
                    style={{ width: 180 }}
                    onClick={() => onOpenCreate()}
                    buttonName={'THÊM BỆNH NHÂN'}
                    buttonType={'create'}
                />
            </div>


            <div className=' '>
                <BaseForm ref={formRefFilter}
                    onSubmit={(value) => { onLoadDataGrid(value); }}
                    style={{ width: '100%' }}
                    defaultValues={{}} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px', marginBottom: 16 }}>
                        <Col style={{ width: '50%' }} className="">
                            <Form.Item>
                                <TextField noShowLabel={true}
                                    suffix={suffixFilter()}
                                    name={'fullNameOrPhone'}
                                    label={'SĐT, họ tên'} />
                            </Form.Item>
                        </Col>
                        <Col style={{ width: '12%' }} className="">
                            <Controller
                                name={'isFilterByDate'}
                                render={({ field }) =>
                                {
                                    return <Checkbox
                                        {...field}
                                        className="checkbox-red"
                                        onChange={(value) =>
                                        {
                                            const dataForm = formRefFilter.current?.getValues();
                                            formRefFilter.current?.setValues({ ...dataForm, isFilterByDate: value.target.checked });
                                            setIsActiveTimeRange(!value.target.checked);
                                        }}
                                        style={{ display: 'flex' }}
                                    >
                                        <div style={{ width: '70px' }}>
                                            Theo ngày
                                        </div>

                                    </Checkbox>;
                                }
                                }
                            />
                        </Col>
                        <Col style={{ width: '20%' }}>
                            <Controller
                                name={'timeRange'}
                                render={({ field }) =>
                                {
                                    return <RangePicker
                                        {...field}
                                        placeholder={['Từ ngày', 'Đến ngày']}
                                        format={dateFormatList}
                                        disabled={isActiveTimeRange}
                                        suffixIcon={suffixDateTimeFilter()}
                                    />;
                                }
                                }
                            />
                        </Col>
                        <Col className='' style={{ width: '10%', display: 'flex', justifyContent: 'end', justifyItems: 'end', flex: 1, paddingRight: 0 }}>
                            <div className="footer ">
                                <ButtonBase style={{ width: 180, display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                                    buttonName={'TÌM KIẾM'}
                                    buttonType="filter"
                                    htmlType="submit" />
                            </div>
                        </Col>

                    </Row>

                </BaseForm>
            </div>

        </div>
    );
};

export default HeaderFilterPatientWaiting; 