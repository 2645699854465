import { ColDef } from 'ag-grid-community';



export interface HistoryPaymentDto
{
    amount?: string;
    amountPay?: string;
    bankCode?: number;
    bankTransNo?: string;
    orderDescription?: string;
    createdAt?: string;
}

export const HistoryPaymentColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 80,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
    },
    {
        headerName: 'Ghi chú',
        field: 'orderDescription',
        tooltipField: 'orderDescription',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
    ,
    {
        headerName: 'Số điểm',
        field: 'amountPay',
        tooltipField: 'amountPay',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
    ,

    {
        headerName: 'Mã giao dịch',
        field: 'bankTransNo',
        tooltipField: 'bankTransNo',
        headerClass: 'text-center',
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Mã ngân hàng',
        field: 'bankCode',
        tooltipField: 'bankCode',
        headerClass: 'text-center',
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Thời gian',
        field: 'createdAt',
        tooltipField: 'createdAt',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
];


