import '../style/styles.scss';

import { useEffect, useRef } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import appleStoreImg from '../../../assets/images/apple-store.png';
import googlePlayImg from '../../../assets/images/google-play.png';
import useMergeState from '../../../hooks/useMergeState';
import MedicineResultFormScan from '../../../module/ExamResult/MedicineResult/MedicineResultFormScan';
import { ApiResponse } from '../../../types/api.type';
import { MedicneResultDto } from '../../../types/ExamResult/MedicineResult';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import { loadMedicineResult, loadMedicineResultByToken } from '../../PatientRecord/api/constants';
import ViewMedicineResultFormScan from '../components/ViewMedicineResultFormScan';

interface IMedicineState
{
    medicineResult: MedicneResultDto;
    loading: boolean;
}

function ViewMedicineFile()
{

    const componentRef = useRef<HTMLDivElement>(null);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    console.log('token', token);
    const [state, setState] = useMergeState<IMedicineState>({
        medicineResult: {},
        loading: true,
    });
    const medicalExaminationId = 33;
    useEffect(() =>
    {
        loadApi();
    }, []);
    const loadApi = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<MedicneResultDto>>(BASE_API_PATH + loadMedicineResultByToken + '?token=' + token);
        if (response.data.success)
        {
            const data = response.data.results;
            setState({
                loading: false,
                medicineResult: data as MedicneResultDto
            });
        }
    };
    return (
        <div className='fixed top-0 left-0 h-screen w-screen z-50 bg-white' style={{ overflowY: 'scroll', overflowX: 'hidden', paddingBottom: 80 }}>
            <div className='mx-auto max-w-lg flex flex-col justify-center gap-y-4'>
                <ViewMedicineResultFormScan medicineResult={state.medicineResult} componentRef={componentRef} />
            </div>
            <div style={{
                display: 'block',
                flexDirection: 'column',
                width: '100%',
                backgroundColor: 'white',
                position: 'fixed',
                bottom: 0,
                paddingBottom: 5
            }}>
                {/* <button type="button"
                    aria-label="Close"
                    className="ant-modal-close">
                    <span className="ant-modal-close-x">
                        <div className="flex items-center h-full"
                            style={{ justifyContent: 'center' }}>
                            <div style={{ width: 20, height: 20, backgroundColor: 'GrayText', borderRadius: 15 }}></div>
                        </div>
                    </span>
                </button> */}
                <span style={{ display: 'block', textAlign: 'center', fontStyle: 'italic' }}>Tải ứng dụng mobile VNMEDIC để xem nhiều hơn!</span>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto' }}>
                    <div style={{ width: '50%', margin: 'auto' }}>
                        <img src={appleStoreImg}
                            style={{ width: 90, marginLeft: 10, marginRight: 10, float: 'right' }}
                            onClick={() =>
                            {
                                window.location.href = 'https://apps.apple.com/app/vnmedic/id1663700343';
                            }}></img>
                    </div>
                    <div style={{ width: '50%', margin: 'auto', float: 'left' }}>
                        <img src={googlePlayImg}
                            style={{ width: 90, marginLeft: 10, marginRight: 10 }}
                            onClick={() =>
                            {
                                window.location.href = 'https://play.google.com/store/apps/details?id=vn.vnmedic.app';
                            }}></img>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ViewMedicineFile;