import {ColDef, ICellRendererParams, RowClassParams, RowStyle} from 'ag-grid-community';
import React, {useEffect, useRef} from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, {ModalRef} from '../../../components/CustomModal/CustomModal';
import {BaseFormRef} from '../../../components/FormFields/BaseForm';
import Overlay, {OverlayRef} from '../../../components/Overlay/Overlay';
import {ADD_TO_SUCCESS, UPDATE_TO_SUCCESS} from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import {ApiResponse, PaginatedList} from '../../../types/api.type';
import {PartnerGridColumns} from '../../../types/PartnerManagement/Partner';
import {PartnerCreateDto, PartnerGridDto, PartnerRole} from '../../../types/PartnerManagement/PartnerGridDto';
import {ApiUtil, BASE_API_PATH} from '../../../utils/ApiUtil';
import {activatePartner, createPartner, getPartnerList, updatePartner} from '../api/constants';
import HeaderFilterPartner from './HeaderFilterPartner';
import PartnerCreateForm from './PartnerCreateForm';
import StopPartnerModal from './StopPartnerModal';

interface IState
{
    partner: PartnerGridDto[];
    loading?: boolean;
}

function PartnerList() {
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const formRefFilter = useRef<BaseFormRef>(null);

    const [state, setState] = useMergeState<IState>({
        partner: [],
        loading: true,
    });

    useEffect(() => {
        loadApi();
    }, []);

    const loadApi = async () => {
        const params = {
            text:'ALL',
            provinceId: -1,
            status: -1,
            page: 0,
            limit: 50,
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<PartnerGridDto>>>(BASE_API_PATH + getPartnerList, params);
        let allPartner: PartnerGridDto[] = [];
        if (response.data.success)
        {
            allPartner = response.data.results?.items as unknown as PartnerGridDto[];
        }
        setState({
            ...state,
            partner: allPartner,
        });
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onUpdatePartner = async (value: PartnerCreateDto) => {
        const params = {
            ...value
        };
        const response = await AxiosClient.post<ApiResponse<PartnerCreateDto>>(BASE_API_PATH + updatePartner, params);
        if (response.data.success) {
            await loadApi();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            modalRef.current?.onClose();
        } else {
            ApiUtil.ToastError(response.data.message);

        }
    };
    const onCreatePartner = async (value: PartnerCreateDto) => {
        const params = {
            ...value
        };
        const response = await AxiosClient.post<ApiResponse<PartnerCreateDto>>(BASE_API_PATH + createPartner, params);
        if (response.data.success) {
            await loadApi();
            ApiUtil.ToastSuccess(ADD_TO_SUCCESS);
            modalRef.current?.onClose();
        } else {
            ApiUtil.ToastError(response.data.message);

        }
    };
    const handleActivatePartner = async (id: number, isActivated: boolean) => {
        const params = {
            partnerId: id,
            activated: isActivated
        };
        const response = await AxiosClient.post<ApiResponse<PartnerCreateDto>>(BASE_API_PATH + activatePartner, params);
        if (response.data.success) {
            await loadApi();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            modalRef.current?.onClose();
        } else {
            ApiUtil.ToastError(response.data.message);

        }
    };

    const handleOpenUpdateModal = (data: PartnerGridDto) => {
        modalRef.current?.onOpen(<PartnerCreateForm handleSubmit={onUpdatePartner}
            defaultValue={data}
            onClose={onCloseModal}
            isEdit />, 'Sửa thông tin đối tác', 25);
    };
    const handleOpenCreateModal = () => {
        modalRef.current?.onOpen(<PartnerCreateForm handleSubmit={onCreatePartner}
            onClose={onCloseModal} />, 'Thêm đối tác', 25);
    };
    const handleOpenStopModal = (data : PartnerGridDto) => {
        modalRef.current?.onOpen(<StopPartnerModal partner={data}
            handleSubmit={handleActivatePartner}
            onClose={onCloseModal} />, 'Tạm dừng đối tác', 25);
    };

    const getColumnConfig = () : ColDef[] => {
        return [
            ...PartnerGridColumns,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<PartnerGridDto>) =>
                {
                    const data = params.data as PartnerGridDto;
                    const partner = data.roles?.find((item: PartnerRole) => item.name === 'PARTNER');
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <GridButtonBase type={'edit'}
                                onClick={() => { handleOpenUpdateModal(data); }} />
                            {
                                partner?.status ?
                                    <GridButtonBase type={'stop'}
                                        onClick={() =>
                                        {
                                            handleOpenStopModal(data);
                                        }} />
                                    :<GridButtonBase type={'activate'}
                                        onClick={() =>
                                        {
                                            handleActivatePartner(data.id, true);
                                        }} />
                            }
                        </div>
                    );
                }, }
        ];
    };

    const onFilter = async () =>
    {
        overlayRef.current?.open();
        const dataFilter = formRefFilter.current?.getValues();
        const params = {
            ...dataFilter,
            status: Number(dataFilter?.status) || -1
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<PartnerGridDto>>>(BASE_API_PATH + getPartnerList, params);
        let allPartner: PartnerGridDto[] = [];
        if (response.data.success)
        {
            allPartner = response.data.results?.items as unknown as PartnerGridDto[];
            setState({
                ...state,
                partner: allPartner
            });
        }
        overlayRef.current?.close();

    };

    const ClinicHeader = () =>
    {
        return <HeaderFilterPartner
            onLoadDataGrid={onFilter}
            onOpenCreate={handleOpenCreateModal}
            formRefFilter={formRefFilter}
        />;
    };

    const renderGrid = () =>
    {
        return <div className='mt-2' style={{ height: '80%' }}>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                columnDefs={getColumnConfig()}
                suppressRowTransform={true}
                pagination={true}
                suppressClickEdit={true}
                rowData={state.partner}
                getRowStyle={(params: RowClassParams<any>): RowStyle => {
                    const partner = params.data.roles?.find((item: PartnerRole) => item.name === 'PARTNER');
                    if (!partner.status) {
                        return { background: '#e5e7eb' };
                    } else {
                        return {};
                    }
                }} />
        </div>;
    };

    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='h-full'>
                {ClinicHeader()}
                {renderGrid()}
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>
    );
}

export default PartnerList;