import { CellClassRules, ColDef, ColSpanParams } from 'ag-grid-community';

import { DiseaseAnalysisGrid } from './DiseaseAnalysis';

export const ragCellClassRules: CellClassRules<DiseaseAnalysisGrid> = {
    'cell-span': (params) =>
    {
        return params.data?.isMerge === true;
    } 
};
export const DiseaseAnalysisFormGridColumns : ColDef[] = [
    {
        headerName: 'STT',
        field:'stt',
        rowSpan: (params:ColSpanParams<DiseaseAnalysisGrid>) =>{
            const length = params.data?.diseaseAnalysisDetailDetailDtos?.length as number; 
            const numberSpan = length > 1 ? length :1;
            if(params.data?.isMerge)
            {
                return numberSpan;
            }
            else return 1;
        },
        cellClassRules: ragCellClassRules,
        maxWidth: 80,
        headerClass:'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Tên mô tả KQ',
        field: 'name',
        width: 150,
        rowSpan: (params:ColSpanParams<DiseaseAnalysisGrid>) =>{
            const length = params.data?.diseaseAnalysisDetailDetailDtos?.length as number; 
            const numberSpan = length > 1 ? length :1;
            if(params.data?.isMerge)
            {
                return numberSpan;
            }
            else return 1;
        },
        cellClassRules: ragCellClassRules,
        tooltipField:'name',
        headerClass:'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Phân tích bệnh',
        field: 'content',
        tooltipField:'content',
        headerClass:'text-center',
        width: 150,
        cellStyle: { 'text-align': 'start' }
    }
];