import { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { ADD_TO_SUCCESS, DELETE_TO_SUCCESS, UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import MedicineGroupForm from '../../../module/MedicineGroup/MedicineGroupForm';
import { ApiResponse, PaginatedList } from '../../../types/api.type';
import { MedicineGroup } from '../../../types/MedicineGroup/MedicineGroup';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createMedicineGroup, deleteMedicineGroup, editMedicineGroup, indexMedicineGroup } from '../api/contants';
import HeaderMedicineGroup from './HeaderMedicineGroup';
import MedicineGroupGrid from './MedicineGroupGrid';


const MedicineGroupView = () =>
{
    const [medicineGroup, setMedicineGroup] = useState<MedicineGroup[]>([]);
    const modalRef = useRef<ModalRef>(null);
    const overlayRef = useRef<OverlayRef>(null);


    useEffect(() =>
    {
        loadMedicineGroup();
    }, []);


    const loadMedicineGroup = async () =>
    {

        const response = await AxiosClient.get<ApiResponse<MedicineGroup>>(BASE_API_PATH + indexMedicineGroup, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as MedicineGroup[];
            setMedicineGroup(data);
        }
    };


    const onOpenUpdate = (value: MedicineGroup) =>
    {
        modalRef.current?.onOpen(<MedicineGroupForm defaultvalue={value}
            handleSubmit={onUpdateMedicineGroup}
            onClose={onCloseModal} />, 'Sửa nhóm thuốc', 30);

    };

    const onOpenCreate = () =>
    {
        modalRef.current?.onOpen(<MedicineGroupForm handleSubmit={onCreateMedicineGroup} onClose={onCloseModal} />, 'Thêm nhóm thuốc', 30);
    };



    const onCreateMedicineGroup = async (value: MedicineGroup) =>
    {
        const params = {
            ...value,
        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<MedicineGroup>>>(BASE_API_PATH + createMedicineGroup, params, { withCredentials: true });
        if (response.data.success)
        {
            await loadMedicineGroup();
            ApiUtil.ToastSuccess(ADD_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };

    const onUpdateMedicineGroup = async (value: MedicineGroup) =>
    {
        const medicineGroupId = value.id;
        const response = await AxiosClient.put<ApiResponse<MedicineGroup>>(BASE_API_PATH + editMedicineGroup + medicineGroupId, value);
        if (response.data.success)
        {
            await loadMedicineGroup();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onDeleteMedicineGroup = async (value: MedicineGroup) =>
    {
        overlayRef.current?.open();
        const medicineGroupId = value.id;
        const response = await AxiosClient.delete<ApiResponse<MedicineGroup>>(BASE_API_PATH + deleteMedicineGroup + medicineGroupId);
        if (response.data.success)
        {
            await loadMedicineGroup();
            ApiUtil.ToastSuccess(DELETE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError('Nhóm thuốc đang được sử dụng tại vị trí khác!');
        }
        onCloseModal();
        overlayRef.current?.close();

    };

    return (
        <>
            <HeaderMedicineGroup onOpenCreate={onOpenCreate} />
            <MedicineGroupGrid onEdit={onOpenUpdate}
                onDelete={onDeleteMedicineGroup}
                record={medicineGroup} />
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </>

    );
};

export default MedicineGroupView; 
