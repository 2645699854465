import '../style/styles.scss';

import {Checkbox, Input} from 'antd';
import React, {useState} from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import {ApiResponse} from '../../../types/api.type';
import {ApiUtil, BASE_API_PATH} from '../../../utils/ApiUtil';
import {userRemove} from '../api/constants';

interface RemoveUserInterface {
    userName: string;
    causes: string;
}

function RemoveUser() {
    const [phone, setPhone] = useState<string>('');
    const [agree, setAgree] = useState<boolean>(false);
    const handleRemoveUser = async (value: string) =>
    {
        if (!agree) {
            ApiUtil.ToastError('Bạn chưa đồng ý với điều khoản dịch vụ ứng dụng Vnmedic');
            return;
        }
        const params: RemoveUserInterface = {
            userName: value,
            causes: ''
        };
        const url = userRemove;
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + url, params, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess('Chúng tôi sẽ xem xét yêu cầu của bạn và sớm liên hệ với bạn để hoàn thành yêu cầu này.');
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    return (
        <div className='fixed top-0 left-0 h-screen w-screen z-50 bg-white'>
            <div className='mx-auto max-w-lg flex flex-col justify-center gap-y-4 pt-20'>
                <h1 className='text-2xl font-bold text-[#2C999C] text-center'>Xoá tài khoản</h1>
                <div>
                    <label className="text-lg font-semibold mb-2">Số điện thoại:</label>
                    <Input placeholder='Nhập số điện thoại' onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div>
                    <Checkbox defaultChecked={false} onChange={(e) => setAgree(e.target.checked)}>Tôi đồng ý với <a target="_blank"
                        href='https://www.vnmedic.vn/dieukhoandichvu'
                        rel="noreferrer">điều khoản dịch vụ</a> ứng dụng Vnmedic</Checkbox>
                </div>
                <div className='flex items-center justify-center'>
                    <ButtonBase buttonName="Gửi"
                        buttonType='save'
                        onClick={()=>handleRemoveUser(phone)} />
                </div>
            </div>
        </div>
    );
}

export default RemoveUser;