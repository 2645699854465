import i18next from 'i18next';



i18next
    .init({
        // lng: 'en',
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    learn: 'dsadsadsada',
                    HelloDoctor: 'dsjakldjsaklj'
                }
            }
        }
    });
export default i18next;

