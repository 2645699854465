import { ColDef } from 'ag-grid-community';

import { InfoPatientFileResulExamDto } from './ExamResult';

export type MedicneResultDto = {
    infoPatient?: InfoPatientFileResulExamDto,
    medicineResultGrid?: MedicineResultGrid[],
    noteMedicine?: string,
    prescriptionAmount?: number,
    tokenUrl?: string | null;
    symptom?: string;
    note?: string;
}

export interface MedicineResultGrid
{
    medicineGroup?: string;
    medicineCode?: string;
    medicineName?: string;
    order?: number;
    noteUse?: string;
    quantity?: number
    noteUseMedicinePlus?: string
}

export const MedicineResultColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 60,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start', 'margin': 'auto' },
    },
    {
        headerName: 'Nhóm thuốc',
        field: 'medicineGroup',
        tooltipField: 'medicineGroup',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    },
    {
        headerName: 'Mã thuốc',
        field: 'medicineCode',
        tooltipField: 'medicineCode',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
    ,
    {
        headerName: 'Tên thuốc/Hàm lượng/Quy cách',
        field: 'medicineName',
        tooltipField: 'medicineName',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
    ,
    {
        headerName: 'Số lượng',
        field: 'quantity',
        tooltipField: 'quantity',
        headerClass: 'text-center',
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Cách dùng',
        field: 'noteUseMedicinePlus',
        tooltipField: 'noteUseMedicinePlus',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
];


export const MedicineResultScanColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 60,
        headerClass: 'text-center',
        autoHeight: true,
        cellStyle: { 'text-align': 'start' },
    },
    {
        headerName: 'Nhóm thuốc',
        field: 'medicineGroup',
        tooltipField: 'medicineGroup',
        headerClass: 'text-center',
        resizable: true,
        autoHeight: true,
        wrapText: true,
        cellStyle: { 'text-align': 'start' },
        maxWidth: 150,
    },
    {
        headerName: 'Mã thuốc',
        field: 'medicineCode',
        tooltipField: 'medicineCode',
        resizable: true,
        autoHeight: true,
        wrapText: true,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        maxWidth: 100,
    }
    ,
    {
        headerName: 'Tên thuốc/Hàm lượng/Quy cách',
        field: 'medicineName',
        tooltipField: 'medicineName',
        autoHeaderHeight: true,
        resizable: true,
        wrapText: true,
        wrapHeaderText: true,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        maxWidth: 150,
    }
    ,
    {
        headerName: 'Số lượng',
        field: 'quantity',
        tooltipField: 'quantity',
        autoHeight: true,
        wrapText: true,

        headerClass: 'text-center',
        maxWidth: 100,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Cách dùng',
        field: 'noteUseMedicinePlus',
        autoHeight: true,
        autoHeaderHeight: true,
        resizable: true,
        wrapText: true,
        wrapHeaderText: true,
        tooltipField: 'noteUseMedicinePlus',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start', 'white-space': 'normal' },
        maxWidth: 200,
    }
];
