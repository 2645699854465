import React from 'react';
import ReactToPrint from 'react-to-print';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { pathPrint } from '../../../constants/SvgIcon';
import { InfoPatientFileResulExamDto } from '../../../types/ExamResult/ExamResult';
import { Remove_Dau } from '../../../utils/StringUtil';



interface IHeaderProps
{
    infoPatientExam: InfoPatientFileResulExamDto,
    datePatient: string
    componentRef?: React.RefObject<HTMLDivElement>
    isScan?: boolean
    note: string;
    symptom: string;
}



const HeaderResult = (props: IHeaderProps) =>
{
    const { note, symptom, infoPatientExam, datePatient, componentRef, isScan } = props;
    const iconPrint: React.ReactNode = <svg fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d={pathPrint} fill="#fff" />
    </svg>;

    function formatCurrency(value: number)
    {
        const formatter = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' });
        const formattedValue = formatter.format(value).replace(/\u00A0/g, ' ');
        return formattedValue;
    }
    const documentTitle = `${ infoPatientExam.fullName ?? 'Vnmedic' }-${ datePatient }-Kết quả khám`;
    return (
        <div className="mt-2 justify-between " style={{ height: isScan ? '190px' : '180px' }}>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', height: isScan ? '50px' : '30px' }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: 17, flex: 1 }}>THÔNG TIN BỆNH NHÂN</p>

                {isScan &&
                    <div className='flex w-full' style={{ height: 50, width: 180, justifyContent: 'end' }}>
                        <ReactToPrint
                            trigger={() => (
                                <ButtonBase className=''
                                    onClick={() => { }}
                                    style={{ width: 180, height: 40, justifyContent: 'center' }}
                                    buttonName={'IN KẾT QUẢ'}
                                    iconCustom={iconPrint}
                                    backgroudColorCustom='#4D9FDA'
                                    buttonType="button_custom"
                                    htmlType="submit" />
                            )}
                            content={() => componentRef?.current != null ? componentRef?.current : null}
                            documentTitle={documentTitle}
                            pageStyle={`
                                @page {
                                    size: A4;
                                    margin: 10mm 5mm;
                                }

                                @page :first {
                                    margin-top: 0;
                                }

                                body {
                                    margin: 0;
                                }

                                .page-break {
                                    page-break-before: always;
                                }
                                `} />
                    </div>
                }
            </div>

            <div className='ml-6 grid grid-cols-4 gap-2' style={{ backgroundColor: '#F3F5F9', borderRadius: '8px', padding: '12px', height: '140px' }}>
                <div>
                    <span className='font-bold'>1. Họ và tên: </span> <span> {infoPatientExam.fullName} </span>
                </div>
                <div>
                    <span className='font-bold'>2. Năm sinh: </span> <span> {infoPatientExam.yearOfBirth} </span>
                </div>
                <div>
                    <span className='font-bold'>3. Giới tính: </span> <span> {infoPatientExam.gender} </span>
                </div>
                <div>
                    <span className='font-bold'>4. Số điện thoại: </span> <span> {infoPatientExam.phone} </span>
                </div>
                <div>
                    <span className='font-bold'>5. Địa chỉ: </span> <span> {infoPatientExam.location} </span>
                </div>
                <div>
                    <span className='font-bold'>6. Lý do khám: </span> <span>  {infoPatientExam.reason} </span>
                </div>
                <div>
                    <span className='font-bold'>7. Chỉ định khám: </span> <span> {infoPatientExam.designationMedicalExamName as string} </span>
                </div>
                <div>
                    <span className='font-bold'>8. Đơn giá: </span> <span> {formatCurrency(infoPatientExam.designationMedicalAmount as number)} </span>
                </div>
                <div>
                    <span className='font-bold'>9. Triệu chứng: </span> <span> {symptom} </span>
                </div>
                <div>
                    <span className='font-bold'>10. Ghi chú: </span> <span> {note} </span>
                </div>
                {/* <div>
                    <span className='font-bold'>8. Thời gian khám: </span> <span> {datePatient} </span>
                </div> */}
            </div>

        </div>
    );
};

export default HeaderResult; 