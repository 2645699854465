import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import { useAppDispatch } from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import InfomationUpdate from '../../../module/Infomation/InfomationUpdate';
import TimeClinicForm from '../../../module/TimeClinic/TimeClinicForm';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { comboDistrict, comboProvince, loadInfomationClinic, updateInfomationClinic, updatePdfClinic } from '../api/constants';
import { clinicAction } from '../ClinicSlice';

interface IState
{
    optionsProvince: IComboBox[];
    infomationClinic: Clinic;
}
const WorkingTimeClinicView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const [state, setState] = useMergeState<IState>({
        optionsProvince: [],
        infomationClinic: {},
    });
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>();
    const dispatch = useAppDispatch();
    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
    };

    return (
        // <div className='overflow-hidden'>
        <div className='h-full w-full flex flex-col'>
            <div className="flex justify-between" style={{ height: '30px' }}>
                <div className='text-xl font-bold text-[#2c999c]  ml-3'>
                    CÀI ĐẶT GIỜ KHÁM
                </div>
            </div>
            <div className="flex flex-col flex-1 ">
                {/* {state.infomationClinic.id && optionsDistrict ?  : <div></div>} */}
                <TimeClinicForm
                />
            </div>
            <Overlay ref={overlayRef} />

        </div>

    );
};

export default WorkingTimeClinicView;