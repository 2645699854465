import './style/header-adv.scss';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Menu, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';

import AxiosClient from '../apis/api/axiosClient';
import { createPay, deductHistoryPayment, historyPaymentUrl, loadInfomationClinic, plusPayment } from '../features/Infomation/api/constants';
import { clinicAction, selectNotification } from '../features/Infomation/ClinicSlice';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import HistoryDeductPayment from '../module/HistoryPayment/HistoryDeductPayment';
import HistoryPaymentForm from '../module/HistoryPayment/HistoryPaymentForm';
import UnitDetailForm from '../module/HistoryPayment/UnitDetailForm';
import { ApiResponse } from '../types/api.type';
import { Clinic } from '../types/Clinic';
import { GetInfoPay } from '../types/PaymentClinic/GetInfoPay';
import { HistoryDeductDto } from '../types/PaymentClinic/HistoryDeductPayment';
import { HistoryPaymentDto } from '../types/PaymentClinic/HistoryPayment';
import { ApiUtil, BASE_API_PATH } from '../utils/ApiUtil';
import { ModalRef } from './CustomModal/CustomModal';

interface IProps
{
    infoPayment?: GetInfoPay | null;
    modalRef: React.RefObject<ModalRef>;
    roles: string[]
}




const HeaderADV = (props: IProps) =>
{
    const { infoPayment, modalRef, roles } = props;
    // const [showPayment, setShowPayment] = useState<boolean>(false);
    // const [urlCreate, setUrlCreate] = useState<boolean>(false);


    useEffect(() =>
    {
        loadInfoClinic();
    }, []);

    const dispatch = useAppDispatch();
    const infomation = useAppSelector(selectNotification);

    const loadInfoClinic = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<Clinic>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true });
        if (response.data.success)
        {
            if (response.data.results)
            {
                dispatch(clinicAction.setShowStorageWarning(response.data.results.isSizeWarning || false));
            }
            localStorage.setItem('ClinicInfo', JSON.stringify(response.data.results));
            const data = response.data.results?.notification;
            dispatch(clinicAction.loadNotification({ notification: data as string }));
        }
    };
    const classNames = (...classes: any) =>
    {
        return classes.filter(Boolean).join(' ');
    };

    const onCreatePayment = async () =>
    {
        // setShowPayment(!showPayment);

        const response = await AxiosClient.get<ApiResponse<string>>(BASE_API_PATH + createPay, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results;
            window.open(data, '_blank');
        }
        else
        {
            return ApiUtil.ToastError(response.data.message);
        }
    };

    const onLoadDeDuct = async () =>
    {
        let data: HistoryDeductDto[] = [];
        const response = await AxiosClient.get<ApiResponse<HistoryDeductDto[]>>(BASE_API_PATH + deductHistoryPayment, { withCredentials: true });
        if (response.data.success)
        {
            data = response.data.results as HistoryDeductDto[];
        }
        return data;
    };


    const onLoadHistoryPayment = async () =>
    {
        let data: HistoryPaymentDto[] = [];
        const response = await AxiosClient.get<ApiResponse<HistoryPaymentDto[]>>(BASE_API_PATH + historyPaymentUrl, { withCredentials: true });
        if (response.data.success)
        {
            data = response.data.results as HistoryPaymentDto[];
        }
        return data;
    };
    const onOpenHistoryDeDuct = async () =>
    {
        const defaultValue = await onLoadDeDuct();
        modalRef.current?.onOpen(<HistoryDeductPayment defaultvalue={defaultValue} onClose={onClose} />, 'Lịch sử trừ lượt khám', 70);
        // setShowPayment(!showPayment);

    };

    const onOpenHistoryPayment = async () =>
    {
        const defaultValue = await onLoadHistoryPayment();
        modalRef.current?.onOpen(<HistoryPaymentForm defaultvalue={defaultValue} onClose={onClose} />, 'Lịch sử nạp điểm - quy đổi', 70);
        // setShowPayment(!showPayment);

    };

    const onShowUnitDetail = () =>
    {
        modalRef.current?.onOpen(<UnitDetailForm defaultvalue={infoPayment} onClose={onClose} />, 'Chi tiết lượt khám', 40);

    };

    const onClose = () =>
    {
        modalRef.current?.onClose();
    };

    const onLoadPayVn = () =>
    {
        return (<div className="relative order-last flex" data-te-dropdown-ref>
            {onRenderInfoPayment()}
            {roles.indexOf('CLINIC_ADMIN') != -1 && infoPayment ? onRenderOption() : <div style={{ height: '70px' }}></div>}
        </div >);
    };

    const onRenderInfoPayment = () =>
    {
        return (
            <div className='mr-8' >
                <div style={{ marginTop: '-10px', height: 20, color: '#FFFFFF', fontWeight: 400, fontSize: 14 }}>
                    Số dư : {
                        infoPayment ?
                            (infoPayment?.totalBalancePrice ? infoPayment?.totalBalancePrice : '0 Điểm')
                            : '...'
                    }
                </div>
                <div className='flex' style={{ height: 20, color: '#FFFFFF', fontWeight: 400, fontSize: 14 }}>
                    Còn lại {
                        infoPayment ?
                            infoPayment?.totalUnit
                            : '...'
                    } lượt khám
                    <div className='ml-2'
                        style={{ marginTop: '-5px' }}>
                        <QuestionCircleOutlined
                            onClick={onShowUnitDetail}
                            style={{ fontSize: '100%' }}
                            className='mr-1' />
                    </div>



                </div>


            </div>
        );
    };


    // const onRenderOption = () =>
    // {
    //     return (
    //         <div
    //         >
    //             <button type="button"
    //                 className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    //                 id="menu-button"
    //                 aria-expanded="true"
    //                 onClick={() => { setShowPayment(!showPayment); }}
    //                 aria-haspopup="true">
    //                 Tài khoản

    //                 <svg className="-mr-1 h-5 w-5 text-gray-400"
    //                     viewBox="0 0 20 20"
    //                     fill="currentColor"
    //                     aria-hidden="true">
    //                     <path fillRule="evenodd"
    //                         d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
    //                         clipRule="evenodd" />
    //                 </svg>
    //             </button>
    //             <ul
    //                 className={'absolute left-auto right-0 z-[1000] float-left m-0 mt-1 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block'}
    //                 style={{ display: showPayment ? 'block' : 'none', backgroundColor: '#2C999C', color: '#fff', height: '110px', width: '190px' }}
    //                 onBlur={() => { setShowPayment(false); }}
    //             >
    //                 <li>
    //                     <a
    //                         className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-500 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
    //                         href="#"
    //                         onClick={() => { onCreatePayment(); }}
    //                         style={{ color: '#fff' }}

    //                         data-te-dropdown-item-ref
    //                     >
    //                         Nạp điểm

    //                     </a>
    //                 </li>
    //                 <li>
    //                     <a
    //                         className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-500 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
    //                         href="#"
    //                         style={{ color: '#fff' }}

    //                         onClick={() => { onOpenHistoryDeDuct(); }}
    //                         data-te-dropdown-item-ref
    //                     >
    //                         Lịch sử trừ lượt khám
    //                     </a>
    //                 </li>
    //                 <li>
    //                     <a
    //                         className="block w-full bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-500 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 "
    //                         href="#"
    //                         style={{ color: '#fff' }}

    //                         onClick={() => { onOpenHistoryPayment(); }}

    //                         data-te-dropdown-item-ref
    //                     >
    //                         Lịch sử nạp điểm - quy đổi
    //                     </a>
    //                 </li>
    //             </ul>
    //         </div>
    //     );
    // };

    const onRenderOption = () =>
    {
        return (
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Tài khoản
                        <svg className="-mr-1 h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true">
                            <path fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd" />
                        </svg>
                    </Menu.Button>
                </div>


                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute left-auto right-0 z-[1000] float-left m-0 mt-1 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                        style={{ backgroundColor: '#2C999C', color: '#fff' }}
                    >
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={() => { onCreatePayment(); }}
                                        style={{ color: '#fff' }}
                                        className={classNames(
                                            active ? 'bg-gray-500 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                                    >
                                        Nạp điểm
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        style={{ color: '#fff' }}
                                        onClick={() => { onOpenHistoryDeDuct(); }}
                                        className={classNames(
                                            active ? 'bg-gray-500 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        Lịch sử trừ lượt khám
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        style={{ color: '#fff' }}
                                        onClick={() => { onOpenHistoryPayment(); }}
                                        className={classNames(
                                            active ? 'bg-gray-500 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        Lịch sử nạp điểm - quy đổi
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>

        );
    };

    return (
        <div className='header-adv-content justify-between'
        >
            <div className='icon-noti flex'>
                <i className="fa fa-bell-o fa-xs"></i>
                <div className='noti-adv-text ml-2'>
                    {infomation}
                </div>
            </div>

            {roles.indexOf('SUPER_ADMIN') != -1 ? <div></div> : onLoadPayVn()}
        </div>
    );
};

export default HeaderADV;
