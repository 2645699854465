import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import ReactECharts from 'echarts-for-react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { dataPieChart, StatisticEqualToday, StatisticTodayEqual } from '../../../types/StatisticsDashboardView.ts/StatisticsDashboard';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import { getTodayStatisticAdmin } from '../api/constants';



interface IState
{
    date: string[];
    data: StatisticTodayEqual
}

interface IProps 
{
    clinicId: string[]
}
const StatisticTodayAdminView = (props: IProps) =>
{
    const { clinicId } = props;
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);

    const [state, setState] = useMergeState<IState>({
        date: [],
        data: {}
    });
    const options = useRef<any>({
    });

    useEffect(() =>
    {
        loadApi();
    }, [clinicId]);


    const dateByFilter = (type: string, typeShow: string) =>
    {
        //Type Filter Api
        const dateFilterArr = [];
        let dateOf = 0;
        let dateOfShow = 0;
        if (type === 'day')
        {
            dateOf = 2;
        }
        else if (type === 'month')
        {
            dateOf = 35;
        }
        else
        {
            dateOf = 365;
        }
        let startDayNotFormat;
        let endDayNotFormat;
        const now = new Date();
        now.setDate(now.getDate() - dateOf);
        for (let i = 0; i < dateOf; i++)
        {
            const nowSet = _.cloneDeep(now);
            nowSet.setDate(nowSet.getDate() + i);
            dateFilterArr.push(moment(nowSet.toISOString().split('T')[0].replaceAll('-', '/')).format('DD/MM/YYYY'));
            if (i == 0)
            {
                startDayNotFormat = nowSet;
            }
            else if (i + 1 >= dateOf)
            {
                endDayNotFormat = nowSet;
            }
        }

        const endDayString = moment(endDayNotFormat?.toISOString().split('T')[0].replaceAll('-', '/')).format('YYYY/MM/DD');
        const startDayString = moment(startDayNotFormat?.toISOString().split('T')[0].replaceAll('-', '/')).format('YYYY/MM/DD');
        //Type Filter Show
        if (typeShow === 'day')
        {
            dateOfShow = 2;

        }
        else if (typeShow === 'week')
        {
            dateOfShow = 7;
            startDayNotFormat = new Date(startDayString);
            endDayNotFormat = new Date(endDayString);
            const resultNew = getWeek(startDayNotFormat as Date, endDayNotFormat as Date);
            return resultNew;
        }
        else if (typeShow === 'month')
        {
            dateOfShow = 31;
            const resultNew = getMonth(startDayString, endDayString);
            return resultNew;
        }
        else
        {
            dateOfShow = 365;
            const resultNew = getYear(startDayNotFormat as Date, endDayNotFormat as Date);
            return resultNew;
        }

        return dateFilterArr;
    };


    const getWeek = (start: Date, end: Date) =>
    {
        let sDate;
        let eDate;
        const dateArr = [];
        while (start <= end)
        {
            if (start.getDay() == 1 || (dateArr.length == 0 && !sDate))
            {
                sDate = new Date(start.getTime());
            }

            if ((sDate && start.getDay() == 0) || start.getTime() == end.getTime())
            {
                eDate = new Date(start.getTime());
            }

            if (sDate && eDate)
            {
                sDate.setDate(sDate.getDate() + 1);
                eDate.setDate(eDate.getDate() + 1);
                //
                const startDateFormat = moment(sDate.toISOString().split('T')[0].replaceAll('-', '/')).format('DD');
                const endDateFormat = moment(eDate.toISOString().split('T')[0].replaceAll('-', '/')).format('DD/MM/YYYY');
                dateArr.push(startDateFormat + '-' + endDateFormat);
                sDate = undefined;
                eDate = undefined;
            }
            start.setDate(start.getDate() + 1);
        }
        return dateArr;
    };



    const getMonth = (startDate: string, endDate: string) =>
    {
        const start = startDate.split('/');
        const end = endDate.split('/');
        const startYear = parseInt(start[0]);
        const endYear = parseInt(end[0]);
        const dates = [];

        for (let i = startYear; i <= endYear; i++)
        {
            const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1)
            {
                const month = j + 1;
                const displayMonth = month < 10 ? '0' + month : month;
                dates.push([displayMonth, i].join('/'));
            }
        }
        return dates;

    };
    const getYear = (startDate: Date, endDate: Date) =>
    {
        const dates = [];
        const numberDiffYear = getYearDiffWithMonth(startDate, endDate);
        for (let i = 0; i <= numberDiffYear; i++)
        {
            const year = startDate.getFullYear() + i;
            dates.push(year.toString());

        }
        return dates;
    };

    const getYearDiffWithMonth = (startDate: Date, endDate: Date) =>
    {
        return Math.abs(startDate.getFullYear() - endDate.getFullYear());
    };




    const loadApi = async () =>
    {
        overlayRef.current?.open();

        let data: StatisticTodayEqual = {};

        const params = {
            clinicId: clinicId
        };
        const dateArr = dateByFilter('month', 'day');
        const response = await AxiosClient.post<ApiResponse<StatisticTodayEqual>>(BASE_API_PATH + getTodayStatisticAdmin, params, { withCredentials: true });
        if (response.data.success)
        {
            data = response.data.results as StatisticTodayEqual;
        }

        onChangeConfig(data, dateArr);
        setState({ ...state, data: state.data, date: dateArr });
        overlayRef.current?.close();

    };

    const onSetValue = (data: StatisticEqualToday) =>
    {
        const dataOption: dataPieChart[] = [];
        if (data != null)
        {
            Object.entries(data as StatisticEqualToday).forEach(
                ([key, value]) => 
                {
                    if (key === 'today' && value !== 0)
                    {
                        dataOption.push({
                            value: value,
                            name: 'Hôm nay'
                        });
                    }
                    else if (key === 'previous' && value !== 0)
                    {
                        dataOption.push({
                            value: value,
                            name: 'Hôm qua'
                        });
                    }
                }
            );
        }

        return dataOption;
    };


    const onChangeConfig = (data: StatisticTodayEqual, dateArr: string[]) =>
    {
        let configSetting = options.current;
        const dataOptionPatientToday: dataPieChart[] = onSetValue(data.patientFileToday as StatisticEqualToday);
        const dataOptionPresToday: dataPieChart[] = onSetValue(data.presToday as StatisticEqualToday);
        const dataOptionPatientNewToday = onSetValue(data.patientFileNewToday as StatisticEqualToday);
        const colorToday = (data.patientFileToday?.percent as number) < 0 ? 'red' : 'green';
        const colorPres = (data.presToday?.percent as number) < 0 ? 'red' : 'green';
        const colorPatientNew = (data.patientFileNewToday?.percent as number) < 0 ? 'red' : 'green';
        configSetting = {
            tooltip: {
                trigger: 'item',
                textStyle: {
                    fontFamily: 'sans-serif',
                }
            },
            legend: {
                top: '5%',
                left: 'center',
                data: ['Hôm qua', 'Hôm nay']

            },
            title: [
                {
                    subtext: 'Tổng số bệnh nhân khám',
                    left: '15%',
                    top: '80%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontSize: 20,
                        fontFamily: 'sans-serif',
                        fontWeight: 'bold'

                    }
                },
                {
                    subtext: 'Tổng số đơn thuốc',
                    left: '47%',
                    top: '80%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontSize: 20,
                        fontFamily: 'sans-serif',
                        fontWeight: 'bold'

                    }
                },
                {
                    subtext: 'Tổng số bệnh nhân mới',
                    left: '80%',
                    top: '80%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontSize: 20,
                        fontFamily: 'sans-serif',
                        fontWeight: 'bold'
                    }
                },
            ],

            series: [
                {
                    name: 'Tổng số bệnh nhân khám',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    center: ['15%', '50%'],
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: true,
                        fontSize: 35,
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',
                        color: `${ colorToday }`,
                        position: 'center',
                        formatter: function ()
                        {
                            let icon = '';
                            if ((data.patientFileToday?.percent as number) > 0)
                            {
                                icon = '⬆';
                            }
                            else if ((data.patientFileToday?.percent as number) < 0)
                            {
                                icon = '⬇';
                            }
                            const value = Math.abs(data.patientFileToday?.percent as number);
                            const iconValue = data.patientFileToday?.previous === 0 ? ' bệnh nhân' : '%';

                            return `${ icon }${ value }${ iconValue }`;
                        },
                    },
                    data: dataOptionPatientToday
                },
                {
                    name: 'Tổng số bệnh nhân khám',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    center: ['15%', '50%'],
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: true,
                        fontSize: 35,
                        fontWeight: 'bold',
                        color: 'green',
                        // position: 'inside',
                        formatter: function (params: any)
                        {
                            const value = params.data.value === 0 ? '' : params.data.value;
                            return `${ value }`;
                        },

                    },
                    data: dataOptionPatientToday
                },
                {
                    name: 'Tổng số đơn thuốc',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    center: ['48%', '50%'],
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: true,
                        fontSize: 35,
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',
                        color: `${ colorPres }`,
                        position: 'center',
                        formatter: function ()
                        {
                            let icon = '';
                            if ((data.presToday?.percent as number) > 0)
                            {
                                icon = '⬆';
                            }
                            else if ((data.presToday?.percent as number) < 0)
                            {
                                icon = '⬇';
                            }
                            const value = Math.abs(data.presToday?.percent as number);
                            const iconValue = data.presToday?.previous === 0 ? ' đơn thuốc' : '%';

                            return `${ icon }${ value } ${ iconValue }`;
                        },
                    },
                    data: dataOptionPresToday
                },
                {
                    name: 'Tổng số đơn thuốc',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    center: ['48%', '50%'],
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: true,
                        fontSize: 35,
                        fontWeight: 'bold',
                        color: 'green',
                        // position: 'inside',
                        formatter: function (params: any)
                        {
                            const value = params.data.value === 0 ? '' : params.data.value;
                            return `${ value }`;
                        },

                    },
                    data: dataOptionPresToday
                }, {
                    name: 'Tổng số bệnh nhân mới',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    center: ['80%', '50%'],
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: true,
                        fontSize: 35,
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',

                        color: `${ colorPatientNew }`,
                        position: 'center',
                        formatter: function ()
                        {
                            let icon = '';
                            if ((data.patientFileNewToday?.percent as number) > 0)
                            {
                                icon = '⬆';
                            }
                            else if ((data.patientFileNewToday?.percent as number) < 0)
                            {
                                icon = '⬇';
                            }
                            const value = Math.abs(data.patientFileNewToday?.percent as number);
                            const iconValue = data.patientFileNewToday?.previous === 0 ? ' bệnh nhân' : '%';
                            return `${ icon }${ value } ${ iconValue }`;
                        },
                    },
                    data: dataOptionPatientNewToday
                },
                {
                    name: 'Tổng số bệnh nhân mới',
                    type: 'pie',
                    radius: ['30%', '50%'],
                    center: ['80%', '50%'],
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: true,
                        fontSize: 35,
                        fontWeight: 'bold',
                        color: 'green',
                        // position: 'inside',
                        formatter: function (params: any)
                        {
                            const value = params.data.value === 0 ? '' : params.data.value;
                            return value;
                        },
                    },
                    data: dataOptionPatientNewToday
                },

            ]
        };

        options.current = configSetting;
    };


    return (
        <div
            className='overflow-y-hidden '
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className=' h-full w-full'>

                <div className='h-full w-full'>
                    <ReactECharts
                        option={options.current}
                        style={{ width: '100%', height: '100%' }}
                    ></ReactECharts>
                </div>
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default StatisticTodayAdminView;