import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { ColDef, ICellRendererParams } from 'ag-grid-community';
import axios from 'axios';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import ClinicCreateForm from '../../../module/Clinic/ClinicCreateForm';
import ClinicDetailForm from '../../../module/Clinic/ClinicDetailForm';
import ClinicVisitsForm from '../../../module/ClinicVisits/ClinicVisitsForm';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { ClinicGridColumns } from '../../../types/Clinic/Clinic';
import { ClinicVisitsGridDto, ClinicVisitsUpdateDto } from '../../../types/ClinicVisits/ClinicVisitsDto';
import { ClinicVisitsGridColumns } from '../../../types/ClinicVisits/ClinicVisitsGrid';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { comboDistrict, comboProvince } from '../../Infomation/api/constants';
import { indexAllVisitsClinic, updateAllVisitsClinic } from '../api/constants';
interface IState
{
    clinic: ClinicVisitsGridDto[];
    loading?: boolean;
    optionsProvince: IComboBox[]
}
const ClinicVisitsView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const formRefFilter = useRef<BaseFormRef>(null);

    const [state, setState] = useMergeState<IState>({
        clinic: [],
        loading: true,
        optionsProvince: []
    });
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>([]);

    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
        overlayRef.current?.open();

        Axios.all([
            await AxiosClient.get<ApiResponse<ClinicVisitsGridDto>>(BASE_API_PATH + indexAllVisitsClinic)
        ]).then(axios.spread((resClinicVisits) =>
        {
            let allClinic: ClinicVisitsGridDto[] | null = [];
            if (resClinicVisits.data.success)
            {
                allClinic = resClinicVisits.data.results as unknown as ClinicVisitsGridDto[];
            }

            if (allClinic === null)
            {
                allClinic = [];
                ApiUtil.ToastWarning('Hệ thống thanh toán đang đóng!');
            }
            setState({
                loading: false,
                clinic: allClinic,
            });
            overlayRef.current?.close();
        }));


    };

    const onLoadGrid = async () =>
    {
        overlayRef.current?.open();
        const response = await AxiosClient.get<ApiResponse<ClinicVisitsGridDto>>(BASE_API_PATH + indexAllVisitsClinic);
        let allClinic: ClinicVisitsGridDto[] = [];
        if (response.data.success)
        {
            allClinic = response.data.results as unknown as ClinicVisitsGridDto[];
        }

        if (allClinic === null)
        {
            allClinic = [];
            ApiUtil.ToastWarning('Hệ thống thanh toán đang đóng!');
        }
        setState({
            ...state,
            clinic: allClinic,
        });
        overlayRef.current?.close();

    };


    const onLoadAllClinic = async () =>
    {
        // const response = await AxiosClient.get<ApiResponse<ClinicGridDto>>(BASE_API_PATH + indexAllClinic);
        // let allClinic: ClinicGridDto[] = [];
        // if (response.data.success)
        // {
        //     allClinic = response.data.results as unknown as ClinicGridDto[];
        // }
        // setState({
        //     ...state,
        //     clinic: allClinic,
        // });
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };



    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...ClinicVisitsGridColumns,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<ClinicVisitsGridDto>) =>
                {
                    const data: ClinicVisitsUpdateDto = {
                        ...params.data
                    };
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <GridButtonBase
                                type={'add_visist'}
                                onClick={() => { HandleOpenUpdate(data); }} />
                            {/* <GridButtonBase type={'detail'}
                                onClick={() =>
                                {
                                    HandleDetailUpdate(data as Clinic);
                                    //openDetail(data?.medicalExamId as unknown as number, data.datePatient as string);
                                }} /> */}
                            {/* <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { onHandleDelete(data.id as number); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm> */}

                        </div>
                    );
                },
            },

        ];
    };
    const renderGrid = () =>
    {
        return <div className='mt-2' style={{ height: '98%' }}>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                columnDefs={getColumnConfig()}
                suppressRowTransform={true}
                pagination={true}
                suppressClickEdit={true}
                rowData={state.clinic}
            />
        </div>;
    };


    const onUpdateClinic = async (value: ClinicVisitsUpdateDto) =>
    {
        const params = {
            clinicId: value.id,
            userId: 0,
            numberVisitsChange: value.numberAdd
        };
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + updateAllVisitsClinic, params, { withCredentials: true }); //x
        if (response.data.success)
        {
            await onLoadAllClinic();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            await onLoadGrid();
            modalRef.current?.onClose();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }

    };

    const HandleOpenUpdate = (value: ClinicVisitsUpdateDto) =>
    {
        modalRef.current?.onOpen(<ClinicVisitsForm
            handleSubmit={onUpdateClinic}
            defaultValue={value}
            onClose={onCloseModal} />, 'Thêm lượt khám', 50);
    };


    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='h-full'>
                {/* {ClinicHeader()} */}
                {renderGrid()}
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default ClinicVisitsView;