import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { MedicineGroup } from '../../types/MedicineGroup/MedicineGroup';

const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IFormMedicineGroupFormProps
{
    handleSubmit: (value: MedicineGroup) => Promise<void>;
    onClose: () => void,
    defaultvalue?: MedicineGroup
}

const MedicineGroupForm: FunctionComponent<IFormMedicineGroupFormProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, defaultvalue } = props;
    const onSubmit = async (value: MedicineGroup) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
            resolver={yupResolver(schema)} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField requiredError={true}
                            name={'name'}
                            label={'Tên Nhóm Thuốc'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />
        </BaseForm>
    );
};
export default MedicineGroupForm; 
