import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import { useAppDispatch } from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import InfomationUpdate from '../../../module/Infomation/InfomationUpdate';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { comboDistrict, comboProvince, loadInfomationClinic, updateInfomationClinic, updatePdfClinic } from '../api/constants';
import { clinicAction } from '../ClinicSlice';

interface IState
{
    optionsProvince: IComboBox[];
    infomationClinic: Clinic;
}
const InfomationView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const [state, setState] = useMergeState<IState>({
        optionsProvince: [],
        infomationClinic: {},
    });
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>();
    const dispatch = useAppDispatch();
    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
        Axios.all([
            await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince),
            await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true })
        ]).then(axios.spread((resComboProvince, resInfomation) =>
        {
            let optionsProvince: IComboBox[] = [];
            let infomationClinic: Clinic = {};
            if (resComboProvince.data.success)
            {
                optionsProvince = resComboProvince.data.results as unknown as IComboBox[];
            }
            if (resInfomation.data.success)
            {
                if (resInfomation.data.results) {
                    dispatch(clinicAction.setShowStorageWarning(resInfomation.data.results.isSizeWarning || false));
                }
                infomationClinic = resInfomation.data.results as unknown as Clinic;
                if (infomationClinic.provinceId)
                {
                    loadOptionsDistrict(infomationClinic.provinceId.toString());
                }
                else
                {
                    loadOptionsDistrict('0');
                }
            }
            setState({
                optionsProvince: optionsProvince,
                infomationClinic: infomationClinic
            });
        }));
    };

    const loadInfo = async () =>
    {
        const res = await AxiosClient.get<ApiResponse<Clinic>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true });
        let infomationClinic: Clinic = {};

        if (res.data.success)
        {
            if (res.data.results) {
                dispatch(clinicAction.setShowStorageWarning(res.data.results.isSizeWarning || false));
            }
            infomationClinic = res.data.results as unknown as Clinic;
        }
        setState({
            ...state,
            infomationClinic: infomationClinic
        });
    };

    const loadOptionsDistrict = async (value: string) =>
    {

        const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
        if (response.data.success)
        {
            const data = response.data.results as unknown as IComboBox[];
            setOptionsDistrict(data);
        }
    };

    const onSaveInfomationClinic = async (value: Clinic, formData: FormData) =>
    {
        overlayRef.current?.open();
        const params: Clinic = {
            ...value,
            districtId: parseInt(value.districtId?.toString() as string),
            provinceId: parseInt(value.provinceId?.toString() as string),
        };
        Axios.all([
            await AxiosClient.post<ApiResponse<Clinic>>(BASE_API_PATH + updatePdfClinic, formData, { withCredentials: true }),
            await AxiosClient.post<ApiResponse<Clinic>>(BASE_API_PATH + updateInfomationClinic, params, { withCredentials: true })
        ]).then(axios.spread((resPdf, resUpdate) =>
        {
            if (resUpdate.data.success)
            {
                ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
                loadInfo();
                dispatch(clinicAction.loadNotification({ notification: value.notification as string }));
            }
            else
            {
                ApiUtil.ToastError(UPDATE_TO_FAIL);
            }

            overlayRef.current?.close();

        }));

    };

    return (
        // <div className='overflow-hidden'>
        <div className='h-full w-full'>
            <div className="flex justify-between">
                <div className='text-xl font-bold text-[#2c999c]  ml-3'>
                    CÀI ĐẶT THÔNG TIN PHÒNG KHÁM
                </div>
            </div>
            <div >
                {state.infomationClinic.id && optionsDistrict ? <InfomationUpdate defaultvalue={state.infomationClinic}
                    handleSubmit={onSaveInfomationClinic}
                    loadOptionsDistrict={loadOptionsDistrict}
                    optionsDistinct={optionsDistrict ? optionsDistrict : []}
                    optionsProvince={state.optionsProvince} /> : <div></div>}
            </div>
            <Overlay ref={overlayRef} />

        </div>

    );
};

export default InfomationView;