export const CONFIRM_TO_DELETE = 'Bạn có muốn xóa dữ liệu này!';
export const CONFIRM_TO_DISABLE_STATUS = 'Bạn có muốn tắt hoạt động?';
export const CONFIRM_TO_ENABLE_STATUS = 'Bạn có muốn bật hoạt động?';
export const CONFIRM_TO_TOP = 'Bạn có chắc muốn ưu tiên đơn đặt khám này không? Nếu xác nhận, đơn đặt khám này sẽ được đặt ở vị trí đầu tiên trong danh sách chờ';

export const ADD_TO_SUCCESS = 'Tạo dữ liệu thành công!';
export const ADD_TO_FAIL = 'Tạo dữ liệu thất bại!';

export const DELETE_TO_SUCCESS = 'Xóa dữ liệu thành công!';
export const UPDATE_TO_SUCCESS = 'Cập nhật dữ liệu thành công!';
export const APPROVE_REQUEST_SENT = 'Đã gửi yêu cầu phê duyệt!';
export const UPDATE_TO_FAIL = 'Cập nhật dữ liệu thất bại!';

export const SESSION_CANCEL_SUCCESSFUL = 'Huỷ phiên khám thành công!';


export const MESSAGE_EXPIRED = 'Phiên đăng nhập của bạn đã hết hạn, vui lòng đăng nhập lại!';


export const SUCCESS = 'Đồng ý';
export const CANCEL = 'Không';

export const CHANGE_STATUS_SUCCESS = 'Thay đổi trạng thái thành công';

export const CHANGE_STATUS_ERROR = 'Thay đổi trạng thái thất bại';
