import './style/clinic-form.scss';

import { Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';

import AxiosClient from '../../apis/api/axiosClient';
import { Loading } from '../../components/common/Loading';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { optionFilterTimeClinic } from '../../constants/OptionsCombo';
import { pathDateTime } from '../../constants/SvgIcon';
import { detailStatictisClinic } from '../../features/Clinic/api/constants';
import useMergeState from '../../hooks/useMergeState';
import { ApiResponse } from '../../types/api.type';
import { Clinic } from '../../types/Clinic';
import { StatisticClinic } from '../../types/Clinic/ClinicGridDto';
import { BASE_API_PATH } from '../../utils/ApiUtil';
import {humanFileSize} from '../../utils/StringUtil';


interface IInfomationProps
{
    optionsProvince: IComboBox[];
    defaultvalue?: Clinic

}

interface IState
{
    isLoading: boolean,
    optionsDistrict?: IComboBox[]
}
const ClinicStatisticForm = (props: IInfomationProps) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const disabled = useRef<boolean>(true);
    const { defaultvalue } = props;
    const formRef = useRef<BaseFormRef>(null);
    const { RangePicker } = DatePicker;
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const suffixDateTimeFilter = () =>
    {
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };

    useEffect(() =>
    {

    }, []);

    const onLoadInfoStatictis = async () =>
    {
        overlayRef.current?.open();
        const dataForm = formRef.current?.getValues();
        const timeRange = dataForm?.['timeRange'];
        const filterByDateFrom = timeRange ? timeRange[0].format('DD/MM/YYYY') : null;
        const filterByDateTo = timeRange ? timeRange[1].format('DD/MM/YYYY') : null;
        const params = {
            dateFrom: filterByDateFrom,
            dateTo: filterByDateTo,
            clinicId: defaultvalue?.id
        };
        const response = await AxiosClient.post<ApiResponse<StatisticClinic>>(BASE_API_PATH + detailStatictisClinic, params);
        if (response.data.success)
        {
            const data = response.data.results as StatisticClinic;
            formRef.current?.setValues({
                ...dataForm,
                ...data
            });
        }
        overlayRef.current?.close();

    };

    const onChangeRangeFilter = () =>
    {
        const dataForm = formRef.current?.getValues();
        formRef.current?.setValues({ ...dataForm, optionValue: null });
        onLoadInfoStatictis();

    };

    const onChangeTimeField = (value: string) =>
    {
        const numberDay = value === 'week' ? 7 : (value === 'month' ? 31 : 365);
        const dataFilter = formRef.current?.getValues();
        const dayNow = moment();
        const dayPrevious = moment().subtract(numberDay, 'd');
        const valueSet = [dayPrevious, dayNow];
        formRef.current?.setValues({ ...dataFilter, timeRange: valueSet, optionValue: value });
        onLoadInfoStatictis();
    };




    return ( //onSubmit(value);
        <BaseForm ref={formRef}
            className="p-3"
            style={{ overflowX: 'hidden' }}
            defaultValues={defaultvalue}
            onSubmit={(value) => { }}
        >
            <Row className="flex m-1" gutter={24}>
                <Col
                    style={{ width: '20%' }}
                    className="">
                    <Form.Item>
                        <ComboBox noUpperCaseLabel={true}
                            onChange={(value) =>
                            {
                                onChangeTimeField(value);
                            }}
                            comboValue={optionFilterTimeClinic}
                            name={'optionValue'}
                            label={'Tìm kiếm theo'} />
                    </Form.Item>
                </Col>
                <Col style={{ marginTop: '24px' }}>
                    <Controller
                        name={'timeRange'}
                        render={({ field }) =>
                        {
                            return <RangePicker
                                {...field}
                                placeholder={['Từ ngày', 'Đến ngày']}
                                format={dateFormatList}
                                style={{ borderRadius: '7px' }}
                                disabledDate={(current) =>
                                {
                                    return current && current >= moment().endOf('day');
                                }}
                                onChange={
                                    (value) =>
                                    {
                                        const dataForm = formRef.current?.getValues();
                                        formRef.current?.setValues({ ...dataForm, timeRange: value });
                                        if (value)
                                        {
                                            onChangeRangeFilter();
                                        }
                                        else
                                        {
                                            formRef.current?.setValues({
                                                ...dataForm
                                                , timeRange: null
                                                , numberMedicalExam: null
                                                , numberSms: null
                                                , numberNoti: null
                                                , totalStorage: null
                                                , totalStorageVideo: null
                                                , totalStorageImage: null
                                                , optionValue: null
                                            });

                                        }
                                    }
                                }
                                suffixIcon={suffixDateTimeFilter()}
                            />;
                        }
                        }
                    />
                </Col>
            </Row >
            <Row className="flex m-1 mt-2" gutter={24}>
                <Col style={{ width: '33%' }} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            disabled={disabled.current}
                            noShowPlaceholder={true}
                            name={'numberMedicalExam'}
                            label={'Số lượng bệnh án'} />
                    </Form.Item>

                </Col>
                <Col style={{ width: '33%' }} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            disabled={disabled.current}
                            noShowPlaceholder={true}
                            name={'numberNoti'}
                            label={'Số lượng thông báo'} />
                    </Form.Item>

                </Col>
                <Col style={{ width: '33%' }} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            noShowPlaceholder={true}
                            disabled={disabled.current}
                            name={'numberSms'}
                            label={'Số lượng tin nhắn'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginBottom: 16, marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 14, height: '15px', flex: 1 }}>DUNG LƯỢNG CLOUD CỦA PHÒNG KHÁM</p>
            </div>
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '33%' }} className="flex-1">
                    <TextField noUpperCaseLabel={true}
                        noShowPlaceholder={true}
                        disabled={disabled.current}
                        name={'totalSize'}
                        value={humanFileSize(defaultvalue?.totalSize || 0)}
                        label={'Tổng dung lượng'} />

                </Col>
                <Col style={{ width: '33%' }} className="flex-1">
                    <TextField noUpperCaseLabel={true}
                        noShowPlaceholder={true}
                        disabled={disabled.current}
                        name={'videoSize'}
                        value={humanFileSize(defaultvalue?.videoSize || 0)}
                        label={'Dung lượng theo video'} />

                </Col>
                <Col style={{ width: '33%' }} className="flex-1">
                    <TextField noUpperCaseLabel={true}
                        noShowPlaceholder={true}
                        disabled={disabled.current}
                        name={'imageSize'}
                        value={humanFileSize(defaultvalue?.imageSize || 0)}
                        label={'Dung lượng theo hình ảnh'} />
                </Col>
            </Row>
            <Overlay ref={overlayRef} />

        </BaseForm >

    ); //onClose
};

export default ClinicStatisticForm;