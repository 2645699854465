import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Input, Popconfirm } from 'antd';
import { createRef, useCallback, useRef } from 'react';
import React from 'react';

import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { CustomInputRef } from '../../../components/FormFields/CustomInput';
import { CustomSelectRef } from '../../../components/FormFields/CustomSelect';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { CANCEL, CONFIRM_TO_DELETE, SUCCESS } from '../../../constants/MessageConstants';
import { InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';
import { OptionCellRenderer, PrescriptionGridColumns, PrescriptionRows } from '../../../types/PatientRecord/PrescriptionSetting';
import { PatientWaitingFilterDto } from '../../../types/PatientWaiting/PatientWaitingFilterDto';
import { MedicineCombo, MedicineGroupCombo, MedicineNoteCombo } from '../../../types/share/ComboOption';
import HeaderPrescription from './HeaderPrescription';
interface IState
{
    patientWaiting: Array<any>;
    loading: boolean;
}
interface Props
{
    infoPatientExam: InformationPatientExam;
    medicineGroupCombo: MedicineGroupCombo[];
    medicineNoteUseCombo: MedicineNoteCombo[];
    gridRefMedicine: React.RefObject<AgGridReact>;
    getRowData: () => PrescriptionRows[];
    medicineNote?: React.MutableRefObject<string>;
    showConfirm: () => void;
    onChangeProcessBar: (value: string, oldValue: string) => void
    setSelectFlow: React.Dispatch<React.SetStateAction<number>>
    isMedicine?: boolean | null | undefined

}

const defaultColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
};
const PrescriptionView: React.FC<Props> = (props) =>
{
    const { getRowData, showConfirm, gridRefMedicine, medicineNote, medicineNoteUseCombo, onChangeProcessBar, setSelectFlow, isMedicine } = props;
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);

    const options = {
        groupMedicine: props.medicineGroupCombo,
        medicineCode: [],
        medicineNoteUse: medicineNoteUseCombo,
        medicineName: '',
        noteUse: '',
        noteUseMedicinePlus: '',
        order: 1,
    } as OptionCellRenderer;

    const addItem = useCallback(() =>
    {
        gridRefMedicine.current?.api.applyTransaction({
            add: [{
                medicineCodeRef: createRef<CustomSelectRef>(),
                medicineNoteUseRef: createRef<CustomSelectRef>(),
                quantity: 1,
                medicineName: '',
                noteUse: '',
                medicineNameRef: createRef<CustomInputRef>(),
                noteUseMedicinePlusRef: createRef<CustomInputRef>(),
                noteUseRef: createRef<HTMLDivElement>(),
                quantityRef: createRef<CustomInputRef>(),
            } as PrescriptionRows]
        });
    }, []);

    const removeItem = useCallback((rowData: PrescriptionRows) =>
    {
        gridRefMedicine.current?.api.applyTransaction({
            remove: [rowData]
        });
    }, []);

    const removeAllItem = useCallback(() =>
    {
        const dataCurrent = getRowData();
        gridRefMedicine.current?.api.applyTransaction({
            remove: dataCurrent
        });
        setSelectFlow(3);
        onChangeProcessBar('3', '4');
    }, []);


    const updateRowData = (type: string, value: any, rowData: PrescriptionRows) =>
    {
        switch (type)
        {
            case 'medicineGroup': {
                const { medicineCombo } = props.medicineGroupCombo.find(item => item.value === value) as MedicineGroupCombo;
                rowData.medicineCodeRef.current?.setData(medicineCombo);
                const medicine = medicineCombo.find(x => x) as MedicineCombo | null;
                rowData.medicineCodeRef.current?.setNewValue(medicine);
                // rowData.medicineNameRef.current!.innerText = medicine?.label ?? '';
                rowData.medicineNameRef.current?.setDisable(false);
                rowData.medicineNameRef.current?.setDefaultValue(medicine?.label ?? '');
                rowData.medicineName = medicine?.label ?? '';
                // rowData.medicineNameRef.current?
                rowData.noteUseMedicinePlusRef.current?.setDisable(false);

                if (medicine)
                {
                    const medicineNoteUseComboRef = medicineNoteUseCombo.filter(x => x.medicineId.toString() === medicine?.value.toString());
                    rowData.medicineNoteUseRef.current?.setData(medicineNoteUseComboRef);
                    const medicineNote = medicineNoteUseComboRef.find(x => x) as MedicineNoteCombo | null;
                    rowData.medicineNoteUseRef.current?.setNewValue(medicineNote);
                    rowData.noteUse = medicineNote?.label;

                    rowData.noteUseMedicinePlus = medicineNote?.label;
                    rowData.noteUseMedicinePlusRef.current?.setDefaultValue(medicineNote?.label ?? '');
                }
                // rowData.noteUseRef.current!.innerText = medicine?.noteUse ?? '';
                rowData.medicineGroup = value;
                rowData.medicineCode = medicine?.value;
                rowData.quantityRef.current?.setDisable(false);

                break;
            }
            case 'medicineCode': {
                const { medicineCombo } = props.medicineGroupCombo.find(item => item.value === rowData.medicineGroup) as MedicineGroupCombo;
                const { noteUse, label } = medicineCombo.find(item => item.value === value) as MedicineCombo;
                rowData.medicineCode = value;
                rowData.medicineNameRef.current?.setDisable(false);
                rowData.medicineNameRef.current?.setDefaultValue(label);
                rowData.medicineName = label ?? '';
                rowData.noteUseMedicinePlusRef.current?.setDisable(false);
                const medicineNoteUseComboRef = medicineNoteUseCombo.filter(x => x.medicineId === value);
                rowData.medicineNoteUseRef.current?.setData(medicineNoteUseComboRef);
                const medicineNote = medicineNoteUseComboRef.find(x => x) as MedicineNoteCombo | null;
                rowData.noteUse = medicineNote?.label ?? '';
                rowData.medicineNoteUseRef.current?.setNewValue(medicineNote);

                rowData.noteUseMedicinePlus = medicineNote?.label;
                rowData.noteUseMedicinePlusRef.current?.setDefaultValue(medicineNote?.label ?? '');
                break;
            }
            case 'noteUse': {
                const medicineNoteUse = medicineNoteUseCombo.find(x => x.value == value);
                rowData.noteUse = medicineNoteUse?.label;
                rowData.noteUseMedicinePlus = medicineNoteUse?.label;
                rowData.noteUseMedicinePlusRef.current?.setDefaultValue(medicineNoteUse?.label ?? '');
                break;
            }
            case 'medicineName':
                rowData.medicineName = value;
                break;
            case 'noteUseMedicinePlus':
                rowData.noteUseMedicinePlus = value;
                break;
            case 'quantity':
                console.log('value', value);
                rowData.quantity = value;
                break;
            default:
                break;
        }
        gridRefMedicine.current?.api.applyTransaction({
            update: [rowData],
        });
    };


    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...PrescriptionGridColumns(options, updateRowData),

            {
                pinned: 'right',
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                maxWidth: 120,
                cellRenderer: (params: ICellRendererParams) =>
                {
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => removeItem(params.data)}

                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() => { }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };


    const renderGrid = () =>
    {
        const rowData: PrescriptionRows[] = [];
        if (gridRefMedicine)
        {
            const dataCurrent = getRowData();
            rowData.push(...dataCurrent);
        }
        return <div className='flex flex-col flex-1'>
            <div className='flex justify-between' style={{ height: 50 }}>
                <div className="ml-6 flex aligns-center items-center" style={{ width: '500px' }}>
                    <Input placeholder="Thêm ghi chú"
                        style={{ borderRadius: '8px', width: '500px' }}
                        onChange={(value) => { medicineNote!.current = value.target.value as string; }} />
                </div>
                <div className='order-last mr-2 flex aligns-center items-center'>
                    <ButtonBase style={{ width: 165, height: 40 }}
                        onClick={addItem}
                        buttonName={'THÊM THUỐC'}
                        buttonType={'create'} />
                </div>

            </div>
            <div className="w-full flex-1" >
                <AgGridReact ref={gridRefMedicine}
                    className="ag-theme-alpine base-grid"
                    // className="ag-theme-alpine"
                    rowSelection={'multiple'}
                    columnDefs={getColumnConfig()}
                    onGridReady={(event: GridReadyEvent) =>
                    {
                        event.api.sizeColumnsToFit();
                    }}
                    pagination={true}
                    paginationPageSize={10}
                    suppressRowTransform={true}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    enterMovesDown={true}
                    overlayNoRowsTemplate={'Không có dữ liệu!'}
                />
            </div>
            <div className='flex' style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                <ButtonBase style={{ width: 240, height: 40, justifyContent: 'center', marginBottom: 45 }}
                    buttonName={'HOÀN TẤT ĐƠN THUỐC'}
                    className='mr-2'
                    buttonType="save"
                    onClick={() =>
                    {
                        setSelectFlow(3);
                        onChangeProcessBar('3', '4');
                    }} />
                {
                    isMedicine === false ? <ButtonBase className='mr-2'
                        onClick={() =>
                        {
                            removeAllItem();
                        }}
                        style={{ width: 180, height: 40, justifyContent: 'center' }}
                        buttonName={'Hủy đơn thuốc'}
                        backgroudColorCustom='#E78429'
                        buttonType="close"
                    /> : <div></div>
                }
                <ButtonBase className=''
                    onClick={() =>
                    {
                        showConfirm();
                    }}
                    style={{ width: 180, height: 40, justifyContent: 'center' }}
                    buttonName={'Hủy luồng khám'}
                    backgroudColorCustom='#E78429'
                    buttonType="close"
                />

            </div >

        </div >;
    };


    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '98%', width: '100%' }}
        >
            <div style={{ height: '100%' }} className='flex flex-col'>

                {renderGrid()}

            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default PrescriptionView;