import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Col, Form, Row } from 'antd';
import React, { useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { AutoSend } from '../../types/AutoSend/AutoSend';

const schema = yup.object().shape({
    numberDaysBeforeSending: yup.string().required('Vui lòng chọn thiết lập!'),
});

interface IInfomationProps
{
    formRef: React.RefObject<BaseFormRef>
    handleSubmit: (value: AutoSend) => Promise<void>;
    defaultvalue?: AutoSend

}
const optionHour: IComboBox[] = [
    { value: '0', label: 'Không tự động gửi' },
    { value: '1', label: 'Trước 1 ngày' },
    { value: '2', label: 'Trước 2 ngày' }
];
// const optionSend: IComboBox[] = [
//     { value: 'notification', label: 'Gửi thông báo' },
//     { value: 'sms', label: 'Gửi tin nhắn' },
// ];

const AutoSendNotificationForm = (props: IInfomationProps) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const { handleSubmit, defaultvalue, formRef } = props;

    const onSubmit = async (value: AutoSend) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };

    return ( //onSubmit(value);
        <BaseForm
            ref={formRef}
            className=" flex flex-col h-full w-full"
            defaultValues={defaultvalue}
            resolver={yupResolver(schema)}
            onSubmit={(value) => { onSubmit(value); }} >
            <div className='flex flex-col flex-1'>

                <Row className="flex m-1" gutter={24}>
                    {/* <Col className="flex-1" style={{ width: '40%' }}>
                        <Form.Item>
                            <ComboBox
                                noUpperCaseLabel={true}
                                requiredError={true}
                                comboValue={optionSend}
                                onChange={(value) => { loadInfoAutoSend(value); }}
                                name={'optionSend'}
                                label={'Gửi trước khi tái khám'} />

                        </Form.Item>
                    </Col> */}
                    <Col className="flex-1" style={{ width: '100%' }}>
                        <Form.Item>
                            <ComboBox
                                noUpperCaseLabel={true}
                                requiredError={true}
                                comboValue={optionHour}
                                name={'numberDaysBeforeSending'}
                                label={'Gửi trước khi tái khám'} />

                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div className="footer flex justify-center items-center mt-4" style={{ height: 50 }}>
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'HOÀN TẤT'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>

    );
};

export default AutoSendNotificationForm;