import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import NumberField from '../../components/FormFields/NumberField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { phoneRegExp } from '../../constants/YupContants';
import { UserAccountDoctor } from '../../types/UserAccountDoctor';

//resolver={yupResolver(schema)}
const schema = yup.object().shape({
    // title: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IFormAccountSettingProps
{
    handleSubmit: (title: string, phoneNotiArr: string[], content: string) => Promise<void>;
    // onClose: () => void
    phoneArr: string[],
    content: string,
    nameClinic: string,
    onClose: () => void
}

interface IValueForm
{
    title: string
}

const NotificationForm: FunctionComponent<IFormAccountSettingProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, phoneArr, content, nameClinic, onClose } = props;

    const onSubmit = async (value: IValueForm) =>
    {
        overlayRef.current?.open();
        const title = value.title ? value.title + ' - ' + nameClinic : nameClinic;
        await handleSubmit(title, phoneArr, content);
        overlayRef.current?.close();
        onClose();
    };
    return ( //defaultValues={{check:'a'}}
        <BaseForm ref={formRef}
            className="p-3"
            resolver={yupResolver(schema)}
            onSubmit={(value) => { onSubmit(value); }} >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField
                            name={'title'}
                            placeholder={nameClinic}
                            label={'Tên thông báo'} />
                    </Form.Item>
                </Col>

            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>
    );
};

export default NotificationForm; 
