import { ColDef } from 'ag-grid-community';

export const DiseaseAnalysisDetailSetting: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        maxWidth: 80,
        cellStyle: { 'text-align': 'start' },
        headerClass: 'text-center',

    },
    {
        headerName: 'Phân tích bệnh',
        field: 'content',
        width: 150,
        tooltipField: 'content',
        headerClass: 'text-center',
        // filter: 'agTextColumnFilter',
        floatingFilter: false,
        cellStyle: { 'text-align': 'start' },
        singleClickEdit: true,
    }
];