/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage } from '@hookform/error-message';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import React, { CSSProperties, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps extends InputProps
{
    indexLabel?: string;
    label?: string;
    labelClass?: string;
    labelStyle?: CSSProperties;
    errors?: any;
    name: string;
    requiredError?: boolean | null
    noUpperCaseLabel?: boolean;

}

const NumberField: React.FC<IProps> = (props) =>
{
    const [valueInput, setValueInput] = useState<string>('');
    const { label, labelStyle = {}, name, requiredError, indexLabel, noUpperCaseLabel = false } = props;
    const methods = useFormContext();
    const { control, formState: { errors }, setValue } = methods;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const { value: inputValue } = e.target;
        if (!Number(inputValue) && inputValue != '' && inputValue != '0')
        {
            setValue(name, valueInput);
            setValueInput(valueInput);
            return;
        }
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-')
        {
            setValue(name, inputValue);
            setValueInput(inputValue);
        }
    };

    const handleBlur = () =>
    {
        let valueTemp = valueInput;
        if (valueInput.charAt(valueInput.length - 1) === '.' || valueInput === '-')
        {
            valueTemp = valueInput.slice(0, -1);
            setValueInput(valueTemp.replace(/(\d+)/, '$1'));
        }
    };
    return (
        <div className="flex flex-col justify-start">
            <div className="flex items-center">
                <div className="w-full px-3 md:mb-0">
                    <div className='flex' style={{ width: 200 }}>
                        {label && (
                            <label
                                className={noUpperCaseLabel ?
                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                    : 'block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                                }
                                style={{
                                    ...labelStyle,
                                }}
                            >
                                {indexLabel ? (indexLabel + '. ') : null}{label}
                            </label>
                        )}
                        {requiredError && (<div className='text-red-600 ml-1 -left-4 -top-4'> *</div>)}
                    </div>
                    <Controller
                        name={name}
                        control={control}
                        render={(
                            { field }) =>
                        {
                            const value = field.value;
                            return <Input
                                {...props}
                                status={errors[name] ? 'error' : ''}
                                style={{ borderRadius: '7px' }}
                                className='border-red-800'
                                name={name}
                                // {...field}
                                onChange={handleChange}
                                value={value}
                                onBlur={handleBlur}
                                placeholder={'Nhập ' + label?.toLocaleLowerCase() + '...' || ''} />;
                        }
                        }
                    />
                </div>
            </div>
            {errors && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (message ? <p className="ml-6 mt-1 m-0 text-red-400">{message}</p> : null)}

                />
            )}
        </div>
    );
};

export default NumberField;
