	
export const MedicalExamTemplateGridColumns = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        headerClass:'text-center',
        maxWidth: 80,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Tên mẫu',
        field: 'name',
        width: 150,
        headerClass:'text-center',
        tooltipField:'name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' }
    }
];