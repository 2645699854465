import { yupResolver } from '@hookform/resolvers/yup';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Col, Form, Popconfirm, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import GridButtonBase from '../../components/ButtonBase/GridButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { CANCEL, CONFIRM_TO_DELETE, SUCCESS } from '../../constants/MessageConstants';
import HeaderDiseaseAnalysisDetail from '../../features/DiseaseAnalysis/pages/HeaderDiseaseAnalysisDetail';
import { DiseaseAnalysis, DiseaseAnalysisGrid } from '../../types/DiseaseAnalysis/DiseaseAnalysis';
import { DiseaseAnalysisDetail } from '../../types/DiseaseAnalysisDetail/DiseaseAnalysisDetail';
import { DiseaseAnalysisDetailSetting } from '../../types/DiseaseAnalysisDetail/DiseaseAnalysisDetailSetting';
import { ApiUtil } from '../../utils/ApiUtil';
const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IFormDiseaseAnalysisFormProps
{
    handleSubmit: (value: DiseaseAnalysis, diseaseAnalysisDetailList: DiseaseAnalysisDetail[]) => Promise<void>;
    onClose: () => void,
    defaultvalue?: DiseaseAnalysisGrid,
    isCreate?: boolean,
}

const DiseaseAnalysisForm: FunctionComponent<IFormDiseaseAnalysisFormProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const gridOptions: GridOptions<DiseaseAnalysisDetail> = {};
    const formRef = useRef<BaseFormRef>(null);
    const { handleSubmit, defaultvalue } = props;
    const getColumnConfig = (): ColDef[] =>
    {

        return [
            ...DiseaseAnalysisDetailSetting,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<DiseaseAnalysisDetail>) =>
                {
                    const rowIndex = params.rowIndex;
                    // const data =params.data as DiseaseAnalysisDetail;
                    return (
                        <div className="flex items-center justify-center">
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                        onRemove(rowIndex);
                                    }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };
    const onCreate = () =>
    {
        gridOptions.api?.stopEditing();
        gridOptions.rowData?.unshift({});
        const rowData = gridOptions.rowData as DiseaseAnalysisDetail[];
        gridOptions.api?.setRowData(rowData);
        setTimeout(() =>
        {
            gridOptions.api?.startEditingCell({ rowIndex: 0, colKey: 'content' });
        }, 300);
    };

    const onGridReady = () =>
    {
        if (props.isCreate ?? false)
        {
            onCreate();
        } else
        {
            gridOptions.api?.startEditingCell({ rowIndex: 0, colKey: 'content' });
        }
    };

    const onRemove = (rowIndex: number) =>
    {
        gridOptions.api?.stopEditing();
        gridOptions.rowData?.splice(rowIndex, 1);
        gridOptions.api?.setRowData(gridOptions.rowData as DiseaseAnalysisDetail[]);
    };

    const onSubmit = async (value: DiseaseAnalysis) =>
    {
        gridOptions.api?.stopEditing();
        const diseaseAnalysisDetail = gridOptions.rowData as DiseaseAnalysisDetail[];
        console.log('🚀 ~ file: DiseaseAnalysisForm.tsx:91 ~ diseaseAnalysisDetail:', diseaseAnalysisDetail);
        if (!diseaseAnalysisDetail || diseaseAnalysisDetail.length === 0) return ApiUtil.ToastError('Vui lòng có ít nhất một phân tích bệnh!');

        const isCheckEmpty = onCheckValueGrid(diseaseAnalysisDetail);
        if (isCheckEmpty) return ApiUtil.ToastError('Vui lòng nhập đầy đủ dữ liệu trên bảng phân tích!');

        overlayRef.current?.open();
        await handleSubmit(value, diseaseAnalysisDetail);
        overlayRef.current?.close();
    };

    const onCheckValueGrid = (value: DiseaseAnalysisDetail[]): boolean =>
    {
        let dataEmpty = false;
        const dataEmptyList = value.filter(x => !x.content === true) as DiseaseAnalysisDetail[];
        if (dataEmptyList.length > 0)
            dataEmpty = true;
        return dataEmpty;
    };

    const checkKeyDown = (e: any) =>
    {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') 
        {
            onCreate();
            e.preventDefault();
        }
    };

    return (
        <div>
            <BaseForm ref={formRef}
                onSubmit={(value) => { onSubmit(value); }}
                onKey={checkKeyDown}
                className="p-3"
                defaultValues={defaultvalue ? defaultvalue : {}}
                resolver={yupResolver(schema)} >
                <Row className="flex m-1" gutter={24}>
                    <Col span={24} className="flex-1">
                        <Form.Item>
                            <TextField name={'name'}
                                requiredError={true}
                                label={'Tên mô tả KQ'} />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ height: '400px' }}>
                    <HeaderDiseaseAnalysisDetail onOpenCreate={onCreate} />
                    <div className='mt-2' style={{ height: '90%' }}>
                        <BaseGrid
                            gridOptions={gridOptions}
                            className="ag-theme-alpine"
                            rowSelection={'multiple'}
                            rowGroupPanelShow={'always'}
                            pivotPanelShow={'always'}
                            columnDefs={getColumnConfig()}
                            pagination={true}
                            rowData={defaultvalue ? defaultvalue.diseaseAnalysisDetailDetailDtos : []}
                            onGridReady={onGridReady}
                        />
                    </div>                </div>
                <div className="footer flex justify-center items-center">
                    <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                        buttonName={'Hoàn tất'}
                        buttonType="save"
                        htmlType="submit" />
                </div>
                <Overlay ref={overlayRef} />
            </BaseForm>

        </div>
    );
};
export default DiseaseAnalysisForm; 
