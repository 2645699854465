import { Col } from 'antd';
import QRCode from 'qrcode.react';
import { useEffect, useRef } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import { Loading } from '../../../components/common/Loading';
import BaseForm from '../../../components/FormFields/BaseForm';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import HeaderExamResultProcessScanView from '../../../features/ExamResultProcess/pages/HeaderExamResultProcessScanView';
import { loadInfomationClinic } from '../../../features/Infomation/api/constants';
import {clinicAction} from '../../../features/Infomation/ClinicSlice';
import {useAppDispatch} from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { ExamResultModal } from '../../../types/ExamResult/ExamResult';
import { InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';
import { BASE_API_PATH } from '../../../utils/ApiUtil';

interface IProps
{
    data: ExamResultModal,
    datePatient: string,
    componentRef: React.RefObject<HTMLDivElement>
}


interface IState
{
    loading: boolean,
    infoExamResult?: ExamResultModal,
    infoClinic?: Clinic,
}
const ResultViewScan = (props: IProps) =>
{
    const { data, componentRef } = props;
    const overlayRef = useRef<OverlayRef>(null);
    const dispatch = useAppDispatch();

    const [state, setState] = useMergeState<IState>({
        loading: false,
        infoExamResult: data,
        infoClinic: {}
    });

    useEffect(() =>
    {
        loadInfoClinic();
    }, []);

    const loadInfoClinic = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<Clinic>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results;
            if (data) {
                dispatch(clinicAction.setShowStorageWarning(data.isSizeWarning || false));
            }
            setState({
                ...state,
                infoClinic: data as Clinic
            });
        }
    };

    const renderDiseaseList = () =>
    {
        return <div>
            {state.infoExamResult?.diseaseAnalysisResults.map((item, index) =>
            {
                return (<Col
                    key={index}
                    className="mt-1 mb-2">
                    <div className="flex items-center">
                        <div className="font-bold col-3" style={{ width: 150 }}>
                            {item?.diseaseAnalysisName}:&nbsp;
                        </div>
                        <div className="col-9">
                            <div>
                                {item?.content}
                            </div>
                        </div>
                    </div>
                </Col>);
            })}
        </div>;
    };

    const onRenderImage = () =>
    {
        return state.infoExamResult?.imageResults.map((item, index) =>
        {
            const fileType = item.fileType;
            return FileType.Image.includes(fileType ?? '') ?
                <div key={index} style={{ width: 160, display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: '95%', width: '95%' }} >
                        <div className={'rounded-lg'}
                            style={{ width: '100%', aspectRatio: 'auto' }}
                        >
                            <div className="flex h-full w-full" >
                                <div className="flex flex-col h-full w-full" style={{ borderRadius: '8px', justifyContent: 'center' }}>
                                    <img
                                        src={item.thumbUrl}
                                        style={{ height: '100%', width: '100%' }}
                                        className="bg-white border rounded"
                                        alt="..."
                                    />
                                </div>
                            </div>
                            <div className='ml-1 font-bold'>
                                {item.name}
                            </div>
                        </div>
                    </div>
                </div> : <div className='display-none'></div>;
        });

    };

    const renderBaseForm = () =>
    {
        return <BaseForm
            onSubmit={() => { }}
            style={{}}
            defaultValues={{}} >
            <div className='w-full ' style={{ height: '100%' }}>
                <div className="divide-y-2" style={{ width: '100%' }}>
                    <div style={{}} className="ml-3">
                        {renderDiseaseList()}
                    </div>
                    <div style={{}} className="ml-3">
                        <Col className=" mb-2">
                            <div className="flex items-center row">
                                <div className="font-bold col-3" style={{ width: 150 }}>
                                    Lịch tái khám:
                                </div>
                                <div className="col-9">
                                    <div>
                                        {state.infoExamResult?.reExamDate}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>

                </div>

            </div>
        </BaseForm>;
    };
    const renderInfoClinic = () =>
    {
        return <div className='w-full'>
            <div style={{}}>
                <p className="text-xl font-bold" style={{ font: 'Roboto', fontSize: 16, height: 14, flex: 1 }}>{state.infoClinic?.name}</p>
                <p className='text-xs ml-3' style={{ font: 'Roboto', fontSize: 16, height: 14, flex: 1 }}>{state.infoClinic?.address}</p>
            </div>
            <div className='ml-3' style={{ marginTop: '5px' }}>
                <p className='flex text-xl font-bold text-[#2c999c]' style={{ justifyContent: 'center', font: 'Roboto', fontSize: 24, height: 17, flex: 1 }}>THÔNG TIN KHÁM BỆNH</p>
            </div>
            <div></div>
        </div>;
    };

    if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;

    return (
        <div
            ref={componentRef}
            style={{ width: '100%', paddingBottom: 120 }}
            className='flex flex-col ml-3 mr-3'>
            <div >
                {renderInfoClinic()}
            </div>
            <div className='mb-2' style={{}}>
                {<HeaderExamResultProcessScanView infoPatientExam={state.infoExamResult?.infoPatient as InformationPatientExam} />}
            </div>
            <div className='flex flex-col flex-1  ml-3 mr-3'>
                <div className=' font-bold text-[#2C999C]' style={{ fontSize: '16px' }}>
                    MÔ TẢ KẾT QUẢ KHÁM BỆNH
                </div>
                <div className='flex flex-col flex-1 overflow-x-hidden'>
                    <div className='flex flex-col' style={{ width: '100%' }}>
                        {renderBaseForm()}
                        <div className='' style={{ width: '100%' }}>
                            <div className=' font-bold text-[#2C999C] mt-2 mb-2' style={{ fontSize: '16px' }}>
                                HÌNH ẢNH KHÁM BỆNH
                            </div>
                            <div style={{ width: '100%' }}>
                                <div className="flex" style={{ height: '100%', width: '100%', flexFlow: 'wrap' }}>
                                    {onRenderImage()}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Overlay ref={overlayRef} />
            </div>
            {state.infoExamResult?.tokenUrl && state.infoExamResult?.tokenUrl != '' &&
                (<div style={{
                    position: 'absolute',
                    bottom: 0,
                }}>
                    <QRCode
                        id='qrcode'
                        value={state.infoExamResult?.tokenUrl ?? ''}
                        style={{ height: '120px', width: '120px' }}
                        level={'H'}
                        includeMargin={true}
                    />
                    <span style={{ fontStyle: 'italic' }}>Quét mã QR hoặc tải ứng dụng VNMedic để xem chi tiết</span>
                </div>)}
        </div>);
};

export default ResultViewScan;