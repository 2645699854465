import moment from 'moment';
import React from 'react';
import ReactToPrint from 'react-to-print';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { pathPrint } from '../../../constants/SvgIcon';
import { InfoPatientFileResulExamDto } from '../../../types/ExamResult/ExamResult';


interface IHeaderProps
{
    infoPatientExam: InfoPatientFileResulExamDto
    isScan?: boolean
    componentRef?: React.RefObject<HTMLDivElement>
    prescriptionAmount?: number
    datePatient: string
    note: string;
    symptom: string;
}



const HeaderMedicineResult = (props: IHeaderProps) =>
{
    const { symptom, note, infoPatientExam, isScan, componentRef, prescriptionAmount } = props;
    const iconPrint: React.ReactNode = <svg fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d={pathPrint} fill="#fff" />
    </svg>;
    const documentTitle = `${ props?.infoPatientExam?.fullName ?? 'Vnmedic-' } - ${ props?.datePatient } - Đơn thuốc`;

    return (
        <div className="flex flex-col mt-2 justify-between " style={{ height: '17%' }}>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', height: isScan ? '50px' : '30px' }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: 17, flex: 1 }}>ĐƠN THUỐC</p>

                {isScan &&
                    <div className='flex w-full' style={{ height: 50, width: 180, justifyContent: 'end' }}>
                        <ReactToPrint
                            trigger={() => (
                                <ButtonBase className='mr-2'
                                    onClick={() => { }}
                                    style={{ width: 180, height: 40, justifyContent: 'center' }}
                                    buttonName={'IN ĐƠN THUỐC'}
                                    iconCustom={iconPrint}
                                    backgroudColorCustom='#4D9FDA'
                                    buttonType="button_custom"
                                    htmlType="submit" />
                            )}
                            content={() => componentRef?.current != null ? componentRef?.current : null}
                            documentTitle={documentTitle}
                            pageStyle={`
                                @page {
                                    size: A5;
                                    margin: 10mm 5mm;
                                }

                                @page :first {
                                    margin-top: 0;
                                }

                                body {
                                    margin: 0;
                                }

                                .page-break {
                                    page-break-before: always;
                                }
                                `} />
                    </div>
                }
            </div>

            {/* <div className='ml-6 grid grid-cols-4 gap-4' style={{ backgroundColor: '#F3F5F9', borderRadius: '8px', padding: '12px', height: '110px' }}> */}
            <div className='flex flex-1 flex-col ml-6 grid grid-cols-4 gap-4' style={{ backgroundColor: '#F3F5F9', borderRadius: '8px', padding: '12px' }}>
                <div>
                    <span className='font-bold'>1. Họ và tên bệnh nhân: </span> <span> {infoPatientExam.fullName} </span>
                </div>
                <div>
                    <span className='font-bold'>2. Năm sinh: </span> <span> {infoPatientExam.yearOfBirth} </span>
                </div>
                <div>
                    <span className='font-bold'>3. Giới tính: </span> <span> {infoPatientExam.gender} </span>
                </div>
                <div>
                    <span className='font-bold'>4. Số điện thoại: </span> <span> {infoPatientExam.phone} </span>
                </div>
                <div>
                    <span className='font-bold'>5. Địa chỉ: </span> <span> {infoPatientExam.location} </span>
                </div>
                <div>
                    <span className='font-bold'>6. Lý do khám: </span> <span>  {infoPatientExam.reason} </span>
                </div>
                <div>
                    <span className='font-bold'>7. Chỉ định khám: </span> <span> {infoPatientExam.designationMedicalExamName} </span>
                </div>
                <div>
                    <span className='font-bold'>8. Thành tiền: </span> <span> {prescriptionAmount?.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })} </span>
                </div>
                <div>
                    <span className='font-bold'>9. Triệu chứng: </span> <span> {symptom} </span>
                </div>
                <div>
                    <span className='font-bold'>10. Ghi chú: </span> <span> {note} </span>
                </div>
            </div>
        </div>
    );
};

export default HeaderMedicineResult; 