
export const createUserAccount = '/User/create-user';
export const editUserAccount = '/User/edit-user';
export const editPasswordUserAccount = '/User/edit-password-user';

export const deleteUserAccount = '/User/delete/';


export const indexUserAccount = '/User/index-user';

export const loadInfoUser = '/User/load-info';
export const loadDeviceToken = '/User/load-deviceToken';

export const changePasswordUser = '/User/change-password';

// api TẮT hoạt động user
export const disableStatusUser = '/User/disable/';

// api BẬT hoạt động user
export const enableStatusUser = '/User/enable/';


export const loadCheckLogin = '/User/check-login'; 
