import React from 'react';

import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import { MedicalExaminationFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';
import { InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';


interface IHeaderProps
{
    infoPatientExam: InformationPatientExam
}



const HeaderExamResultProcessScanView = (props: IHeaderProps) =>
{
    const { infoPatientExam } = props;
    return (
        <div className="mt-2 justify-between">
            <div className='text-xl font-bold text-[#2c999c] ml-3 mr-3'>
                <span style={{ font: 'Roboto', fontSize: 16 }}>THÔNG TIN BỆNH NHÂN</span>
            </div>
            <div className='ml-6 mr-3 grid grid-cols-4 mt-0'>
                <div>
                    <span className='font-bold'>Họ và tên: </span> <span> {infoPatientExam?.fullName} </span>
                </div>
                <div>
                    <span className='font-bold'>Giới tính: </span> <span> {infoPatientExam?.gender} </span>
                </div>
                <div>
                    <span className='font-bold'>Năm sinh: </span> <span> {infoPatientExam?.yearOfBirth} </span>
                </div>
            </div>
            <div className='ml-6 mr-3 row'>
                <div>
                    <span className='font-bold'>Địa chỉ: </span> <span> {infoPatientExam?.location} </span>
                </div>
                <div>
                    <span className='font-bold'>Lý do khám: </span> <span>  {infoPatientExam?.reason} </span>
                </div>
            </div>

        </div>
    );
};

export default HeaderExamResultProcessScanView; 