import { useCookies } from 'react-cookie';
import { Redirect, Route, RouteProps } from 'react-router-dom';


export function PrivateRoute (props: RouteProps) {
    const [cookies] = useCookies();
    //Check đã login hay chưa
    const isLoggedIn = Boolean(cookies['dev2earn-clinic']);
    if(!isLoggedIn) return <Redirect to="/"/>;
    return <Route {...props}/>;
}