import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { useRef } from 'react';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm from '../../components/FormFields/BaseForm';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { changePasswordUser } from '../../features/UserAccountSetting/api/urlUserAccountConstant';
import { ApiResponse } from '../../types/api.type';
import { ChangePassword } from '../../types/ChangePassword/ChangePassword';
import { ApiUtil, BASE_API_PATH } from '../../utils/ApiUtil';

const schema = yup.object().shape({
    passwordOld: yup.string()
        .required('Vui lòng nhập mật khẩu cũ!')
        .min(8, 'Mật khẩu tối thiểu 8 kí tự.')
        .matches(/[a-zA-Z0-9]/, 'Nhập mật khẩu đúng định dạng. Mật khẩu bao gồm chữ cái hoặc số'),
    passwordNew: yup.string()
        .required('Vui lòng nhập mật khẩu mới!')
        .min(8, 'Mật khẩu tối thiểu 8 kí tự.')
        .matches(/[a-zA-Z0-9]/, 'Nhập mật khẩu đúng định dạng. Mật khẩu bao gồm chữ cái hoặc số'),
    passwordNewConfirm: yup.string()
        .required('Vui lòng nhập mật khẩu xác nhận!')
        .min(8, 'Mật khẩu tối thiểu 8 kí tự.')
        .matches(/[a-zA-Z0-9]/, 'Nhập mật khẩu đúng định dạng. Mật khẩu bao gồm chữ cái hoặc số'),
});

interface IProps
{
    onCloseModal: () => void
}
const ChangePasswordForm = (props: IProps) =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const { onCloseModal } = props;
    // const formRef = useRef<BaseFormRef>(null);


    const onSubmit = async (value: ChangePassword) =>
    {
        overlayRef.current?.open();
        if (value.passwordNew !== value.passwordNewConfirm) 
        {
            overlayRef.current?.close();
            return ApiUtil.ToastError('Mật khẩu xác nhận phải giống mật khẩu mới nhập!');
        }

        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + changePasswordUser, value, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess('Đổi mật khẩu thành công!');
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        overlayRef.current?.close();

    };


    return ( //onSubmit(value);
        <BaseForm
            // ref={formRef}
            className="p-3"
            resolver={yupResolver(schema)}
            onSubmit={(value) => { onSubmit(value); }} >
            <Row className="flex m-1" gutter={24}>
                <Col className="w-full flex-1">
                    <Form.Item>
                        <TextField type='password'
                            name={'passwordOld'}
                            requiredError={true}
                            label={'Mật khẩu cũ'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col className="w-full flex-1">
                    <Form.Item>
                        <TextField type='password'
                            name={'passwordNew'}
                            requiredError={true}
                            label={'Mật khẩu mới'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col className="w-full flex-1">
                    <Form.Item>
                        <TextField type='password'
                            name={'passwordNewConfirm'}
                            requiredError={true}
                            label={'Xác nhận mật khẩu mới'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'HOÀN TẤT'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>

    ); //onClose
};

export default ChangePasswordForm;