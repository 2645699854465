import { Select } from 'antd';
import { Option } from 'antd/lib/mentions';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { IComboBox } from '../../../components/FormFields/ComboBox';


interface IHeaderProps
{
    onOpenCreate: () => void;
    valueOptions: string
    onChange: (value: string) => Promise<void>;
    options?: IComboBox[];
}

const HeaderMedicine = (props: IHeaderProps) =>
{
    const { options, onChange, valueOptions } = props;
    return (
        <div className="flex mt-6" >
            <div className=' text-xl font-bold text-[#2c999c]  ml-3' style={{ width: '80px' }}>
                THUỐC
            </div>
            <div className='flex-1 flex mr-3'>
                <div className='w-full mr-3 flex'>
                    <div className='mr-2 mt-2' style={{ width: 125 }}>
                        <p className='font-bold'>Chọn nhóm thuốc</p>
                    </div>
                    <div className='flex flex-1'>
                        <Select
                            style={{ width: '100%', borderRadius: '7px' }}
                            defaultValue={(options?.find(x => x))?.value}
                            onChange={(value) => { onChange(value); }}>
                            {options?.map(item =>
                            {
                                return <Option key="1" value={item.value}>{item.label}</Option>;
                            })}
                        </Select>

                    </div>


                </div>
                <div className='order-last mr-2' style={{ width: 150 }}>
                    <ButtonBase style={{ width: 150 }}
                        disabled={!valueOptions ? true : false}
                        onClick={props.onOpenCreate}
                        buttonName={'THÊM THUỐC'}
                        buttonType={'create'} />
                </div>
            </div>

        </div >
    );
};

export default HeaderMedicine;