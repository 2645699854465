
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';






function HeaderExaminationHistory()
{


    const history = useHistory();


    return (
        <div className="mt-3 justify-between">
            <div className='flex items-center gap-2 pl-3 py-3 cursor-pointer font-bold'
                onClick={() =>
                {
                    history.push('/patient-management');
                }}><ArrowLeftOutlined /> Quản lý bệnh nhân</div>
            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginBottom: 16, marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>Lịch sử khám bệnh</p>
            </div>

        </div>
    );
}

export default HeaderExaminationHistory;