import React from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';

interface IHeaderProps
{
    onOpenCreate: () => void;
}

const HeaderMedicalExamTemplate = (props: IHeaderProps) =>
{
    return (
        <div className="flex mt-6 justify-between">
            <div className='text-xl font-bold text-[#2c999c]  ml-3'>
                CẤU HÌNH MẪU KẾT QUẢ
            </div>
            <div className='order-last mr-3'>
                <ButtonBase onClick={props.onOpenCreate}
                    buttonName={'THÊM MẪU'}
                    buttonType={'create'} />
            </div>
        </div>
    );
};

export default HeaderMedicalExamTemplate; 