import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import { VapidKey } from './types/NotificationWeb/NotificationConfig';
const firebaseConfig = {
    apiKey: 'AIzaSyBIFVqqat3yUX0E_E6tANZBekSrHPZPwTU',
    authDomain: 'vnmedic-greenlight.firebaseapp.com',
    projectId: 'vnmedic-greenlight',
    storageBucket: 'vnmedic-greenlight.appspot.com',
    messagingSenderId: '1025523451784',
    appId: '1:1025523451784:web:5f3a912086cabf6cdfdc3d',
    measurementId: 'G-4V8MFTMZBV'
};
const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

function requestPermission() {
    if(isSupported()) {
        Notification?.requestPermission().then((permission) => {
            if (permission === 'granted') {
                const app = initializeApp(firebaseConfig);

                const messaging = getMessaging(app);
                getToken(messaging, {
                    vapidKey:VapidKey,
                }).then((currentToken) => {
                    if (currentToken) {
                        console.log('currentToken: ', currentToken);
                    } else {
                        console.log('Can not get token');
                    }
                });
            } else {
                console.log('Do not have permission!');
            }
        });
    }
}

requestPermission();
