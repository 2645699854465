import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, Row } from 'antd';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../../components/FormFields/ComboBox';
import TextField from '../../../components/FormFields/TextField';
import { optionGender, optionGenderPatientCare } from '../../../constants/OptionsCombo';
import { MedicalExaminationFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';

interface IHeaderProps
{
    onFilter: () => void;
    loadOptionsDistrict: (value: string) => Promise<void>;
    optionsProvince: IComboBox[];
    optionsDistrict: IComboBox[];
    options?: IComboBox[];
    onLoadDataGrid: (value: MedicalExaminationFilterDto) => Promise<void>;
    formRefFilter: React.RefObject<BaseFormRef>
}


const HeaderFilterPatientCare = (props: IHeaderProps) =>
{
    const { optionsProvince, optionsDistrict, loadOptionsDistrict, onLoadDataGrid, formRefFilter } = props;
    // const formRef = useRef<BaseFormRef>(null);

    const [isActiveTimeRange, setIsActiveTimeRange] = useState<boolean>(true);
    const suffixFilter = () =>
    {
        return <SearchOutlined />;
    };
    const { RangePicker } = DatePicker;


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const onChangeProvince = (value: string) =>
    {
        const dataForm = formRefFilter.current?.getValues();
        loadOptionsDistrict(value);
        formRefFilter.current?.setValues({ ...dataForm, districtId: null });

    };

    return (
        <div className="mt-3 justify-between">
            <div className=' text-xl font-bold text-[#2c999c] ml-3'>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px' }}>DANH SÁCH BỆNH NHÂN ĐÃ KHÁM</p>
            </div>
            <div className=' '>
                <BaseForm ref={formRefFilter}
                    onSubmit={(value) => { onLoadDataGrid(value); }}
                    style={{ width: '100%' }}
                    defaultValues={{}} >
                    <Row className="flex"
                        gutter={30}
                        style={{ width: '100%', height: '45px' }}>
                        <Col style={{ width: '60%' }} className="">
                            <Form.Item>
                                <TextField noShowLabel={true}
                                    suffix={suffixFilter()}
                                    name={'fullNameOrPhone'}
                                    label={'kiếm bằng số điện thoại'} />
                            </Form.Item>
                        </Col>
                        <Col style={{ width: '20%' }} className="">
                            <Controller
                                name={'isFilterByDate'}
                                render={({ field }) =>
                                {
                                    return <Checkbox
                                        {...field}
                                        className="checkbox-red"
                                        onChange={(value) =>
                                        {
                                            const dataForm = formRefFilter.current?.getValues();
                                            formRefFilter.current?.setValues({ ...dataForm, isFilterByDate: value.target.checked });
                                            setIsActiveTimeRange(!value.target.checked);
                                            // setValue(field.name, value.target.checked);
                                        }}
                                        style={{ display: 'flex' }}
                                    >
                                        <div style={{ width: '70px' }}>
                                            Theo ngày
                                        </div>

                                    </Checkbox>;
                                }
                                }
                            />
                        </Col>
                        <Col style={{ width: '20%', padding: 0 }}>
                            <Controller
                                name={'timeRange'}
                                render={({ field }) =>
                                {
                                    return <RangePicker
                                        {...field}
                                        placeholder={['Từ ngày', 'Đến ngày']}
                                        format={dateFormatList}
                                        disabled={isActiveTimeRange}
                                    />;
                                }
                                }
                            />
                        </Col>

                    </Row>
                    <Row className="flex "
                        gutter={30}
                        style={{ width: '100%' }}>
                        <div style={{ width: '60%', display: 'flex' }} className="">
                            <Col style={{ width: '30%' }} className="">
                                <Form.Item>
                                    <ComboBox noShowLabel={true}
                                        onChange={() => { }}
                                        comboValue={optionGenderPatientCare}
                                        name={'gender'}
                                        label={'Giới tính'} />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '40%' }} className="">
                                <Form.Item>
                                    <ComboBox noShowLabel={true}
                                        comboValue={optionsDistrict}
                                        name={'districtId'}
                                        label={'Quận/Huyện'} />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '30%' }} className="">
                                <Form.Item>
                                    <ComboBox noShowLabel={true}
                                        onChange={(value) => { onChangeProvince(value); }}
                                        comboValue={optionsProvince}
                                        name={'provinceId'}
                                        label={'Tỉnh/Thành phố'} />
                                </Form.Item>
                            </Col>
                        </div>
                        <Col style={{ width: '20%' }} className="">
                            {/* <div style={{width:'18%'}} className="ml-1"> */}
                            <Controller
                                name={'reExam'}
                                render={({ field }) =>
                                {
                                    return <Checkbox
                                        {...field}
                                        className="checkbox-red"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        <div style={{ marginTop: '2px', width: '205px' }}>
                                            Bệnh nhân có lịch tái khám
                                        </div>

                                    </Checkbox>;
                                }
                                }
                            />
                            {/* </div> */}
                        </Col>

                        <Col className='' style={{ width: '20%', display: 'flex', justifyContent: 'end', justifyItems: 'end' }}>
                            <div className="footer ">
                                <ButtonBase style={{ width: 180, display: 'flex', justifyContent: 'center', justifyItems: 'center' }}
                                    buttonName={'TÌM KIẾM'}
                                    buttonType="filter"
                                    htmlType="submit" />
                            </div>
                        </Col>

                    </Row>

                </BaseForm>
            </div>

        </div>
    );
};

export default HeaderFilterPatientCare; 