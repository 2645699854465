import React, { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { DELETE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import DiseaseAnalysisForm from '../../../module/DiseaseAnalysis/DiseaseAnalysisForm';
import { ApiResponse } from '../../../types/api.type';
import { DiseaseAnalysis, DiseaseAnalysisFilter, DiseaseAnalysisFormApi, DiseaseAnalysisGrid } from '../../../types/DiseaseAnalysis/DiseaseAnalysis';
import { DiseaseAnalysisDetail } from '../../../types/DiseaseAnalysisDetail/DiseaseAnalysisDetail';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { createDiseaseAnalysis, deleteDiseaseAnalysis, indexDiseaseAnalysis, indexDiseaseAnalysisByFilter, loadComboDesignationMedicalExam, updateDiseaseAnalysis } from '../api/constants';
import FilterGrid from './FilterGrid';
import GridDiseaseAnalysis from './GridDiseaseAnalysis';
import HeaderDiseaseAnalysis from './HeaderDiseaseAnalysis';

interface IState
{
    valueOption: string;
    diseaseAnalysis: DiseaseAnalysisGrid[];
    loading: boolean;
}
const DiseaseAnalysisView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const [optionsDesignationMedicalExam, setOptionsDesignationMedicalExam] = useState<IComboBox[]>([]);
    const [state, setState] = useMergeState<IState>({
        valueOption: '',
        diseaseAnalysis: [],
        loading: true
    });
    useEffect(() =>
    {
        loadOptionsDesignationMedicalExam();
        setState({
            ...state,
            loading: false
        });
    }, []);
    useEffect(() =>
    {
        if (optionsDesignationMedicalExam.length > 0)
        {
            onChangeComboDiseaseAnalysis(optionsDesignationMedicalExam[0].value);
        }
    }, [optionsDesignationMedicalExam]);
    const loadOptionsDesignationMedicalExam = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadComboDesignationMedicalExam, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as IComboBox[];
            setOptionsDesignationMedicalExam(data);
        }
    };
    const onChangeComboDiseaseAnalysis = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<DiseaseAnalysisFormApi[]>>(BASE_API_PATH + indexDiseaseAnalysis + value);
        let dataGrid: DiseaseAnalysisGrid[] = [];
        if (response.data.success)
        {
            const data = response.data.results as DiseaseAnalysisFormApi[];
            dataGrid = onFormatDataGrid(data);
        }
        setState({
            valueOption: value,
            diseaseAnalysis: dataGrid
        });
    };

    const onFormatDataGrid = (value: DiseaseAnalysisFormApi[]): DiseaseAnalysisGrid[] =>
    {
        const result: DiseaseAnalysisGrid[] = [];
        let stt = 0;
        value.map(item =>
        {
            const dataLenght = item.diseaseAnalysisDetailDetailDtos.length;
            if (dataLenght === 0)
            {
                stt++;
                result.push({
                    stt: stt,
                    id: item.id,
                    name: item.name,
                    designationMedicalExamId: item.designationMedicalExamId,
                    isMerge: false,
                    diseaseAnalysisDetailDetailDtos: item.diseaseAnalysisDetailDetailDtos
                });
            }
            else
            {
                item.diseaseAnalysisDetailDetailDtos.map((itemDetail, index) =>
                {
                    if (index === 0) stt++;
                    result.push({
                        stt: stt,
                        id: item.id,
                        name: index === 0 ? item.name : '',
                        isMerge: dataLenght === 1 ? false : (index === 0 ? true : false),
                        content: itemDetail.content,
                        designationMedicalExamId: item.designationMedicalExamId,
                        diseaseAnalysisDetailDetailDtos: item.diseaseAnalysisDetailDetailDtos
                    });
                });
            }
        });
        return result;
    };

    const onOpenCreate = () =>
    {
        modalRef.current?.onOpen(<DiseaseAnalysisForm isCreate={true}
            handleSubmit={onCreateDesignationMedicalExam}
            onClose={onCloseModal} />, 'THÊM MÔ TẢ KQ', 80);
    };

    const onOpenUpdate = (value: DiseaseAnalysisGrid) =>
    {
        modalRef.current?.onOpen(<DiseaseAnalysisForm defaultvalue={value}
            handleSubmit={onUpdateDiseaseAnalys}
            onClose={onCloseModal} />, 'Sửa phân tích', 80);
    };

    const onCreateDesignationMedicalExam = async (value: DiseaseAnalysis, diseaseAnalysisDetailList: DiseaseAnalysisDetail[]) =>
    {
        const params: DiseaseAnalysisFormApi = {
            name: value.name as string,
            designationMedicalExamId: parseInt(state.valueOption),
            diseaseAnalysisDetailDetailDtos: diseaseAnalysisDetailList
        };
        const response = await AxiosClient.post<ApiResponse<DiseaseAnalysis>>(BASE_API_PATH + createDiseaseAnalysis, params);
        if (response.data.success)
        {
            await onChangeComboDiseaseAnalysis(state.valueOption);
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onUpdateDiseaseAnalys = async (value: DiseaseAnalysis, diseaseAnalysisDetailList: DiseaseAnalysisDetail[]) =>
    {
        const params: DiseaseAnalysisFormApi = {
            id: value.id,
            name: value.name as string,
            designationMedicalExamId: parseInt(state.valueOption),
            diseaseAnalysisDetailDetailDtos: diseaseAnalysisDetailList
        };
        const response = await AxiosClient.post<ApiResponse<DiseaseAnalysis>>(BASE_API_PATH + updateDiseaseAnalysis, params);
        if (response.data.success)
        {
            await onChangeComboDiseaseAnalysis(state.valueOption);
            ApiUtil.ToastSuccess(response.data.message);
            onCloseModal();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }

    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onDeleteDesignationMedicalExam = async (value: DiseaseAnalysis) =>
    {
        overlayRef.current?.open();
        const dseaseAnalysisId = value.id;
        const response = await AxiosClient.post<ApiResponse<DiseaseAnalysis>>(BASE_API_PATH + deleteDiseaseAnalysis + dseaseAnalysisId);
        if (response.data.success)
        {
            await onChangeComboDiseaseAnalysis(state.valueOption);
            ApiUtil.ToastSuccess(DELETE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError('Thiết lập đang được sử dụng tại vị trí khác!');
        }
        onCloseModal();
        overlayRef.current?.close();
    };

    const onLoadDataFilter = async (value: DiseaseAnalysisFilter) =>
    {
        overlayRef.current?.open();
        const params: DiseaseAnalysisFilter = {
            ...value,
            designationMedicalExamId: parseInt(state.valueOption)
        };
        const response = await AxiosClient.post<ApiResponse<DiseaseAnalysisFormApi[]>>(BASE_API_PATH + indexDiseaseAnalysisByFilter, params);
        let dataGrid: DiseaseAnalysisGrid[] = [];
        if (response.data.success)
        {
            const data = response.data.results as DiseaseAnalysisFormApi[];
            dataGrid = onFormatDataGrid(data);
        }
        setState({
            ...state,
            diseaseAnalysis: dataGrid
        });
        overlayRef.current?.close();
    };

    return (
        <div className=''>
            <HeaderDiseaseAnalysis valueOptions={state.valueOption}
                onChange={onChangeComboDiseaseAnalysis}
                options={optionsDesignationMedicalExam}
                onOpenCreate={onOpenCreate} />
            <FilterGrid onLoadData={onLoadDataFilter} />
            <GridDiseaseAnalysis record={state.diseaseAnalysis}
                onEdit={onOpenUpdate}
                onDelete={onDeleteDesignationMedicalExam} />
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>
    );
};

export default DiseaseAnalysisView;