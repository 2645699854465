import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { StatisticsDashboard, StatisticsRequestDashboard } from '../../../types/StatisticsDashboardView.ts/StatisticsDashboard';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { getDataStatistic } from '../api/constants';
import StatisticsDashboardHeader from './StatisticsDashboardHeader';



interface IState
{
    date: string[];
    data: StatisticsDashboard
}
const NotificationDashboardView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const formRefFilter = useRef<BaseFormRef>(null);

    const [state, setState] = useMergeState<IState>({
        date: [],
        data: {
            notificationArr: [],
            smsArr: [],
            totalCostSms: 0

        }
    });
    const options = useRef<any>({
        grid: { top: 50, right: 40, bottom: 70, left: 40 },
        textStyle: {
            fontFamily: 'sans-serif'
        },
        legend: {
            data: ['Gửi thông báo', 'Gửi tin nhắn'],
            top: -2
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: state.date,
        },
        yAxis: {
            type: 'value',
            name: 'Số lượng',
            minInterval: 1
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100
            },
            {
                start: 0,
                end: 100,

            }
        ],
        series: [

        ],
        tooltip: {
            trigger: 'axis'
        }
    });

    useEffect(() =>
    {
        loadApi();
    }, []);


    const dateByFilter = (type: string, typeShow: string) =>
    {
        //Type Filter Api
        const dateFilterArr = [];
        let dateOf = 0;
        let dateOfShow = 0;
        if (type === 'day')
        {
            dateOf = 2;
        }
        else if (type === 'week')
        {
            dateOf = 7;
        }

        else if (type === 'month')
        {
            dateOf = 30;
        }
        else
        {
            dateOf = 365;
        }
        let startDayNotFormat;
        let endDayNotFormat;
        const now = new Date();
        now.setDate(now.getDate() - dateOf);
        for (let i = 0; i < dateOf; i++)
        {
            const nowSet = _.cloneDeep(now);
            nowSet.setDate(nowSet.getDate() + i);
            dateFilterArr.push(moment(nowSet.toISOString().split('T')[0].replaceAll('-', '/')).format('DD/MM/YYYY'));
            if (i == 0)
            {
                startDayNotFormat = nowSet;
            }
            else if (i + 1 >= dateOf)
            {
                endDayNotFormat = nowSet;
            }
        }

        const endDayString = moment(endDayNotFormat?.toISOString().split('T')[0].replaceAll('-', '/')).format('YYYY/MM/DD');
        const startDayString = moment(startDayNotFormat?.toISOString().split('T')[0].replaceAll('-', '/')).format('YYYY/MM/DD');
        //Type Filter Show
        if (typeShow === 'day')
        {
            dateOfShow = 2;

        }
        else if (typeShow === 'week')
        {
            dateOfShow = 7;
            startDayNotFormat = new Date(startDayString);
            endDayNotFormat = new Date(endDayString);
            const resultNew = getWeek(startDayNotFormat as Date, endDayNotFormat as Date);
            return resultNew;
        }
        else if (typeShow === 'month')
        {
            dateOfShow = 31;
            const resultNew = getMonth(startDayString, endDayString);
            return resultNew;
        }
        else
        {
            dateOfShow = 365;
            const resultNew = getYear(startDayNotFormat as Date, endDayNotFormat as Date);
            return resultNew;
        }

        return dateFilterArr;
    };


    const getWeek = (start: Date, end: Date) =>
    {
        let sDate;
        let eDate;
        const dateArr = [];
        while (start <= end)
        {
            if (start.getDay() == 1 || (dateArr.length == 0 && !sDate))
            {
                sDate = new Date(start.getTime());
            }

            if ((sDate && start.getDay() == 0) || start.getTime() == end.getTime())
            {
                eDate = new Date(start.getTime());
            }

            if (sDate && eDate)
            {
                sDate.setDate(sDate.getDate() + 1);
                eDate.setDate(eDate.getDate() + 1);
                //
                const startDateFormat = moment(sDate.toISOString().split('T')[0].replaceAll('-', '/')).format('DD');
                const endDateFormat = moment(eDate.toISOString().split('T')[0].replaceAll('-', '/')).format('DD/MM/YYYY');
                dateArr.push(startDateFormat + '-' + endDateFormat);
                sDate = undefined;
                eDate = undefined;
            }
            start.setDate(start.getDate() + 1);
        }
        return dateArr;
    };



    const getMonth = (startDate: string, endDate: string) =>
    {
        const start = startDate.split('/');
        const end = endDate.split('/');
        const startYear = parseInt(start[0]);
        const endYear = parseInt(end[0]);
        const dates = [];

        for (let i = startYear; i <= endYear; i++)
        {
            const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1)
            {
                const month = j + 1;
                const displayMonth = month < 10 ? '0' + month : month;
                dates.push([displayMonth, i].join('/'));
            }
        }
        return dates;

    };
    const getYear = (startDate: Date, endDate: Date) =>
    {
        const dates = [];
        const numberDiffYear = getYearDiffWithMonth(startDate, endDate);
        for (let i = 0; i <= numberDiffYear; i++)
        {
            const year = startDate.getFullYear() + i;
            dates.push(year.toString());

        }
        return dates;
    };

    const getYearDiffWithMonth = (startDate: Date, endDate: Date) =>
    {
        return Math.abs(startDate.getFullYear() - endDate.getFullYear());
    };


    const dateByFilterTypeDay = (dateFrom: string, dateTo: string, typeShow: string) =>
    {
        const dateFromDate = new Date(dateFrom);
        const dateToDate = new Date(dateTo);
        const dateFilterArr: string[] = [];
        let start = moment(dateFrom);
        const end = moment(dateTo);

        while (start <= end)
        {
            dateFilterArr.push(moment(start).format('DD/MM/YYYY'));
            start = moment(start).add(1, 'days');
        }

        if (typeShow === 'week')
        {
            const resultNew = getWeek(dateFromDate, dateToDate);
            return resultNew;
        }
        else if (typeShow === 'month')
        {
            const resultNew = getMonth(dateFrom, dateTo);
            return resultNew;
        }
        else if (typeShow === 'year')
        {
            dateFromDate.setDate(dateFromDate.getDate() + 1);
            dateToDate.setDate(dateToDate.getDate() + 1);
            const resultNew = getYear(dateFromDate, dateToDate);
            return resultNew;
        }

        return dateFilterArr;
    };


    const loadApi = async () =>
    {
        overlayRef.current?.open();
        const params: StatisticsRequestDashboard = {
            isGetByDate: false,
            type: 'month',
            typeSeen: 'day'
        };
        let data: StatisticsDashboard = {
            notificationArr: [],
            smsArr: [],
            totalCostSms: 0

        };
        const dateArr = dateByFilter('month', 'day');
        const response = await AxiosClient.post<ApiResponse<StatisticsDashboard>>(BASE_API_PATH + getDataStatistic, params, { withCredentials: true });
        if (response.data.success)
        {
            data = response.data.results as StatisticsDashboard;
        }
        onChangeConfig(data, dateArr);
        setState({ ...state, data: data, date: dateArr });

        overlayRef.current?.close();

    };

    const onChangeFilter = async () =>
    {
        overlayRef.current?.open();

        const dataFilter = formRefFilter.current?.getValues();
        const timeRange = dataFilter?.['timeRange'];
        const filterByDateFrom = timeRange ? timeRange[0].format('DD/MM/YYYY') : null;
        const filterByDateTo = timeRange ? timeRange[1].format('DD/MM/YYYY') : null;
        const type = dataFilter?.['optionValue'];
        const typeSeen = dataFilter?.['comboSetting'];
        const isDayFilter = dataFilter?.['optionValue'] === 'day';
        const typeShow = dataFilter?.['comboSetting'] as string;
        if (!filterByDateFrom && !filterByDateTo && isDayFilter)
        {
            ApiUtil.ToastError('Vui lòng chọn ngày để tìm kiếm!');
            overlayRef.current?.close();

            return;
        }
        const params: StatisticsRequestDashboard = {
            isGetByDate: dataFilter?.['optionValue'] === 'day' ? true : false,
            type: type,
            dateFrom: filterByDateFrom,
            dateTo: filterByDateTo,
            typeSeen: typeSeen
        };

        let data: StatisticsDashboard = {
            notificationArr: [],
            smsArr: [],
            totalCostSms: 0
        };
        const filterDateFromFormat = timeRange ? timeRange[0].format('YYYY/MM/DD') : null;
        const filterDateToFormat = timeRange ? timeRange[1].format('YYYY/MM/DD') : null;
        const dateArr = isDayFilter ? dateByFilterTypeDay(filterDateFromFormat, filterDateToFormat, typeShow) : dateByFilter(dataFilter?.['optionValue'], typeShow);
        const response = await AxiosClient.post<ApiResponse<StatisticsDashboard>>(BASE_API_PATH + getDataStatistic, params, { withCredentials: true });
        if (response.data.success)
        {
            data = response.data.results as StatisticsDashboard;
        }
        onChangeConfig(data, dateArr);
        setState({ ...state, data: data, date: dateArr });
        overlayRef.current?.close();
    };



    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onChangeConfig = (data: StatisticsDashboard, dateArr: string[]) =>
    {
        let configSetting = options.current;
        configSetting = {
            ...configSetting,
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: dateArr
            },
            series: [
                {
                    name: 'Gửi thông báo',
                    data: data.notificationArr as [],
                    type: 'line',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (param: any)
                        {
                            return param.data == 0 ? '' : param.data;
                        },

                    },
                    smooth: true
                },
                {
                    name: 'Gửi tin nhắn',
                    data: data.smsArr as [],
                    type: 'line',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (param: any)
                        {
                            return param.data == 0 ? '' : param.data;
                        },

                    },
                    smooth: true
                }
            ]
        };
        options.current = configSetting;
    };


    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='h-full w-full flex flex-col'>
                <div style={{ height: '120px' }}>
                    {<StatisticsDashboardHeader formRefFilter={formRefFilter}
                        isNotification={true}
                        costSms={state.data.totalCostSms}
                        onChangeFilter={onChangeFilter} />}

                </div>
                <div className='flex flex-1'>
                    <ReactECharts
                        option={options.current}
                        style={{ width: '100%', minHeight: '-webkit-fill-available' }}
                    ></ReactECharts>
                </div>
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default NotificationDashboardView;