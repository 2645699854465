import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useRef } from 'react';
import * as yup from 'yup';

import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import HeaderDiseaseAnalysisDetail from '../../features/DiseaseAnalysis/pages/HeaderDiseaseAnalysisDetail';
import { DiseaseAnalysisDetail } from '../../types/DiseaseAnalysisDetail/DiseaseAnalysisDetail';
import { StatisticsPatientFile, StatisticsPatientFileDashboard } from '../../types/StatisticsDashboardView.ts/StatisticsDashboard';

const schema = yup.object().shape({
    content: yup.string().required('Vui lòng nhập thông tin!'),
});

interface IFormDiseaseAnalysisDetailFormProps
{
    onCloseModal: () => void
    defaultvalue?: StatisticsPatientFile
}

const StatisticDashboardForm: FunctionComponent<IFormDiseaseAnalysisDetailFormProps> = (props) =>
{
    const overlayRef = useRef<OverlayRef>(null);

    const formRef = useRef<BaseFormRef>(null);
    const { onCloseModal, defaultvalue } = props;
    const onSubmit = async (value: DiseaseAnalysisDetail) =>
    {
        // overlayRef.current?.open();
        // overlayRef.current?.close();
    };
    return (
        <BaseForm ref={formRef}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
            resolver={yupResolver(schema)} >

            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>TỔNG HỒ SƠ BỆNH NHÂN CŨ</p>
            </div>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField name={'oldPatientTotal'}
                            readOnly={true}
                            label={'CÓ LỊCH TÁI KHÁM'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField name={'oldPatientReExamTotal'}
                            readOnly={true}
                            label={'KHÔNG CÓ LỊCH TÁI KHÁM'} />
                    </Form.Item>
                </Col>
            </Row>

            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>TỔNG HỒ SƠ BỆNH NHÂN MỚI</p>
            </div>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField name={'newPatientTotal'}
                            readOnly={true}
                            label={'CÓ LỊCH TÁI KHÁM'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField name={'newPatientReExamTotal'}
                            readOnly={true}
                            label={'KHÔNG CÓ LỊCH TÁI KHÁM'} />
                    </Form.Item>
                </Col>
            </Row>

            <div className=' text-xl font-bold text-[#2c999c] ml-3' style={{ display: 'flex', marginRight: 16 }}>
                <p style={{ font: 'Roboto', fontSize: 16, height: '17px', flex: 1 }}>TỔNG HỒ SƠ BỆNH NHÂN</p>
            </div>
            <Row className="flex m-1" gutter={24}>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField name={'patientTotal'}
                            readOnly={true}
                            label={'CÓ LỊCH TÁI KHÁM'} />
                    </Form.Item>
                </Col>
                <Col span={12} className="flex-1">
                    <Form.Item>
                        <TextField name={'patientReExamTotal'}
                            readOnly={true}
                            label={'KHÔNG CÓ LỊCH TÁI KHÁM'} />
                    </Form.Item>
                </Col>
            </Row>

            <div className="footer flex justify-center items-center">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'ĐÓNG'}
                    onClick={() => { onCloseModal(); }}
                    buttonType="close"
                />
            </div>
            <Overlay ref={overlayRef} />
        </BaseForm>
    );
};
export default StatisticDashboardForm; 
