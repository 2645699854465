import { ColDef } from 'ag-grid-community';

export const MedicineSettingGridColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',

        maxWidth: 80,
        headerClass: 'text-center',
        cellStyle: { textAlign: 'center' },

    },
    // {
    //     headerClass: 'text-center',
    //     headerName: 'Nhóm thuốc',
    //     field: 'medicineGroup.name',
    //     width: 150,
    //     filter: 'agTextColumnFilter',
    //     floatingFilter: true,
    //     cellStyle: { 'text-align': 'start' }
    // },
    {
        headerClass: 'text-center',
        headerName: 'Mã thuốc',
        field: 'medicineCode',
        width: 150,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerClass: 'text-center',
        headerName: 'Tên thuốc',
        field: 'name',
        width: 150,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerClass: 'text-center',
        headerName: 'Hoạt chất',
        field: 'activeIngredients',
        width: 150,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerClass: 'text-center',
        headerName: 'Đơn vị',
        field: 'unit.text',
        width: 150,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerClass: 'text-center',
        headerName: 'Đơn giá',
        field: 'price',
        width: 150,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' },
        cellRenderer: (param: any) =>
        {
            const data = param.data;
            return data.price.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
        }
    },
    {
        headerClass: 'text-center',
        headerName: 'Hạn dùng',
        field: 'expiredTime',
        width: 150,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { 'text-align': 'start' },
        cellRenderer: (param: any) =>
        {
            const dateString = param.data.expiredTime;
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
    },
    // {
    //     headerClass: 'text-center',
    //     headerName: 'Cách dùng',
    //     field: 'noteUse',
    //     width: 150,
    //     filter: 'agTextColumnFilter',
    //     floatingFilter: true,
    //     cellStyle: { 'text-align': 'start' }
    // }

];



export const MedicineDetailSetting: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        maxWidth: 80,
        cellStyle: { 'text-align': 'start' },
        headerClass: 'text-center',

    },
    {
        headerName: 'Cách dùng',
        field: 'note',
        width: 150,
        tooltipField: 'note',
        headerClass: 'text-center',
        // filter: 'agTextColumnFilter',
        floatingFilter: false,
        cellStyle: { 'text-align': 'start' },
        singleClickEdit: true,
        cellClass: 'vnm-grid-editable-cell',

    }
];