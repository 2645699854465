export const createDiseaseAnalysis = '/DiseaseAnalysis/create-custom';
export const updateDiseaseAnalysis = '/DiseaseAnalysis/update-custom';
export const deleteDiseaseAnalysis = '/DiseaseAnalysis/delete-custom/';

export const indexDiseaseAnalysis = '/DiseaseAnalysis/index-by-designation-id/';
export const indexDiseaseAnalysisByFilter = '/DiseaseAnalysis/index-by-filter';

export const loadComboDesignationMedicalExam = '/MedicalExaminationPictures/combo';

export const loadComboDesignationMedicalExamByServiceType = '/MedicalExaminationPictures/combo-by-group';
