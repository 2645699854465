import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Popconfirm } from 'antd';
import React from 'react';

import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import { CANCEL, CONFIRM_TO_DELETE, SUCCESS } from '../../../constants/MessageConstants';
import { MedicalExamTemplate } from '../../../types/MedicalExamTemplate/MedicalExamTemplate';
import { MedicalExamTemplateGridColumns } from '../../../types/MedicalExamTemplate/MedicalExamTemplateSetting';

interface IGridMedicalExamTemplate
{
    record: MedicalExamTemplate[],
    onEdit: (value: MedicalExamTemplate) => void;
    onDelete: (value: MedicalExamTemplate) => void;
}

const GridMedicalExamTemplate = (props: IGridMedicalExamTemplate) =>
{
    const { onEdit, onDelete } = props;
    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...MedicalExamTemplateGridColumns,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<MedicalExamTemplate>) =>
                {
                    const data = params.data as MedicalExamTemplate;
                    return (
                        <div className="flex items-center justify-center">
                            <GridButtonBase type={'edit'} onClick={() => { onEdit(data); }} />
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { onDelete(data); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };
    return (
        <div className='mt-2' style={{ height: 'calc(68vh)' }}>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                rowGroupPanelShow={'always'}
                pivotPanelShow={'always'}
                columnDefs={getColumnConfig()}
                pagination={true}
                suppressClickEdit={true}
                rowData={props.record}
            />
        </div>
    );
};

export default GridMedicalExamTemplate; 