import { FileAddOutlined, MedicineBoxOutlined, ProfileOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, {CSSProperties} from 'react';

import { pathDelete, pathEdit } from '../../constants/SvgIcon';

interface IProps
{
    type: 'detail' | 'edit' | 'delete' | 'medicine' | 'add_visist' | 'top' | 'request_approve' | 'stop' | 'activate';
    onClick: () => void;
    style?: CSSProperties;
}

const GridButtonBase: React.FC<IProps> = (props) =>
{
    const { type, onClick, style = {} } = props;
    const getButtonProperties = () =>
    {
        let body = null;
        let title = '';
        switch (type)
        {
            case 'medicine':
                title = 'Đơn thuốc';
                body = (
                    <button
                        type="button"
                        onClick={onClick}
                        style={{ ...style, width: '40px', justifyContent: 'center', backgroundColor: 'rgb(101 163 13)' }}
                        className="flex text-white  focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
                    >
                        <MedicineBoxOutlined style={{ fontSize: '125%' }} />
                    </button>
                );
                break;
            case 'add_visist':
                title = 'Thêm lượt khám';
                body = (
                    <button
                        type="button"
                        onClick={onClick}
                        style={{ width: '40px', justifyContent: 'center', backgroundColor: 'rgb(101 163 13)' }}
                        className="flex text-white  focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
                    >
                        <FileAddOutlined style={{ fontSize: '125%' }} />
                    </button>
                );
                break;
            case 'request_approve':
                title = 'Yêu cầu phê duyệt';
                body = (
                    <button
                        type="button"
                        onClick={onClick}
                        style={{ width: '40px', justifyContent: 'center', backgroundColor: 'rgb(101 163 13)' }}
                        className="flex text-white  focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
                    >
                        <FileAddOutlined style={{ fontSize: '125%' }} />
                    </button>
                );
                break;
            case 'detail':
                title = 'Chi tiết';
                body = (
                    <button
                        type="button"
                        onClick={onClick}
                        style={{ ...style, width: '40px', justifyContent: 'center' }}
                        className="flex text-white bg-gradient-to-r from-purple-600 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
                    >
                        <ProfileOutlined style={{ fontSize: '125%' }} />
                    </button>
                );
                break;
            case 'stop':
                title = 'Tạm dừng';
                body = (
                    <button
                        type="button"
                        style={{ width: '40px', height: '36px', justifyContent: 'center', backgroundColor: '#E45B63' }}
                        className="flex text-white bg-gradient-to-r hover:bg-gradient-to-br focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg mr-2 mb-2 px-3 justify-center items-center"
                        onClick={onClick}

                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-ban">
                            <circle cx="12"
                                cy="12"
                                r="10"/>
                            <path d="m4.9 4.9 14.2 14.2"/>
                        </svg>
                    </button>
                );
                break;
            case 'activate':
                title = 'Kích hoạt';
                body = (
                    <button
                        type="button"
                        style={{ width: '40px', height: '36px', justifyContent: 'center', backgroundColor: '#2C999C' }}
                        className="flex text-white bg-gradient-to-r hover:bg-gradient-to-br focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg mr-2 mb-2 px-3 justify-center items-center"
                        onClick={onClick}

                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="36"
                            height="36"
                            fill='white'><path d="M18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z"></path></svg>
                    </button>
                );
                break;
            case 'edit':
                title = 'Sửa';
                body = (
                    <button
                        type="button"
                        className="text-white   focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 font-medium rounded-lg text-sm px-3 py-2 text-center mr-2 mb-2"
                        onClick={onClick}
                        style={{ backgroundColor: '#E78429' }}
                    >

                        <svg fill="none"
                            className="w-3 h-4 sm:w-4 sm:h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 19 20">
                            <path d={pathEdit} fill="#fff" />
                        </svg>

                        {/* <EditOutlined style={{fontSize:'130%'}} /> */}
                    </button>
                );
                break;
            case 'top':
                title = 'Ưu tiên';
                body = (
                    <button
                        type="button"
                        onClick={onClick}
                        style={{ width: '40px', justifyContent: 'center' }}
                        className="flex text-white bg-gradient-to-r from-purple-600 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
                    >
                        <UpCircleOutlined style={{ fontSize: '125%' }} />
                    </button>
                );
                break;
            case 'delete':
                title = 'Xóa';
                body = (
                    <button
                        type="button"
                        className="text-white bg-gradient-to-r hover:bg-gradient-to-br  focus:outline-none  dark:focus:ring-red-800 font-medium rounded-lg text-sm px-3 py-2 text-center mr-2 mb-2"
                        onClick={onClick}
                        style={{ backgroundColor: '#E45B63' }}
                    >
                        <svg fill="none"
                            className="w-4 h-5 sm:w-4 sm:h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 21 21">
                            <path d={pathDelete} fill="#fff" />
                        </svg>
                    </button>
                );
                break;
        }
        return { body, title };
    };
    const { body, title } = getButtonProperties();
    return <Tooltip title={title}>{body}</Tooltip>;
};

export default GridButtonBase;
 