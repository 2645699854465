
import { Pagination, Spin, Table } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { MESSAGE_EXPIRED } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import MedicineResultForm from '../../../module/ExamResult/MedicineResult/MedicineResultForm';
import ResultView from '../../../module/ExamResult/Result/ResultView';
import { ApiResponsePage } from '../../../types/api.type';
import { MedicalExaminationPatientRecordFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';
import { InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';
import { SessionDataDTO } from '../../../types/Session/StartSessionBody';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { getListSession } from '../../PatientRecord/api/constants';
import HeaderFilterPatientRecord from '../../PatientRecord/page/HeaderFilterPatientRecord';
import HeaderExaminationHistory from './HeaderExaminationHistory';

interface IState
{
    medicalExamination: SessionDataDTO[];
    infoPatientExam?: InformationPatientExam | null,
    loading?: boolean;

}




function ExaminationHistory()
{
    const formRefFilter = useRef<BaseFormRef>(null);
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const currencyFormater = new Intl.NumberFormat('vn-VI', {
        style: 'currency',
        currency: 'VND',
    });
    const paramsUrl: any = useParams();
    const patientId = paramsUrl?.patientId;



    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);


    const [selectedRowKey, setSelectedRowKey] = useState('');
    const [state, setState] = useMergeState<IState>({
        medicalExamination: [],

        infoPatientExam: null,
        loading: true,

    });


    const getFlatSessionChildren = (response: SessionDataDTO[]): SessionDataDTO[] =>
    {
        return response.map((sessionData) => ({
            ...sessionData, children: sessionData.listMedicalExam.map(item => ({
                ...item,
                totalAmount: sessionData.totalAmount
            }))
        }));
    };

    const onLoadDataGrid = async (value?: MedicalExaminationPatientRecordFilterDto) =>
    {
        setState({
            ...state,
            loading: true,
        });

        overlayRef.current?.open();
        const params = {
            textSearch: value?.fullNameOrPhoneorLocation,
            fromDate: value?.timeRange ? value?.timeRange[0].format('DD/MM/YYYY') : null,
            toDate: value?.timeRange ? value?.timeRange[1].format('DD/MM/YYYY') : null,
            page: currentPage,
            size: currentPageSize,
            patientId: patientId
        };
        const response = await AxiosClient.post<ApiResponsePage<SessionDataDTO>>(BASE_API_PATH + getListSession, params, { withCredentials: true });
        if (response.data.success)
        {
            setState({
                loading: false,
                medicalExamination: getFlatSessionChildren(response.data.results?.content || []) as SessionDataDTO[]
            });
            setCurrentPage(response.data.results?.page as unknown as number);
            setCurrentPageSize(response.data.results?.size as unknown as number);
            setTotalRecord(response.data.results?.totalRecord as unknown as number);
        }
        overlayRef.current?.close();
    };

    useEffect(() =>
    {
        const defaultParams: any = {
            textSearch: '',
            fromDate: null,
            toDate: null,
            page: 1,
            size: 10
        };
        onLoadDataGrid(defaultParams);
    }, []);

    const handlePageChange = (pageNumber: number, pageSize: number) =>
    {
        setState({
            ...state,
            loading: true,
        });
        setCurrentPage(pageNumber);
        setCurrentPageSize(pageSize);
    };




    const openDetail = (medicalExamId: number, datePatient: string) =>
    {
        modalRef.current?.onOpen(<ResultView datePatient={datePatient} medicalExaminationId={medicalExamId} />, 'Kết quả khám', 90);
    };

    const openMedicine = (medicalExamId: number, datePatient: string) =>
    {
        modalRef.current?.onOpen(<MedicineResultForm datePatient={datePatient} medicalExaminationId={medicalExamId} />, 'Đơn thuốc', 90);
    };


    const loadApi = async (pageParam?: number, sizeParam?: number) =>
    {
        overlayRef.current?.open();
        const filterValue = formRefFilter?.current?.getValues();

        const params = {
            textSearch: filterValue?.fullNameOrPhoneorLocation ?? null,
            fromDate: filterValue?.timeRange ? filterValue?.timeRange[0].format('DD/MM/YYYY') : null,
            toDate: filterValue?.timeRange ? filterValue?.timeRange[1].format('DD/MM/YYYY') : null,
            page: pageParam ?? 1,
            size: sizeParam ?? 10,
            patientId: patientId
        };

        try
        {

            Axios.all([

                await AxiosClient.post<any>(BASE_API_PATH + getListSession, params, { withCredentials: true }),

            ]).then(axios.spread((resPatientDay: any) =>
            {

                if (resPatientDay.data.success)
                {

                    setState({
                        loading: false,
                        medicalExamination: getFlatSessionChildren(resPatientDay.data.results?.content || []) as SessionDataDTO[]
                    });

                }

                overlayRef.current?.close();
            }));

        } catch (error: any)
        {
            overlayRef.current?.close();
            ApiUtil.ToastWarning(MESSAGE_EXPIRED);
        }

    };

    useEffect(() =>
    {
        loadApi(currentPage, currentPageSize);
    }, [currentPage, currentPageSize]);

    const renderGrid = () =>
    {
        const columns = [
            {},

            {
                title: 'STT',
                render: (text: any, record: any, index: number) => !record.medicalExamId ? <p className='flex m-0 p-0'>{currentPage * currentPageSize + index + 1 - currentPageSize}</p> : '',
            },
            {
                title: 'Họ và tên',
                dataIndex: 'fullName',
                render: (value: any, record: any) => !record.medicalExamId ? record.fullName : '',
                key: 'fullName',
                minWidth: 150,
            },
            {
                title: 'Năm sinh',
                dataIndex: 'yearOfBirth',
                key: 'yearOfBirth',
                maxWidth: 90,
            }
            ,
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                minWidth: 70,
            }
            ,
            {
                title: 'Giới tính',
                dataIndex: 'genderLabel',
                key: 'genderLabel',
                maxWidth: 90,
            }
            ,
            {
                title: 'Chi tiết',
                dataIndex: 'location',
                key: 'location',
                minWidth: 120,
                render: (value: any, record: any) =>
                {
                    return !record.medicalExamId ? (
                        <div className='m-0 p-0'><p className='m-0 p-0'>{record.location}</p><p className='m-0 p-0'>Lý do khám: {record.reason}</p></div>
                    ) : (
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0'>{record.designationName}</p>
                            {record?.prescriptionAmount > 0 ? <p>Đơn thuốc</p> : (<div className='display-none'></div>)}
                        </div>
                    );
                },
            },
            {
                title: 'Loại chỉ định',
                dataIndex: 'reason',
                key: 'reason',
                minWidth: 120,
                render: (value: any, record: any) =>
                {
                    return !record.medicalExamId ? (
                        <div />
                    ) : (
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0'>{record.designationType}</p>
                        </div>
                    );
                },
            },
            {
                title: 'Số tiền',
                dataIndex: 'reason',
                // render: (value: any, record: any) => !record.medicalExamId ? record.totalAmount : record.medicalExamAmount,
                render: (value: any, record: any) =>
                {
                    return !record.medicalExamId ? (
                        <div className='m-0 p-0'><p className='m-0 p-0 text-right'>{currencyFormater.format(record.totalAmount)}</p></div>
                    ) : (
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0 text-right'>{currencyFormater.format(record.designationMedicalAmount)}</p>
                            {record?.prescriptionAmount > 0 ? <p className='m-0 p-0 text-right'>{currencyFormater.format(record.prescriptionAmount)}</p> : (<div className='display-none'></div>)}
                        </div>
                    );
                },
                key: 'reason',
                minWidth: 250,
            }
            ,
            {
                title: 'Thời gian khám',
                dataIndex: 'datePatient',
                render: (value: any, record: any) => !record.medicalExamId ? record.dateMedicalSession : record.datePatient,
                key: 'datePatient',
                minWidth: 150,
            },
            {
                title: 'Hành động',
                key: 'datePatient',
                render: (value: any, data: any) =>
                {
                    if (!data.medicalExamId) return null;
                    return (
                        <div className="flex items-center justify-center gap-x-4">
                            {data.isMedicineOnly ?
                                <GridButtonBase style={{ margin: 0 }}
                                    type={'medicine'}
                                    onClick={() =>
                                    {
                                        openMedicine(data?.medicalExamId as unknown as number, data.datePatient as string);
                                    }} /> :
                                <>
                                    <GridButtonBase style={{ margin: 0 }}
                                        type={'detail'}
                                        onClick={() =>
                                        {
                                            openDetail(data?.medicalExamId as unknown as number, data.datePatient as string);
                                        }} />
                                    <GridButtonBase style={{ margin: 0 }}
                                        type={'medicine'}
                                        onClick={() =>
                                        {
                                            openMedicine(data?.medicalExamId as unknown as number, data.datePatient as string);
                                        }} />
                                </>}
                        </div>
                    );
                },
            }
        ];

        return <div className='flex-1' style={{ marginTop: -14 }}>
            <Spin spinning={state.loading}>
                <Table className='collapse-table hover-none'
                    rowClassName={(record) =>
                    {
                        const medicalSessionRowClass = !record.medicalExamId ? 'patient-record-grid-session' : 'patient-record-grid-exam';
                        const rowKey = !record.medicalExamId ? `queue_${ record.designationQueueId }` : `medical_exam_${ record.medicalExamId }`;
                        return (rowKey === selectedRowKey ? 'selected-row ' : '') + `${ medicalSessionRowClass }`;
                    }}
                    columns={columns}
                    dataSource={state.medicalExamination}
                    rowKey={record => !record.medicalExamId ? `queue_${ record.designationQueueId }` : `medical_exam_${ record.medicalExamId }`}
                    expandable={{
                        expandIcon: ({ expanded, onExpand, record }) =>
                        {
                            if (record.medicalExamId) return <div className='h-[26px] w-[22px]' />;
                            return expanded ?
                                <div className='flex items-center justify-center' onClick={(e) => onExpand(record, e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        height="18"><path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path></svg>
                                </div>
                                :
                                <div className='flex items-center justify-center' onClick={(e) => onExpand(record, e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        height="18"><path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path></svg>
                                </div>;
                        },
                    }}
                    onRow={(record) =>
                    {
                        return {
                            onClick: (e) =>
                            {
                                // nếu là record exam thì mới fill vào form, còn nếu là record về session thì thôi
                                if (!record.medicalExamId)
                                {
                                    setSelectedRowKey(!record.medicalExamId ? `queue_${ record.designationQueueId }` : `medical_exam_${ record.medicalExamId }`);

                                }
                            }
                        };
                    }}
                    pagination={false}
                /> <Pagination
                    style={{ marginTop: '16px' }}
                    className="text-center"
                    current={currentPage}
                    pageSize={currentPageSize}
                    total={totalRecord}
                    onChange={handlePageChange}
                />
            </Spin>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>;
    };






    return (
        <div className='body-patient-record flex flex-1 flex-col' style={{ height: '440px' }}>
            <HeaderExaminationHistory />
            <HeaderFilterPatientRecord formRefFilter={formRefFilter}
                onLoadDataGrid={onLoadDataGrid}
                isFilterByDate />
            {renderGrid()}
        </div>
    );
}

export default ExaminationHistory;

