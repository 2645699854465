import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { call, fork, put, take } from 'redux-saga/effects';

import authApi from '../../apis/api/authApi';
import { useAppSelector } from '../../hooks/hook';
import { ApiResponse, ListResponse } from '../../types/api.type';
import { userAccountLogin } from '../../types/config/userAccountLogin';
import { ApiUtil } from '../../utils/ApiUtil';
import { authAction, LoginPayload, LogoutPayload, selectDeviceToken } from './AuthSlice';


function* handleLogin(payload: LoginPayload)
{
    const response: ListResponse<ApiResponse<userAccountLogin>> = yield call(authApi.loginToken, payload);
    if (response.data.success)
    {
        ApiUtil.ToastSuccess('Đăng nhập thành công!');
        const url = (response.data.results?.roles?.indexOf('SUPER_ADMIN') != -1) ? '/clinic' : '/patient-record';
        yield put(push(url));
        return true;
    }
    else
    {
        ApiUtil.ToastError(response.data.message);
        return false;
    }
}

function* handleLogout(payload: string)
{
    const response: ListResponse<ApiResponse> = yield call(authApi.logout, payload);
    if (response.data.success)
    {
        yield put(push('/'));
    }

}

function* watchLoginFlow()
{
    while (true)
    {
        //Check điều kiện đã login hay chưa
        const isLoggedIn = Boolean(Cookies.get('dev2earn-clinic'));
        let resultLogin = false;
        if (!isLoggedIn)
        {

            const action: PayloadAction<LoginPayload> = yield take(authAction.login.type);
            resultLogin = yield call(handleLogin, action.payload);
        }
        else
        {
            resultLogin = true;
        }
        if (resultLogin)
        {
            // Perform any other neccessary action with the token
            const action: PayloadAction<string> = yield take(authAction.loadDeviceToken.type);
            yield take(authAction.logout.type);
            // yield fork(handleLogout);
            yield call(handleLogout, action.payload); // Không dùng fork chỗ này vì nó là non-blocking
        }

    }
}

export function* authSaga()
{
    yield fork(watchLoginFlow);
}