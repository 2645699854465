import { ColDef } from 'ag-grid-community';
import { Col, Form, Row } from 'antd';
import { FunctionComponent } from 'react';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm from '../../components/FormFields/BaseForm';
import TextField from '../../components/FormFields/TextField';
import { GetInfoPay } from '../../types/PaymentClinic/GetInfoPay';
import { HistoryPaymentColumns, HistoryPaymentDto } from '../../types/PaymentClinic/HistoryPayment';


interface IHistoryPaymentFormProps
{
    onClose: () => void,
    defaultvalue?: GetInfoPay | null
}



const UnitDetailForm: FunctionComponent<IHistoryPaymentFormProps> = (props) =>
{
    const { onClose, defaultvalue } = props;

    return (
        <BaseForm
            onSubmit={(value) => { }}
            className="p-3"
            defaultValues={defaultvalue ? defaultvalue : {}}
        >
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField
                            name={'totalUnitFromBalance'}
                            label={'Lượt khám tài khoản điểm'}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField
                            name={'totalUnitFromMKC'}
                            label={'Lượt khám khuyến mãi'}
                            disabled={true}

                        />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'ĐÓNG'}
                    onClick={() => { onClose(); }}
                    buttonType="close"
                />
            </div>
        </BaseForm>

    );
};
export default UnitDetailForm;



