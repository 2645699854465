import { Select, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import React from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { IComboBox } from '../../../components/FormFields/ComboBox';

interface IHeaderProps
{
    onOpenCreate: () => void;
    onChange: (value: string) => Promise<void>;
    valueOptions: string
    options?: IComboBox[];

}

const HeaderDiseaseAnalysis = (props: IHeaderProps) =>
{
    const { options, onChange, valueOptions } = props;

    const tooltipValue = options?.join(', ');

    const renderToolTip = () =>
    {
        return <Tooltip placement="topLeft" title={tooltipValue} />;
    };
    return (
        <div className="flex w-full mt-6 ">
            <div className=' text-xl font-bold text-[#2c999c]  ml-3' style={{ width: 390 }}>
                CẤU HÌNH MÔ TẢ KQ/ PHÂN TÍCH BỆNH
            </div>
            <div className='flex-1 flex mr-3 ml-2'>
                <div className='w-full mr-3 flex'>
                    <div className='mr-1 mt-2' style={{ width: 140 }}>
                        <p className='font-bold'>Chọn chỉ định khám</p>
                    </div>
                    <div className='flex-1'>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value) => { onChange(value); }}
                            showSearch={true}
                            onMouseEnter={renderToolTip}
                            value={valueOptions}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } >
                            {options?.map(item =>
                            {
                                return <Option key="1" value={item.value}>{item.label}</Option>;
                            })}

                        </Select>
                    </div>
                </div>
                <div className='order-last mr-2' style={{ width: 165 }}>
                    <ButtonBase style={{ width: 165 }}
                        disabled={!valueOptions ? true : false}
                        onClick={props.onOpenCreate}
                        buttonName={'THÊM MÔ TẢ'}
                        buttonType={'create'} />
                </div>
            </div>

        </div>
    );
};

export default HeaderDiseaseAnalysis; 