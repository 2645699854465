import { ColDef } from 'ag-grid-community';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import React from 'react';

import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import { MedicineResultScanColumns, MedicneResultDto } from '../../../types/ExamResult/MedicineResult';

interface Props
{
    medicineResult: MedicneResultDto
    componentRef: React.RefObject<HTMLDivElement>

}

const ViewMedicineResultFormScan: React.FC<Props> = (props) =>
{
    const { medicineResult, componentRef } = props;
    const [height, setHeight] = useState<number>(0);


    useEffect(() =>
    {
        onSetHeightGrid();
    }, []);

    const onSetHeightGrid = () =>
    {
        const valueTotal = medicineResult.medicineResultGrid?.length as number;
        let numberMultip = Math.floor((valueTotal) / 10);
        if (numberMultip < 1) numberMultip = 1;
        const numberResidual = (valueTotal) % 10;
        if (numberResidual > 0 && numberMultip > 1) numberMultip++;
        const heightNew = 950 * numberMultip;
        console.log('🚀 ~ file: MedicineResultFormScan.tsx:42 ~ heightNew:', heightNew);
        setHeight(heightNew);
    };

    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...MedicineResultScanColumns,
        ];
    };


    const renderGrid = () =>
    {
        return <div className='flex flex-col'
            style={{ marginRight: '20px', marginLeft: '20px', width: '420px' }}
        >
            {medicineResult.noteMedicine ? <div className='flex justify-between' style={{ height: 50 }}>
                <div className="ml-6 flex aligns-center items-center" style={{ width: '500px' }}>
                    <p className='font-bold'>Ghi chú đơn thuốc:  </p>
                    <p className='ml-1'> {medicineResult.noteMedicine}</p>
                </div>
            </div> : <div style={{ height: 5 }}></div>}
            <div style={{ height: '595px', padding: '5px', width: '420px', borderWidth: '1px' }}>
                <BaseGrid
                    className="ag-theme-alpine"
                    columnDefs={getColumnConfig()}
                    pagination={false}
                    suppressRowTransform={false}
                    suppressClickEdit={false}
                    rowData={medicineResult.medicineResultGrid}
                    enableBrowserTooltips={false}
                    onGridReady={(event) => event.api.sizeColumnsToFit()}
                    overlayNoRowsTemplate={'Không có dữ liệu!'}
                />
            </div>
        </div>;
    };

    const renderItems = () =>
    {
        return <div className='flex flex-col'
            style={{}}
        >
            <div className="row m-0">
                <div className='text-center ' style={{ fontSize: '14pt' }}>&nbsp;</div>
            </div>
            <div style={{ padding: '5px' }}>
                {medicineResult.medicineResultGrid?.map((item, index) => (
                    <div key={index} className='row'>
                        <div className='display-block' style={{ fontWeight: 'bold', }}>{index + 1}. {item.medicineName}</div>
                        <div className='display-block' style={{ fontStyle: 'italic', }}>- Số lượng: {item.quantity}</div>
                        <div className='display-block' style={{ fontStyle: 'italic', }}>- Cách dùng: {item.noteUseMedicinePlus}</div>
                        <div className='display-block'></div>
                    </div>
                ))}
            </div>
            {medicineResult.noteMedicine ? <div className='flex justify-between' style={{}}>
                <div className="row m-0">
                    <div className='text-center ' style={{ height: '48px' }}>&nbsp;</div>
                </div>
                <div className="flex aligns-center items-center" style={{ width: '100%' }}>
                    <p className='font-bold'>Chú ý:  </p>
                    <p className='ml-1' style={{ fontStyle: 'italic', }}> {medicineResult.noteMedicine}</p>
                </div>
            </div> : <div style={{ height: 5 }}></div>}
        </div>;
    };
    return (
        <div
            className='overflow-y-hidden'
            ref={componentRef}
            style={{ backgroundColor: '#fff', marginRight: '20px', marginLeft: '20px', paddingTop: 20, paddingBottom: 120 }}
        >
            <div
                style={{}}
                className='flex flex-col'>

                {/* <HeaderMedicineResult
                    isScan={false}
                    infoPatientExam={medicineResult?.infoPatient as InfoPatientFileResulExamDto}
                /> */}
                {/* <div className="row m-0">
                    <div className='text-left ' style={{ fontWeight: 'bold', fontSize: '13pt' }}>Phòng khám đa khoa</div>
                    <div className='text-left display-block' style={{}}>Mã PK: VNM-0001</div>
                    <div className='text-left display-block' style={{}}>Địa chỉ: VNM-0001</div>
                </div> */}
                <div className="row m-0">
                    <div className='text-center ' style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '18pt' }}>ĐƠN THUỐC</div>
                </div>
                <div className="row m-0">
                    <div className="col-12 text-start">
                        <div className="row">
                            <span className="" style={{}}>Họ và tên: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.fullName}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Giới tính: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.gender}</span>
                            <span className="" style={{}}>&nbsp;&nbsp;</span>
                            <span className="" style={{}}>Năm sinh: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.yearOfBirth}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Địa chỉ: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.location}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Lý do khám: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.reason}</span>
                        </div>
                        {/* <div className="row">
                            <span className="" style={{}}>Chỉ định: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.designationMedicalExamName}</span>
                        </div> */}
                    </div>
                </div>
                {renderItems()}
            </div>
        </div>

    );
};

export default ViewMedicineResultFormScan;