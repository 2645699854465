import PropTypes from 'prop-types';
import React from 'react';

interface ChangeIconIconData
{
    size?: string
    color?: string

}

export const ChangeIcon: React.FC<ChangeIconIconData> = (props) =>
{
    const { size = '36', color = 'white' } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={size}
            height={size}
            fill={color}><path d="M18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z"></path></svg>
    );
};

ChangeIcon.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};
