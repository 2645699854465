import { fork, take } from 'redux-saga/effects';

import { clinicAction } from './ClinicSlice';



function* setNotification()
{
    yield take(clinicAction.loadNotification.type);
}

// function* watchLoginFlow()
// {
//     while (true)
//     {
//         //Check điều kiện đã login hay chưa
//         const isLoggedIn = Boolean(Cookies.get('dev2earn-clinic'));
//         let resultLogin = false;
//         if (!isLoggedIn)
//         {
//             const action: PayloadAction<LoginPayload> = yield take(authAction.login.type);
//             resultLogin = yield call(handleLogin, action.payload);
			
//         }
//         else {
//             resultLogin = true;
//         }
//         if(resultLogin)
//         {
//             yield take(authAction.logout.type);
//             // yield fork(handleLogout);
//             yield call(handleLogout); // Không dùng fork chỗ này vì nó là non-blocking
//         }
       
//     }
// }

export function* clinicSaga()
{
    yield fork(setNotification);
}