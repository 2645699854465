import React, { FunctionComponent } from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';


interface IState
{
    handleSubmit: () => void;
    onClose: () => void;
    serviceName: string;
}

const ExtraServiceUsage: FunctionComponent<IState> = (props) => {
    const {handleSubmit, onClose, serviceName} = props;
    return (
        <div className={'p-4'}>
            <div className={'text-md font-medium'}>Bạn có muốn kết thúc luồng khám hiện tại và thực hiện thêm dịch vụ dưới đây?</div>
            <h1 className={'text-2xl font-semibold'}>Dịch vụ: {serviceName}</h1>
            <div className={'flex items-center justify-center gap-x-8'}>
                <ButtonBase buttonName={'Đồng ý'} 
                    buttonType={'button_custom'}
                    backgroudColorCustom={'#2C999C'}
                    onClick={() => handleSubmit()}
                />
                <ButtonBase buttonName={'Đóng'}
                    buttonType={'button_custom'}
                    backgroudColorCustom={'#C2C5CB'}
                    onClick={() => onClose()}
                />
            </div>
        </div>
    );
};

export default ExtraServiceUsage;