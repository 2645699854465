import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {ColDef, ICellRendererParams} from 'ag-grid-community';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';

import AxiosClient, {Axios} from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, {ModalRef} from '../../../components/CustomModal/CustomModal';
import {BaseFormRef} from '../../../components/FormFields/BaseForm';
import {IComboBox} from '../../../components/FormFields/ComboBox';
import Overlay, {OverlayRef} from '../../../components/Overlay/Overlay';
import {ADD_TO_SUCCESS, APPROVE_REQUEST_SENT, UPDATE_TO_SUCCESS} from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import ClinicCreateForm from '../../../module/Clinic/ClinicCreateForm';
import ClinicDetailForm from '../../../module/Clinic/ClinicDetailForm';
import {ApiResponse} from '../../../types/api.type';
import {Clinic} from '../../../types/Clinic';
import {ClinicGridColumns} from '../../../types/Clinic/Clinic';
import {ClinicCreateDto, ClinicFilterDto, ClinicGridDto} from '../../../types/Clinic/ClinicGridDto';
import {ApiUtil, BASE_API_PATH} from '../../../utils/ApiUtil';
import {comboDistrict, comboProvince} from '../../Infomation/api/constants';
import {
    createClinic,
    createClinicPartner,
    indexAllClinic,
    indexAllClinicFilter,
    requestApproveClinic,
    updateClinic
} from '../api/constants';
import HeaderFilterClinic from './HeaderFilterClinic';

interface IState
{
    clinic: ClinicGridDto[];
    loading?: boolean;
    optionsProvince: IComboBox[]
}

interface IProps {
    role?: 'PARTNER' | 'SUPER_ADMIN'
}
const ClinicView = (props: IProps) =>
{
    const {role = 'SUPER_ADMIN'} = props;
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const formRefFilter = useRef<BaseFormRef>(null);

    const [state, setState] = useMergeState<IState>({
        clinic: [],
        loading: true,
        optionsProvince: []
    });
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>([]);

    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
        Axios.all([
            await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince),
            await AxiosClient.get<ApiResponse<ClinicGridDto>>(BASE_API_PATH + indexAllClinic)

        ]).then(axios.spread((resComboProvince, resClinic) =>
        {
            let optionsProvince: IComboBox[] = [];
            let allClinic: ClinicGridDto[] = [];

            if (resComboProvince.data.success)
            {
                optionsProvince = resComboProvince.data.results as unknown as IComboBox[];
            }
            if (resClinic.data.success)
            {
                allClinic = resClinic.data.results as unknown as ClinicGridDto[];
            }
            setState({
                loading: false,
                clinic: allClinic,
                optionsProvince: optionsProvince
            });
            overlayRef.current?.close();
        }));


    };


    const onLoadAllClinic = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<ClinicGridDto>>(BASE_API_PATH + indexAllClinic);
        let allClinic: ClinicGridDto[] = [];
        if (response.data.success)
        {
            allClinic = response.data.results as unknown as ClinicGridDto[];
        }
        setState({
            ...state,
            clinic: allClinic,
        });
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const CLINIC_STATUS = {
        UNAPPROVED: 'Chưa phê duyệt',
        PENDING: 'Chờ phê duyệt',
        APPROVED: 'Đã phê duyệt',
        REJECT: 'Bị từ chối',
    };


    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...ClinicGridColumns,
            {
                headerName: 'Dung lượng',
                headerClass: 'text-center',
                cellStyle: { 'text-align': 'end' },
                width: 150,
                maxWidth: 200,
                cellRenderer: (params: ICellRendererParams<ClinicGridDto>) => {
                    const data = params.data as ClinicGridDto;
                    return <div>{Math.round((data.usedSize || 0)/(data.totalSize || 1))*100}%</div>;
                }
            },
            {
                headerName: 'Trạng thái',
                headerClass: 'text-center',
                cellStyle: { 'text-align': 'end' },
                width: 150,
                maxWidth: 200,
                cellRenderer: (params: ICellRendererParams<ClinicGridDto>) => {
                    const data = params.data as ClinicGridDto;

                    if (role === 'PARTNER') return <div>{CLINIC_STATUS[data.status]}</div>;
                    return <div>{data.status === 'PENDING' || data.status === 'REJECT' ? CLINIC_STATUS[data.status] : ''}</div>;
                }
            },
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<ClinicGridDto>) =>
                {
                    const data = params.data as ClinicGridDto;
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <GridButtonBase type={'edit'}
                                onClick={() => { HandleOpenUpdate(data); }} />
                            <GridButtonBase type={'detail'}
                                onClick={() =>
                                {
                                    HandleDetailUpdate(data as Clinic);
                                    //openDetail(data?.medicalExamId as unknown as number, data.datePatient as string);
                                }} />
                            {role === 'PARTNER' && (data.status === 'UNAPPROVED' || data.status === 'REJECT') && <GridButtonBase type={'request_approve'}
                                onClick={() => {
                                    HandleRequestApprove(data.id as string | number);
                                }}/>}
                            {/* <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { onHandleDelete(data.id as number); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm> */}

                        </div>
                    );
                },
            },

        ];
    };
    const onFilter = async () =>
    {
        overlayRef.current?.open();
        const dataFilter = formRefFilter.current?.getValues();
        const params: ClinicFilterDto = {
            ...dataFilter
        };
        const response = await AxiosClient.post<ApiResponse<ClinicGridDto>>(BASE_API_PATH + indexAllClinicFilter, params);
        let allClinic: ClinicGridDto[] = [];
        if (response.data.success)
        {
            allClinic = response.data.results as unknown as ClinicGridDto[];
            setState({
                ...state,
                clinic: allClinic
            });
        }
        overlayRef.current?.close();

    };

    const loadOptionsDistrict = async (value: string) =>
    {
        if (value)
        {
            const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
            if (response.data.success)
            {
                const data = response.data.results as unknown as IComboBox[];
                setOptionsDistrict(data);
            }
        }
        else 
        {
            setOptionsDistrict([]);
        }

    };


    const renderGrid = () =>
    {
        return <div className='mt-2' style={{ height: '80%' }}>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                columnDefs={getColumnConfig()}
                suppressRowTransform={true}
                pagination={true}
                suppressClickEdit={true}
                rowData={state.clinic}
            />
        </div>;
    };

    const onCreateClinic = async (value: ClinicCreateDto) =>
    {
        const params = {
            ...value
        };
        const response = await AxiosClient.post<ApiResponse<ClinicGridDto>>(BASE_API_PATH + (role === 'PARTNER' ? createClinicPartner : createClinic), params);
        if (response.data.success)
        {
            await onLoadAllClinic();
            ApiUtil.ToastSuccess(ADD_TO_SUCCESS);
            modalRef.current?.onClose();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);

        }
    };

    const onUpdateClinic = async (value: ClinicCreateDto) =>
    {
        const params = {
            ...value,
            districtId: value.districtId ? parseInt(value.districtId) : null,
            provinceId: value.provinceId ? parseInt(value.provinceId) : null
        };
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + updateClinic, params); //x
        if (response.data.success)
        {
            await onLoadAllClinic();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            modalRef.current?.onClose();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);

        }
    };

    const HandleOpenCreate = () =>
    {
        modalRef.current?.onOpen(<ClinicCreateForm optionsProvince={state.optionsProvince}
            handleSubmit={onCreateClinic}
            onClose={onCloseModal} />, 'Thêm phòng khám', 50);
    };

    const HandleOpenUpdate = (value: Clinic) =>
    {
        const defaultValue: ClinicCreateDto = {
            id: value.id as number,
            userName: value.phone?.toString() as string,
            address: value.address as string,
            fullAddress: value.fullAddress as string,
            name: value.name as string,
            districtId: value.districtId as string,
            provinceId: value.provinceId as string,
            introduce: value.introduce as string,
            code: value.code as string
        };
        modalRef.current?.onOpen(<ClinicCreateForm optionsProvince={state.optionsProvince}
            handleSubmit={onUpdateClinic}
            defaultValue={defaultValue}
            onClose={onCloseModal} />, 'Cập nhật phòng khám', 50);
    };

    const HandleDetailUpdate = (value: Clinic) =>
    {

        modalRef.current?.onOpen(<ClinicDetailForm
            role={role}
            defaultValue={value}
            optionsProvince={state.optionsProvince}
            onClose={onCloseModal}
            onLoadAllClinic={onLoadAllClinic}/>, 'Chi tiết phòng khám', 90);
    };

    const HandleRequestApprove = async (value: number | string) =>
    {
        const response = await AxiosClient.put<ApiResponse<ClinicGridDto>>(BASE_API_PATH + requestApproveClinic + `/${value}`);
        if (response.data.success)
        {
            await onLoadAllClinic();
            ApiUtil.ToastSuccess(APPROVE_REQUEST_SENT);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);

        }
    };

    const ClinicHeader = () =>
    {
        return <HeaderFilterClinic
            onLoadDataGrid={onFilter}
            optionsProvince={state.optionsProvince}
            onOpenCreate={HandleOpenCreate}
            loadOptionsDistrict={loadOptionsDistrict}
            optionsDistrict={optionsDistrict}
            formRefFilter={formRefFilter}
        />;
    };


    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='h-full'>
                {ClinicHeader()}
                {renderGrid()}
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default ClinicView;