import '../style/patient-record-view.scss';

import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Button, message, Pagination, Popconfirm, Table, Tabs } from 'antd';
import axios from 'axios';
import _, { isNumber } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { MESSAGE_EXPIRED } from '../../../constants/MessageConstants';
import { pathMedicine } from '../../../constants/SvgIcon';
import useMergeState from '../../../hooks/useMergeState';
import MedicineResultForm from '../../../module/ExamResult/MedicineResult/MedicineResultForm';
import ResultView from '../../../module/ExamResult/Result/ResultView';
import { getBeingVitalByUser, getVitalRecord, startVital } from '../../../module/MeasuringVitalSign/api/constants';
import MeasuringVitalSign from '../../../module/MeasuringVitalSign/MeasuringVitalSign';
import ModalDetailVital from '../../../module/MeasuringVitalSign/ModalDetailVital';
import PatientRecordHeaderForm from '../../../module/PatientRecord/PatientRecordHeaderForm';
import { ApiResponse, ApiResponsePage } from '../../../types/api.type';
import { MedicalExaminationPatientRecordFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';
import { MedicalExaminationGridDto } from '../../../types/MedicalExamination/MedicalExaminationGridDto';
import { PatientRecordHeaderDto } from '../../../types/MedicalExamination/PatientRecordHeaderDto';
import
{
    DesignationQueueItem,
    InformationBeingExam,
    InformationBeingSession,
    InformationPatientExam,
    PatientFileRecord
} from '../../../types/PatientRecord/PatientFileRecord';
import { PatientRightList } from '../../../types/PatientRecord/PatientRightList';
import { PatientWaitingGridDto } from '../../../types/PatientWaiting/PatientWaitingGridDto';
import { SessionDataDTO, StartSessionBody } from '../../../types/Session/StartSessionBody';
import { MedicineGroupCombo, MedicineNoteCombo } from '../../../types/share/ComboOption';
import { VitalMeasure } from '../../../types/VitalSign/VitalMeasure';
import { VitalSignGridDto } from '../../../types/VitalSign/VitalSignGridDto';
import { VitalSignRecordSetting } from '../../../types/VitalSign/VitalSignRecordSetting';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { UrlDev } from '../../../utils/AppUtil';
import { loadComboDesignationMedicalExamByServiceType } from '../../DiseaseAnalysis/api/constants';
import ExamProcessLayout from '../../ExaminationProcess/page/ExamProcessLayout';
import { comboDistrict, comboProvince, deductPayment } from '../../Infomation/api/constants';
import { indexPatientWaiting, removePatientDay, successPatientDay, updatePatientDay } from '../../PatientWaiting/api/constants';
import
{
    GET_MEDICINE_GROUP,
    GET_MEDICINE_NOTE_USE,
    getInfoBeingSession,
    getInfomationWaiting,
    getListSession,
    queueItemList,
    startExam,
    startSession
} from '../api/constants';
import CurrentPatient from './CurrentPatient';
import HeaderFilterPatientRecord from './HeaderFilterPatientRecord';

const { TabPane } = Tabs;
interface IState
{
    medicalExamination: SessionDataDTO[];
    optionsProvince: IComboBox[];
    medicineGroupCombo: MedicineGroupCombo[],
    medicineNoteUseCombo: MedicineNoteCombo[],
    infoBeingSession?: InformationBeingSession | null,
    infoBeingExam?: InformationBeingExam | null,
    infoPatientExam?: InformationPatientExam | null,
    loading?: boolean;
    flowValue?: number
    valueSelected?: PatientRecordHeaderDto;
    isMeasuring?: boolean;
    vitalRecord: VitalSignGridDto[] | undefined;
    patientDayRecord?: PatientFileRecord[]
}

interface IStateConnection
{
    connectionWaiting: null | HubConnection;
    connectionBeingExam: null | HubConnection;
    connectionVitalSign: null | HubConnection;
}
interface IProps
{
    clinicId?: number,
    onLoadInfoPay: () => Promise<void>
}

const PatientRecordView = (props: IProps) =>
{
    const { clinicId, onLoadInfoPay } = props;
    const overlayRef = useRef<OverlayRef>(null);

    const gridOptions: GridOptions<MedicalExaminationGridDto> = {};
    const formRefFilter = useRef<BaseFormRef>(null);
    const formRefHeader = useRef<BaseFormRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const modalDetailRef = useRef<ModalRef>(null);
    // const [isUsingAddon, setUsingAddon] = useState<boolean>(false);
    const [listService, setListService] = useState<DesignationQueueItem[]>([]);
    const [designationQueueId, setDesignationQueueId] = useState<number | null>(null);
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>([]);
    const [optionsDesignMedical, setOptionsDesignMedical] = useState<IComboBox[]>([]);
    const [listAddonId, setListAddonId] = useState<string[]>([]);
    const [state, setState] = useMergeState<IState>({
        medicalExamination: [],
        optionsProvince: [],
        medicineGroupCombo: [],
        medicineNoteUseCombo: [],
        infoBeingSession: null,
        infoBeingExam: null,
        infoPatientExam: null,
        loading: true,
        // flowValue: 1,
        valueSelected: {},
        isMeasuring: false,
        vitalRecord: [],
        patientDayRecord: []
    });

    const [stateConnect, setStateConnect] = useMergeState<IStateConnection>({
        connectionWaiting: null,
        connectionBeingExam: null,
        connectionVitalSign: null,
    });
    const [patientWaitingList, setPatientWaitingList] = useState<PatientRightList[]>([]);
    const [patientBeingExamList, setPatientBeingExamList] = useState<PatientRightList[]>([]);
    const [vitalSignMeasure, setVitalSignMeasure] = useState<VitalMeasure[]>([]);

    const [selectedRowKey, setSelectedRowKey] = useState('');
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    // pagination table
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const onFinishHeaderForm = useCallback(async () =>
    {
        // if (isUsingAddon)
        // {
        //     await handleAddonService();
        //     await loadGridData();
        // }
        // else
        // {
        localStorage.setItem('patientInfoValue', JSON.stringify(formRefHeader.current?.getValues()));
        // onHandleStartExam(numberFlow.current);
        if (selectedTags?.length === 0)
        {
            ApiUtil.ToastError('Vui lòng chọn ít nhất 1 chỉ định khám');
        }
        else
        {
            onStartSession(selectedTags?.map(item => Number(item)));
        }
        // set thêm state về thông tin người bệnh để hiển thị ở form
        // }
    }, [JSON.stringify(selectedTags)]);

    const handlePageChange = (pageNumber: number, pageSize: number) =>
    {
        setPage(pageNumber);
        setLimit(pageSize);
    };

    const getColumnVitalConfig = (): ColDef[] =>
    {
        return [
            ...VitalSignRecordSetting,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<any>) =>
                {
                    const data = params.data as any;
                    const onOpenCreate = () =>
                    {
                        modalDetailRef.current?.onOpen(<ModalDetailVital id={data.vitalSignMeasureId} onCloseModal={onCloseModal} />, 'Chỉ số đã đo', 30);
                    };
                    const onCloseModal = () =>
                    {
                        modalDetailRef.current?.onClose();
                    };
                    return (
                        <div className="flex items-center justify-center">
                            <Button type='link' onClick={onOpenCreate}>Chi tiết</Button>
                        </div>
                    );
                },
            },
        ];
    };

    const getQueueItem = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<DesignationQueueItem[]>>(BASE_API_PATH + queueItemList + designationQueueId, { withCredentials: true });
        if (response.data.results)
        {
            setListService(response.data.results);
        }
    };

    useEffect(() =>
    {
        if (designationQueueId)
        {
            getQueueItem();
        }
    }, [designationQueueId]);

    const openDetail = (medicalExamId: number, datePatient: string) =>
    {
        modalRef.current?.onOpen(<ResultView datePatient={datePatient} medicalExaminationId={medicalExamId} />, 'Kết quả khám', 90);
    };

    const openMedicine = (medicalExamId: number, datePatient: string) =>
    {
        modalRef.current?.onOpen(<MedicineResultForm datePatient={datePatient} medicalExaminationId={medicalExamId} />, 'Đơn thuốc', 90);
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const getBeingVital = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + getBeingVitalByUser, { withCredentials: true });
        if (response.data.results)
        {
            setState({ ...state, isMeasuring: true });
        } else
        {
            setState({ ...state, isMeasuring: false });
        }
    };

    useEffect(() =>
    {
        loadApi(page, limit);
        getBeingVital();
        const connection = new HubConnectionBuilder()
            .withUrl(UrlDev + '/waiting', {
                skipNegotiation: true,
                withCredentials: true,

                transport: HttpTransportType.WebSockets
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        const connectionBeginExam = new HubConnectionBuilder()
            .withUrl(UrlDev + '/patient-being-exam', {
                skipNegotiation: true,
                withCredentials: true,
                transport: HttpTransportType.WebSockets
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        const connectionVitalSign = new HubConnectionBuilder()
            .withUrl(UrlDev + '/hub/vitalsign', {
                skipNegotiation: true,
                withCredentials: true,
                transport: HttpTransportType.WebSockets
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        setStateConnect({
            connectionWaiting: connection,
            connectionBeingExam: connectionBeginExam,
            connectionVitalSign: connectionVitalSign
        });
    }, [page, limit]);

    useEffect(() =>
    {
        if (clinicId)
        {
            if (stateConnect.connectionWaiting)
            {
                stateConnect.connectionWaiting
                    .start()
                    .then(() =>
                    {
                        loadDataWaiting();
                    })
                    .catch((error) => console.log(error));
            }
            if (stateConnect.connectionBeingExam)
            {
                stateConnect.connectionBeingExam
                    .start()
                    .then(() =>
                    {
                        console.log('===============> connectionBeingExam start connect');
                        loadDataBeingExam();
                    })
                    .catch((error) => console.log(error));
            }
            if (stateConnect.connectionVitalSign)
            {
                console.log('===============> connectionVitalSign start connect');
                stateConnect.connectionVitalSign
                    .start()
                    .then(() =>
                    {
                        console.log('================================> connectionVitalSign start');
                        initEventResult();
                    })
                    .catch((error) => console.log(error));
            }
        }


    }, [clinicId, stateConnect.connectionWaiting, stateConnect.connectionBeingExam, stateConnect.connectionVitalSign]);

    useEffect(() =>
    {
        getListServiceComboBoxByType();
        const currentData = formRefHeader.current?.getValues();
        formRefHeader.current?.setValues({ ...currentData, designationMedicalExamId: null });
    }, []);

    const getListServiceComboBoxByType = async () =>
    {
        try
        {
            const resAddon = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadComboDesignationMedicalExamByServiceType + '?group=1', { withCredentials: true });
            const resService = await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + loadComboDesignationMedicalExamByServiceType + '?group=0', { withCredentials: true });
            if (resService.data.success && resAddon.data.success)
            {
                setOptionsDesignMedical((resService.data.results as unknown as IComboBox[]).concat(resAddon.data.results as unknown as IComboBox[]));
                setListAddonId((resAddon.data.results as unknown as IComboBox[]).map(item => item.value));
            }
        } catch (error: any)
        {
            ApiUtil.ToastWarning(MESSAGE_EXPIRED);
        }
    };

    const getFlatSessionChildren = (response: SessionDataDTO[]): SessionDataDTO[] =>
    {
        return response.map((sessionData) => ({
            ...sessionData, children: sessionData.listMedicalExam.map(item => ({
                ...item,
                totalAmount: sessionData.totalAmount
            }))
        }));
    };

    const loadApi = async (pageParam?: number, sizeParam?: number) =>
    {
        overlayRef.current?.open();
        const filterValue = formRefFilter?.current?.getValues();

        const params = {
            textSearch: filterValue?.fullNameOrPhoneorLocation ?? null,
            fromDate: filterValue?.timeRange ? filterValue?.timeRange[0].format('DD/MM/YYYY') : null,
            toDate: filterValue?.timeRange ? filterValue?.timeRange[1].format('DD/MM/YYYY') : null,
            page: pageParam ?? 1,
            size: sizeParam ?? 10
        };

        const paramsPatientDay = {
            fullNameOrPhone: '',
            isCurrentDate: true

        };
        try
        {
            const dataFilter = {
                'fullNameOrPhoneorLocation': '',
                'isFilterByDate': false,
                'filterByDateFrom': '',
                'filterByDateTo': ''
            };
            Axios.all([
                await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvince),
                await AxiosClient.post<any>(BASE_API_PATH + getListSession, params, { withCredentials: true }),
                await AxiosClient.get<ApiResponse<InformationBeingSession>>(BASE_API_PATH + getInfoBeingSession, { withCredentials: true }),
                await AxiosClient.get<ApiResponse<MedicineGroupCombo[]>>(BASE_API_PATH + GET_MEDICINE_GROUP, { withCredentials: true }), //
                await AxiosClient.get<ApiResponse<MedicineNoteCombo[]>>(BASE_API_PATH + GET_MEDICINE_NOTE_USE, { withCredentials: true }), //
                await AxiosClient.post<ApiResponse<VitalSignGridDto[]>>(BASE_API_PATH + getVitalRecord, dataFilter, { withCredentials: true }), //
                await AxiosClient.post<any>(BASE_API_PATH + indexPatientWaiting, paramsPatientDay, { withCredentials: true }),
            ]).then(axios.spread((resComboProvince, resMedicalExaminationGrid, resInfoBeingSession, medicineGroupRes, medicineNoteUseRes, resVitalRecord, resPatientDay: any) =>
            {
                let optionsProvince: IComboBox[] = [];
                // let optionsDesign: ComboOption<string>[] = [];
                let informationBesingSession: InformationBeingSession | null = null;
                let informationBeingExam: InformationBeingExam | null = null;
                let informationPatientExam: InformationPatientExam | null = null;
                let medicalExaminationGridDto: SessionDataDTO[] = [];
                let medicineGroupCombo: MedicineGroupCombo[] = [];
                let medicineNoteUseCombo: MedicineNoteCombo[] = [];
                let vitalSignRecord: VitalSignGridDto[] = [];
                let inforPatientDay: PatientFileRecord[] = [];

                if (resComboProvince.data.success)
                {
                    optionsProvince = resComboProvince.data.results as unknown as IComboBox[];
                }
                if (resMedicalExaminationGrid.data.success)
                {
                    medicalExaminationGridDto = resMedicalExaminationGrid.data.results?.content as unknown as SessionDataDTO[];
                    setCurrentPage(resMedicalExaminationGrid.data.results?.page as unknown as number);
                    setCurrentPageSize(resMedicalExaminationGrid.data.results?.size as unknown as number);
                    setTotalRecord(resMedicalExaminationGrid.data.results?.totalRecord as unknown as number);
                }
                if (resInfoBeingSession.data.success)
                {
                    informationBesingSession = resInfoBeingSession.data.results as unknown as InformationBeingSession;
                    informationBeingExam = informationBesingSession?.infoBeginExam as unknown as InformationPatientExam;
                    informationPatientExam = informationBesingSession?.infoBeingPatient as unknown as InformationPatientExam;
                    setDesignationQueueId(informationBesingSession?.designationQueueId);
                }
                if (medicineGroupRes.data.success)
                {
                    medicineGroupCombo = medicineGroupRes.data.results as unknown as MedicineGroupCombo[];
                }
                if (medicineNoteUseRes.data.success)
                {
                    medicineNoteUseCombo = medicineNoteUseRes.data.results as unknown as MedicineNoteCombo[];
                }

                if (resVitalRecord.data.success)
                {
                    vitalSignRecord = resVitalRecord.data.results as unknown as VitalSignGridDto[];
                }
                if (resPatientDay.data.success)
                {
                    inforPatientDay = resPatientDay.data.results;

                }
                setState({
                    optionsProvince: optionsProvince,
                    medicalExamination: getFlatSessionChildren(medicalExaminationGridDto),
                    medicineGroupCombo: medicineGroupCombo,
                    // optionsDesignMedical: optionsDesign,
                    infoBeingSession: informationBesingSession,
                    infoBeingExam: informationBeingExam,
                    infoPatientExam: informationPatientExam,
                    medicineNoteUseCombo: medicineNoteUseCombo,
                    loading: false,
                    vitalRecord: vitalSignRecord,
                    patientDayRecord: inforPatientDay
                });
                overlayRef.current?.close();
            }));
        } catch (error: any)
        {
            overlayRef.current?.close();
            ApiUtil.ToastWarning(MESSAGE_EXPIRED);
        }

    };

    const reloadVitalRecord = async () =>
    {
        overlayRef.current?.open();
        const dataFilter = {
            'fullNameOrPhoneorLocation': '',
            'isFilterByDate': false,
            'filterByDateFrom': '',
            'filterByDateTo': ''
        };
        const response = await AxiosClient.post<ApiResponse<VitalSignGridDto[]>>(BASE_API_PATH + getVitalRecord, dataFilter, { withCredentials: true });
        if (response.data.results)
        {
            setState({ ...state, vitalRecord: response.data.results });
        }
        overlayRef.current?.close();
    };

    const loadInfoBeingSession = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<InformationBeingSession>>(BASE_API_PATH + getInfoBeingSession, { withCredentials: true });
        if (response.data.results)
        {
            setState({
                ...state,
                infoBeingSession: response.data.results,
                infoBeingExam: response.data.results.infoBeginExam,
                infoPatientExam: response.data.results.infoBeingPatient
            });
            setDesignationQueueId(response.data.results?.designationQueueId);
        }
    };

    const loadInfoBeingAndPatient = async (isMedicineOnly: boolean) =>
    {
        Axios.all([
            await AxiosClient.get<ApiResponse<InformationBeingSession>>(BASE_API_PATH + getInfoBeingSession, { withCredentials: true }),
        ]).then(axios.spread((infoBeingSession) =>
        {
            let infoBeingExamSet: InformationBeingExam | null = null;
            let InfoPatientExamSet: InformationBeingExam | null = null;
            if (infoBeingSession.data.success)
            {
                infoBeingExamSet = infoBeingSession.data.results?.infoBeginExam as unknown as InformationBeingExam;
                InfoPatientExamSet = infoBeingSession.data.results?.infoBeingPatient as unknown as InformationBeingExam;
            }
            setState({
                ...state,
                flowValue: infoBeingExamSet?.flowCurrent ? infoBeingExamSet?.flowCurrent : 1,
                infoBeingSession: infoBeingSession.data.results,
                infoBeingExam: infoBeingExamSet,
                infoPatientExam: InfoPatientExamSet
            });
        }));
    };

    const onEndExam = async (medicalExamId: number) =>
    {
        formRefHeader.current?.setValues({});
        await loadApi();
        await getListServiceComboBoxByType();
        await onDeductAndLoadInfo(medicalExamId);
    };



    const onDeductAndLoadInfo = async (medicalExamId: number) =>
    {
        await onDeductPayment(medicalExamId);
        await onLoadInfoPay();

    };
    const onDeductPayment = async (medicalExamId: number) =>
    {
        const params = {
            medicalExamId: medicalExamId
        };
        await AxiosClient.post<ApiResponse<InformationBeingExam>>(BASE_API_PATH + deductPayment, params, { withCredentials: true });

    };

    const loadDataWaiting = async () =>
    {
        try
        {
            stateConnect.connectionWaiting?.on('ListPatientWaiting', (waitingList: PatientRightList[]) =>
            {
                const waitingFilter = _.cloneDeep(waitingList).filter(x => x.clinicId == clinicId);
                setPatientWaitingList(waitingFilter);
            });
            await stateConnect.connectionWaiting?.invoke('PatientWaiting');
        } catch (error)
        {
            console.log('🚀 ~ file: PatientRecordView.tsx ~ line 97 ~ testSignalr ~ error', error);
        }
    };
    const loadDataBeingExam = async () =>
    {
        try
        {
            stateConnect.connectionBeingExam?.on('ListPatientBeingExamined', (waitingList: PatientRightList[]) =>
            {
                const waitingFilter = waitingList.filter(x => x.clinicId == clinicId);
                setPatientBeingExamList(waitingFilter);
            });
            await stateConnect.connectionBeingExam?.invoke('PatientBeingExamined');
        } catch (error)
        {
            console.log('🚀 ~ file: PatientRecordView.tsx ~ line 97 ~ testSignalr ~ error', error);
        }
    };

    const initEventResult = async () =>
    {
        try
        {
            stateConnect.connectionVitalSign?.on('VitalResult', (data: []) =>
            {
                setVitalSignMeasure(data);
            });
        } catch (error)
        {
            console.log('initEventResult error', error);
        }
    };
    const onLoadDataGrid = async (value: MedicalExaminationPatientRecordFilterDto) =>
    {
        overlayRef.current?.open();
        const params = {
            textSearch: value?.fullNameOrPhoneorLocation,
            fromDate: value?.timeRange ? value?.timeRange[0].format('DD/MM/YYYY') : null,
            toDate: value?.timeRange ? value?.timeRange[1].format('DD/MM/YYYY') : null,
            page: 1,
            size: 10
        };
        const response = await AxiosClient.post<ApiResponsePage<SessionDataDTO>>(BASE_API_PATH + getListSession, params, { withCredentials: true });
        if (response.data.success)
        {
            setState({
                loading: false,
                medicalExamination: getFlatSessionChildren(response.data.results?.content || []) as SessionDataDTO[]
            });
            setCurrentPage(response.data.results?.page as unknown as number);
            setCurrentPageSize(response.data.results?.size as unknown as number);
            setTotalRecord(response.data.results?.totalRecord as unknown as number);
        }
        overlayRef.current?.close();
    };

    // hàm xử lý load dữ liệu khi nhập dữ liệu tìm kiếm - tab danh sách bệnh nhân chờ khám
    const onLoadDataTablePatient = async (value: MedicalExaminationPatientRecordFilterDto) =>
    {
        overlayRef.current?.open();

        const paramsPatient = {
            fullNameOrPhone: value?.fullNameOrPhoneorLocation,
            isCurrentDate: true

        };
        const response: any = await AxiosClient.post<ApiResponsePage<SessionDataDTO>>(BASE_API_PATH + indexPatientWaiting, paramsPatient, { withCredentials: true });
        if (response.data.success)
        {
            const inforPatientDay: PatientFileRecord[] = response.data.results;
            setState({
                loading: false,
                patientDayRecord: inforPatientDay
            });

        }
        overlayRef.current?.close();
    };

    // TODO: thêm type cho params truyền vào api record
    const onLoadDataVitalGrid = async (value: any) =>
    {
        overlayRef.current?.open();

        const params: any = {
            ...value,
            filterByDateFrom: value?.timeRange ? value?.timeRange[0].format('DD/MM/YYYY') : null,
            filterByDateTo: value?.timeRange ? value?.timeRange[1].format('DD/MM/YYYY') : null
        };
        const response = await AxiosClient.post<ApiResponse<VitalSignGridDto[]>>(BASE_API_PATH + getVitalRecord, params, { withCredentials: true });
        if (response.data.success)
        {
            setState({
                loading: false,
                vitalRecord: response.data.results as VitalSignGridDto[]
            });
        }
        overlayRef.current?.close();
    };

    const loadOptionsDistrict = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
        if (response.data.success)
        {
            const data = response.data.results as unknown as IComboBox[];
            setOptionsDistrict(data);
        }
    };

    const onClickFillHeaderForm = (value: PatientRecordHeaderDto, patientWaitingId: number | null) =>
    {
        overlayRef.current?.open();
        const dataOld = formRefHeader.current?.getValues();
        const valueSetting: PatientRecordHeaderDto = {
            ...value,
            yearOfBirth: value.yearOfBirth ? moment(value.yearOfBirth, 'YYYY') as moment.Moment : '',
            genderValue: value.genderValue,
            designationMedicalExamId: dataOld?.designationMedicalExamId,
            patientWaitingId: patientWaitingId

        };
        const provinceId = valueSetting.provinceId;
        if (provinceId)
        {
            const districtId = valueSetting.districtId;
            loadOptionsDistrict(provinceId);
            onChangeProvinceId(provinceId);
            if (districtId)
            {
                onChangeDistrictId(districtId);
            }
        }
        formRefHeader.current?.setValues(valueSetting);
        setState({
            ...state,
            valueSelected: valueSetting
        });
        overlayRef.current?.close();
    };

    const onChangeProvinceId = (id: string) =>
    {
        localStorage.setItem('provinceId', id);
    };

    const onChangeDistrictId = (id: string) =>
    {
        localStorage.setItem('districtId', id);
    };


    const onClickInfomationWaiting = async (patientWaitingId: number) =>
    {
        const response = await AxiosClient.get<ApiResponse<PatientRecordHeaderDto>>(BASE_API_PATH + getInfomationWaiting + patientWaitingId);
        if (response.data.success)
        {
            const data = response.data.results as PatientRecordHeaderDto;
            onClickFillHeaderForm(data, patientWaitingId);
        }

    };

    const currencyFormater = new Intl.NumberFormat('vn-VI', {
        style: 'currency',
        currency: 'VND',
    });

    const renderGrid = () =>
    {
        const columns = [
            {},
            {
                title: 'STT',
                render: (text: any, record: any, index: number) => !record.medicalExamId ? <p className='flex m-0 p-0'>{index + 1}</p> : '',
            },
            {
                title: 'Họ và tên',
                dataIndex: 'fullName',
                render: (value: any, record: any) => !record.medicalExamId ? record.fullName : '',
                key: 'fullName',
                minWidth: 150,
            },
            {
                title: 'Năm sinh',
                dataIndex: 'yearOfBirth',
                key: 'yearOfBirth',
                maxWidth: 90,
            }
            ,
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                minWidth: 70,
            }
            ,
            {
                title: 'Giới tính',
                dataIndex: 'genderLabel',
                key: 'genderLabel',
                maxWidth: 90,
            }
            ,
            {
                title: 'Chi tiết',
                dataIndex: 'location',
                key: 'location',
                minWidth: 120,
                render: (value: any, record: any) =>
                {
                    return !record.medicalExamId ? (
                        <div className='m-0 p-0'><p className='m-0 p-0'>{record.location}</p><p className='m-0 p-0'>Lý do khám: {record.reason}</p></div>
                    ) : (
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0'>{record.designationName}</p>
                            {record?.prescriptionAmount > 0 ? <p>Đơn thuốc</p> : (<div className='display-none'></div>)}
                        </div>
                    );
                },
            },
            {
                title: 'Loại chỉ định',
                dataIndex: 'reason',
                key: 'reason',
                minWidth: 120,
                render: (value: any, record: any) =>
                {
                    return !record.medicalExamId ? (
                        <div />
                    ) : (
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0'>{record.designationType}</p>
                        </div>
                    );
                },
            },
            {
                title: 'Số tiền',
                dataIndex: 'reason',
                // render: (value: any, record: any) => !record.medicalExamId ? record.totalAmount : record.medicalExamAmount,
                render: (value: any, record: any) =>
                {
                    return !record.medicalExamId ? (
                        <div className='m-0 p-0'><p className='m-0 p-0 text-right'>{currencyFormater.format(record.totalAmount)}</p></div>
                    ) : (
                        <div className='m-0 p-0'>
                            <p className='m-0 p-0 text-right'>{currencyFormater.format(record.designationMedicalAmount)}</p>
                            {record?.prescriptionAmount > 0 ? <p className='m-0 p-0 text-right'>{currencyFormater.format(record.prescriptionAmount)}</p> : (<div className='display-none'></div>)}
                        </div>
                    );
                },
                key: 'reason',
                minWidth: 250,
            }
            ,
            {
                title: 'Thời gian khám',
                dataIndex: 'datePatient',
                render: (value: any, record: any) => !record.medicalExamId ? record.dateMedicalSession : record.datePatient,
                key: 'datePatient',
                minWidth: 150,
            },
            {
                title: 'Hành động',
                key: 'datePatient',
                render: (value: any, data: any) =>
                {
                    if (!data.medicalExamId) return null;
                    return (
                        <div className="flex items-center justify-center gap-x-4">
                            {data.isMedicineOnly ?
                                <GridButtonBase style={{ margin: 0 }}
                                    type={'medicine'}
                                    onClick={() =>
                                    {
                                        openMedicine(data?.medicalExamId as unknown as number, data.datePatient as string);
                                    }} /> :
                                <>
                                    <GridButtonBase style={{ margin: 0 }}
                                        type={'detail'}
                                        onClick={() =>
                                        {
                                            openDetail(data?.medicalExamId as unknown as number, data.datePatient as string);
                                        }} />
                                    <GridButtonBase style={{ margin: 0 }}
                                        type={'medicine'}
                                        onClick={() =>
                                        {
                                            openMedicine(data?.medicalExamId as unknown as number, data.datePatient as string);
                                        }} />
                                </>}
                        </div>
                    );
                },
            }
        ];

        return <div className='flex-1' style={{ marginTop: -14 }}>
            {/*<BaseGrid*/}
            {/*    gridOptions={gridOptions}*/}
            {/*    className="ag-theme-alpine"*/}
            {/*    rowSelection={'multiple'}*/}
            {/*    rowGroupPanelShow={'always'}*/}
            {/*    onRowClicked={(value) => { onClickFillHeaderForm(value.data as PatientRecordHeaderDto, null); }}*/}
            {/*    suppressClickEdit={true}*/}
            {/*    pivotPanelShow={'always'}*/}
            {/*    columnDefs={getColumnConfig()}*/}
            {/*    pagination={true}*/}
            {/*    rowData={state.medicalExamination}*/}
            {/*/>*/}
            <Table className='collapse-table hover-none'
                rowClassName={(record) =>
                {
                    const medicalSessionRowClass = !record.medicalExamId ? 'patient-record-grid-session' : 'patient-record-grid-exam';
                    const rowKey = !record.medicalExamId ? `queue_${ record.designationQueueId }` : `medical_exam_${ record.medicalExamId }`;
                    return (rowKey === selectedRowKey ? 'selected-row ' : '') + `${ medicalSessionRowClass }`;
                }}
                columns={columns}
                dataSource={state.medicalExamination}
                rowKey={record => !record.medicalExamId ? `queue_${ record.designationQueueId }` : `medical_exam_${ record.medicalExamId }`}
                expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                    {
                        if (record.medicalExamId) return <div className='h-[26px] w-[22px]' />;
                        return expanded ?
                            <div className='flex items-center justify-center' onClick={(e) => onExpand(record, e)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"><path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path></svg>
                            </div>
                            :
                            <div className='flex items-center justify-center' onClick={(e) => onExpand(record, e)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"><path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path></svg>
                            </div>;
                    },
                }}
                onRow={(record) =>
                {
                    return {
                        onClick: (e) =>
                        {
                            // nếu là record exam thì mới fill vào form, còn nếu là record về session thì thôi
                            if (!record.medicalExamId)
                            {
                                setSelectedRowKey(!record.medicalExamId ? `queue_${ record.designationQueueId }` : `medical_exam_${ record.medicalExamId }`);
                                onClickFillHeaderForm({
                                    id: null,
                                    parentExamId: null,
                                    fullName: record.fullName,
                                    yearOfBirth: record.yearOfBirth,
                                    genderValue: record.genderLabel === 'Nam' ? 'MALE' : 'FEMALE',
                                    phone: record.phone,
                                    location: record.location,
                                    provinceId: record.provinceId,
                                    districtId: record.districtId,
                                    isMedicineOnly: false,
                                    reason: record.reason,
                                    patientWaitingId: null,
                                    designationQueueItemId: null,
                                }, null);
                            }
                        }
                    };
                }}
                pagination={false}
            /> <Pagination
                style={{ marginTop: '16px' }}
                className="text-center"
                current={currentPage}
                pageSize={currentPageSize}
                total={totalRecord}
                onChange={handlePageChange}
            />
        </div>;
    };

    const renderVitalGrid = () =>
    {
        return <div className='flex-1' style={{ marginTop: -14 }}>
            <BaseGrid
                gridOptions={gridOptions}
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                rowGroupPanelShow={'always'}
                suppressClickEdit={true}
                pivotPanelShow={'always'}
                columnDefs={getColumnVitalConfig()}
                pagination={true}
                rowData={state.vitalRecord}
                onRowClicked={(value) => { onClickFillHeaderForm(value.data as PatientRecordHeaderDto, null); }}
            />
        </div>;
    };

    // hàm xử lý cập nhật bác sĩ cho từng bệnh nhân
    const onHandleUpdateDoctor = async (value: number) =>
    {
        const response = await AxiosClient.put<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + updatePatientDay(value));
        if (response.data.success)
        {
            loadApi().then(() =>
            {
                message.success('Cập nhật thành công!!');
            });
        } else
        {
            message.error('Cập nhật thất bại!');
        }
        onCloseModal();
    };

    // hàm xử lý cập nhật trạng thái đã khám xong cho từng bệnh nhân
    const onHandleSuccess = async (value: number) =>
    {
        const response = await AxiosClient.put<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + successPatientDay(value));
        if (response.data.success)
        {
            loadApi().then(() =>
            {
                message.success('Cập nhật thành công!!');
            });
        } else
        {
            message.error('Cập nhật thất bại!');
        }
        onCloseModal();
    };

    // hàm xử lý xóa bác sĩ cho từng bệnh nhân
    const onHandleRemoveDoctor = async (value: number) =>
    {
        const response = await AxiosClient.put<ApiResponse<PatientWaitingGridDto>>(BASE_API_PATH + removePatientDay(value));
        if (response.data.success)
        {
            loadApi().then(() =>
            {
                message.success('Cập nhật thành công!');
            });
        }
        else
        {
            message.error('Cập nhật thất bại!');
        }
        onCloseModal();
    };


    // hàm custom lại ô của một cột
    const renderColumn = (paramsTable: ICellRendererParams<PatientFileRecord>) =>
    {
        const record = paramsTable.data as PatientFileRecord;
        const value = paramsTable.value;

        return <div onClick={() =>
        {
            onClickFillHeaderForm(record as PatientRecordHeaderDto, null);
        }}>
            {value}
        </div>;
    };


    // danh sách các cột của bảng 'danh sách bệnh nhân chờ khám'
    const columnTablePatientDay: ColDef[] = [
        {
            headerName: 'STT',
            valueGetter: 'node.rowIndex + 1',
            field: 'stt',
            maxWidth: 80,
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        },
        {
            headerName: 'Họ và tên',
            field: 'fullName',
            width: 150,
            tooltipField: 'fullName',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) =>
            {
                const record = params.data as PatientFileRecord;
                const hasDoctor = record.doctorId;

                return <div onClick={() =>
                {
                    onClickFillHeaderForm(record as PatientRecordHeaderDto, null);
                }}>
                    {
                        hasDoctor ? <>
                            <p className='m-0 leading-5 font-medium truncate'>{params.data?.fullName}</p>
                            <p className='m-0 leading-5 text-gray-600 truncate'>{params.data?.doctorName}</p>
                        </> :
                            <p className='font-medium truncate'>{params.data?.fullName}</p>
                    }
                </div>;
            }

        },
        {
            headerName: 'Năm sinh',
            field: 'yearOfBirth',
            tooltipField: 'yearOfBirth',
            headerClass: 'text-center',
            width: 70,
            cellStyle: { 'text-align': 'center' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        }
        ,
        {
            headerName: 'Điện thoại',
            field: 'phone',
            tooltipField: 'phone',
            headerClass: 'text-center',
            width: 80,
            cellStyle: { 'text-align': 'start' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        }
        ,
        {
            headerName: 'Giới tính',
            field: 'gender',
            tooltipField: 'gender',
            headerClass: 'text-center',
            width: 60,
            cellStyle: { 'text-align': 'start' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        }
        ,
        {
            headerName: 'Địa chỉ',
            field: 'location',
            tooltipField: 'location',
            headerClass: 'text-center',
            width: 100,
            cellStyle: { 'text-align': 'start' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        },
        {
            headerName: 'Lý do khám',
            field: 'reason',
            tooltipField: 'reason',
            headerClass: 'text-center',
            width: 150,
            cellStyle: { 'text-align': 'start' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        },

        {
            headerName: 'TG đặt khám',
            field: 'startBook',
            tooltipField: 'startBook',
            cellStyle: { 'text-align': 'center' },
            width: 80,
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)


        },

        {
            headerName: 'Ngày',
            field: 'dateBook',
            tooltipField: 'dateBook',
            headerClass: 'text-center',
            cellStyle: { 'text-align': 'start' },
            width: 80,
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        },

        {
            headerName: 'Số TT',
            field: 'numberOrder',
            tooltipField: 'numberOrder',
            headerClass: 'text-center',
            maxWidth: 100,
            cellStyle: { 'text-align': 'center' },
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) => renderColumn(params)
        },
        {
            headerName: 'Hành động',
            field: 'action',
            headerClass: 'text-center',
            maxWidth: 200,
            pinned: 'right',
            cellRenderer: (params: ICellRendererParams<PatientFileRecord>) =>
            {


                const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
                const [openConfirmRemove, setOpenConfirmRemove] = useState(false);
                const [openConfirmSuccess, setOpenConfirmSuccess] = useState(false);

                const record = params.data as PatientFileRecord;

                const hasDoctor = record.doctorId;

                // hàm xử lý cập nhật bác sĩ cho bệnh nhân
                const confirmUpdateDoctor = () =>
                {
                    onHandleUpdateDoctor(Number(record.id));
                    setOpenConfirmUpdate(false);
                };

                // hàm xử lý hủy cập nhật bác sĩ cho mỗi bệnh nhân
                const confirmRemoveDoctor = () =>
                {
                    onHandleRemoveDoctor(Number(record.id));
                    setOpenConfirmRemove(false);
                };

                // hàm xử lý xác nhận đã khám xong
                const confirmSuccessDoctor = () =>
                {
                    onHandleSuccess(Number(record.id));
                    setOpenConfirmSuccess(false);
                };





                return (
                    <div className="flex items-center justify-center gap-x-4" >
                        {
                            !hasDoctor || record?.isDisable ? <Popconfirm
                                title="Bạn có chắc chắn muốn cập nhật?"
                                onConfirm={confirmUpdateDoctor}
                                onCancel={() =>
                                {
                                    setOpenConfirmUpdate(false);
                                }}
                                open={openConfirmUpdate}
                                okText="Xác nhận"
                                cancelText="Hủy"
                            >
                                <Button
                                    type='primary'
                                    className="mt-1  focus:outline-none font-medium  text-sm text-center !rounded-md"
                                    disabled={record?.isDisable}
                                    onClick={() =>
                                    {
                                        setOpenConfirmUpdate(true);
                                    }}
                                >
                                    Khám bệnh
                                </Button>
                            </Popconfirm>

                                : <>


                                    <Popconfirm
                                        title="Bạn có chắc chắn muốn cập nhật?"
                                        onConfirm={confirmSuccessDoctor}
                                        okText="Xác nhận"
                                        cancelText="Hủy"
                                        onCancel={() =>
                                        {
                                            setOpenConfirmSuccess(false);
                                        }}
                                        open={openConfirmSuccess}
                                    >
                                        <Button
                                            type='primary'
                                            className=" focus:outline-none font-medium !rounded-md text-sm px-3 py-2 text-center !bg-teal-600 !border-teal-600 mt-1"
                                            onClick={() =>
                                            {
                                                setOpenConfirmSuccess(true);
                                            }}
                                        >
                                            Đã xong
                                        </Button>

                                    </Popconfirm>

                                    <Popconfirm
                                        title="Bạn có chắc chắn muốn hủy?"
                                        onConfirm={confirmRemoveDoctor}
                                        okText="Xác nhận"
                                        cancelText="Hủy"
                                        onCancel={() =>
                                        {
                                            setOpenConfirmRemove(false);
                                        }}
                                        open={openConfirmRemove}
                                    >
                                        <Button
                                            type='default'
                                            className="!text-white !bg-orange-400 focus:outline-none font-medium !rounded-md text-sm px-3 py-2 text-center mt-1"
                                            onClick={() =>
                                            {
                                                setOpenConfirmRemove(true);
                                            }}
                                        >
                                            Hủy
                                        </Button>
                                    </Popconfirm>

                                </>

                        }

                    </div >
                );
            },
        },
    ];



    // hàm render giao diện của bảng 'danh sách bệnh nhân chờ khám'
    const renderTablePatientInDay = () =>
    {
        return <div className='flex-1' style={{ marginTop: -14 }}>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                columnDefs={columnTablePatientDay}
                suppressRowTransform={true}
                pagination={true}
                suppressClickEdit={true}
                rowData={state.patientDayRecord}
                rowGroupPanelShow={'always'}

            />

        </div>;
    };

    const handleAddonService = async (formValue?: object, endExamParams?: object, medicalSessionId?: number | null, designationQueueItemId?: number | null, designationMedicalExamId?: number | null) =>
    {
        overlayRef.current?.open();
        let isUpdate = true;
        let params: PatientRecordHeaderDto = formValue ? formValue : {
            ...formRefHeader.current?.getValues()
        };
        if (designationQueueItemId) params = state.infoPatientExam as unknown as PatientRecordHeaderDto;
        const valueForm = _.cloneDeep(formValue ? formValue : formRefHeader.current?.getValues()) as PatientRecordHeaderDto;
        const valueSelect = _.cloneDeep(state.valueSelected) as PatientRecordHeaderDto;
        const patientWaitingId = valueSelect.patientWaitingId;
        const reason = valueForm.reason;
        valueForm.designationMedicalExamId = '';
        valueSelect.designationMedicalExamId = '';
        valueForm.patientWaitingId = null;
        valueSelect.patientWaitingId = null;
        valueForm.reason = null;
        valueSelect.reason = null;
        onChangeTypeValue(valueSelect);
        onChangeTypeValue(valueForm);
        const valueSelectEqual = _.omit(valueSelect, ['districtId', 'provinceId', 'location']);
        const valueFormEqual = _.omit(valueForm, ['districtId', 'provinceId', 'location']);
        isUpdate = _.isEqual(valueSelectEqual, valueFormEqual);
        if (!isUpdate)
        {
            params.id = null;
        }
        params = {
            ...params,
            yearOfBirth: !isNaN(Number(moment(params.yearOfBirth).format('YYYY').toString())) ? moment(params.yearOfBirth).format('YYYY').toString() : null,
            patientWaitingId: patientWaitingId,
            reason: reason,
            isMedicineOnly: false,
            parentExamId: 0,
            designationQueueItemId,
            designationMedicalExamId,
            medicalSessionId
        };


        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + startExam, params, { withCredentials: true });
        formRefHeader.current?.setValues({});
        if (response.data.success)
        {
            ApiUtil.ToastSuccess('Khám thành công');
            await loadApi();
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        overlayRef.current?.close();
    };

    /**
* Hàm dùng để bắt đầu phiên khám
* @param designationExamIds danh sách id của các chỉ định
* @param isMedicineOnly check có phải tạo đơn thuốc hay không (mặc định không truyền, khi tạo đơn thuốc thì truyền true, và chỉ có 1 chỉ định được chọn)
*/
    const onStartSession = async (designationExamIds: number[], isMedicineOnly = false) =>
    {
        overlayRef.current?.open();
        let isUpdate = true;
        let params = {
            ...formRefHeader.current?.getValues()
        };
        const valueForm = _.cloneDeep(formRefHeader.current?.getValues()) as StartSessionBody;
        const valueSelect = _.cloneDeep(state.valueSelected) as StartSessionBody;
        const patientWaitingId = valueSelect.patientWaitingId;
        const reason = valueForm.reason;
        valueForm.designationMedicalExamId = '';
        valueSelect.designationMedicalExamId = '';
        valueForm.patientWaitingId = null;
        valueSelect.patientWaitingId = null;
        valueForm.reason = null;
        valueSelect.reason = null;
        onChangeTypeValue(valueSelect);
        onChangeTypeValue(valueForm);
        const valueSelectEqual = _.omit(valueSelect, ['districtId', 'provinceId', 'location']);
        const valueFormEqual = _.omit(valueForm, ['districtId', 'provinceId', 'location']);
        isUpdate = _.isEqual(valueSelectEqual, valueFormEqual);
        if (!isUpdate)
        {
            params.id = null;
        }
        params = {
            ...params,
            yearOfBirth: !isNaN(Number(moment(params.yearOfBirth).format('YYYY').toString())) ? moment(params.yearOfBirth).format('YYYY').toString() : null,
            patientWaitingId: patientWaitingId,
            reason: reason,
            isMedicineOnly: false,
            parentExamId: 0,
            designationExamIds
        };
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + startSession, params, { withCredentials: true });
        if (response.data.success)
        {
            await loadInfoBeingSession();
            if (isMedicineOnly)
            {
                await onStartExam(4, {}, Number(response.data.results?.medicalSessionId), listService[0].id, listService[0].designationExamId,);
            }
        }
        else
        {
            formRefHeader.current?.setValues({});
            ApiUtil.ToastError(response.data.message);
        }
        overlayRef.current?.close();
    };

    /**
*
* @param flowValue step trong quy trình khám (4 nghĩa là chỉ kê đơn thuốc, 1 nghĩa là bắt đầu khám)
* @param formValue truyền thẳng formValue thay vì get giá trị từ form (dùng trong trường hợp khám dịch vụ bổ sung)
* @param medicalSessionId id của session đang khám (đối với luồng khám mới)
* @param designationQueueItemId id của queueItem trong hàng đợi dịch vụ (đối với luồng mới)
*/
    const onStartExam = async (flowValue?: number, formValue?: object, medicalSessionId?: number | null, designationQueueItemId?: number | null, designationMedicalExamId?: number | null) =>
    {
        overlayRef.current?.open();
        let isUpdate = true;
        let params: PatientRecordHeaderDto = formValue ? formValue : {
            ...formRefHeader.current?.getValues()
        };
        if (designationQueueItemId && flowValue !== 4) params = state.infoPatientExam as unknown as PatientRecordHeaderDto;
        const valueForm = _.cloneDeep(formValue ? formValue : formRefHeader.current?.getValues()) as PatientRecordHeaderDto;
        const valueSelect = _.cloneDeep(state.valueSelected) as PatientRecordHeaderDto;
        const patientWaitingId = valueSelect.patientWaitingId;
        const reason = valueForm.reason;
        valueForm.designationMedicalExamId = '';
        valueSelect.designationMedicalExamId = '';
        valueForm.patientWaitingId = null;
        valueSelect.patientWaitingId = null;
        valueForm.reason = null;
        valueSelect.reason = null;
        onChangeTypeValue(valueSelect);
        onChangeTypeValue(valueForm);
        const valueSelectEqual = _.omit(valueSelect, ['districtId', 'provinceId', 'location']);
        const valueFormEqual = _.omit(valueForm, ['districtId', 'provinceId', 'location']);
        isUpdate = _.isEqual(valueSelectEqual, valueFormEqual);
        if (!isUpdate && !formValue)
        {
            params.id = null;
        }
        const isMedicineOnly = flowValue === 4 ? true : false;
        params = {
            ...params,
            yearOfBirth: !isNaN(Number(moment(params.yearOfBirth).format('YYYY').toString())) ? moment(params.yearOfBirth).format('YYYY').toString() : null,
            patientWaitingId: patientWaitingId,
            reason: reason,
            isMedicineOnly,
            parentExamId: !formValue || JSON.stringify(formValue) === '{}' ? 0 : (state.infoBeingExam?.parentExamId === 0 ? state.infoBeingExam?.medicalExaminationId : state.infoBeingExam?.parentExamId),
            designationQueueItemId,
            designationMedicalExamId,
            medicalSessionId
        };

        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + startExam, params, { withCredentials: true });
        if (response.data.success)
        {
            await loadInfoBeingAndPatient(isMedicineOnly);
        }
        else
        {
            formRefHeader.current?.setValues({});
            ApiUtil.ToastError(response.data.message);
        }
        overlayRef.current?.close();
    };

    const onChangeTypeValue = (value: PatientRecordHeaderDto) =>
    {
        if (value.provinceId != null && isNumber(value.provinceId))
        {
            const provinceId = value.provinceId as number;
            value.provinceId = provinceId.toString();
            if (value.districtId != null && isNumber(value.districtId))
            {
                const districtId = value.districtId as number;
                value.districtId = districtId.toString();
            }
        }
    };


    const renderGridRight = () =>
    {
        // return <PatientWaiting onClickInfomationWaiting={onClickInfomationWaiting}
        //     patientWaitingList={patientWaitingList}
        //     patientBeingExamList={patientBeingExamList} />;
        return <div className='content-right flex flex-col gap-y-8'>
            {/*<PatientQueue />*/}
            {state.infoBeingSession !== null && <CurrentPatient
                handleAddonService={handleAddonService}
                listService={listService}
                getQueueItem={getQueueItem}
                optionsDesignMedical={optionsDesignMedical}
                loadApi={loadApi}
                currentSessionId={state.infoBeingSession?.id}
                onStartExam={onStartExam}
                infoPatientExam={state.infoPatientExam ?? {} as InformationPatientExam}
                designationQueueId={designationQueueId} />}
        </div>;
    };

    const startVitalSign = async (body: any) =>
    {
        await AxiosClient.post<ApiResponse<any>>(BASE_API_PATH + startVital, body, { withCredentials: true });
    };

    const iconMedicine: React.ReactNode = <svg
        fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 14">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={pathMedicine}
            fill="#fff" />
    </svg>;

    const renderGridLeft = () =>
    {
        return <>
            <div className='content-body-header-left'>
                <PatientRecordHeaderForm
                    listAddonId={listAddonId}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    onFinishForm={onFinishHeaderForm}
                    state={state}
                    setState={setState}
                    // isUsingAddon={isUsingAddon}
                    // setUsingAddon={setUsingAddon}
                    handleAddonService={handleAddonService}
                    onHandleStartExam={onStartExam}
                    handleStartSession={onStartSession}
                    formRefFilter={formRefHeader}
                    optionsDesignMedicalExam={optionsDesignMedical}
                    optionsProvince={state.optionsProvince}
                    optionsDistrict={optionsDistrict}
                    valueSelected={state.valueSelected}
                    loadOptionsDistrict={loadOptionsDistrict} />

            </div>

            <Tabs style={{ position: 'relative', margin: 0 }}
                defaultActiveKey='1'
                tabBarStyle={{ margin: 0 }}>
                <TabPane
                    tab={<div className=' text-xl font-bold text-[#2c999c] ml-3'>
                        <p style={{ font: 'Roboto', fontSize: 16, height: '10px' }}>DANH SÁCH BỆNH NHÂN CHỜ KHÁM HÔM NAY</p>
                    </div>}
                    key='1'
                >
                    <div className='body-patient-record flex flex-1 flex-col' style={{ height: '440px' }}>
                        <HeaderFilterPatientRecord formRefFilter={formRefFilter}
                            onLoadDataGrid={onLoadDataTablePatient}
                        />
                        {renderTablePatientInDay()}

                    </div>
                </TabPane>
                <TabPane
                    tab={<div className=' text-xl font-bold text-[#2c999c] ml-3'>
                        <p style={{ font: 'Roboto', fontSize: 16, height: '10px' }}>DANH SÁCH BỆNH NHÂN ĐÃ KHÁM</p>
                    </div>}
                    key='2'
                >
                    <div className='body-patient-record flex flex-1 flex-col' style={{ height: '440px' }}>
                        <HeaderFilterPatientRecord formRefFilter={formRefFilter}
                            onLoadDataGrid={onLoadDataGrid}
                            isFilterByDate />
                        {renderGrid()}
                    </div>
                </TabPane>
                <TabPane
                    tab={<div className=' text-xl font-bold text-[#2c999c] ml-3'>
                        <p style={{ font: 'Roboto', fontSize: 16, height: '10px' }}>DANH SÁCH BỆNH NHÂN ĐO CHỈ SỐ</p>
                    </div>}
                    key='3'
                >
                    <div className='body-patient-record flex flex-1 flex-col' style={{ height: '440px' }}>
                        <HeaderFilterPatientRecord formRefFilter={formRefFilter}
                            onLoadDataGrid={onLoadDataVitalGrid}
                            isFilterByDate />
                        {renderVitalGrid()}
                        <CustomModal ref={modalDetailRef} />
                    </div>
                </TabPane>

            </Tabs>

        </>;
    };

    const onloadInfoBeingExam = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<InformationBeingSession>>(BASE_API_PATH + getInfoBeingSession, { withCredentials: true });
        if (response.data.success)
        {
            const informationBeingExam = response.data.results?.infoBeginExam as unknown as InformationBeingExam;
            const informationPatient = response.data.results?.infoBeingPatient as unknown as InformationPatientExam;
            setState({
                ...state,
                infoBeingSession: response.data.results,
                infoBeingExam: informationBeingExam,
                infoPatientExam: informationPatient
            });
        }
    };

    return (
        <>
            {state.loading === false && <div className="h-full w-full flex gap-x-4 bg-[#f0f2f5]">
                <div className='h-full w-full'>
                    {state.infoBeingSession === null ?
                        <div className='h-full'>
                            {
                                !state.isMeasuring ?
                                    <div className='body-content patient-record overflow-y-hidden'>
                                        <div className='content-left flex flex-col'>
                                            {renderGridLeft()}
                                        </div>
                                    </div>
                                    : <MeasuringVitalSign vitalSignMeasure={vitalSignMeasure}
                                        setState={setState}
                                        state={state}
                                        reloadVitalRecord={reloadVitalRecord}
                                    />
                            }
                        </div>
                        :
                        <div className='h-full'>
                            {
                                state.infoBeingSession?.infoBeginExam === null ? <div><h1>Vui lòng bắt đầu 1 chỉ định khám</h1></div> :
                                    <ExamProcessLayout
                                        steps={state.infoBeingSession?.infoBeginExam?.steps}
                                        clinicId={clinicId ?? 0}
                                        medicalSessionId={state.infoBeingSession?.id || 0}
                                        onHandleStartExam={onStartExam}
                                        handleAddonService={handleAddonService}
                                        onEndExam={onEndExam}
                                        overlayRef={overlayRef}
                                        onloadInfoBeingExam={onloadInfoBeingExam}
                                        informationBeingExam={state.infoBeingSession?.infoBeginExam}
                                        medicineGroupCombo={state.medicineGroupCombo}
                                        medicineNoteUseCombo={state.medicineNoteUseCombo}
                                        flowValue={state.infoBeingSession?.infoBeginExam?.flowCurrent as number}
                                        infoPatientExam={state.infoPatientExam ?? {} as InformationPatientExam}
                                        listService={listService}
                                        getQueueItem={getQueueItem} />
                            }
                        </div>
                    }
                </div>
                {renderGridRight()}
            </div>}

            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />

        </>

    );
};

export default PatientRecordView;