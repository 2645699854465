export const pathNotification = 'M15.5 0a3.5 3.5 0 0 1 3.48 3.124 7 7 0 1 1 7.021 11.586L26 23h.5a3.5 3.5 0 1 1 0 7h-5.842a6.002 6.002 0 0 1-11.316 0H3.5a3.5 3.5 0 1 1 0-7H4v-9c0-4.664 2.903-8.65 7-10.25V3.5A3.5 3.5 0 0 1 14.308.005L14.5 0h1Zm2.964 30.001h-6.928A3.998 3.998 0 0 0 15 32c1.48 0 2.772-.804 3.464-1.999ZM26.5 25h-23a1.5 1.5 0 0 0 0 3h23a1.5 1.5 0 0 0 0-3ZM15 5a9 9 0 0 0-8.996 8.735L6 14v9h18v-8a7 7 0 0 1-6.484-9.642 8.916 8.916 0 0 0-2.25-.354L15 5Zm9-2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-8.5-1h-1a1.5 1.5 0 0 0-1.465 1.175 11.066 11.066 0 0 1 3.928 0A1.498 1.498 0 0 0 15.5 2Z';


export const pathPlay = 'M5.25.388C6.55.099 8.124 0 10 0s3.451.1 4.75.388c1.31.291 2.399.788 3.236 1.626.838.837 1.335 1.926 1.626 3.236C19.901 6.55 20 8.124 20 10s-.1 3.451-.388 4.75c-.291 1.31-.788 2.399-1.626 3.236-.837.838-1.926 1.335-3.236 1.626-1.299.289-2.874.388-4.75.388s-3.451-.1-4.75-.388c-1.31-.291-2.399-.788-3.236-1.626C1.176 17.15.679 16.06.388 14.75.099 13.45 0 11.876 0 10s.1-3.451.388-4.75c.291-1.31.788-2.399 1.626-3.236C2.85 1.176 3.94.679 5.25.388Zm8.346 7.944c1.291.737 1.291 2.599 0 3.336l-4.442 2.539c-1.183.675-2.654-.178-2.654-1.54V7.333c0-1.362 1.471-2.215 2.654-1.54l4.442 2.539Z';
export const pathPlayWaiting = 'M7.063.485C8.686.124 10.655 0 13 0s4.314.124 5.937.485c1.638.364 2.999.985 4.046 2.032 1.047 1.047 1.668 2.408 2.032 4.046.361 1.623.485 3.592.485 5.937s-.124 4.314-.485 5.937c-.364 1.638-.985 2.999-2.032 4.046-1.047 1.047-2.408 1.668-4.046 2.032-1.623.361-3.592.485-5.937.485s-4.314-.124-5.937-.485c-1.638-.364-2.999-.985-4.046-2.032-1.047-1.047-1.668-2.408-2.032-4.046C.624 16.814.5 14.845.5 12.5s.124-4.314.485-5.937c.364-1.638.985-2.999 2.032-4.046C4.064 1.47 5.425.849 7.063.485Zm10.432 9.93c1.614.922 1.614 3.248 0 4.17l-5.553 3.174c-1.478.844-3.317-.223-3.317-1.926V9.167c0-1.703 1.84-2.77 3.318-1.925l5.552 3.173Z';
export const pathPlus = 'M8.684 1.105a1.07 1.07 0 0 0-.347-.781A1.228 1.228 0 0 0 7.5 0c-.314 0-.615.116-.837.324a1.07 1.07 0 0 0-.347.781v4.79H1.184c-.314 0-.615.116-.837.323A1.07 1.07 0 0 0 0 7c0 .293.125.574.347.782.222.207.523.323.837.323h5.132v4.79c0 .293.125.574.347.781.222.208.523.324.837.324.314 0 .615-.116.837-.324a1.07 1.07 0 0 0 .347-.781v-4.79h5.132c.314 0 .615-.116.837-.323A1.07 1.07 0 0 0 15 7a1.07 1.07 0 0 0-.347-.782 1.229 1.229 0 0 0-.837-.323H8.684v-4.79Z';

export const pathFilterTextbox = 'M4.438.79C5.412.575 6.593.5 8 .5s2.588.074 3.562.29c.983.22 1.8.592 2.428 1.22.628.628 1 1.445 1.22 2.428.216.974.29 2.155.29 3.562s-.074 2.588-.29 3.562c-.22.983-.592 1.8-1.22 2.428-.628.628-1.445 1-2.428 1.22-.974.216-2.155.29-3.562.29s-2.588-.074-3.562-.29c-.983-.22-1.8-.592-2.428-1.22-.628-.628-1-1.445-1.22-2.428C.575 10.588.5 9.407.5 8s.074-2.588.29-3.562c.22-.983.592-1.8 1.22-2.428.628-.628 1.445-1 2.428-1.22ZM5.75 7.626a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0ZM7.625 4.25a3.375 3.375 0 1 0 1.797 6.232l1.048 1.048a.75.75 0 1 0 1.06-1.06l-1.047-1.048A3.375 3.375 0 0 0 7.625 4.25Z';
export const pathFilterButton = 'M4.333.592C5.2.399 6.25.333 7.5.333c1.25 0 2.3.066 3.167.259.873.194 1.599.525 2.157 1.084.559.558.89 1.284 1.084 2.157.193.866.259 1.917.259 3.167 0 1.25-.066 2.3-.259 3.167-.194.873-.525 1.599-1.084 2.157-.558.559-1.284.89-2.157 1.084-.866.193-1.916.259-3.167.259-1.25 0-2.3-.066-3.167-.259-.873-.194-1.599-.525-2.157-1.084-.559-.558-.89-1.284-1.084-2.157C.9 9.3.833 8.25.833 7c0-1.25.067-2.3.259-3.167.194-.873.525-1.599 1.084-2.157.558-.559 1.284-.89 2.157-1.084ZM5.5 6.667a1.667 1.667 0 1 1 3.333 0 1.667 1.667 0 0 1-3.333 0Zm1.667-3a3 3 0 1 0 1.597 5.54l.931.931a.667.667 0 0 0 .943-.943l-.931-.931a3 3 0 0 0-2.54-4.597Z';
export const pathMedicine = 'M6.667 6.333a.667.667 0 1 0-1.334 0v1h-1a.667.667 0 0 0 0 1.334h1v1a.667.667 0 1 0 1.334 0v-1h1a.667.667 0 1 0 0-1.334h-1v-1Zm-.346-6c.453 0 .854-.001 1.225.153.371.153.655.438.974.758.633.635 1.267 1.27 1.902 1.902.321.32.605.603.759.974.154.371.153.773.152 1.225-.002 1.678 0 3.355 0 5.032 0 .591 0 1.1-.054 1.508-.06.437-.192.856-.531 1.196-.34.34-.76.472-1.196.53-.408.056-.917.056-1.508.056H3.956c-.59 0-1.1 0-1.508-.055-.437-.059-.856-.191-1.196-.531-.34-.34-.472-.759-.53-1.196-.055-.408-.055-.917-.055-1.508V3.623c0-.591 0-1.1.054-1.508.06-.437.192-.856.531-1.196.34-.34.76-.472 1.196-.53.408-.056.917-.056 1.508-.056h2.365Z';

export const pathDateTime = 'm1.501 7.5-.001.234v4.032c0 .837.044 1.561.179 2.17.137.616.378 1.158.802 1.583.425.424.967.665 1.584.802.608.135 1.332.179 2.169.179h5.532c.837 0 1.561-.044 2.17-.179.616-.137 1.158-.378 1.583-.802.424-.425.665-.967.802-1.584.135-.608.179-1.332.179-2.169V7.734l-.001-.234H1.5ZM3.75 3.26V2.25a.75.75 0 0 1 1.5 0v.776c.308-.018.636-.026.984-.026h5.532c.348 0 .676.008.984.026V2.25a.75.75 0 0 1 1.5 0v1.01c.487.145.918.37 1.269.721.424.425.665.967.802 1.584.03.139.057.284.079.435H1.6c.022-.151.048-.296.079-.435.137-.617.378-1.159.802-1.584.351-.35.782-.576 1.269-.72Z';
export const pathEdit = 'M18.125 17.594H.875a.75.75 0 0 0-.75.75v.843c0 .104.084.188.188.188h18.375a.188.188 0 0 0 .187-.188v-.843a.75.75 0 0 0-.75-.75ZM3.54 15.625a.953.953 0 0 0 .14-.012l3.943-.691a.23.23 0 0 0 .124-.066l9.935-9.935a.233.233 0 0 0 0-.33L13.787.693a.232.232 0 0 0-.167-.068.232.232 0 0 0-.166.068l-9.935 9.935a.238.238 0 0 0-.066.124l-.691 3.942a.785.785 0 0 0 .22.699c.155.15.35.232.558.232Z';

export const pathDelete = 'M8.5 4h4a2 2 0 1 0-4 0ZM7 4a3.5 3.5 0 1 1 7 0h5.75a.75.75 0 1 1 0 1.5h-1.32l-1.17 12.111A3.75 3.75 0 0 1 13.526 21H7.474a3.75 3.75 0 0 1-3.733-3.389L2.57 5.5H1.25a.75.75 0 0 1 0-1.5H7Zm2 4.75a.75.75 0 0 0-1.5 0v7.5a.75.75 0 1 0 1.5 0v-7.5ZM12.75 8a.75.75 0 0 0-.75.75v7.5a.75.75 0 1 0 1.5 0v-7.5a.75.75 0 0 0-.75-.75Z';

export const pathMessage = 'M12.833.333H2.166C1.433.333.84.933.84 1.667l-.007 12L3.5 11h9.333c.733 0 1.333-.6 1.333-1.333v-8c0-.734-.6-1.334-1.333-1.334Zm-7.333 6H4.166V5H5.5v1.333Zm2.666 0H6.833V5h1.333v1.333Zm2.667 0H9.5V5h1.333v1.333Z';

export const pathNoti = 'M6 13.667a1.333 1.333 0 0 1-1.333-1.334h2.667A1.333 1.333 0 0 1 6 13.667Zm5.334-2H.667v-1.334L2 9.667V6a5.377 5.377 0 0 1 .616-2.707 3.103 3.103 0 0 1 2.051-1.506V.333h2.43a3 3 0 0 0 2.867 4.934c.024.238.035.484.035.733v3.667l1.333.666v1.334h.002Zm-2-7.334a2 2 0 1 1 .006-4 2 2 0 0 1-.006 4Z';

export const pathClock = 'M10 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666Zm2.038 10.693a.833.833 0 0 0 .924-1.387l-2.129-1.419v-3.72a.833.833 0 0 0-1.667 0V9.6c0 .528.265 1.022.705 1.316l2.167 1.444ZM3.922 1.91a.833.833 0 0 1 0 1.18l-.833.833a.833.833 0 0 1-1.178-1.179l.833-.833a.833.833 0 0 1 1.178 0ZM16.078 1.91a.833.833 0 0 0 0 1.18l.833.833a.833.833 0 1 0 1.178-1.179l-.833-.833a.833.833 0 0 0-1.178 0Z';

export const pathLoadImage1 = 'M9.667 11.458h29.791V27.5h4.584V11.458a4.588 4.588 0 0 0-4.584-4.583H9.667a4.588 4.588 0 0 0-4.584 4.583v27.5a4.588 4.588 0 0 0 4.584 4.584H28v-4.584H9.667v-27.5Z';
export const pathLoadImage2 = 'm18.833 25.208-6.875 9.167h25.209L28 20.625l-6.875 9.167-2.292-4.584Z';
export const pathLoadImage3 = 'M44.042 32.083h-4.584v6.875h-6.875v4.584h6.875v6.875h4.584v-6.875h6.875v-4.584h-6.875v-6.875Z';
export const pathFolder = 'M21.5 15.5a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm0 2-.09.007a.5.5 0 0 0-.402.402L21 18v2.5h-2.502l-.09.008a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H21v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008.09-.008a.5.5 0 0 0 .402-.402l.008-.09V21.5l2.504.001.09-.008a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H22v-2.5l-.008-.09a.5.5 0 0 0-.402-.403l-.09-.008ZM23.75 7a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v7.06a6.5 6.5 0 0 0-9.977 8.19H4.25a2.25 2.25 0 0 1-2.245-2.096L2 22.25V10.999l8.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L15.821 7h7.929ZM10.207 4.5c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106l-.106.007L2 9.499V6.75a2.25 2.25 0 0 1 2.096-2.245L4.25 4.5h5.957Z';

export const pathClose = 'M10 1.667A8.282 8.282 0 0 0 1.667 10 8.282 8.282 0 0 0 10 18.333 8.282 8.282 0 0 0 18.333 10 8.282 8.282 0 0 0 10 1.667Zm3.214 12.5L10 10.952l-3.214 3.215-.953-.953L9.048 10 5.833 6.786l.953-.953L10 9.048l3.214-3.215.953.953L10.953 10l3.214 3.214-.953.953Z';

export const pathTick = 'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10Zm8.644 3.923 5.277-6.597-.953-.763-4.5 5.624L6.113 9.53l-.782.938 4.146 3.456v-.002Z';


export const pathCloseButton = 'M10.5 0C4.929 0 .5 4.429.5 10s4.429 10 10 10 10-4.429 10-10-4.429-10-10-10Zm3.857 15L10.5 11.143 6.643 15 5.5 13.857 9.357 10 5.5 6.143 6.643 5 10.5 8.857 14.357 5 15.5 6.143 11.643 10l3.857 3.857L14.357 15Z';

export const pathPrint = 'M3.501 8h17c.57 0 .477-.608.193-.707C20.409 7.194 17.251 6 16.7 6H16V3H8v3h-.699c-.55 0-3.709 1.194-3.993 1.293-.284.099-.377.707.193.707ZM21 9H3c-.55 0-1 .45-1 1v5c0 .551.45 1 1 1h2.283l-.882 5H19.6l-.883-5H21c.551 0 1-.449 1-1v-5c0-.55-.449-1-1-1ZM6.603 19l1.198-7.003H16.2L17.399 19H6.603Z';

export const pathCloseModal = 'M12 2C6.429 2 2 6.429 2 12s4.429 10 10 10 10-4.429 10-10S17.571 2 12 2Zm3.857 15L12 13.143 8.143 17 7 15.857 10.857 12 7 8.143 8.143 7 12 10.857 15.857 7 17 8.143 13.143 12 17 15.857 15.857 17Z';

export const pathCrop1 = 'M103.48 224a71.64 71.64 0 0 0 44.76-15.66l41.5 16.89 6.82-12.63a39.15 39.15 0 0 1 4.32-6.37l14.22-14.42-41.17-24.94A72 72 0 1 0 103.48 224Zm0-112a40 40 0 1 1-40 40 40 40 0 0 1 40-40Z';
export const pathCrop2 = 'm480 169-5.52-12.58c-4.48-10.42-14.74-16-32.78-17.85-10.12-1-26.95-1.24-49.69 3.81-20 4.45-122.14 28.2-164.95 58.62-20.25 14.39-24.06 33.67-27.06 49.16-2.78 14.14-5 25.31-18 35-15 11.14-27.27 16.38-33.58 18.6a71.74 71.74 0 1 0 24.79 38Zm-224.52 87a16 16 0 1 1 16-16 16 16 0 0 1-16 16Zm-152 144a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z';
export const pathCrop3 = 'm343.79 259.87-83.74 48.18 27.63 13.08 3.62 1.74C310 331.92 359.74 356 410.53 359c3.89.23 7.47.34 10.78.34C442 359.31 453 354 459.75 350L480 336Z';

export const pathMessageChat = 'M11.946 19.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.68.045L22 24l6-8-8 6-8.054-2.685Z';

export const avartaUser = 'https://static2.yan.vn/YanNews/2167221/202102/facebook-cap-nhat-avatar-doi-voi-tai-khoan-khong-su-dung-anh-dai-dien-e4abd14d.jpg';