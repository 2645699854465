import '../style/patient-process-view.scss';

import {AgGridReact} from 'ag-grid-react';
import {Modal} from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import AxiosClient, {Axios} from '../../../apis/api/axiosClient';
import {BaseFormRef} from '../../../components/FormFields/BaseForm';
import {IComboBox} from '../../../components/FormFields/ComboBox';
import {OverlayRef} from '../../../components/Overlay/Overlay';
import useMergeState from '../../../hooks/useMergeState';
import {ApiResponse} from '../../../types/api.type';
import {
    DiseaseAnalysisProcess,
    DiseaseAnalysisProcessDetailViewDto
} from '../../../types/DiseaseAnalysisProcess/DiseaseAnalysisProcess';
import {ParamExamResultProcess} from '../../../types/ExamResult/ExamResultProcess';
import {
    DesignationQueueItem,
    InformationBeingExam,
    InformationPatientExam
} from '../../../types/PatientRecord/PatientFileRecord';
import {PrescriptionRows} from '../../../types/PatientRecord/PrescriptionSetting';
import {Prescription} from '../../../types/Prescription/Prescription';
import {MedicineGroupCombo, MedicineNoteCombo} from '../../../types/share/ComboOption';
import {InfoFileUpload, InfoFileUploadCombo_v2} from '../../../types/UploadFile/InfoFileUpload';
import {ApiUtil, BASE_API_PATH} from '../../../utils/ApiUtil';
import DiseaseAnalysisProcessView from '../../DiseaseAnalysisProcess/pages/DiseaseAnalysisProcessView';
import ExamResultProcessScanView from '../../ExamResultProcess/pages/ExamResultProcessScanView';
import ExamResultProcessView from '../../ExamResultProcess/pages/ExamResultProcessView';
import {
    cancleExam,
    endExam,
    loadDiseaseAnalysisList,
    loadImageFileBeingExam,
    loadImageFileBeingExamV2, updateFlow
} from '../../PatientRecord/api/constants';
import PrescriptionView from '../../Prescription/page/PrescriptionView';
import {MedicalExamPictureCombo} from '../../UploadImage/api/contants';
import UploadImageView_V2 from '../../UploadImage/page/UploadImageView_V2';
import {ProcessBar} from '../components/ProcessBar';


interface IState {
    imageFileBeingExam?: { [medicalPicturesId: number]: InfoFileUpload[] },
    imageFileBeingExamViewCreate?: InfoFileUploadCombo_v2[],
    diseaseAnalysisProcess?: DiseaseAnalysisProcessDetailViewDto,
    optionsMedicalMedicalPicture?: IComboBox[]
}

export interface IStateExamResultScan {
    isChecked: boolean,
    reExamDate: string
}

interface IProps {
    medicalSessionId: number
    getQueueItem: () => Promise<void>
    listService: DesignationQueueItem[]
    onHandleStartExam: (value: number, formValue?: object) => Promise<void>;
    handleAddonService: (formValue?: object, endExamParams?: object) => Promise<void>;
    flowValue: number,
    infoPatientExam: InformationPatientExam;
    medicineGroupCombo: MedicineGroupCombo[];
    informationBeingExam: InformationBeingExam | undefined;
    medicineNoteUseCombo: MedicineNoteCombo[];
    onEndExam: (medicalExamId: number) => Promise<void>;
    onloadInfoBeingExam: () => Promise<void>;
    overlayRef: React.RefObject<OverlayRef>;
    clinicId: number;
    steps?: string[]
}

const ExamProcessLayout = (props: IProps) => {
    const {
        steps,
        medicalSessionId = 0,
        getQueueItem,
        listService,
        onHandleStartExam,
        handleAddonService,
        informationBeingExam,
        onEndExam,
        onloadInfoBeingExam,
        medicineNoteUseCombo,
        overlayRef
    } = props;
    const [state, setState] = useMergeState<IState>({
        imageFileBeingExam: [],
        imageFileBeingExamViewCreate: [],
        optionsMedicalMedicalPicture: [],
        diseaseAnalysisProcess: {}
    });
    const {clinicId} = props;

    const [stateScan, setStateScan] = useMergeState<IStateExamResultScan>({
        isChecked: false,
        reExamDate: ''
    });
    const [selectFlow, setSelectFlow] = useState<number>(props.flowValue);

    const componentRef = useRef<HTMLDivElement>(null);
    const gridRefMedicine = useRef<AgGridReact>(null);
    const oldNumber = useRef<number>(props.flowValue);
    const formRef = useRef<BaseFormRef>(null);
    const formRefExamResult = useRef<BaseFormRef>(null);
    const formRefExamResultScan = useRef<BaseFormRef>(null);
    const imageArrCurrent = useRef<InfoFileUploadCombo_v2[]>([]);
    const diseaseAnalysisProcessCurrent = useRef<any>({});
    const medicineNote = useRef<string>('');

    // const [optionsMedicalMedicalPicture, setOptionsMedicalPicture] = useState<IComboBox[]>([]);
    const {confirm} = Modal;


    useEffect(() => {
        loadApi();
        onChangeProcessBar(props.flowValue.toString(), '1');
    }, []);

    const updateNextFlow = async (nextFlow: '1' | '2' | '3' | '4' | '5', prescriptions?: any, reExamDate?: any) => {
        // nếu chưa phải bước cuối thì cập nhật flowCurrent
        if (nextFlow !== '5') await AxiosClient.put<ApiResponse>(BASE_API_PATH + updateFlow + `/${informationBeingExam?.id}/${nextFlow}`, {withCredentials: true});
        else {
            // nếu bước tiếp theo là kết thúc thì sẽ end-exam
            await onSumbitFlow({ useExtraService: false });
            localStorage.removeItem('patientInfoValue');
        }

    };

    const handleAddonServiceExtra = async (designationMedicalExamId: string | undefined) => {
        const patientInfoFormValue = JSON.parse(localStorage.getItem('patientInfoValue') || '{}');
        const medicineNoteParams = medicineNote.current;
        const valueFormMedicine = getRowData();
        const isCheckMedicine = onCheckMedicine(valueFormMedicine);
        if (!isCheckMedicine) {
            overlayRef.current?.close();
            return;
        }
        const arrMedicine = onFormatDataMedicine(valueFormMedicine);
        const valueFormExam = formRefExamResult.current?.getValues();
        const reExam = valueFormExam?.['reExam'];
        if (reExam && !valueFormExam?.['reExamDate']) {
            overlayRef.current?.close();
            return ApiUtil.ToastError('Vui lòng chọn lịch tái khám!');
        }

        let reExamDate: string | null = null;
        if (valueFormExam?.['reExamDate']) {
            reExamDate = (valueFormExam?.['reExamDate'] as moment.Moment).format('DD/MM/YYYY');
        }
        const medicalExamId = informationBeingExam?.medicalExaminationId as number;
        const params: ParamExamResultProcess = {
            reExam: reExam,
            reExamDate: reExamDate,
            medicalExamId: medicalExamId,
            prescriptions: arrMedicine,
            medicineNote: medicineNoteParams
        };
        handleAddonService({...patientInfoFormValue, designationMedicalExamId}, params);
    };

    const loadApi = async () => {
        Axios.all([
            await AxiosClient.get<ApiResponse<{
                [medicalPicturesId: number]: InfoFileUpload[]
            }>>(BASE_API_PATH + loadImageFileBeingExam, {withCredentials: true}),
            await AxiosClient.get<ApiResponse<InfoFileUploadCombo_v2[]>>(BASE_API_PATH + loadImageFileBeingExamV2, {withCredentials: true}),
            await AxiosClient.get<ApiResponse<IComboBox[]>>(BASE_API_PATH + MedicalExamPictureCombo, {withCredentials: true}),
            await AxiosClient.get<ApiResponse<{
                [medicalPicturesId: number]: DiseaseAnalysisProcess
            }>>(BASE_API_PATH + loadDiseaseAnalysisList, {withCredentials: true})
        ]).then(axios.spread((imagesListRes, imagesListCreateRes, medicalExamPictureRes, diseaseAnalysisProcessRes) => {
            let imageFileBeingExamList: { [medicalPicturesId: number]: InfoFileUpload[] } = [];
            let imageFileBeingExamCreateList: InfoFileUploadCombo_v2[] = [];
            let diseaseAnalysisProcessList: DiseaseAnalysisProcessDetailViewDto = {};
            let medicalExamPictures: IComboBox[] = [];
            if (imagesListRes.data.success) {
                imageFileBeingExamList = imagesListRes.data.results as {
                    [medicalPicturesId: number]: InfoFileUpload[]
                };
            }
            if (imagesListCreateRes.data.success) {
                imageFileBeingExamCreateList = imagesListCreateRes.data.results as InfoFileUploadCombo_v2[];
            }
            if (medicalExamPictureRes.data.success) {
                medicalExamPictures = medicalExamPictureRes.data.results as IComboBox[];
            }
            if (diseaseAnalysisProcessRes.data.success) {
                diseaseAnalysisProcessList = diseaseAnalysisProcessRes.data.results as unknown as DiseaseAnalysisProcessDetailViewDto;

            }
            setState({
                ...state,
                imageFileBeingExam: imageFileBeingExamList,
                imageFileBeingExamViewCreate: imageFileBeingExamCreateList,
                optionsMedicalMedicalPicture: medicalExamPictures,
                diseaseAnalysisProcess: diseaseAnalysisProcessList
            });
        }));
    };

    const loadImageProcess = async () => {
        Axios.all([
            await AxiosClient.get<ApiResponse<{
                [medicalPicturesId: number]: InfoFileUpload[]
            }>>(BASE_API_PATH + loadImageFileBeingExam, {withCredentials: true}),
            await AxiosClient.get<ApiResponse<InfoFileUploadCombo_v2[]>>(BASE_API_PATH + loadImageFileBeingExamV2, {withCredentials: true}),
        ]).then(axios.spread((imagesListRes, imagesListCreateRes) => {
            let imageFileBeingExamList: { [medicalPicturesId: number]: InfoFileUpload[] } = [];
            let imageFileBeingExamCreateList: InfoFileUploadCombo_v2[] = [];
            if (imagesListRes.data.success) {
                imageFileBeingExamList = imagesListRes.data.results as {
                    [medicalPicturesId: number]: InfoFileUpload[]
                };
            }
            if (imagesListCreateRes.data.success) {
                imageFileBeingExamCreateList = imagesListCreateRes.data.results as InfoFileUploadCombo_v2[];
            }

            setState({
                ...state,
                imageFileBeingExam: imageFileBeingExamList,
                imageFileBeingExamViewCreate: imageFileBeingExamCreateList,
            });
        }));
    };
    const getRowData = useCallback(() => {
        const rowData: PrescriptionRows[] = [];
        gridRefMedicine.current?.api.forEachNode(function (node) {
            rowData.push(node.data);
        });
        return rowData;
    }, []);

    const onSumbitFlow = async ({useExtraService, designationMedicalExamId}: {
        useExtraService: boolean,
        designationMedicalExamId?: string
    }) => {
        overlayRef.current?.open();
        const medicineNoteParams = medicineNote.current;
        const valueFormMedicine = getRowData();
        const isCheckMedicine = onCheckMedicine(valueFormMedicine);
        if (!isCheckMedicine) {
            overlayRef.current?.close();
            return;
        }
        const arrMedicine = onFormatDataMedicine(valueFormMedicine);
        const valueFormExam = formRefExamResult.current?.getValues();
        const reExam = valueFormExam?.['reExam'] ?? false;
        if (reExam && !valueFormExam?.['reExamDate']) {
            overlayRef.current?.close();
            return ApiUtil.ToastError('Vui lòng chọn lịch tái khám!');
        }

        let reExamDate: string | null = null;
        if (valueFormExam?.['reExamDate']) {
            reExamDate = (valueFormExam?.['reExamDate'] as moment.Moment).format('DD/MM/YYYY');
        }
        const medicalExamId = informationBeingExam?.medicalExaminationId as number;
        const inprogressService = !informationBeingExam?.isMedicine ? listService.find(item => item.status === 'INPROGRESS') : listService[0];
        const params: ParamExamResultProcess = {
            reExam: reExam,
            reExamDate: reExamDate,
            medicalExamId: medicalExamId,
            prescriptions: arrMedicine,
            medicineNote: medicineNoteParams,
            designationQueueItemId: inprogressService?.id
        };
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + endExam, params, {withCredentials: true});
        if (response.data.success) {
            ApiUtil.ToastSuccess(response.data.message);
            if (!useExtraService) {
                await onEndExam(medicalExamId);
            } else {
                const patientInfoFormValue = JSON.parse(localStorage.getItem('patientInfoValue') || '{}');
                await onHandleStartExam(1, {...patientInfoFormValue, designationMedicalExamId});
                window.location.reload();
            }
            await getQueueItem();
        } else {
            ApiUtil.ToastError(response.data.message);
        }

        overlayRef.current?.close();

    };
    const onFormatDataMedicine = (valueArr: PrescriptionRows[]) => {
        const medicineGroupCombo = props.medicineGroupCombo;
        const result = valueArr.map(item => {
            const medicineGroup = medicineGroupCombo.find(x => x.value === item.medicineGroup);
            const medicineComboitem = medicineGroup?.medicineCombo;
            const codeMedicine = medicineComboitem?.find(x => x.value === item.medicineCode);
            const itemResult: Prescription = {
                quantity: parseInt(item.quantity),
                medicineId: item.medicineCode,
                medicalExaminationId: informationBeingExam?.medicalExaminationId as number,
                medicineName: item.medicineName,
                medicineNoteUse: item.noteUse,
                medicineNoteUsePlus: item.noteUseMedicinePlus,
                medicineCodeName: codeMedicine?.code,
                medicineGroupName: medicineGroup?.label
            };
            return itemResult;
        });
        return result;
    };


    const onCheckMedicine = (valueArr: PrescriptionRows[]): boolean => {
        let status = true;
        valueArr.forEach(item => {
            if (item.medicineGroup === undefined) {
                ApiUtil.ToastError('Vui lòng chọn nhóm thuốc! Nhóm thuốc đang được bỏ trống!');
                status = false;
                return;
            }
            if (item.medicineCode === undefined) {
                ApiUtil.ToastError('Vui lòng chọn mã thuốc! Mã thuốc đang được bỏ trống!');
                status = false;
                return;
            }
            if (item.quantity === undefined) {
                ApiUtil.ToastError('Vui lòng chọn số lượng thuốc! Số lượng thuốc đang được bỏ trống!');
                status = false;
                return;
            }
        });
        return status;
    };

    const loadDiseaseAnalysis = async () => {
        const response = await AxiosClient.get<ApiResponse<DiseaseAnalysisProcessDetailViewDto>>(BASE_API_PATH + loadDiseaseAnalysisList, {withCredentials: true});
        if (response.data.success) {
            const data = response.data.results as DiseaseAnalysisProcessDetailViewDto;
            setState({
                ...state,
                diseaseAnalysisProcess: data
            });

        }
    };

    const onChangeProcessBar = (value: string, oldValue: string) => {

        const newActive = document.getElementById(value);
        const oldActive = document.getElementById(oldValue);
        oldActive?.classList.replace('inline-block', 'hidden');
        newActive?.classList.replace('hidden', 'inline-block');
        oldNumber.current = parseInt(value);

    };

    const onCancleProcess = async () => {
        const medicalExamId = informationBeingExam?.medicalExaminationId as number;

        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + cancleExam + informationBeingExam?.medicalExaminationId + '/' + medicalSessionId, {withCredentials: true});
        if (response.data.success) {
            onEndExam(medicalExamId);
            ApiUtil.ToastSuccess(response.data.message);
        } else {
            ApiUtil.ToastError(response.data.message);
        }

    };
    const showConfirm = () => {
        return confirm({
            title: 'Bạn có chắc chắn huỷ tất cả luồng khám?',
            content: 'Nếu đồng ý tất cả thông tin của luồng khám hiện tại sẽ bị mất bao gồm hình ảnh/ video khám bệnh, thông tin chuẩn đoán và đơn thuốc',
            okText: 'Đồng ý',
            className: 'newStyleConfirm',
            style: {marginTop: '10%', borderRadius: '8px'},
            cancelText: 'Hủy',
            async onOk() {
                try {
                    return onCancleProcess();
                } catch (e) {
                    return console.log('Lỗi confirm!');
                }
            },
            onCancel() {
            },
        });
    };


    const renderBodyProcess = () => {
        return <>
            {steps?.includes('1') && <div id="1"
                className="inline-block h-full w-full"
                style={{backgroundColor: '#f0f2f5'}}>
                <UploadImageView_V2
                    updateNextFlow={updateNextFlow}
                    steps={steps}
                    clinicId={clinicId}
                    onloadInfoBeingExam={onloadInfoBeingExam}
                    loadImageProcess={loadImageProcess}
                    onChangeProcessBar={onChangeProcessBar}
                    showConfirm={showConfirm}
                    imageArrCurrent={imageArrCurrent}
                    setSelectFlow={setSelectFlow}
                    informationBeingExam={informationBeingExam}
                    imageFileBeingExamCreate={state.imageFileBeingExamViewCreate as InfoFileUploadCombo_v2[]}/>
            </div>}
            {steps?.includes('2') && <div id="2" className="hidden h-full w-full">
                <DiseaseAnalysisProcessView
                    updateNextFlow={updateNextFlow}
                    steps={steps}
                    onloadInfoBeingExam={onloadInfoBeingExam}
                    onChangeProcessBar={onChangeProcessBar}
                    setSelectFlow={setSelectFlow}
                    medicalExaminationId={informationBeingExam?.medicalExaminationId as number}
                    formRef={formRef}
                    showConfirm={showConfirm}
                    diseaseAnalysisProcess={state.diseaseAnalysisProcess}
                    diseaseAnalysisProcessCurrent={diseaseAnalysisProcessCurrent}
                    loadDiseaseAnalysis={loadDiseaseAnalysis}
                    optionsMedicalMedicalPictureLayout={state.optionsMedicalMedicalPicture as IComboBox[]}
                    imageFileBeingExam={state.imageFileBeingExam}/>
            </div>}
            {steps?.includes('3') && <div id="3" className="hidden h-full w-full">
                <ExamResultProcessView
                    handleAddonService={handleAddonServiceExtra}
                    onloadInfoBeingExam={onloadInfoBeingExam}
                    onChangeProcessBar={onChangeProcessBar}
                    setSelectFlow={setSelectFlow}
                    onSumbitFlow={onSumbitFlow}
                    medicalExaminationId={informationBeingExam?.medicalExaminationId as number}
                    informationBeingExam={informationBeingExam}
                    formRef={formRefExamResult}
                    diseaseAnalysisProcess={state.diseaseAnalysisProcess}
                    showConfirm={showConfirm}
                    loadDiseaseAnalysis={loadDiseaseAnalysis}
                    optionsMedicalMedicalPictureLayout={state.optionsMedicalMedicalPicture as IComboBox[]}
                    imageFileBeingExam={state.imageFileBeingExam}
                    setStateScan={setStateScan}
                    componentRef={componentRef}
                    infoPatientExam={props.infoPatientExam}
                />
                <div
                    className="hidden"
                >
                    <ExamResultProcessScanView
                        stateScan={stateScan}
                        infoPatientExam={props.infoPatientExam}
                        formRef={formRefExamResultScan}
                        componentRef={componentRef}
                        diseaseAnalysisProcess={state.diseaseAnalysisProcess}
                        optionsMedicalMedicalPictureLayout={state.optionsMedicalMedicalPicture as IComboBox[]}
                        tokenUrl={informationBeingExam?.tokenUrl ?? ''}
                        imageFileBeingExam={state.imageFileBeingExam}/>
                </div>


            </div>}
            {steps?.includes('4') && <div id="4" className="hidden h-full w-full">
                <PrescriptionView
                    getRowData={getRowData}
                    gridRefMedicine={gridRefMedicine}
                    onChangeProcessBar={onChangeProcessBar}
                    setSelectFlow={setSelectFlow}
                    isMedicine={informationBeingExam?.isMedicine}
                    medicineNoteUseCombo={props.medicineNoteUseCombo}
                    medicineNote={medicineNote}
                    showConfirm={showConfirm}
                    infoPatientExam={props.infoPatientExam}
                    medicineGroupCombo={props.medicineGroupCombo}/>
            </div>}
        </>;
    };


    const onCheckChangeImageProcess = () => {
        if (state.imageFileBeingExamViewCreate?.length === 0) {
            return false;
        }
        if (state.imageFileBeingExamViewCreate?.length === imageArrCurrent.current.length) {
            let status = false;
            imageArrCurrent.current.forEach(item => {
                const imageFileCreate = _.cloneDeep(state.imageFileBeingExamViewCreate)?.find(itemFilter => itemFilter.nameImage === item.nameImage);
                if (imageFileCreate?.isTick !== item.isTick) {
                    status = true;
                }
            });
            return status;
        }

        return true;
    };

    const onCheckChangeDiseaseAnalysisProcess = () => {
        const dataForm = formRef.current?.getValues();
        const isChange = !_.isEqual(diseaseAnalysisProcessCurrent.current, dataForm);
        return isChange;

    };

    return (
        <div className="body-content patient-record-process overflow-y-hidden">
            {/*<HeaderPrescription*/}
            {/*    infoPatientExam={props.infoPatientExam}*/}
            {/*    onFilter={() => { }} />*/}

            <ProcessBar
                steps={steps}
                flowValue={props.flowValue}
                informationBeingExam={informationBeingExam}
                oldNumber={oldNumber}
                setSelectFlow={setSelectFlow}
                selectFlow={selectFlow}
                onCheckChangeImageProcess={onCheckChangeImageProcess}
                onCheckChangeDiseaseAnalysisProcess={onCheckChangeDiseaseAnalysisProcess}
                onChangeProcessBar={(value, oldValue) => {
                    onChangeProcessBar(value, oldValue);
                }}/>
            <div className="body-upload-file">
                {renderBodyProcess()}
            </div>
        </div>
    );
};

export default ExamProcessLayout; 
