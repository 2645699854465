import { ColDef } from 'ag-grid-community';

export const ClinicVisitsGridColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 80,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Tên phòng khám',
        field: 'name',
        width: 150,
        tooltipField: 'name',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        tooltipField: 'phone',
        headerClass: 'text-center',
        width: 100,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Tổng số lượt khám còn lại',
        field: 'remainingVisits',
        tooltipField: 'remainingVisits',
        headerClass: 'text-center',
        width: 60,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Tổng số lượt đã khám',
        field: 'numberOfVisits',
        tooltipField: 'numberOfVisits',
        headerClass: 'text-center',
        width: 60,
        cellStyle: { 'text-align': 'start' }
    }

];
