import { Pie } from '@ant-design/plots';
import React, { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { ApiResponse } from '../../../types/api.type';
import { StatisticsStorage } from '../../../types/StatisticsDashboardView.ts/StatisticsDashboard';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import { humanFileSize } from '../../../utils/StringUtil';
import { getStorageStatistic } from '../api/constants';

function StorageDashboardView()
{
    const overlayRef = useRef<OverlayRef>(null);
    const [usedStoreData, setUsedStoreData] = useState<{ label: string, value: number }[]>([]);
    const [categoryData, setCategoryData] = useState<{ label: string, value: number }[]>([]);

    const loadData = async () =>
    {
        overlayRef.current?.open();
        const response = await AxiosClient.get<ApiResponse<StatisticsStorage>>(BASE_API_PATH + getStorageStatistic, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as StatisticsStorage;

            setUsedStoreData(data.memoryChart);
            setCategoryData(data.fileTypeChart);
        }
        overlayRef.current?.close();
    };

    useEffect(() =>
    {
        loadData();
    }, []);
    const renderStatistic = (containerWidth: number, text: any, style: { fontSize: any; lineHeight?: any; }) =>
    {
        const textWidth = 50;
        const textHeight = style.lineHeight || style.fontSize;
        const R = containerWidth / 2;
        // r^2 = (w / 2)^2 + (h - offsetY)^2
        let scale = 1;
        if (containerWidth < textWidth)
        {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
        const textStyleStr = `width:${ containerWidth }px;`;
        return `<div style="${ textStyleStr };font-size:${ scale }em;line-height:${ scale < 1 ? 1 : 'inherit' };">${ text }</div>`;
    };
    const config: any = {
        appendPadding: 10,
        angleField: 'value',
        colorField: 'label',
        radius: 0.9,
        legend: {
            layout: 'vertical',
            position: 'bottom',
            title: ({ percent, value }: any) => `${ (percent * 100).toFixed(0) }% (${ humanFileSize(value, false) })`,
        },
        label: {
            type: 'outer',
            //offset: '-30%',
            content: ({ percent, value }: any) => `${ (percent * 100).toFixed(0) }% (${ humanFileSize(value, false) })`,
            style: {
                fontSize: 14,
                textAlign: 'center',
                margin: 5,
            },
        },
        //interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    };
    return <div className='w-full flex items-center gap-x-40 justify-center'>
        <Pie {...config} data={usedStoreData} />
        <Pie {...config} data={categoryData} />
        <Overlay ref={overlayRef} />
    </div>;
}

export default StorageDashboardView;