import { Col } from 'antd';
import { useEffect, useRef } from 'react';

import AxiosClient from '../../../apis/api/axiosClient';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import { Loading } from '../../../components/common/Loading';
import BaseForm from '../../../components/FormFields/BaseForm';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import FileType from '../../../constants/FileType';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { ExamResultModal } from '../../../types/ExamResult/ExamResult';
import { InformationPatientExam } from '../../../types/PatientRecord/PatientFileRecord';
import { BASE_API_PATH } from '../../../utils/ApiUtil';
import HeaderExamResultProcessScanView from '../../ExamResultProcess/pages/HeaderExamResultProcessScanView';
import { loadInfomationClinic } from '../../Infomation/api/constants';

interface IProps
{
    data: ExamResultModal,
}

const ViewMedicalExamResult = (props: IProps) =>
{
    const { data } = props;
    const overlayRef = useRef<OverlayRef>(null);

    const renderDiseaseList = () =>
    {
        return <div>
            {data?.diseaseAnalysisResults && data?.diseaseAnalysisResults?.map((item, index) =>
            {
                return (<Col
                    key={index}
                    className="mt-1 mb-2">
                    <div className="flex items-center">
                        <div className="font-bold">
                            {item?.diseaseAnalysisName}:&nbsp;
                        </div>
                        <div className="">
                            {item?.content}
                        </div>
                    </div>
                </Col>);
            })}
        </div>;
    };

    const onRenderImage = () =>
    {
        return data?.imageResults.map((item, index) =>
        {
            const fileType = item.fileType;
            return <div key={index} style={{ width: 160, display: 'flex', justifyContent: 'center' }}>
                <div style={{ height: '95%', width: '95%' }} >
                    <div className={'rounded-lg'}
                        style={{ width: '100%', aspectRatio: 'auto' }}
                    >
                        <div className="flex h-full w-full" >
                            <div className="flex flex-col h-full w-full" style={{ borderRadius: '8px', justifyContent: 'center' }}>
                                {FileType.Video.includes(fileType ?? '') ?
                                    <video
                                        style={{ height: '100%', width: '100%' }}
                                        className="bg-white border rounded"
                                        controls
                                    >
                                        <source
                                            src={item.thumbUrl}
                                        />
                                    </video>
                                    : (FileType.Pdf.includes(fileType ?? '')) ?
                                        <a href={item.thumbUrl}
                                            target="_blank"
                                            rel="noreferrer"><img
                                                src={pdfIcon}
                                                style={{ height: '100%', width: '100%' }}
                                                className="bg-white border rounded"
                                                alt="..."
                                            /></a>
                                        : <img
                                            src={item.thumbUrl}
                                            style={{ height: '100%', width: '100%' }}
                                            className="bg-white border rounded"
                                            alt="..."
                                        />
                                }
                            </div>
                        </div>
                        <div className='ml-1 font-bold'>
                            {item.name}
                        </div>
                    </div>
                </div>
            </div>;
        });

    };

    const renderBaseForm = () =>
    {
        return <div className='w-full ' style={{ height: '100%' }}>
            <div style={{ width: '100%' }}>
                <div style={{}} className="ml-0">
                    {renderDiseaseList()}
                </div>
                <div className="flex items-center row" style={{ fontStyle: 'italic' }}>
                    <div className="font-bold">
                        Lịch tái khám:&nbsp;
                    </div>
                    <div>
                        {data?.reExamDate}
                    </div>
                </div>
            </div>
        </div>;
    };
    return (
        <div
            style={{ width: '100%', overflowY: 'scroll' }}
            className='flex flex-col ml-3 mr-3'>
            <div >
                <div className='w-full'>
                    <div style={{}}>
                        <p className="text-xl font-bold" style={{ font: 'Roboto', fontSize: 12, height: 14, flex: 1 }}>{data?.clinicDetail?.name}</p>
                        <p className='text-xs ml-3' style={{ font: 'Roboto', fontSize: 12, height: 14, flex: 1 }}>{data?.clinicDetail?.address}</p>
                    </div>
                    <div className='ml-3' style={{ marginTop: '5px' }}>
                        <p className='flex text-xl font-bold text-[#2c999c]' style={{ justifyContent: 'center', font: 'Roboto', fontSize: 18, height: 17, flex: 1 }}>THÔNG TIN KHÁM BỆNH</p>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className='mb-2' style={{}}>
                <div className="mt-2 justify-between">
                    <div className='text-xl font-bold text-[#2c999c] ml-3 mr-3'>
                        <span style={{ font: 'Roboto', fontSize: 14 }}>THÔNG TIN BỆNH NHÂN</span>
                    </div>
                    <div className='ml-3 mr-3 grid grid-cols-4 mt-0'>
                        <div>
                            <span className='font-bold'>Họ và tên: </span> <span> {data?.infoPatient?.fullName} </span>
                        </div>
                    </div>
                    <div className='ml-3 mr-3 grid grid-cols-4 mt-0'>
                        <div>
                            <span className='font-bold'>Giới tính: </span> <span> {data?.infoPatient?.gender} </span>
                        </div>
                        <div>
                            <span className='font-bold'>Năm sinh: </span> <span> {data?.infoPatient?.yearOfBirth} </span>
                        </div>
                    </div>
                    <div className='ml-3 mr-3 row'>
                        <div>
                            <span className='font-bold'>Địa chỉ: </span> <span> {data?.infoPatient?.location} </span>
                        </div>
                        <div>
                            <span className='font-bold'>Lý do khám: </span> <span>  {data?.infoPatient?.reason} </span>
                        </div>
                    </div>

                </div>
            </div>
            <div className='flex flex-col flex-1  ml-3 mr-3'>
                <div className=' font-bold text-[#2C999C]' style={{ fontSize: 14 }}>
                    MÔ TẢ KẾT QUẢ KHÁM BỆNH
                </div>
                <div className='flex flex-col flex-1 overflow-x-hidden'>
                    <div className='flex flex-col' style={{ width: '100%' }}>
                        {renderBaseForm()}
                        <div className='' style={{ width: '100%' }}>
                            <div className=' font-bold text-[#2C999C] mt-2 mb-2' style={{ fontSize: 14 }}>
                                HÌNH ẢNH KHÁM BỆNH
                            </div>
                            <div style={{ width: '100%' }}>
                                <div className="flex" style={{ height: '100%', width: '100%', flexFlow: 'wrap' }}>
                                    {onRenderImage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default ViewMedicalExamResult;