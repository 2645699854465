import React from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';

interface IHeaderProps
{
    onOpenCreate: () => void;
}

const HeaderDiseaseAnalysisDetail = (props: IHeaderProps) =>
{
    return (
        <div className="flex justify-between">
            <div></div>
            <div className='order-last flex mr-3'>
                <ButtonBase style={{ width: 170 }}
                    onClick={props.onOpenCreate}
                    buttonName={'THÊM PHÂN TÍCH'}
                    buttonType={'create'} />
            </div>

        </div>
    );
};

export default HeaderDiseaseAnalysisDetail; 