import { FieldTimeOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Pagination, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AxiosClient from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import useMergeState from '../../../hooks/useMergeState';
import { ApiResponse, PaginatedList } from '../../../types/api.type';
import { PatientDataDto } from '../../../types/UserManagement/PatientGridDto';
import { renderPatientsGridColumns } from '../../../types/UserManagement/Patients';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { getPatientList, updatePatient } from '../api/constants';
import HeaderFilterPatient from './HeaderFilterPatient';
import UserCreateOrEditForm from './PatientCreateOrEditForm';


interface IState
{
    patient: any;
    loading?: boolean;
}

function PatientList()
{
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const formRefFilter = useRef<BaseFormRef>(null);
    const history = useHistory();

    const [state, setState] = useMergeState<IState>({
        patient: [],
        loading: true,
    });



    const [params, setParams] = useState({
        search: '',
        page: 1,
        size: 15,
    });

    useEffect(() =>
    {
        loadApi();
    }, [params]);

    const loadApi = async () =>
    {

        const response = await AxiosClient.post<any>(BASE_API_PATH + getPatientList, params);
        let allUser: PatientDataDto[] = [];
        if (response.data.success)
        {
            allUser = response.data.results as unknown as PatientDataDto[];

        }
        setState({
            ...state,
            patient: allUser,
        });
    };



    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    const onUpdatePatient = async (value: PatientDataDto) =>
    {
        const params = {
            id: value.id,
            fullName: value.fullName,
            yearOfBirth: value.yearOfBirth,
            gender: value.gender,
            location: value.location,
            provinceId: value.provinceId,
            districtId: value.districtId,
        };
        const response = await AxiosClient.put<ApiResponse<PatientDataDto>>(BASE_API_PATH + updatePatient, params);
        if (response.data.success)
        {
            await loadApi();
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            modalRef.current?.onClose();
        } else
        {
            ApiUtil.ToastError(response.data.message);

        }
    };

    // const handleActivateUser = async (id: number, isActivated: boolean) =>
    // {
    //     const params = {
    //         userId: id,
    //         activated: isActivated
    //     };
    //     const response = await AxiosClient.post<ApiResponse<PatientDataDto>>(BASE_API_PATH + activateUser, params);
    //     if (response.data.success)
    //     {
    //         await loadApi();
    //         ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
    //         modalRef.current?.onClose();
    //     } else
    //     {
    //         ApiUtil.ToastError(response.data.message);

    //     }
    // };

    const handleOpenUpdateModal = (data: PatientDataDto) =>
    {
        modalRef.current?.onOpen(<UserCreateOrEditForm handleSubmit={onUpdatePatient}
            defaultValue={data}
            onClose={onCloseModal}
            isEdit />, 'Thông tin bệnh nhân', 25);
    };


    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...renderPatientsGridColumns(params.page, state?.patient?.limit),
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<PatientDataDto>) =>
                {
                    const data = params.data as PatientDataDto;
                    // const user = data.roles?.find((item: UserRole) => item.name === 'PARTNER');
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <GridButtonBase type={'edit'}
                                onClick={() => { handleOpenUpdateModal(data); }} />
                            <Tooltip title='Lịch sử khám'>
                                <div
                                    onClick={() =>
                                        history.push(`/medical-examination-history/${ data?.id }`)
                                    }
                                    className=' flex w-11 h-9 text-white bg-[#2C999C] hover:!bg-[#2C999C] focus:!bg-[#2C999C] font-medium rounded-lg mr-2 mb-2  justify-center items-center'>
                                    <FieldTimeOutlined className='text-xl leading-[0px]' />
                                </div>
                            </Tooltip >
                            {/* {
                                user?.status ?
                                    <GridButtonBase type={'stop'}
                                        onClick={() =>
                                        {
                                            handleOpenStopModal(data);
                                        }} />
                                    : <GridButtonBase type={'activate'}
                                        onClick={() =>
                                        {
                                            handleActivateUser(data.id, true);
                                        }} />
                            } */}
                        </div >
                    );
                },
            }
        ];
    };

    const onFilter = async () =>
    {
        overlayRef.current?.open();
        const dataFilter: any = formRefFilter.current?.getValues();

        const paramsSearch = {
            ...params,
            search: dataFilter.search,


        };
        const response = await AxiosClient.post<ApiResponse<PaginatedList<PatientDataDto>>>(BASE_API_PATH + getPatientList, paramsSearch);
        let allUser: PatientDataDto[] = [];
        if (response.data.success)
        {
            allUser = response.data.results as unknown as PatientDataDto[];
            setState({
                ...state,
                patient: allUser
            });
        }
        overlayRef.current?.close();

    };

    const ClinicHeader = () =>
    {
        return <HeaderFilterPatient
            onLoadDataGrid={onFilter}
            formRefFilter={formRefFilter}
        />;
    };






    const renderGrid = () =>
    {
        return <div className='mt-2 flex flex-col items-center gap-4 h-[75%]' >
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                columnDefs={getColumnConfig()}
                suppressRowTransform={true}
                pagination={false}
                suppressClickEdit={true}
                rowData={state.patient?.items}
            />
            <PaginationCustom total={state?.patient?.totalCount || 0}
                pageSize={state?.patient?.limit || 0}
                onChange={(value: number) =>
                {
                    setParams({
                        ...params,
                        page: value
                    });

                }}
                current={params.page}
            />
        </div>;
    };

    return (
        <div
            className='overflow-y-hidden'
            style={{ backgroundColor: '#fff', height: '100%', width: '100%' }}
        >
            <div className='h-full'>
                {ClinicHeader()}
                {renderGrid()}
            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>
    );
}

export default PatientList;

const PaginationCustom = styled(Pagination)`
  & .ant-pagination-item-link{
    display: flex;
    justify-content: center;
    align-items: center
}
`;