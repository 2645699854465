import '../style/auto-send.scss';

import { Tabs } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import { Loading } from '../../../components/common/Loading';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../../constants/MessageConstants';
import { useAppDispatch } from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import AutoCancleExamForm from '../../../module/AutoCancleExam/AutoCancleExamForm';
import AutoSendNotificationForm from '../../../module/AutoSendNotification/AutoSendNotificationForm';
import InfomationUpdate from '../../../module/Infomation/InfomationUpdate';
import { ApiResponse } from '../../../types/api.type';
import { AutoCancle, AutoSend, AutoSendUpdate } from '../../../types/AutoSend/AutoSend';
import { Clinic } from '../../../types/Clinic';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { indexAutoSendNotification, indexAutoSendSms, indexCancleExamBeing, updateAutoSendNotification, updateAutoSendSms, updateCancleExamBeing } from '../api/constants';

interface IState
{
    infoAutoSendNoti: AutoSend;
    infoAutoSendSms: AutoSend;
    infoAutoCancle: AutoCancle;

    loading: boolean
}
const { TabPane } = Tabs;

const AutoSendNotificationView = () =>
{
    const overlayRef = useRef<OverlayRef>(null);
    const formRefNoti = useRef<BaseFormRef>(null);
    const formRefSms = useRef<BaseFormRef>(null);
    const formRefExam = useRef<BaseFormRef>(null);

    const [state, setState] = useMergeState<IState>({
        infoAutoSendNoti: {},
        infoAutoSendSms: {},
        infoAutoCancle: {},
        loading: true
    });
    useEffect(() =>
    {
        loadApi();
    }, []);

    const loadApi = async () =>
    {
        await loadInfoAutoSend();
    };

    const loadInfoAutoSend = async () =>
    {
        Axios.all([
            await AxiosClient.get<ApiResponse<AutoSend>>(BASE_API_PATH + indexAutoSendNotification, { withCredentials: true }),
            await AxiosClient.get<ApiResponse<AutoSend>>(BASE_API_PATH + indexAutoSendSms, { withCredentials: true }),
            await AxiosClient.get<ApiResponse<AutoCancle>>(BASE_API_PATH + indexCancleExamBeing, { withCredentials: true })
        ]).then(axios.spread((resNoti, resSms, resSmsCancle) =>
        {
            let infomationNoti: AutoSend = {};
            let infomationSms: AutoSend = {};
            let infomationAutoCancle: AutoCancle = {};

            if (resNoti.data.success)
            {
                infomationNoti = resNoti.data.results as unknown as AutoSend;
            }
            if (resSms.data.success)
            {
                infomationSms = resSms.data.results as unknown as AutoSend;
            }
            if (resSmsCancle.data.success)
            {
                infomationAutoCancle = resSmsCancle.data.results as unknown as AutoCancle;
            }

            setState({
                ...state,
                infoAutoSendNoti: infomationNoti,
                infoAutoSendSms: infomationSms,
                infoAutoCancle: infomationAutoCancle,
                loading: false
            });


        }));
    };



    const onSaveInfomationNotiAutoSend = async (value: AutoSend) =>
    {
        const params: AutoSendUpdate = {
            autoSend: value.numberDaysBeforeSending !== '0' ? true : false,
            clinicId: value.clinicId,
            numberDaysBeforeSending: value.numberDaysBeforeSending
        };
        const url = updateAutoSendNotification;
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + url, params, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };
    const onSaveInfomationSmsAutoSend = async (value: AutoSend) =>
    {
        const params: AutoSendUpdate = {
            autoSend: value.numberDaysBeforeSending !== '0' ? true : false,
            clinicId: value.clinicId,
            numberDaysBeforeSending: value.numberDaysBeforeSending
        };
        const url = updateAutoSendSms;
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + url, params, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };

    const onSaveInfomationAutoCancle = async (value: AutoCancle) =>
    {

        const params: AutoCancle = {
            numberTimeCancle: parseInt(value.numberTimeCancle as string),
            clinicId: value.clinicId,
            isCancle: value.isCancle
        };
        const url = updateCancleExamBeing;
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + url, params, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };



    if (state.loading) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;

    return (
        <div className='overflow-hidden flex flex-col' style={{ width: '100%', height: '100%' }}>
            <div className="flex mt-6 justify-between" style={{ height: '50px' }}>
                <div className='text-xl font-bold text-[#2c999c]  ml-3'>
                    CẤU HÌNH TỰ ĐỘNG
                </div>
            </div>
            <div className='flex-1 ml-2'>
                <Tabs className='w-full h-full'>
                    <TabPane
                        className='w-full h-full'
                        tab="Gửi thông báo"
                        key="1">
                        <AutoSendNotificationForm
                            formRef={formRefNoti}
                            handleSubmit={onSaveInfomationNotiAutoSend}
                            defaultvalue={state.infoAutoSendNoti}
                        />
                    </TabPane>
                    {/* <TabPane tab="Gửi tin nhắn" key="2">
                        <AutoSendNotificationForm
                            formRef={formRefSms}
                            handleSubmit={onSaveInfomationSmsAutoSend}
                            defaultvalue={state.infoAutoSendSms}
                        />
                    </TabPane> */}
                    <TabPane tab="Tự động hủy luồng khám" key="3">
                        <AutoCancleExamForm
                            formRef={formRefExam}
                            handleSubmit={onSaveInfomationAutoCancle}
                            defaultvalue={state.infoAutoCancle}
                        />
                    </TabPane>
                </Tabs>

            </div>
            <Overlay ref={overlayRef} />

        </div>

    );
};

export default AutoSendNotificationView;