import '../../../components/style/login-page.scss';
import '../../../components/style/login-page.scss';

import { Avatar, Button, Checkbox, Form, Input } from 'antd';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import appleStoreImg from '../../../assets/images/apple-store.png';
import googlePlayImg from '../../../assets/images/google-play.png';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { useAppDispatch, useAppSelector } from '../../../hooks/hook';
import { Clinic } from '../../../types/Clinic';
import { authAction, selectIsLogging } from '../AuthSlice';

interface IFormLogin
{
    username: string;
    password: string;
    remember: boolean;
}


const LoginPage = () =>
{
    const dispatch = useAppDispatch();
    const logging = useAppSelector(selectIsLogging);

    const [cookies] = useCookies();
    const history = useHistory();
    const overlayRef = useRef<OverlayRef>(null);
    const [clinicInfo, setClinicInfo] = useState<Clinic>({});

    useEffect(() =>
    {
        const isLoggedIn = Boolean(cookies['dev2earn-clinic']);
        const goToHomePage = () => history.push({
            pathname: '/patient-record',
        });
        if (isLoggedIn) goToHomePage();
        const clinic = JSON.parse(localStorage.getItem('ClinicInfo') ?? '{}');
        setClinicInfo(clinic);
    }, []);

    // useEffect(() =>
    // {
    //     if (logging)
    //     {
    //         overlayRef.current?.open();
    //     } else
    //     {
    //         overlayRef.current?.close();
    //     }

    // }, [logging]);



    const onSubmitLogin = (async (value: IFormLogin) =>
    {
        const userName = value.username;
        const password = value.password;
        dispatch(authAction.login({ userName: userName, password: password }));
    });

    return (
        <div className="login-page-content row">
            <img src={'/background-login.jpg'} alt="" />
            <div className="box-background ">
                <div className="box-content-left">
                    <div className="content-top">
                        <div className="title-top text-center">
                            {clinicInfo?.name && clinicInfo?.name !== '' ? clinicInfo?.name : 'VNMEDIC'}
                        </div>
                        <div className="information">
                            <span style={{ display: 'block' }}>
                                {clinicInfo?.clinicCode && clinicInfo?.clinicCode !== '' ? clinicInfo?.clinicCode : 'PHẦN MỀM PHÒNG KHÁM BÁC SĨ CHUYÊN KHOA'}
                            </span>
                            <span style={{ display: 'block' }}>
                                {clinicInfo?.address && clinicInfo?.address !== '' ? `Địa chỉ: ${ clinicInfo?.address }` : ''}
                            </span>
                            <span style={{ display: 'block' }}>
                                {clinicInfo?.phone && clinicInfo?.phone !== '' ? `Điện thoại: ${ clinicInfo?.phone }` : ''}
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ display: 'block', textAlign: 'center' }}>Ứng dụng mobile VNMEDIC đã có trên Apple Store và GooglePlay</span>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto' }}>
                            <div style={{ width: '50%', margin: 'auto' }}>
                                <img src={appleStoreImg} style={{ width: 120, margin: 20 }}></img>
                            </div>
                            <div style={{ width: '50%', margin: 'auto' }}>
                                <img src={googlePlayImg} style={{ width: 120, margin: 20 }}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-form-login">
                <div className="box-content-right">
                    <div className="title-top">
                        <span>PHẦN MỀM PHÒNG KHÁM BÁC SĨ CHUYÊN KHOA</span>
                    </div>
                    <div className='border-avt-circle'>
                        <Avatar size={140} src={'/logo.png'} />
                    </div>
                    <div className="title-login">
                        <span>ĐĂNG NHẬP</span>
                    </div>
                    <div className="body-form-login">
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}
                            onFinish={onSubmitLogin}
                            // onFinishFailed={onFinishFailed}

                            autoComplete="off"
                        >
                            <Form.Item
                                className="container-input-user"
                                label="Tên đăng nhập"
                                name="username"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required={false}
                                rules={[
                                    { required: true, message: 'Tên đăng nhập' },
                                ]}
                            >
                                <Input placeholder='Nhập tài khoản' />
                            </Form.Item>

                            <Form.Item
                                className="container-input-password"
                                label="Mật khẩu"
                                name="password"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required={false}
                                rules={[{ required: true, message: 'Mật khẩu' }]}
                            >
                                <Input.Password placeholder='Nhập mật khẩu' />
                            </Form.Item>

                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                wrapperCol={{ offset: 8, span: 16 }}
                            >
                                <Checkbox>Nhớ mật khẩu</Checkbox>
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{ offset: 8, span: 16 }}
                                className="button-login"
                            >
                                <Button type="primary" htmlType="submit">
                                    Đăng nhập
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <Overlay ref={overlayRef} />

        </div >
    );
};
export default LoginPage;
