import { Col, Form, Row } from 'antd';
import React from 'react';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import BaseForm from '../../../components/FormFields/BaseForm';
import TextField from '../../../components/FormFields/TextField';
import { DiseaseAnalysisFilter } from '../../../types/DiseaseAnalysis/DiseaseAnalysis';

interface IFilterProps
{
    onLoadData: (value: DiseaseAnalysisFilter) => Promise<void>;
}

const FilterGrid = (props: IFilterProps) =>
{
    const { onLoadData } = props;

    return (
        <div className="flex justify-between w-100">
            <BaseForm ref={null}
                onSubmit={(value) => { onLoadData(value); }}
                style={{ width: '100%' }}
                className="flex"
                defaultValues={{}} >
                <Row className="flex m-1"
                    gutter={30}
                    style={{ width: '50%' }}>
                    <Col span={40} className="flex-1">
                        <Form.Item>
                            <TextField labelPositison={'left'}
                                labelStyle={{ width: 110, justifyContent: 'center', alignItems: 'end', display: 'flex' }}
                                name={'name'}
                                label={'Mô tả KQ'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="flex m-1"
                    gutter={30}
                    style={{ width: '42%' }}>
                    <Col span={28} className="flex-1">
                        <Form.Item>
                            <TextField labelPositison={'left'}
                                labelStyle={{ width: 110, justifyContent: 'center', alignItems: 'end', display: 'flex' }}
                                name={'content'}
                                label={'Phân tích bệnh'} />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="footer ">
                    <ButtonBase style={{ width: 110 }}
                        buttonName={'TÌM KIẾM'}
                        buttonType="filter"
                        htmlType="submit" />
                </div>
            </BaseForm>
        </div>
    );
};

export default FilterGrid; 