import './../Style/aggrid.scss';

import { ColDef } from 'ag-grid-community';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import React from 'react';

import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import { Clinic } from '../../../types/Clinic';
import { MedicineResultScanColumns, MedicneResultDto } from '../../../types/ExamResult/MedicineResult';

interface Props
{
    medicineResult: MedicneResultDto
    componentRef: React.RefObject<HTMLDivElement>
}

const MedicineResultFormScan: React.FC<Props> = (props) =>
{
    const { medicineResult, componentRef } = props;
    const [height, setHeight] = useState<number>(0);
    const [clinicInfo, setClinicInfo] = useState<Clinic>({});

    useEffect(() =>
    {
        onSetHeightGrid();
        const clinic = JSON.parse(localStorage.getItem('ClinicInfo') ?? '{}');
        setClinicInfo(clinic);
    }, []);

    const onSetHeightGrid = () =>
    {
        const valueTotal = medicineResult.medicineResultGrid?.length as number;
        let numberMultip = Math.floor((valueTotal) / 10);
        if (numberMultip < 1) numberMultip = 1;
        const numberResidual = (valueTotal) % 10;
        if (numberResidual > 0 && numberMultip > 1) numberMultip++;
        const heightNew = 950 * numberMultip;
        console.log('🚀 ~ file: MedicineResultFormScan.tsx:42 ~ heightNew:', heightNew);
        setHeight(heightNew);
    };

    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...MedicineResultScanColumns,
        ];
    };

    const renderItems = () =>
    {
        return <div className='flex flex-col'
            style={{}}
        >
            <div className="row m-0">
                <div className='text-center ' style={{ fontSize: '14pt' }}>THUỐC ĐIỀU TRỊ</div>
            </div>
            <div style={{ padding: '5px' }}>
                {medicineResult.medicineResultGrid?.map((item, index) => (
                    <div key={index} className='row'>
                        <div className='display-block' style={{ fontWeight: 'bold', }}>{index + 1}. {item.medicineName}</div>
                        <div className='display-block' style={{ fontStyle: 'italic', }}>- Số lượng: {item.quantity}</div>
                        <div className='display-block' style={{ fontStyle: 'italic', }}>- Cách dùng: {item.noteUseMedicinePlus}</div>
                        <div className='display-block'></div>
                    </div>
                ))}
            </div>
            {medicineResult.noteMedicine ? <div className='flex justify-between' style={{}}>
                <div className="row m-0">
                    <div className='text-center ' style={{ height: '48px' }}>&nbsp;</div>
                </div>
                <div className="flex aligns-center items-center" style={{ width: '100%' }}>
                    <p className='font-bold'>Chú ý:  </p>
                    <p className='ml-1' style={{ fontStyle: 'italic', }}> {medicineResult.noteMedicine}</p>
                </div>
            </div> : <div style={{ height: 5 }}></div>}
        </div>;
    };
    return (
        <div
            className='overflow-y-hidden'
            ref={componentRef}
            style={{ backgroundColor: '#fff', marginRight: '20px', marginLeft: '20px', paddingTop: 20, paddingBottom: 120 }}
        >
            <div
                style={{}}
                className='flex flex-col'>

                {/* <HeaderMedicineResult
                    isScan={false}
                    infoPatientExam={medicineResult?.infoPatient as InfoPatientFileResulExamDto}
                /> */}
                {/* <div className="row m-0">
                    <div className='text-left ' style={{ fontWeight: 'bold', fontSize: '13pt' }}>Phòng khám đa khoa</div>
                    <div className='text-left display-block' style={{}}>Mã PK: VNM-0001</div>
                    <div className='text-left display-block' style={{}}>Địa chỉ: VNM-0001</div>
                </div> */}
                <div className="row m-0">
                    <div className=' ' style={{ fontWeight: 'bold', fontSize: '11pt' }}>Mã đơn thuốc: <span style={{ fontWeight: 'normal' }}></span></div>
                    <div className=' ' style={{ fontWeight: 'bold', fontSize: '11pt' }}>Tên đơn vị: <span style={{ fontWeight: 'normal' }}>{clinicInfo?.name}</span></div>
                    <div className=' ' style={{ fontWeight: 'bold', fontSize: '11pt' }}>Số điện thoại: <span style={{ fontWeight: 'normal' }}>{clinicInfo?.phone}</span>  Địa chỉ: <span style={{ fontWeight: 'normal' }}>{clinicInfo?.address}</span></div>
                </div>
                <div className="row m-0">
                    <div className='text-center ' style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '18pt' }}>ĐƠN THUỐC</div>
                </div>
                <div className="row m-0">
                    <div className="col-12 text-start">
                        <div className="row">
                            <span className="" style={{}}>Họ và tên: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.fullName}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Năm sinh: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.yearOfBirth}</span>
                            <span className="" style={{}}>&nbsp;&nbsp;</span>
                            <span className="" style={{}}>Cân nặng: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>......................</span>
                            <span className="" style={{}}>&nbsp;&nbsp;</span>
                            <span className="" style={{}}>Giới tính: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.gender}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Số thẻ BHYT (nếu có): ..........................................</span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.location}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Địa chỉ liên hệ: </span>
                            <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.location}</span>
                        </div>
                        <div className="row">
                            <span className="" style={{}}>Chẩn đoán: ............................................................</span>
                            {/* <span className="" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{medicineResult?.infoPatient?.reason}</span> */}
                        </div>
                    </div>
                </div>
                {renderItems()}
                <div className="row">
                    <span className="" style={{}}>Lời dặn: {medicineResult.noteMedicine ? medicineResult.noteMedicine : '.............................'}</span>
                </div>
            </div>
            {medicineResult?.tokenUrl && medicineResult?.tokenUrl != '' &&
                (<div style={{
                    position: 'absolute',
                    bottom: 0,
                }}>
                    <QRCode
                        id='qrcode'
                        value={medicineResult?.tokenUrl ?? ''}
                        style={{ height: '100px', width: '100px' }}
                        level={'H'}
                        includeMargin={true}
                    />
                    <span style={{ fontStyle: 'italic' }}>Quét mã QR hoặc tải ứng dụng VNMedic để xem chi tiết</span>
                </div>)}
        </div>

    );
};

export default MedicineResultFormScan;