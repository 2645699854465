import { yupResolver } from '@hookform/resolvers/yup';
import { Col, DatePicker, Form, Row, Space, TimePicker } from 'antd';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { Loading } from '../../components/common/Loading';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox } from '../../components/FormFields/ComboBox';
import NumberField from '../../components/FormFields/NumberField';
import RadioField from '../../components/FormFields/RadioField';
import TextField from '../../components/FormFields/TextField';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { optionGender } from '../../constants/OptionsCombo';
import { pathClock, pathDateTime } from '../../constants/SvgIcon';
import { comboDistrict } from '../../features/Infomation/api/constants';
import { ApiResponse } from '../../types/api.type';
import { PatientFileRecord, PatientFileUpdateRecord } from '../../types/PatientRecord/PatientFileRecord';
import { PatientRecord } from '../../types/PatientRecord/PatientRecord';
import { BASE_API_PATH } from '../../utils/ApiUtil';

const schema = yup.object().shape({
    reason: yup.string().required('Vui lòng nhập thông tin!')
});

interface IPatienCareProps
{
    handleSubmit: (value: PatientFileUpdateRecord) => Promise<void>;
    onClose: () => void,
    defaultvalue: PatientFileRecord,
    optionsProvince: IComboBox[];
}

const PatientFileUpdate: FunctionComponent<IPatienCareProps> = (props) =>
{

    const { defaultvalue, handleSubmit, optionsProvince } = props;
    const overlayRef = useRef<OverlayRef>(null);
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>([]);
    const formRef = useRef<BaseFormRef>(null);
    useEffect(() =>
    {
        if (defaultvalue.provinceId)
        {
            loadOptionsDistrict(defaultvalue.provinceId);
        }
    }, []);

    const suffixDateTimeFilter = () =>
    {
        return <svg fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path d={pathDateTime} fill="#65676B" />
        </svg>;
    };
    const sufficClock = () =>
    {
        return <svg fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 h-3 sm:w-4 sm:h-4"
            viewBox="0 0 20 20">
            <path fillRule="evenodd"
                clipRule="evenodd"
                d={pathClock}
                fill="#65676B" />
        </svg>;
    };
    const onChangeProvince = (value: string) =>
    {
        const dataForm = formRef.current?.getValues();
        loadOptionsDistrict(value);
        formRef.current?.setValues({ ...dataForm, districtId: '' });
    };
    const onSubmit = async (value: PatientRecord) =>
    {
        overlayRef.current?.open();
        await handleSubmit(value);
        overlayRef.current?.close();
    };


    const loadOptionsDistrict = async (value: string) =>
    {
        const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrict + value);
        if (response.data.success)
        {
            const data = response.data.results as unknown as IComboBox[];
            setOptionsDistrict(data);
        }
    };
    if (optionsDistrict?.length === 0 && defaultvalue.provinceId) return <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading />
    </div>;
    return (
        <BaseForm ref={formRef}
            defaultValues={defaultvalue}
            onSubmit={(value) => { onSubmit(value); }}
            className="p-3"
            resolver={yupResolver(schema)} >
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '70%', paddingRight: 0 }}>
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            disabled={true}
                            requiredError={true}
                            name={'fullName'}
                            label={'Họ và tên'} />
                    </Form.Item>
                </Col>
                <Col style={{ width: '30%', padding: 0 }}>
                    <Form.Item>
                        <RadioField noUpperCaseLabel={true}
                            disabled={true}
                            requiredError={true}
                            comboValue={optionGender}
                            name={'genderValue'}
                            label={'Giới tính'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '50%' }}>
                    <Form.Item style={{ marginLeft: '10px' }}
                    >
                        <label className='text-xs mr-2 mb-2 block tracking-wide text-gray-700 font-bold'>Năm sinh</label>

                        <Controller
                            name={'yearOfBirth'}
                            render={({ field }) =>
                            {
                                return <DatePicker
                                    {...field}
                                    placeholder="Chọn năm sinh"
                                    disabled={true}
                                    suffixIcon={null}
                                    disabledDate={(current) =>
                                    {
                                        return current && current > moment().endOf('day');
                                    }}
                                    style={{ width: '100%', borderRadius: '7px' }}
                                    picker="year"
                                />;
                            }
                            }
                        />
                    </Form.Item>
                </Col>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            disabled={true}
                            requiredError={true}
                            name={'phone'}
                            label={'Số điện thoại'} />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <ComboBox
                            noUpperCaseLabel={true}
                            onChange={onChangeProvince}
                            comboValue={optionsProvince}
                            disabled={true}
                            name={'provinceId'}
                            label={'Thành phố/Tỉnh'} />

                    </Form.Item>
                </Col>
                <Col style={{ width: '50%' }}>
                    <Form.Item>
                        <ComboBox
                            noUpperCaseLabel={true}
                            comboValue={optionsDistrict as IComboBox[]}
                            disabled={true}
                            name={'districtId'}
                            label={'Quận/Huyện'} />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '100%' }} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            disabled={true}
                            name={'location'}
                            label={'Địa chỉ bệnh nhân'} />
                    </Form.Item>

                </Col>
            </Row>
            <Row className="flex m-1" gutter={24}>
                <Col style={{ width: '100%' }} className="flex-1">
                    <div className='ml-2 mb-4 font-semibold'>Thời gian đặt lịch</div>

                </Col>
            </Row>
            <Row className="flex" gutter={24}>

                {/* <Col style={{ marginTop: '-4px', width: '20%' }} className="">
                    <Form.Item>
                        <NumberField noUpperCaseLabel={true}
                            name={'order'}
                            label={'STT khám'} />
                    </Form.Item>
                </Col> */}
                <Col style={{ marginTop: '-8px', width: '50%' }} className="">
                    <Form.Item style={{ marginLeft: '10px' }}
                    >
                        <label className='text-xs mb-2 block tracking-wide text-gray-700 font-bold'>Khung giờ</label>
                        <Controller
                            name={'startTimeBook'}
                            render={({ field }) =>
                            {
                                return <Space direction="vertical" size={14} >
                                    <TimePicker
                                        {...field}
                                        placeholder='Chọn thời gian khám'
                                        format='HH:mm'
                                        style={{ width: '200px', borderRadius: '7px' }}
                                        suffixIcon={sufficClock()}
                                    />
                                </Space>;
                            }
                            }
                        />
                    </Form.Item>
                </Col>
                <Col style={{ marginTop: '-4px', width: '50%' }} className="">
                    <Form.Item style={{ marginLeft: '10px', marginRight: '10px' }}
                    >
                        <label className='text-xs mb-2 blocks tracking-wide text-gray-700 font-bold'>Ngày</label>
                        <Controller
                            name={'dateBook'}
                            render={({ field }) =>
                            {
                                return <DatePicker
                                    {...field}
                                    placeholder='Chọn ngày khám'
                                    suffixIcon={suffixDateTimeFilter()}
                                    style={{ borderRadius: '7px', width: '100%' }}
                                    format='DD/MM/YYYY'
                                />;
                            }
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={24} className="flex-1">
                    <Form.Item>
                        <TextField noUpperCaseLabel={true}
                            requiredError={true}
                            name={'reason'}
                            label={'Lý do khám'} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="footer flex justify-center items-center mt-4">
                <ButtonBase style={{ width: 110 }}
                    className="mr-2"
                    buttonName={'Hoàn tất'}
                    buttonType="save"
                    htmlType="submit" />
            </div>
            <Overlay ref={overlayRef} />

        </BaseForm>
    );
};

export default PatientFileUpdate; 
