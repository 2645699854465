import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Select, TimePicker } from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import CustomModal, { ModalRef } from '../../components/CustomModal/CustomModal';
import { IComboBox, IComboBoxAntd } from '../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../components/Overlay/Overlay';
import { UPDATE_TO_FAIL, UPDATE_TO_SUCCESS } from '../../constants/MessageConstants';
import { pathClock } from '../../constants/SvgIcon';
import { getTimeWorkClinic, updateTimeWorkClinic } from '../../features/Infomation/api/constants';
import useMergeState from '../../hooks/useMergeState';
import { ApiResponse } from '../../types/api.type';
import { TimeClinicDto } from '../../types/Clinic/ClinicGridDto';
import { ApiUtil, BASE_API_PATH } from '../../utils/ApiUtil';


const comboxDay: IComboBoxAntd[] = [
    { label: 'Thứ 2', value: '1', disabled: false },
    { label: 'Thứ 3', value: '2', disabled: false },
    { label: 'Thứ 4', value: '3', disabled: false },
    { label: 'Thứ 5', value: '4', disabled: false },
    { label: 'Thứ 6', value: '5', disabled: false },
    { label: 'Thứ 7', value: '6', disabled: false },
    { label: 'Chủ nhật', value: '7', disabled: false },
];

interface IState
{
    comboDay: IComboBoxAntd[];
    disableButton: boolean;

}


// const TimeClinicForm = (props: IInfomationProps) =>
const TimeClinicForm = () =>
{
    const modalRef = useRef<ModalRef>(null);
    const formRef = useRef<FormInstance<any>>(null);
    const overlayRef = useRef<OverlayRef>(null);

    const [form] = Form.useForm();

    // const [comboDay, setComboDay] = useState<IComboBoxAntd[]>(comboxDay);
    // const [disableButton, setSisableButton] = useState<boolean>(false);


    const [state, setState] = useMergeState<IState>({
        comboDay: comboxDay,
        disableButton: false
    });

    const sufficClock = () =>
    {
        return <svg fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 h-3 sm:w-4 sm:h-4"
            viewBox="0 0 20 20">
            <path fillRule="evenodd"
                clipRule="evenodd"
                d={pathClock}
                fill="#65676B" />
        </svg>;
    };
    const iconPlus: React.ReactNode = <PlusCircleOutlined style={{ fontSize: '125%' }} className='mr-1' />;
    const iconMinus: React.ReactNode = <MinusCircleOutlined style={{ fontSize: '125%' }} className='mr-1' />;

    // const overlayRef = useRef<OverlayRef>(null);
    // const { handleSubmit, optionsProvince, optionsDistinct, loadOptionsDistrict, defaultvalue } = props;
    // const formRef = useRef<BaseFormRef>(null);

    useEffect(() =>
    {
        onLoadTimeClinic();
    }, []);

    const onLoadTimeClinic = async () =>
    {
        overlayRef.current?.open();
        const response = await AxiosClient.get<ApiResponse<TimeClinicDto[]>>(BASE_API_PATH + getTimeWorkClinic, { withCredentials: true });
        if (response.data.success)
        {
            const data = response.data.results as TimeClinicDto[];
            const newValueForm: any[] = data.map(item =>
            {
                const day = item.day;
                const openTimeList = item.openTime;
                const closeTimeList = item.closeTime;
                const timeClinic = openTimeList.map((itemOpenTime, index) =>
                {
                    return {
                        [`dayStart${ index }`]: moment(itemOpenTime, 'x'), //.format('hh:mm'),
                        [`dayEnd${ index }`]: moment(closeTimeList?.[index], 'x')//.format('hh:mm')
                    };
                });
                return {
                    day: day,
                    timeClinic: timeClinic
                };
            });
            formRef.current?.setFieldsValue({ forms: newValueForm });
            onSetDay();
        }
        overlayRef.current?.close();

    };

    const onFinish = async (values: any) =>
    {
        const valueForm = formRef.current?.getFieldValue('forms');
        if (valueForm.length === 0) return ApiUtil.ToastError('Vui lòng thiết lập khung giờ khám!');
        const params: TimeClinicDto[] = [];
        let isCheckTimeOpen = false;
        valueForm.map((item: any) =>
        {
            const openTimeList: string[] = [];
            const closeTimeList: string[] = [];
            item?.['timeClinic'].map((itemTime: any, index: number) =>
            {
                openTimeList.push(itemTime?.[`dayStart${ index }`].valueOf().toString());
                closeTimeList.push(itemTime?.[`dayEnd${ index }`].valueOf().toString());
            });
            if (openTimeList.length === 0) isCheckTimeOpen = true;
            const valueParams: TimeClinicDto = {
                day: item?.['day'] as string[],
                openTime: openTimeList,
                closeTime: closeTimeList
            };
            params.push(valueParams);
        });
        if (isCheckTimeOpen) return ApiUtil.ToastError('Vui lòng thêm giờ hoạt động, vui lòng không được bỏ trống!');
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + updateTimeWorkClinic, params, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess(UPDATE_TO_SUCCESS);
            await onLoadTimeClinic();
        }
        else
        {
            ApiUtil.ToastError(UPDATE_TO_FAIL);
        }
    };

    const onSetDay = () =>
    {
        const valueForm = formRef.current?.getFieldValue('forms');
        let arrayDay: string[] = [];
        valueForm.map((item: any) =>
        {
            arrayDay = arrayDay.concat(item?.['day']);
        });
        const dayCombo = _.cloneDeep(state.comboDay);
        const disableButton = arrayDay.length >= 7 ? true : false;
        const dayComboNew = dayCombo.map(item =>
        {
            if (arrayDay.includes(item.value))
            {
                item.disabled = true;
            }
            else
            {
                item.disabled = false;
            }
            return item;
        });
        setState({
            comboDay: dayComboNew,
            disableButton: disableButton
        });

        // setComboDay(dayComboNew);
    };

    const onCheckTimeClose = (value: Moment | null, indexParent: number, indexValue: number) =>
    {
        if (value != null)
        {
            const valueForm = formRef.current?.getFieldValue('forms');
            const valueDayStart = valueForm?.[indexParent]?.['timeClinic']?.[indexValue]?.[`dayStart${ indexValue }`];

            const onResetValue = () =>
            {
                const updatedForm = _.cloneDeep(valueForm).map((item: any, index: number) =>
                {
                    if (index === indexParent)
                    {
                        const itemTimeClinic = _.cloneDeep(item.timeClinic);
                        const valueIndex = 0;
                        const itemTimeClinicNew = itemTimeClinic.map((itemTime: any, indexTime: number) =>
                        {
                            if (indexTime === indexValue)
                            {
                                const newValue = {
                                    ...itemTime,
                                    [`dayEnd${ indexTime }`]: null
                                };
                                return newValue;
                            }
                            return itemTime;
                        });
                        item.timeClinic = itemTimeClinicNew;
                    }
                    return item;
                });

                formRef.current?.setFieldsValue({ forms: updatedForm });
            };

            if (valueDayStart == null)
            {
                onResetValue();
                return ApiUtil.ToastError('Vui lòng chọn giờ bắt đầu mở cửa!');
            }
            else
            {
                const compareValue = valueDayStart < value;
                if (!compareValue)
                {
                    onResetValue();
                    return ApiUtil.ToastError('Vui lòng chọn giờ đóng cửa sau giờ mở cửa!');

                }
            }
        }

    };

    const onCheckTimeOpen = (value: Moment | null, indexParent: number, indexValue: number) =>
    {
        if (value != null)
        {
            const valueForm = formRef.current?.getFieldValue('forms');
            const valueDayEnd = valueForm?.[indexParent]?.['timeClinic']?.[indexValue]?.[`dayEnd${ indexValue }`];

            const onResetValue = () =>
            {
                const updatedForm = _.cloneDeep(valueForm).map((item: any, index: number) =>
                {
                    if (index === indexParent)
                    {
                        const itemTimeClinic = _.cloneDeep(item.timeClinic);
                        const itemTimeClinicNew = itemTimeClinic.map((itemTime: any, indexTime: number) =>
                        {
                            if (indexTime === indexValue)
                            {
                                const newValue = {
                                    ...itemTime,
                                    [`dayStart${ indexTime }`]: null,
                                    [`dayEnd${ indexTime }`]: null

                                };
                                return newValue;
                            }
                            return itemTime;
                        });
                        item.timeClinic = itemTimeClinicNew;
                    }
                    return item;
                });

                formRef.current?.setFieldsValue({ forms: updatedForm });
            };
            const onResetValueDayEnd = () =>
            {
                const updatedForm = _.cloneDeep(valueForm).map((item: any, index: number) =>
                {
                    if (index === indexParent)
                    {
                        const itemTimeClinic = _.cloneDeep(item.timeClinic);
                        const itemTimeClinicNew = itemTimeClinic.map((itemTime: any, indexTime: number) =>
                        {
                            if (indexTime === indexValue)
                            {
                                const newValue = {
                                    ...itemTime,
                                    [`dayEnd${ indexTime }`]: null

                                };
                                return newValue;
                            }
                            return itemTime;
                        });
                        item.timeClinic = itemTimeClinicNew;
                    }
                    return item;
                });

                formRef.current?.setFieldsValue({ forms: updatedForm });
            };

            if (value > valueDayEnd) onResetValueDayEnd();

            if (indexValue > 0)
            {
                const valueDayEndPre = valueForm?.[indexParent]?.['timeClinic']?.[indexValue - 1]?.[`dayEnd${ indexValue - 1 }`];
                if (valueDayEndPre === null)
                {
                    onResetValue();
                    return ApiUtil.ToastError('Vui lòng chọn giờ đóng cửa ở phía trước!');
                }
                const isCheck = value > valueDayEndPre;
                if (!isCheck)
                {
                    onResetValue();
                    return ApiUtil.ToastError('Vui lòng chọn giờ mở cửa lớn hơn giờ đóng cửa ở trước!');
                }
            }


        }
    };




    const onSearch = (value: string) =>
    {
    };



    return (
        <div style={{ height: '100%' }}>
            <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                form={form}
                ref={formRef}

                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className='flex flex-col flex-1'>
                    <Form.List
                        name="forms"
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <div
                                className="ml-4 mt-2 w-full"
                            >
                                {fields.map((field, index) => (
                                    <div
                                        key={index}
                                        style={{ width: '100%' }}
                                        className="flex mt-4">
                                        <div style={{ width: '50%' }}>
                                            <label
                                                className={
                                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                                }
                                            >
                                                Thứ
                                            </label>
                                            <Form.Item
                                                {...field}
                                                name={[index, 'day']}
                                                rules={[{ required: true, message: 'Vui lòng chọn thứ!' }]}

                                                validateTrigger={['onChange', 'onBlur']}
                                            >

                                                <Select
                                                    showSearch
                                                    placeholder="Chọn thứ"
                                                    optionFilterProp="children"
                                                    mode="multiple"
                                                    style={{
                                                        width: '100%', borderRadius: '8px',
                                                    }}
                                                    allowClear={true}
                                                    onChange={onSetDay}
                                                    onClear={onSetDay}
                                                    onSearch={onSearch}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={state.comboDay}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={{ width: '40%' }}>
                                            <Form.List
                                                name={[field.name, 'timeClinic']}
                                                initialValue={[{ dayStart0: null, dayEnd0: null }]}
                                            >
                                                {(fieldsChildren, { add, remove }, { errors }) => (
                                                    <div className='flex' style={{ width: '100%' }}>
                                                        <div style={{ width: '80%' }}>
                                                            {fieldsChildren.map((fieldTemp, indexTemp) => (
                                                                <div className={(indexTemp > 0 ? 'mt-3 flex' : 'flex')} key={indexTemp}>
                                                                    <div className='ml-3' style={{ width: '40%' }}>
                                                                        {
                                                                            indexTemp > 0 ? <label></label> : <label
                                                                                className={
                                                                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                                                                }

                                                                            >
                                                                                Giờ mở cửa
                                                                            </label>
                                                                        }

                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[indexTemp, `dayStart${ fieldTemp.name }`]}
                                                                            rules={[{ required: true, message: 'Vui lòng chọn giờ!' }]}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            style={indexTemp > 0 ? { marginTop: '-15px' } : {}}
                                                                        >
                                                                            <TimePicker
                                                                                // name={`dayStart${ fieldTemp.name }`}

                                                                                style={{ width: '100%', borderRadius: '7px' }}
                                                                                placeholder='Chọn giờ mở khám'
                                                                                format='HH:mm'
                                                                                onChange={(value) =>
                                                                                {
                                                                                    onCheckTimeOpen(value, index, indexTemp);
                                                                                }}
                                                                                suffixIcon={sufficClock()}
                                                                            />

                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className='ml-4' style={{ width: '40%' }}>
                                                                        {
                                                                            indexTemp > 0 ? <label></label> : <label
                                                                                className={
                                                                                    'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                                                                }
                                                                            >
                                                                                Giờ đóng cửa
                                                                            </label>
                                                                        }
                                                                        <Form.Item
                                                                            {...field}
                                                                            name={[indexTemp, `dayEnd${ fieldTemp.name }`]}
                                                                            rules={[{ required: true, message: 'Vui lòng chọn giờ!' }]}
                                                                            style={indexTemp > 0 ? { marginTop: '-15px' } : {}}
                                                                        >
                                                                            <TimePicker
                                                                                // name={`dayEnd${ fieldTemp.name }`}
                                                                                style={{ width: '100%', borderRadius: '7px' }}
                                                                                placeholder='Chọn đóng cửa khám'
                                                                                format='HH:mm'
                                                                                onChange={(value) =>
                                                                                {
                                                                                    onCheckTimeClose(value, index, indexTemp);
                                                                                }

                                                                                }
                                                                                suffixIcon={sufficClock()}
                                                                            />

                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className={indexTemp > 0 ? 'ml-3' : 'ml-3 mt-5'}
                                                                        style={indexTemp > 0 ? { marginTop: '-15px', width: '3%' } : { width: '3%' }}
                                                                    >

                                                                        <label
                                                                            className={
                                                                                'block tracking-wide text-gray-700 text-xs font-bold mb-2'
                                                                            }
                                                                        >
                                                                        </label>
                                                                        <MinusCircleOutlined
                                                                            className="dynamic-delete-button"
                                                                            onClick={() =>
                                                                            {
                                                                                remove(fieldTemp.name);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            ))}
                                                        </div >
                                                        <div style={{ width: '20%' }}>
                                                            <ButtonBase className='mt-4'
                                                                onClick={() => { add(); }}
                                                                style={{ width: '100%', height: 40, justifyContent: 'center' }}
                                                                buttonName={'Thêm giờ'}
                                                                iconCustom={iconPlus}
                                                                backgroudColorCustom='#4D9FDA'
                                                                buttonType="button_custom"
                                                            />
                                                        </div>

                                                    </div >


                                                )}
                                            </Form.List >
                                        </div >

                                        <div style={{ width: '10%' }}>
                                            <ButtonBase
                                                className='mt-4 mr-1'
                                                onClick={() =>
                                                {
                                                    remove(field.name);
                                                    onSetDay();
                                                }}
                                                style={{ width: '90%', height: 40, justifyContent: 'center' }}
                                                buttonName={'Xóa'}
                                                iconCustom={iconMinus}
                                                backgroudColorCustom='#da4d5a'
                                                buttonType="button_custom"
                                            />
                                        </div>
                                    </div >
                                ))}
                                <Form.Item>

                                    <Button
                                        onClick={() =>
                                        {
                                            add();
                                        }}
                                        disabled={state.disableButton}
                                        style={{
                                            width: '200px',
                                            marginTop: '20px',
                                            backgroundColor: '#fff',
                                            borderColor: '#4D9FDA',
                                            borderRadius: '7px',
                                            color: '#4D9FDA'
                                        }}
                                    >
                                        Thêm thời gian hoạt động
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </div >
                        )}
                    </Form.List >
                </div >

                <div className="footer flex justify-center items-center mt-2" style={{ height: 70 }}>
                    <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                        buttonName={'HOÀN TẤT'}
                        buttonType="save"
                        htmlType="submit" />
                </div>
            </Form >
            <Overlay ref={overlayRef} />

            <CustomModal ref={modalRef} />
        </div >
    );
};

export default TimeClinicForm;