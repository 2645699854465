import { ColDef } from 'ag-grid-community';


	
export const UserAccountSettingGridColumns :ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        maxWidth: 80,
        headerClass:'text-center',
        cellStyle: { textAlign: 'center' },
		
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        headerClass:'text-center',
        tooltipField:'fullName',
        cellStyle: { textAlign: 'start' }
    },
    {
        headerName: 'Email',
        field: 'email',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        headerClass:'text-center',
        tooltipField:'email',
        cellStyle: { textAlign: 'start' }
    },
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        filter: 'agTextColumnFilter',
        headerClass:'text-center',
        tooltipField:'phone',
        floatingFilter: true,
        cellStyle: { textAlign: 'start' }
    },
    {
        headerName: 'Tên đăng nhập',
        field: 'userName',
        tooltipField:'userName',
        headerClass:'text-center',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellStyle: { textAlign: 'start' }
    },
	
    // {
    //     headerName: 'Trạng thái',
    //     field: 'status',
    // }
];